import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback,
} from "reactstrap"
import { Link, useParams, Redirect } from "react-router-dom";
import Select from "react-select"
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import WV from '../../workVariables'
import InputSpinner from "react-bootstrap-input-spinner";
import { useHistory } from "react-router-dom";


const TihTilEdit = (props) => {
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)
    const { id } = useParams()
    const [form, setForm] = useState({
        name: '',
        tih_til: '',
        interval: '',
        part_candle: '',
        comment: '',
        option_tih_til: {},
        option_part_candle: {}
    })

    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const updateItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/tih-til/update`, form)
            .then((response) => {
                // console.log(response.data);
                history.goBack()
                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })

                }, 1000);
                if (response.data.res === "Success") {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.error(error);
            })
        }
    }, [formErrors]); 

    useEffect(() => {
        const getTihtils = async () => {
            const response = await axios.get(`${config.apiUrl}/tih-til/edit/` + id)
            console.log(response.data)

            let optionTihTil = {}
            if (response.data.tih_til === "TIH") {
                optionTihTil.label = 'TIH'
                optionTihTil.value = 'TIH'
            }
            if (response.data.tih_til === "TIL") {
                optionTihTil.label = 'TIL'
                optionTihTil.value = 'TIL'
            }

            let optionPartCandle = {}
            if (response.data.part_candle === "open") {
                optionPartCandle.label = 'Открытия'
                optionPartCandle.value = 'open'
            }
            if (response.data.part_candle === "close") {
                optionPartCandle.label = 'Закрытия'
                optionPartCandle.value = 'close'
            }
            if (response.data.part_candle === "high") {
                optionPartCandle.label = 'High'
                optionPartCandle.value = 'high'
            }
            if (response.data.part_candle === "low") {
                optionPartCandle.label = 'Low'
                optionPartCandle.value = 'low'
            }

            setForm({
                id: response.data.id,
                name: response.data.name,
                tih_til: response.data.tih_til,
                interval: response.data.interval,
                part_candle: response.data.part_candle,
                comment: response.data.comment,
                option_tih_til: optionTihTil,
                option_part_candle: optionPartCandle
            })
        }
        getTihtils()
    }, [id])

    
    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Редактирование TIH / TIL | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Редактирование TIH / TIL #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={updateItem}>
                                                Изменить
                                            </Button>
                                            <Link to='/tih-til' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>                                            
                                        </div>                                       
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                }}
                                            />
                                        </div>
                                        <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                    </Col>
                                    {form.interval &&
                                        <Col xs={12} md={6} xl={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="interval">Период</Label>
                                                <InputSpinner
                                                    value={form.interval}
                                                    name="interval"
                                                    type={"real"}
                                                    precision={0}
                                                    max={1000}
                                                    min={2}
                                                    step={1}
                                                    onChange={(num) => setForm({ ...form, interval: num })
                                                    }
                                                />
                                                {/* <Input
                                                type="number"
                                                className="form-control"
                                                placeholder='Период TIH / TIL...'
                                                id="interval"
                                                value={form.interval}
                                                name="interval"
                                                step={1}
                                                min={0}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            /> */}
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="tihtil">Тип TIH / TIL</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id='tih_til'
                                                placeholder="выбрать..."
                                                options={[
                                                    { label: 'TIH', value: 'TIH' },
                                                    { label: 'TIL', value: 'TIL' },
                                                ]}
                                                classNamePrefix="select2-selection"
                                                name="tih_til"
                                                value={form.option_tih_til}
                                                onChange={(value) => {
                                                    setForm({ ...form, tih_til: value.value, option_tih_til: value })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="partCandle">Применяется к ценам</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id='part_candle'
                                                placeholder="выбрать..."
                                                name="part_candle"
                                                options={[
                                                    { label: 'Открытия', value: 'open' },
                                                    { label: 'Закрытия', value: 'close' },
                                                    { label: 'High', value: 'high' },
                                                    { label: 'Low', value: 'low' },
                                                ]}
                                                classNamePrefix="select2-selection"
                                                value={form.option_part_candle}
                                                onChange={(value) => {
                                                    setForm({ ...form, part_candle: value.value, option_part_candle: value })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                value={form.comment}
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                     
                    </Col>
                </Row>
            </Container>
            {/* {redirect &&
                <Redirect
                    to={{
                        pathname: "/tih-til",
                    }}
                />
            } */}
        </div>
    )
}

export default TihTilEdit