import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback,   
} from "reactstrap"
import { Redirect, useHistory } from "react-router-dom";
import makeAnimated from 'react-select/animated';
import Select from "react-select"
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";
import WV from '../../../workVariables'
const animatedComponents = makeAnimated();


const StrategyAdd = () => {
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)
    const history = useHistory();

    const [form, setForm] = useState({
        name: '',
        name_user: '',
        trade_schemes: '',
        comment: '',
    })

    const [schemes, setSchemes] = useState([]);
       
    const [error, setError] = useState({
        name: false,
        name_user: false
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.name_user == '') errors.name_user = " ";
        if (values.trade_schemes == '') errors.trade_schemes = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
           
            if (form.trade_schemes === '') form.trade_schemes = null;
        else {
            let arrMa = form.trade_schemes;
            for (let i = 0; i < arrMa.length; i++)
                (i === 0) ? form.trade_schemes = arrMa[i].value : form.trade_schemes = form.trade_schemes + ',' + arrMa[i].value
            console.log(form.trade_schemes);

        }
         axios.post(`${config.apiUrl}/ip-strategy-store`, form)
            .then((response) => {
                history.goBack()
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })
                    }, 300);    
            })
            .catch(error => {
                console.error(error);
            })
        }
    }, [formErrors]); 

    useEffect(() => {
        let arraySchemes = [];
        const getSchemes = async () => {
            await axios
                .get(`${config.apiUrl}/trade-schemes`)
                .then(async (response) => {
                    for await (let item of response.data) {
                        let option = {};
                        option.label = item.name;
                        option.value = item.id;
                        arraySchemes.push(option);
                    }
                    setSchemes(arraySchemes);
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        getSchemes();
    }, []);

    

     //белый цвет шрифта при поиске в Select
     const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }

    let errorItem;    
    return (
        <div className="page-content">
            <MetaTags>
                <title>Торговые стратегии | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Добавление Торговой стратегии</h4>
                                        <div>
                                            <Button color="primary me-3" onClick={storeItem}>
                                                Сохранить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                        <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Администратора</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);}}
                                            />
                                             <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Пользователя</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name_user"
                                                value={form.name_user}
                                                name="name_user"
                                                invalid={
                                                    (form.name_user.length === 0 && error.name_user) ||
                                                    formErrors.name_user
                                                }
                                                onChange={(e) => { 
                                                    setError({ ...error, name_user: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name_user
                                                    setFormErrors(errorItem);
                                                    setForm({ ...form, [e.target.name]: e.target.value })}}
                                            />
                                             <FormFeedback invalid> {formErrors.name_user}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                        </Col>
                                        <Col xs={12} md={6} xl={8}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="textarea"
                                                style={{resize: 'none', height: '7.4rem'}}
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>

                                    </div>
                                </CardTitle>
                                <Row> 
                                <Col xs={12} md={12} xl={12}>  
                                        <div className="mb-3">
                                            <Label symbol="maId">Торговая схема</Label>
                                            <Select
                                             theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: `${WV.colorHoverSelect}`,
                                                    primary: `${WV.colorSelected}`,
                                                },
                                            })}
                                            styles={customStyles}
                                                id='maId'
                                                value={form.trade_schemes}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setError({ ...error, trade_schemes: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.trade_schemes
                                                    setFormErrors(errorItem);
                                                    setForm({ ...form, trade_schemes: value })
                                                }}
                                                options={schemes}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.trade_schemes}</p>

                                        </div>
                                        </Col>

                                </Row>
                                
{/* 
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="scheme_id">Подключить торговую схему</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="scheme_id"
                                                name="scheme_id"
                                                placeholder="выбрать..."
                                                onChange={(e) => {
                                                    let el = {}
                                                    el.value = e.value
                                                    el.label = e.label
                                                    setNewSelect(true)
                                                    setSelectScheme([...selectScheme, el])

                                                    let rowList = form.schemesList
                                                    console.log(rowList);
                                                    if (rowList.length === 0) {
                                                        rowList = e.value
                                                        rowList = rowList.toString()
                                                    } else {
                                                        rowList = rowList + ',' + e.value
                                                    }

                                                    console.log(rowList);


                                                    setForm({ ...form, schemesList: rowList })
                                                }}
                                                options={schemes}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="scheme_id">Выбранные Торговые схемы:</Label>
                                            <div className="table-responsive">
                                                <SchemesList listData={selectScheme} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row> */}
                              
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {redirect &&
                <Redirect
                    to={{
                        pathname: "/strategy",
                    }}
                />
            }

        </div>
    )
}

export default StrategyAdd