import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
} from "reactstrap"
import { Link, useParams, Redirect } from "react-router-dom";
import { useState, useEffect } from 'react'
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";
import WV from '../../../workVariables'


const ButtonAction = () => {
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)

    const { command } = useParams()
    const [commandTitle, setCommandTitle] = useState('')
    const [showPeriod, setShowPeriod] = useState(false)

    const [form, setForm] = useState({
        command: '',
        year: '',
        month: '',
        period: ''
    })

    useEffect(() => {
        switch (command) {
            case 'close_period':
                setCommandTitle('Рассчитать период')
                break;
            case 'calc_comission':
                setCommandTitle('Начислить комиссию')
                setShowPeriod(true)
                break;
            case 'show_profit':
                setCommandTitle('Отразить профит в Балансе')
                setShowPeriod(true)
                break;
            case 'show_comission':
                setCommandTitle('Отразить комиссию в Балансе')
                break;
            default:
                break;
        }
        setForm({ ...form, command: command })
    }, command)



    console.log(form);


    const actionSubmit = async () => {
        await axios.post(`${config.apiUrl}/ip-pfb-button-action-core`, form)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Начисления Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>{commandTitle}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                        <Button color="primary" onClick={actionSubmit}>
                                            Выполнить
                                        </Button>
                                        <Link to='/ip-profit-investor' className='btn btn-danger'>
                                            <i className='mdi mdi-backspace' />
                                            &nbsp;
                                            Вернуться
                                        </Link>
                                        </div>                                        
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div>
                                            <Label htmlFor="year">Выберите год: </Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}     
                                                id='year'
                                                placeholder="Выбрать год ..."
                                                options={WV.yaersList}
                                                classNamePrefix="select2-selection"
                                                name="year"
                                                // value={}
                                                onChange={(value) => {
                                                    setForm({ ...form, year: value.value, })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div>
                                            <Label htmlFor="month">Выберите месяц: </Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}     
                                                id='month'
                                                placeholder="Выбрать месяц ..."
                                                options={WV.monthsList}
                                                classNamePrefix="select2-selection"
                                                name="month"
                                                // value={}
                                                onChange={(value) => {
                                                    setForm({ ...form, month: value.value, })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                {showPeriod &&

                                    <Row>
                                        <Col xs={12} md={6} lg={6}>
                                            <div>
                                                <Label htmlFor="period">Выберите период: </Label>
                                                <Select
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: `${WV.colorHoverSelect}`,
                                                            primary: `${WV.colorSelected}`,
                                                        },
                                                    })}
                                                    styles={customStyles}  
                                                    id='period'
                                                    placeholder="Выберите период ..."
                                                    options={WV.periodsList}
                                                    classNamePrefix="select2-selection"
                                                    name="period"
                                                    // value={}
                                                    onChange={(value) => {
                                                        setForm({ ...form, period: value.value, })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }

                            </CardBody>
                        </Card>                     

                    </Col>
                </Row>
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/ip-profit-investor",
                    }}
                />
            }
        </div>
    )

}

export default ButtonAction