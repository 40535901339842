import React from "react";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import WV from "../../workVariables";

const Close1 = (props) => {
  const { close1, setClose1 } = props;
  return (
    <>
      <div className="text-muted flex-grow-1">
        <Row>
          <Col>
            <div className="d-flex gap-2">
              <div className="flex-grow-1">
                <h6 className="text-center">Номер свечи</h6>
                <Select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: `${WV.colorHoverSelect}`,
                      primary: `${WV.colorSelected}`,
                    },
                  })}
                  placeholder="выбрать..."
                  isDisabled={close1.isDisabled}
                  onChange={(value) => {
                    setClose1({ ...close1, num: value.value });
                  }}
                  options={[
                    { label: 1, value: 1 },
                    { label: 2, value: 2 },
                    { label: 3, value: 3 },
                    { label: 4, value: 4 },
                    { label: 5, value: 5 },
                    { label: 6, value: 6 },
                    { label: 7, value: 7 },
                    { label: 8, value: 8 },
                    { label: 9, value: 9 },
                  ]}
                  value={[
                    { label: 1, value: 1 },
                    { label: 2, value: 2 },
                    { label: 3, value: 3 },
                    { label: 4, value: 4 },
                    { label: 5, value: 5 },
                    { label: 6, value: 6 },
                    { label: 7, value: 7 },
                    { label: 8, value: 8 },
                    { label: 9, value: 9 },
                  ].filter((opt, i) => opt.value === close1.num)}
                  classNamePrefix="select2-selection"
                  className="flex-grow-1"
                />
              </div>
              <div className="flex-grow-1">
                <h6 className="text-center">Цвет свечи</h6>
                <Select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: `${WV.colorHoverSelect}`,
                      primary: `${WV.colorSelected}`,
                    },
                  })}
                  placeholder="выбрать..."
                  isDisabled={close1.isDisabled}
                  onChange={(value) => {
                    setClose1({ ...close1, color: value.value });
                  }}
                  options={[
                    { label: "Красная", value: "red" },
                    { label: "Зелёная", value: "green" },
                  ]}
                  value={[
                    { label: "Красная", value: "red" },
                    { label: "Зелёная", value: "green" },
                  ].filter((opt, i) => opt.value === close1.color)}
                  classNamePrefix="select2-selection"
                  className="flex-grow-1"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Close1;
