import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"


const SidebarContent = props => {
    const ref = useRef()
    useEffect(() => {

        function activateParentDropdown(item) {
            item.classList.add("active")
            const parent = item.parentElement
            const parent2El = parent.childNodes[1]
            if (parent2El && parent2El.id !== "side-menu") {
                parent2El.classList.add("mm-show")
            }

            if (parent) {
                parent.classList.add("mm-active")
                const parent2 = parent.parentElement

                if (parent2) {
                    parent2.classList.add("mm-show") // ul tag

                    const parent3 = parent2.parentElement // li tag

                    if (parent3) {
                        parent3.classList.add("mm-active") // li
                        parent3.childNodes[0].classList.add("mm-active") //a
                        const parent4 = parent3.parentElement // ul
                        if (parent4) {
                            parent4.classList.add("mm-show") // ul
                            const parent5 = parent4.parentElement
                            if (parent5) {
                                parent5.classList.add("mm-show") // li
                                parent5.childNodes[0].classList.add("mm-active") // a tag
                            }
                        }
                    }
                }
                scrollElement(item);
                return false
            }
            scrollElement(item);
            return false
        }

        const pathName = props.location.pathname

        const initMenu = () => {
            new MetisMenu("#side-menu")
            let matchingMenuItem = null
            const ul = document.getElementById("side-menu")
            const items = ul.getElementsByTagName("a")
            for (let i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i]
                    break
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem)
            }
        }
        initMenu()
    }, [props.location.pathname])

    useEffect(() => {
        ref.current.recalculate()
    })

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }



    return (
        <React.Fragment>
            <SimpleBar className="h-100" ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li>
                            <Link to="/#" className="">
                                <i className="bx bx-home-circle"></i>
                                <span>Главная</span>
                            </Link>
                        </li>

                        <li className="menu-title">Торговля</li>
                        <li>
                            <Link to="/history/all" className=" ">
                                <i className="bx bx-calendar"></i>
                                <span>История сделок</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/trade" className=" ">
                                <i className='bx bx-shape-circle'></i>
                                <span>Торговые схемы</span>
                            </Link>
                        </li>

                        <li className="menu-title">Условия для стратегий</li>
                        <li>
                            <Link to="/schemas" className=" ">
                                <i className="bx bx-sitemap"></i>
                                <span>Схемы</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/stops" className="">
                                <i className='bx bx-stop-circle'></i>
                                <span>Стопы</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/stop-loss" className="">
                                <i className='bx bxs-traffic-barrier'></i>
                                <span>StopLoss Схемы</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ma" className="">
                                <i className='bx bx-equalizer'></i>
                                <span>Moving Average</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/td" className="">
                                <i className='bx bx-bar-chart-alt'></i>
                                <span>TD Sequential</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/tih-til" className="">
                                <i className='bx bx-transfer-alt'></i>
                                <span>TIH / TIL</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ravi" className="">
                                <i className='bx bx-line-chart'></i>
                                <span>Ravi</span>
                            </Link>
                        </li>



                        <li className="menu-title"></li>
                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="fas fa-hand-holding-usd"></i>
                                <span>Меню Инвесторов</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/strategy">
                                        Справочник стратегий
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/catalog-portfolio">
                                        Стандартные портфели
                                    </Link>
                                </li>
                                <li className="menu-title"></li>
                                <li>
                                    <Link to="/ip-investors">
                                        Инвесторы
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/portfolio">
                                        Портфели Инвесторов
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/si-investor">
                                        Стратегии Инвесторов
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/tsi-investor">
                                        ТС Инвесторов
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/orders-investor">
                                        Сделки Инвесторов
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/ip-balance-investor">
                                        Баланс Инвесторов
                                    </Link>
                                </li>
                                <li className="menu-title"></li>                               
                                <li>
                                    <Link to="/ip-profit-investor">
                                        Прибыль и комиссия
                                    </Link>
                                </li>                                
                                <li>
                                    <Link to="/ip-action-balance">
                                        Справочник действий
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className='bx bxs-flask'></i>
                                <span>Тестировщик</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/test1">
                                        Тестировщик 1.0
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/test2">
                                        Тестировщик 2.0
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/union-schemas">
                                        Объединение схем
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow">
                                <i className="bx bx-cog"></i>
                                <span>Настройки</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/total-settings">
                                        Общие
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/telegram-users">
                                        Телеграм пользователи
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/telegram">
                                        Доп. телеграмм группы
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/account">
                                        Записи
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/logs">
                                        Журнал логов
                                    </Link>
                                </li>

                                {/* <li>
                                    <Link to="/telegram-bot">
                                        Телеграм бот
                                    </Link>
                                </li> */}

                            </ul>
                        </li>
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    )
}

SidebarContent.propTypes = {
    location: PropTypes.object,
}

export default withRouter(SidebarContent)
