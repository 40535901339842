import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import "../../assets/datatables.scss"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import axios from "axios";
import config from "../../config";
import { Link } from "react-router-dom";
import WV from '../../workVariables'
import { useToasts } from "react-toast-notifications";
import TableSearchBar from "../../components/TableSearchBar";
import ModalConfirm from "../../components/ModalConfirm";

const Telegram = (props) => {
    const { addToast } = useToasts()
    const removeItem = async (id) => {      
            axios.get(`${config.apiUrl}/chats-delete/${id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setTelegramchat(telegramchat.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                console.error(error);
            })
            
    }
    const ActionButtons = (props) => {
        const copyItem = async (id) => {
        await axios.get(`${config.apiUrl}/chats-copy/${id}`)       
            .then(async(response) =>{
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    const response = await axios.get(`${config.apiUrl}/chats`)
                    setTelegramchat(response.data)
    
                }
            })
            .catch(error => {
                console.error(error);
            })   

        }
        
        return (
            <div className='d-flex gap-2'>
                <Link to={`/telegram/edit/${props.id}`} className='btn btn-secondary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
                <div className='d-flex gap-2'>
                    <button onClick={() => copyItem(props.id)} className='btn btn-success' title='Дублировать'><i className='mdi mdi-content-copy' /></button>
                </div>
                <div className='d-flex gap-2'>
                <button
                        onClick={() => {
                            setModalConfirm(true)
                            setDeleteId(props.id)
                        }
                        }
                        className='btn btn-danger'
                        title='Удалить'>
                        <i className='mdi mdi-delete' />
                    </button>
                    {/* <button onClick={() => removeItem(props.id)} className='btn btn-danger' title='Удалить'><i className='mdi mdi-delete' /></button> */}
                </div>
           </div>
        )

    }
    const [telegramchat, setTelegramchat] = useState([])
    const [tableData, setTableData] = useState([])
    const [modalConfirm, setModalConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Название группы',
            sort: true
        },
        {
            dataField: 'number',
            text: 'ID группы',
            sort: true
        },
        {
            dataField: 'numb_order',
            text: 'Номер последней сделки',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            style: {
                width: 111,
            }

        },
    ]
    
    useEffect(() => {
        const getTelegram = async () => {
            const response = await axios.get(`${config.apiUrl}/chats`)
            setTelegramchat(response.data)
        }
        getTelegram()
    }, [])
      
    useEffect(() => {
        let tableData = []
        if (telegramchat.length > 0) {
            telegramchat.forEach(tel => {
                tableData.push({
                    ...tel,
                    "actions": (<ActionButtons id={tel.id} />)
                })
            })            
        }       
        setTableData(tableData)
    }, [telegramchat])


    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const sizePerPageList = WV.sizePerPageArr.concat([{ text: 'Все', value: (tableData).length }])
    let nameTable = 'tableSet_telegram'
    let sizePerPage = parseInt(localStorage.getItem(nameTable + 'perPage'));
    if (!sizePerPage) sizePerPage = parseInt(WV.sizePerPage);
    let page = parseInt(localStorage.getItem(nameTable + 'page'));
    if (!page) page = 1;
    const pageOptions = {
        sizePerPage: sizePerPage,
        page: page,
        sizePerPageList: sizePerPageList,
        totalSize: tableData.length,
        custom: true,
        onSizePerPageChange: (sizePerPage, page) => {
            localStorage.setItem(nameTable + 'perPage', sizePerPage)
            localStorage.setItem(nameTable + 'page', 1)
        },
        onPageChange: (page, sizePerPage) => {
            localStorage.setItem(nameTable + 'page', page)
        },
    }

    const { SearchBar } = Search;

    return (
        <div className="page-content">
            <MetaTags>
                <title>Телеграм группы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Доп. телеграмм группы</h4>
                                        <Link to='/telegram/add' className='btn btn-primary'>
                                            <i className='mdi mdi-plus' />
                                            Добавить
                                        </Link>
                                    </div>
                                </CardTitle>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField='id'
                                    sizePerPageList={sizePerPageList}
                                    columns={columns}
                                    data={tableData}
                                >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField='id'
                                            columns={columns}
                                            data={tableData}
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>

                                                    <Row className="mb-2">
                                                        <Col md="4">
                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <TableSearchBar
                                                                        {...toolkitProps.searchProps}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    keyField={"id"}
                                                                    responsive
                                                                    bordered={true}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                />

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row className="align-items-md-center mt-30">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <SizePerPageDropdownStandalone
                                                                    sizePerPageList={sizePerPageList}
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                            }
                                        </ToolkitProvider>
                                    )
                                    }</PaginationProvider>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление Телеграм группы'
            />
        </div>
    )
}

export default Telegram;
