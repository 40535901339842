import AppContext from './AppContext';
import {useState} from "react";

const AppProvider = (props) => {

    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const saveUser = (userData) => {
        setUser(userData)
        if (userData === null) localStorage.removeItem('user')
        else localStorage.user = JSON.stringify(userData)
    }
    return (
        <AppContext.Provider value={{
            user,
            saveUser
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppProvider
