import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Button, Spinner } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import TableSearchBar from "../../components/TableSearchBar";
import WV from '../../workVariables'
import fileDownload from 'js-file-download';


const Logs = () => {
    const { addToast } = useToasts()
    const [logs, setLogs] = useState([])
    const [tableData, setTableData] = useState([])
    const [spinnerStatus, setSpinnerStatus] = useState(false)


    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
        },
        {
            dataField: 'section',
            text: 'Раздел',
            sort: true,
        },
        {
            dataField: 'action',
            text: 'Действие',
            sort: true,
        },
        {
            dataField: 'created_at',
            text: 'Дата',
        },
        {
            dataField: 'actions',
            text: 'Действия',
            style: {
                width: 111,
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const sizePerPageList = WV.sizePerPageArr.concat([{ text: 'Все', value: (tableData).length }])
    let nameTable = 'tableSet_logs'
    let sizePerPage = parseInt(localStorage.getItem(nameTable + 'perPage'));
    if (!sizePerPage) sizePerPage = parseInt(WV.sizePerPage);
    let page = parseInt(localStorage.getItem(nameTable + 'page'));
    if (!page) page = 1;
    const pageOptions = {
        sizePerPage: sizePerPage,
        page: page,
        sizePerPageList: sizePerPageList,
        totalSize: tableData.length,
        custom: true,
        onSizePerPageChange: (sizePerPage, page) => {
            localStorage.setItem(nameTable + 'perPage', sizePerPage)
            localStorage.setItem(nameTable + 'page', 1)
        },
        onPageChange: (page, sizePerPage) => {
            localStorage.setItem(nameTable + 'page', page)
        },
    }

    const getLogsList = () => {
        setSpinnerStatus(true)
        axios.get(`${config.apiUrl}/logs-getlist`)
            .then(() => {
                setTimeout(() => {
                    axios.get(`${config.apiUrl}/logs-getlistfile`, { responseType: 'blob' })
                        .then((response) => {
                            fileDownload(response.data, 'logsList.txt')
                            setSpinnerStatus(false)
                        })
                        .catch(error => {
                            console.error(error);
                        })
                }, 2000);
            })
            .catch(error => {
                console.error(error);
            })
    }

    const ActionButtons = (props) => {
        const getOneLog = (id) => {
            axios.get(`${config.apiUrl}/logs-getone/${id}`)
                .then(() => {
                    setTimeout(() => {
                        axios.get(`${config.apiUrl}/logs-getonefile`, { responseType: 'blob' })
                            .then((response) => {
                                fileDownload(response.data, 'oneLog.txt')
                            })
                            .catch(error => {
                                console.error(error);
                            })
                    }, 500);
                })
                .catch(error => {
                    console.error(error);
                })

        }

        return (
            <div className='d-flex gap-2'>
                <div className='d-flex gap-2'>
                    <button
                        onClick={() => getOneLog(props.id)}
                        className='btn btn-primary' title='Скачать txt лог'>
                        <i className='mdi mdi-download' />
                    </button>
                </div>
            </div>
        )
    }


    useEffect(() => {
        const getData = async () => {
            const response = await axios.get(`${config.apiUrl}/logs-list`)
            setLogs(response.data)
        }
        getData()
    }, [])

    useEffect(() => {
        let tableData = []
        logs.forEach(item => {
            tableData.push({
                ...item,
                "actions": (<ActionButtons id={item.id} />)
            })
        })
        setTableData(tableData)
    }, [logs])

    return (
        <div className="page-content">
            <MetaTags>
                <title>Журнал логов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Журнал логов</h4>
                                    </div>
                                </CardTitle>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField='id'
                                    sizePerPageList={sizePerPageList}
                                    columns={columns}
                                    data={tableData}>

                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField='id'
                                            columns={columns}
                                            data={tableData}
                                            search
                                        >
                                            {
                                                toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="6">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <TableSearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md="6">
                                                                <div className='mb-3 d-flex justify-content-end align-items-center'>
                                                                    {spinnerStatus &&
                                                                        <div style={{ marginRight: "13px" }}>
                                                                            <Spinner className="ms-1" color="info" />
                                                                        </div>
                                                                    }
                                                                    <Button
                                                                        onClick={getLogsList}
                                                                        title='Скачать все логи'
                                                                        className="btn-primary btn-sm me-2 " >
                                                                        <i className='mdi mdi-download me-1' />
                                                                        Скачать все
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={true}
                                                                        striped={true}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        sizePerPageList={sizePerPageList}
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                            }
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Logs;