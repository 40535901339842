import React, { useState } from 'react';
import ProfileMenu from "./header/ProfileMenu";
import Clock from "./header/Clock";

import logo from '../assets/images/SB_logo.svg'

const Header = () => {
    const tToggle = () => {
        let body = document.body;
        if (window.screen.width <= 998) {
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("vertical-collpsed");
            body.classList.toggle("sidebar-enable");
        }
    };

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div className='d-flex gap-3 align-items-center'>
                            <button
                                type="button"
                                onClick={() => {
                                    tToggle();
                                }}
                                className="btn btn-sm px-3 font-size-16 header-item "
                                id="vertical-menu-btn"
                            >
                                <i className="fa fa-fw fa-bars" />
                            </button>
                            <img src={logo} alt='' height={27} /> 
                        </div>
                        <div className='d-flex gap-2 align-items-center'>
                            <Clock />
                            <ProfileMenu />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}

export default Header;
