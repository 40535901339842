import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import { Link } from "react-router-dom";
import Select from "react-select"
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import InputSpinner from "react-bootstrap-input-spinner";
import WV from '../../workVariables'
import { useHistory } from "react-router-dom";


const MovingAverageAdd = (props) => {
    const { addToast } = useToasts()

    const [form, setForm] = useState({
        name: '',
        period: '',
        interval_ma: '',
        typePrice: '',
        typeMoving: '',
        comment: ''
    })
    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.period == '') errors.period = "поле обязательное для заполнения";
        if (values.typePrice == '') errors.typePrice = "поле обязательное для заполнения";
        if (values.typeMoving == '') errors.typeMoving = "поле обязательное для заполнения";
        if ((values.typeMoving == 'exp' || values.typeMoving == 'rma') && (values.interval_ma == '')) errors.interval_ma = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(form);        

            axios.post(`${config.apiUrl}/ma-store`, form)
                .then((response) => {
                    history.goBack()
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })
    
                    }, 300);                                
                    console.log(response.data);
                    if (response.data.res === "Success") {
                        console.log("OK");
                    }
                  
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [formErrors]);   

     //белый цвет шрифта при поиске в Select
     const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Скользящие средние | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Новая Скользящая средняя</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={storeItem}>
                                                Сохранить
                                            </Button>
                                        <Link to='/ma' className='btn btn-danger'>
                                            <i className='mdi mdi-backspace' />
                                            &nbsp;
                                            Вернуться
                                        </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                // invalid={form.name.length < 4}
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                }
                                            }
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="period">Период</Label>
                                            <InputSpinner
                                                value={form.period}
                                                name="period"
                                                type={"real"}
                                                precision={0}
                                                max={1000}
                                                min={2}
                                                step={1}
                                                onChange={(num) => {
                                                    setForm({ ...form, period: num });
                                                    setError({ ...error, period: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.period
                                                    setFormErrors(errorItem);
                                                }
                                                }
                                            />
                                             <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.period}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="typePrice">Применяется к ценам</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}                                            
                                                id='typePrice'
                                                name="typePrice"
                                                placeholder="выбрать..."
                                                onChange={e => {
                                                    setForm({ ...form, typePrice: e.value });
                                                    setError({ ...error, typePrice: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.typePrice
                                                    setFormErrors(errorItem);
                                                }}
                                                options={[
                                                    { label: 'Открытия', value: 'open' },
                                                    { label: 'Закрытия', value: 'close' },
                                                    { label: 'High', value: 'high' },
                                                    { label: 'Low', value: 'low' },
                                                ]}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.typePrice}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="typeMoving">Метод</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='typeMoving'
                                                name="typeMoving"
                                                placeholder="выбрать..."
                                                onChange={e => {
                                                    console.log(e);
                                                    // if (e.value === 'simple') {
                                                    //     console.log('_SIMPLE_');
                                                    //     setForm({ interval_ma: '' })
                                                    // }
                                                    setForm({ ...form, typeMoving: e.value, interval_ma: ''});
                                                    setError({ ...error, typeMoving: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.typeMoving
                                                    setFormErrors(errorItem);
                                                }}
                                                options={[
                                                    { label: 'Простая', value: 'simple' },
                                                    { label: 'Экспоненциальная', value: 'exp' },
                                                    { label: 'RMA', value: 'rma' },
                                                ]}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.typeMoving}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}> 
                                        {(form.typeMoving === 'exp' || form.typeMoving === 'rma') &&
                                            <div className="mb-3">
                                                <Label htmlFor="interval_ma">Количество свечей</Label>
                                                <InputSpinner
                                                    value={form.interval_ma}
                                                    name="interval_ma"
                                                    type={"number"}
                                                    precision={0}
                                                    max={1000}
                                                    min={0}
                                                    step={1}
                                                    onChange={(num) => {
                                                        setForm({ ...form, interval_ma: num })
                                                        setError({ ...error, interval_ma: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.interval_ma
                                                        setFormErrors(errorItem);
                                                    }
                                                    }
                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.interval_ma}</p>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                                                style={{ width: 50, height: 50 }}>
                                                <i className="fas fa-info"></i>
                                            </div>
                                            Экспоненциальное скользящее среднее использует p=2/(N+1) p – весовой коэффициент в интервале от 0 до 1, отражающий скорость старения прошлых данных: чем выше его значение, тем больший удельный вес имеют новые наблюдения случайной величины, и тем меньший старые; N – интервал сглаживания, равный периоду. 
                                            В RMA p=1/N.
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default MovingAverageAdd