import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Route, Redirect, useLocation } from "react-router-dom"
import AppContext from "../store/AppContext";

const AuthMiddleware = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {

    const { user } = useContext(AppContext)

    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthProtected && !user) {
                    return (
                        <Redirect
                            to={{ pathname: "/login", state: { from: props.location } }}
                        />
                    )
                }
                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )
            }}
        />
    )
}


AuthMiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
}

export default AuthMiddleware;
