import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import { Link } from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import config from "../../config";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from 'react-select/animated';
import WV from '../../workVariables'
import InputSpinner from "react-bootstrap-input-spinner";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
const animatedComponents = makeAnimated();
const moment = require('moment')

const RaviAdd = (props) => {
    const [form, setForm] = useState({
        name: '',
        smaId: '',
        fmaId: '',
        barssince: 1,
        comment: '',
        tcl: 0,
        extr_diver: 0,
    });
    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.smaId === '') errors.smaId = "поле обязательное для заполнения";
        if (values.fmaId === '') errors.fmaId = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(form);
            let form1 = {};
            form1.name = form.name;
            form1.comment = form.comment;
            form1.barssince = form.barssince;
            form1.extr_diver = form.extr_diver;
            form1.tcl = form.tcl;
            form1.sma_id = form.smaId.value;
            form1.fma_id = form.fmaId.value;
            axios.post(`${config.apiUrl}/ravi-store`, form1)
                .then((response) => {
                    console.log(response.data.msg);
                    if (response.data.res === "Success") {
                        history.goBack()
                        setTimeout(() => {
                            addToast(response.data.msg, {
                                appearance: response.data.res.toLowerCase(),
                                autoDismiss: true,
                                autoDismissTimeout: 7500,
                                transitionDuration: 2000
                            })
                        }, 300);
                    }
                    if (response.data.res === "Error") {
                        setTimeout(() => {
                            addToast(response.data.msg, {
                                appearance: response.data.res.toLowerCase(),
                                autoDismiss: true,
                                autoDismissTimeout: 7500,
                                transitionDuration: 2000
                            })
                        }, 300);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [formErrors]);

    registerLocale('ru', ru)
    setDefaultLocale('ru')

    const [sma, setSma] = useState([])
    const [fma, setFma] = useState([])
    useEffect(() => {
        const getSma = async () => {
            axios.get(`${config.apiUrl}/schemas-ma`)
                .then((response) => {
                    let arrayMa = response.data;
                    for (let i = 0; i < arrayMa.length; i++) {
                        arrayMa[i].value = arrayMa[i].id;
                        arrayMa[i].label = arrayMa[i].name;
                        delete arrayMa[i].id;
                        delete arrayMa[i].name
                    }
                    setSma(arrayMa)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getSma()
        const getFma = async () => {
            axios.get(`${config.apiUrl}/schemas-ma`)
                .then((response) => {
                    let arrayMa = response.data;
                    for (let i = 0; i < arrayMa.length; i++) {
                        arrayMa[i].value = arrayMa[i].id;
                        arrayMa[i].label = arrayMa[i].name;
                        delete arrayMa[i].id;
                        delete arrayMa[i].name
                    }
                    setFma(arrayMa)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getFma()

    }, [])
    const { addToast } = useToasts()

    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color: `${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Новый Ravi | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Новый RAVI</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={storeItem}>
                                                Сохранить
                                            </Button>
                                            <Link to='/ravi' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                // required
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                id="name"
                                                onChange={
                                                    (e) => {
                                                        setForm({ ...form, name: e.target.value });
                                                        setError({ ...error, name: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.name
                                                        setFormErrors(errorItem);
                                                    }
                                                }
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <Label>Barssince Trend Change Bar &nbsp;
                                                <abbr title="Номер свечи, на которой открывается сделка после смены тренда на true">
                                                    ?
                                                </abbr>
                                            </Label>
                                            <InputSpinner
                                                value={form.barssince}
                                                name="barssince"
                                                type={"real"}
                                                precision={0}
                                                max={100}
                                                min={1}
                                                step={1}
                                                onChange={(num) => {
                                                    setForm({ ...form, barssince: num });
                                                    setError({ ...error, barssince: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.barssince
                                                    setFormErrors(errorItem);
                                                }
                                                }
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.barssince}</p>
                                        </div>

                                    </Col>

                                    <Col xs={12} md={6} xl={8}>
                                        <Row>
                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label>Trend Change Level &nbsp;
                                                        <abbr title="Линия Ravi выше (при лонгах) и ниже (при шортах) открывается сделка">
                                                            ?
                                                        </abbr>
                                                    </Label>
                                                    <InputSpinner
                                                        value={form.tcl}
                                                        name="tcl"
                                                        type={"real"}
                                                        precision={2}
                                                        max={100}
                                                        min={0}
                                                        step={0.01}
                                                        onChange={(num) => {
                                                            setForm({ ...form, tcl: num });
                                                            setError({ ...error, tcl: true })
                                                            errorItem = formErrors;
                                                            delete errorItem.tcl
                                                            setFormErrors(errorItem);
                                                        }
                                                        }
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label>Extremum Exit Filter &nbsp;
                                                        <abbr title="Процент от экстремума за тренд для закрытия сделки">
                                                            ?
                                                        </abbr>
                                                    </Label>
                                                    <InputSpinner
                                                        value={form.extr_diver}
                                                        name="extr_diver"
                                                        type={"real"}
                                                        precision={2}
                                                        max={100}
                                                        min={0}
                                                        step={0.01}
                                                        onChange={(num) => {
                                                            setForm({ ...form, extr_diver: num });
                                                            setError({ ...error, extr_diver: true })
                                                            errorItem = formErrors;
                                                            delete errorItem.extr_diver
                                                            setFormErrors(errorItem);
                                                        }
                                                        }
                                                    />
                                                    <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.extr_diver}</p>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="comment">Комментарии</Label>
                                                    <Input
                                                        type="textarea"
                                                        // style={{ resize: 'none', height: '2.4rem' }}
                                                        style={{ resize: "none", height: "7.4rem" }}
                                                        className="form-control"
                                                        placeholder='...'
                                                        id="comment"
                                                        onChange={
                                                            (e) => {
                                                                setForm({ ...form, comment: e.target.value })
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>
                                    </div>

                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label symbol="smaId">Медленная скользящая средняя (SMA)&nbsp;
                                                {/* <abbr title="MA1 > MA2 > ... > MAn">
                                                        ?
                                                    </abbr> */}
                                            </Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='smaId'
                                                value={form.smaId}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, smaId: value })
                                                    setError({ ...error, smaId: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.smaId
                                                    setFormErrors(errorItem);
                                                }}
                                                options={sma}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.smaId}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>

                                        <Col xs={12} md={6} xl={6}>
                                            <div className="mb-3">
                                                <Label symbol="fmaId">Быстрая скользящая средняя (FMA) &nbsp;
                                                    {/* <abbr title="MA1 > MA2 > ... > MAn">
                                                        ?
                                                    </abbr> */}
                                                </Label>
                                                <Select
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: `${WV.colorHoverSelect}`,
                                                            primary: `${WV.colorSelected}`,
                                                        },
                                                    })}
                                                    styles={customStyles}
                                                    id='fmaId'
                                                    value={form.fmaId}
                                                    placeholder="выбрать..."
                                                    onChange={(value) => {
                                                        setForm({ ...form, fmaId: value })
                                                        setError({ ...error, fmaId: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.fmaId
                                                        setFormErrors(errorItem);
                                                    }}
                                                    options={fma}
                                                    classNamePrefix="select2-selection"
                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.fmaId}</p>

                                            </div>
                                        </Col>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>

        </div>
    );
}

export default RaviAdd;
