import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import {Link, useParams, Redirect} from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import config from "../../config";
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from 'react-select/animated';
import WV from '../../workVariables'
import InputSpinner from "react-bootstrap-input-spinner";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
const animatedComponents = makeAnimated();

const UnionSchemasEdit = (props) => {
    const { id } = useParams()
    const [form, setForm] = useState({
        name: '',
        schemas: '',
        comment: '',
        period_test: WV.periodТestList[0],
        annual_yield: '',
        count_strategy: ''
    });
    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.schemas == '') errors.schemas = "поле обязательное для заполнения";
        if (values.count_strategy == '') errors.count_strategy = "поле обязательное для заполнения";
        if (values.annual_yield == '') errors.annual_yield = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(form);
        let form1 = {};
        form1.id = id;
        form1.name = form.name;
        form1.comment = form.comment;
        form1.count_strategy = form.count_strategy;
        form1.period_test = form.period_test.value;
        form1.annual_yield = form.annual_yield;

        let arrSchemas = form.schemas;
            for (let i = 0; i < arrSchemas.length; i++)
                (i === 0) ? form1.ids_test_schemes_second = arrSchemas[i].value : 
                form1.ids_test_schemes_second = form1.ids_test_schemes_second + ', ' + arrSchemas[i].value          

            axios.post(`${config.apiUrl}/union-schemas-update`, form1)
            .then((response) => {
                console.log(response.data.msg);
                if (response.data.res === "Success") {
                    setRedirect(true)
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })
                    }, 300);    
                    console.log("OK");

                    // <Redirect to="/account" />
                }
            })
            .catch(error => {
                console.error(error);
            })

        }
    }, [formErrors]);

    const [schema, setSchema] = useState([])
    useEffect(() => {    
        const getUnionScheme = async () => {

            const response = await axios.get(`${config.apiUrl}/union-schemas/${id}`)
            let periodTest;
            if (!response.data.period_test) periodTest = [{ label: 'Весь период', value: null }];
            if (response.data.period_test == 1) periodTest = [{label: 'По месяцам', value: '1' }];
            if (response.data.period_test == 2) periodTest = [{ label: 'По кварталам', value: '2' }];
            if (response.data.period_test == 3) periodTest = [{ label: 'По полугодиям', value: '3'}];
            if (response.data.period_test == 4) periodTest = [{ label: 'По годам', value: '4' }];

            setForm({
                id: id,
                name: response.data.name,
                comment: response.data.comment,
                schemas: response.data.schemas,
                period_test: periodTest,
                annual_yield: response.data.annual_yield,
                count_strategy: response.data.count_strategy,
            })
        }
        getUnionScheme()   

        const getScheme = async () => {
                axios.get(`${config.apiUrl}/test-second-schemas`)
                .then((response) => {
                    let arraySchema = response.data;
                    for (let i = 0; i < arraySchema.length; i++) {
                        arraySchema[i].value = arraySchema[i].id;
                        arraySchema[i].label = arraySchema[i].name;
                        delete arraySchema[i].id;
                        delete arraySchema[i].name
                    }
                    setSchema(arraySchema)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getScheme()
      
    }, [])
    const { addToast } = useToasts()
   
    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    const [redirect, setRedirect] = useState(false)
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Редактировать Объединение Схем | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Редактировать Объединение Схем #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={storeItem}>
                                                Изменить
                                            </Button>
                                            <Link to='/union-schemas' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                // required
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                id="name"
                                                value={form.name}
                                                onChange={
                                                    (e) => {
                                                        setForm({ ...form, name: e.target.value });
                                                        setError({ ...error, name: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.name
                                                        setFormErrors(errorItem);
                                                    }
                                                }
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>

                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                // style={{ resize: 'none', height: '2.4rem' }}
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                value={form.comment}
                                                onChange={
                                                    (e) => {
                                                        setForm({ ...form, comment: e.target.value })
                                                    }
                                                }
                                            />
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        {form.count_strategy !== '' &&
                                            <div className="mb-3">
                                                <Label symbol="count_strategy">Количество стратегий</Label>
                                                <InputSpinner
                                                    value={form.count_strategy}
                                                    name="count_strategy"
                                                    type={"real"}
                                                    precision={0}
                                                    max={100}
                                                    min={0}
                                                    step={1}
                                                    onChange={(num) => {
                                                        setForm({ ...form, count_strategy: num });
                                                        setError({ ...error, count_strategy: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.count_strategy
                                                        setFormErrors(errorItem);
                                                    }
                                                    }
                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.count_strategy}</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        {form.annual_yield !== '' &&
                                            <div className="mb-3">
                                                <Label> Годовой % доходности для Сортино</Label>
                                                <InputSpinner
                                                    value={form.annual_yield}
                                                    name="annual_yield"
                                                    type={"real"}
                                                    precision={1}
                                                    max={100}
                                                    min={0}
                                                    step={0.1}
                                                    onChange={(num) => {
                                                        setForm({ ...form, annual_yield: num });
                                                        setError({ ...error, annual_yield: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.annual_yield
                                                        setFormErrors(errorItem);
                                                    }
                                                    }
                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.annual_yield}</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label symbol="position">Разбить на периоды</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='period_test'
                                                value={form.period_test}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, period_test: value })
                                                }}
                                                options={WV.periodТestList}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>

                                </Row>

                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>
                                    </div>

                                </CardTitle>
                                <Row>
                                    <div className="mb-3">
                                        <Label symbol="schemas">Тестовые Схемы 2.0</Label>
                                        <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: `${WV.colorHoverSelect}`,
                                                    primary: `${WV.colorSelected}`,
                                                },
                                            })}
                                            // closeMenuOnSelect={false}
                                            // components={animatedComponents}
                                            // isMulti
                                            styles={customStyles}
                                            id='schemas'
                                            value={form.schemas}
                                            placeholder="выбрать..."
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            onChange={(value) => {
                                                setForm({ ...form, schemas: value });
                                                setError({ ...error, schemas: true })
                                                errorItem = formErrors;
                                                delete errorItem.schemas
                                                setFormErrors(errorItem);
                                            }}
                                            options={schema}
                                            classNamePrefix="select2-selection"
                                        />
                                        <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.schemas}</p>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
             
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/union-schemas",
                    }}
                />
            }

        </div>
    );
}

export default UnionSchemasEdit;
