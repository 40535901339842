import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import { Link, Redirect } from "react-router-dom";
import WV from '../../workVariables'
import TableSearchBar from "../../components/TableSearchBar";
import LookButtons from '../../components/LookButtons';
import ModalConfirm from "../../components/ModalConfirm";

const Trade = (props) => {
    const { addToast } = useToasts()
    const [schemes, setSchemes] = useState([])
    const [tableData, setTableData] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [redirectInfo, setRedirectInfo] = useState({
        action: null,
        id: null
    })
    function schemeNameFormatter(cell, row) {
        return (
            <Link to={'/schemas/edit/' + row.scheme.id} className='btn btn_md' title='Посмотреть схему'>
                {cell}
            </Link>
        );
    }
    
    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Название',
            sort: true,
        },
        {
            dataField: 'scheme.name',
            text: 'Подключенная схема',
            sort: true,
            formatter: schemeNameFormatter
        },
        {
            dataField: 'sumOrder',
            text: 'Сумма',
            sort: true,
        },
        {
            dataField: 'account.name',
            text: 'Запись',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Статус',
            style: {
                width: 111,
            }
        },
        {
            dataField: 'actions',
            text: 'Действия',
            style: {
                width: 111,
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];
    let nameTable = 'tableSet_trade'
    let sizePerPage = parseInt(localStorage.getItem(nameTable + 'perPage'));
    if (!sizePerPage) sizePerPage = parseInt(WV.sizePerPage);
    let page = parseInt(localStorage.getItem(nameTable + 'page'));
    if (!page) page = 1;    

    const sizePerPageList = WV.sizePerPageArr.concat([{ text: 'Все', value: (tableData).length }])
    const pageOptions = {
        // sizePerPage: WV.sizePerPage,
        sizePerPage: sizePerPage,
        page: page,
        sizePerPageList: sizePerPageList,
        totalSize: tableData.length,
        custom: true,
        onSizePerPageChange: (sizePerPage, page) => {
            localStorage.setItem(nameTable + 'perPage', sizePerPage)
            localStorage.setItem(nameTable + 'page', 1)
        },
        onPageChange: (page, sizePerPage) => {
            localStorage.setItem(nameTable + 'page', page)
        },
    }
    
    async function getData() {
        await axios.get(`${config.apiUrl}/trade-schemes`)
            .then((response) => {
                setSchemes(response.data)
            })
            .catch(error => {
                console.error(error);
            })
    }
    // Ф-я УДАЛИТЬ
    const removeItem = async (id) => {
        axios.get(`${config.apiUrl}/trade-schemes/delete/${id}`)
            .then((response) => {
                console.log(response);
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setSchemes(schemes.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                addToast("Ошибка!", {
                    appearance: "error",
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                console.error(error);
            })
    }

    // Блок Копирования ТС
    const ActionButtons = (props) => {
        const copyItem = async (id) => {
            await axios.get(`${config.apiUrl}/trade-schemes/copy/${id}`)
                .then(async (response) => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    if (response.data.res === "Success") {
                        getData()
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }

        return (
            <div className='d-flex gap-2'>
                {props.hasOrder === 0
                    ?
                    <>
                        <div className='d-flex gap-2'>
                            <button onClick={() => {
                                setModalOpenClose(true)
                                setModalOpenCloseInfo({ status: 'open', id: props.id })
                            }}
                                className='btn btn-success'
                                title='OPEN'>
                                <i className='far fa-check-square' />
                            </button>
                        </div>
                    </>
                    :
                    <>
                        <div className='d-flex gap-2'>
                            <button onClick={() => {
                                setModalOpenClose(true)
                                setModalOpenCloseInfo({ status: 'close', id: props.id })
                            }}
                                className='btn btn-danger'
                                title='CLOSE'>
                                <i className='far fa-window-close' />
                            </button>
                        </div>
                    </>
                }
                <Link to={`/trade/show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                    <i class="far fa-eye" />
                </Link>
                <Link to={`/history/${props.id}`} className='btn btn-secondary' title='Сделки'>
                    <i class="fas fa-align-justify" />
                </Link>
                <Link to={`/trade/edit/${props.id}`} className='btn btn-primary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
                <div className='d-flex gap-2'>
                    <button onClick={() => copyItem(props.id)} className='btn btn-success' title='Дублировать'><i className='mdi mdi-content-copy' /></button>
                </div>
                <div className='d-flex gap-2'>
                    <button
                        onClick={() => {
                            setModalConfirm(true)
                            setDeleteId(props.id)
                        }
                        }
                        className='btn btn-danger' title='Удалить'>
                        <i className='mdi mdi-delete' />
                    </button>
                    {/* <button onClick={() => removeItem(props.id)} className='btn btn-danger' title='Удалить'><i className='mdi mdi-delete' /></button> */}
                </div>
            </div>
        )
    }

    // Блок ВКЛ / ВЫКЛ
    const StatusButton = (props) => {
        return (
            <div className='d-flex gap-2'>
                {props.status === '0'
                    ?
                    <>
                        <div className='d-flex gap-2'>
                            <button onClick={() => {
                                setModalOnOff(true)
                                setModalOnOffInfo({ status: 'on', id: props.id })
                            }}
                                className='btn btn-success'
                                title='ВКЛЮЧИТЬ'> <i class="fas fa-power-off" /></button>
                        </div>
                        <div className='d-flex align-items-center text-danger'>
                            <b>ВЫКЛЮЧЕНА</b>
                        </div>
                    </>
                    :
                    <>
                        <div className='d-flex gap-2'>
                            <button onClick={() => {
                                setModalOnOff(true)
                                setModalOnOffInfo({ status: 'off', id: props.id })
                            }}
                                className='btn btn-danger'
                                title='ВЫКЛЮЧИТЬ'> <i class="fas fa-power-off" /></button>
                        </div>
                        <div className='d-flex align-items-center text-success'>
                            <b>ВКЛЮЧЕНА</b>
                        </div>
                    </>
                }
            </div>
        )
    }

    // Ф-я ВКЛ / ВЫКЛ
    const onOff = () => {
        axios.get(`${config.apiUrl}/trade-schemes-${modalOnOffInfo.status}/${modalOnOffInfo.id}`)
            .then(async (response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    getData()
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    // Ф-я Открытия ордера
    const openOrder = (id) => {
        axios.get(`${config.apiUrl}/orders-open/${id}`)
            .then((response) => {
                // console.log(response.data);
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                setRedirectInfo({
                    action: 'open',
                    id: id
                })
                // РЕДИРЕКТ НА КОМПОНЕНТ ВВОДА ПИН КОДА
                setRedirect(true)
            })
            .catch(error => {
                console.error(error);
            })
    }

    // Ф-я Закрытия ордера
    const closeOrder = (id) => {
        axios.get(`${config.apiUrl}/orders-close/${id}`)
            .then((response) => {
                // console.log(response.data);
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                setRedirectInfo({
                    action: 'close',
                    id: id
                })
                // РЕДИРЕКТ НА КОМПОНЕНТ ВВОДА ПИН КОДА
                setRedirect(true)
            })
            .catch(error => {
                console.error(error);
            })
    }

    useEffect(() => {
        const getSchemes = async () => {
            getData()
        }
        getSchemes()
    }, [])

    useEffect(() => {
        let tableData = []
        if (schemes.length > 0) {
            schemes.forEach(item => {
                tableData.push({
                    ...item,
                    "status": (<StatusButton id={item.id} status={item.status} />),
                    "actions": (<ActionButtons id={item.id} hasOrder={item.hasOpenOrder} />)
                })
            })
        }       
        setTableData(tableData)
    }, [schemes])

    const [modalConfirm, setModalConfirm] = useState(false)
    const [modalOnOff, setModalOnOff] = useState(false)
    const [modalOnOffInfo, setModalOnOffInfo] = useState({
        status: null,
        id: null
    })

    const [modalOpenClose, setModalOpenClose] = useState(false)
    const [modalOpenCloseInfo, setModalOpenCloseInfo] = useState({
        status: null,
        id: null
    })

    const [deleteId, setDeleteId] = useState(null)
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }
    const toggleModalOnOff = () => {
        setModalOnOff(!modalOnOff)
    }
    const toggleModalOpenClose = () => {
        setModalOpenClose(!modalOpenClose)
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Торговые схемы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Торговые схемы</h4>
                                        <Link to='/trade/add' className='btn btn-primary'>
                                            <i className='mdi mdi-plus' />
                                            Добавить
                                        </Link>
                                    </div>
                                </CardTitle>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField='id'
                                    sizePerPageList={sizePerPageList}
                                    columns={columns}
                                    data={tableData}>

                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField='id'
                                            columns={columns}
                                            data={tableData}
                                            search
                                        >
                                            {
                                                toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <TableSearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={true}
                                                                        striped={true}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        sizePerPageList={sizePerPageList}
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                            }
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: `/trade/check-pin/${redirectInfo.action}/${redirectInfo.id}`,
                    }}
                />
            }
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление торговой схемы'
            />

            <Modal isOpen={modalOnOff} toggle={toggleModalOnOff} size='xs'>
                <ModalHeader>
                    Изменение статуса Торговой схемы
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {modalOnOffInfo.status === 'on' &&
                                WV.textConfirmOnScheme
                            }
                            {modalOnOffInfo.status === 'off' &&
                                WV.textConfirmOffScheme
                            }
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModalOnOff()
                            onOff()
                        }}>
                        Подтвердить
                    </Button>
                    <Button color="danger" onClick={toggleModalOnOff}>Отмена</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalOpenClose} toggle={toggleModalOpenClose} size='xs'>
                <ModalHeader>
                    Ручное открытие / закрытие сделки
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {modalOpenCloseInfo.status === 'open' &&
                                WV.textConfirmOpenOrder
                            }
                            {modalOpenCloseInfo.status === 'close' &&
                                WV.textConfirmCloseOrder
                            }
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModalOpenClose()
                            if(modalOpenCloseInfo.status === 'open') openOrder(modalOpenCloseInfo.id)
                            if(modalOpenCloseInfo.status === 'close') closeOrder(modalOpenCloseInfo.id)
                        }}>
                        Подтвердить
                    </Button>
                    <Button color="danger" onClick={toggleModalOpenClose}>Отмена</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Trade;
