import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import axios from "axios";
import config from "../../../config";
import { Link } from "react-router-dom";
import PaginationProviderCustom1 from "../../../components/PaginationProviderCustom";

const OrdersInvestor = () => {
    
    const [items, setItems] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataExcel, setTableDataExcel] = useState([])
    function orderNameFormatter(cell, row) {
        return (
            <Link to={'/history-show/' + row.order.id} className='btn btn_md' title='Перейти на страницу Ордера'>
                { cell }
            </Link>         
        );
      }
      function tradeSchemmeNameFormatter(cell, row) {
        return (
            <Link to={'/trade/show/' + row.trade_scheme.id} className='btn btn_md' title='Перейти на страницу Портфеля'>
                { cell }
            </Link>         
        );
      }
      function tradeSchemmeInvestorFormatter(cell, row) {
        return (
            <Link to={'/tsi-investor/show/' + row.trade_schemes_investor.id} className='btn btn_md' title='Перейти на страницу Торговая схема у Инвестора'>
                { cell }
            </Link>         
        );
      }
      function strategyInvestorFormatter(cell, row) {
        return (
            <Link to={'/si-investor/show/' + row.strategy_investor.id} className='btn btn_md' title='Перейти на страницу Стратегия у Инвестора'>
                { cell }
            </Link>         
        );
      }
      function portfolioNameFormatter(cell, row) {
        return (
            <Link to={'/portfolio/show/' + row.portfolio.id} className='btn btn_md' title='Перейти на страницу Портфеля у Инвестора'>
                { cell }
            </Link>         
        );
      }
      function userNameFormatter(cell, row) {
        return (
            <Link to={'/ip-investors/show/' + row.user.id} className='btn btn_md' title='Перейти на страницу Инвестора'>
                { cell }
            </Link>         
        );
      }
    
                
    const columns = [
        {
            dataField: 'order.id',
            text: <span className='text'>№ сделки</span>,
            sort: true,
            formatter: orderNameFormatter,
            headerClasses: 'table-th-50'
        },
        {
            dataField: 'id',
            text: <span className='text'>№ сделки у Инвестора</span>,
            sort: true, 
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'trade_scheme.name',
            text: <span className='text'>ТС</span>,
            sort: true,
            formatter: tradeSchemmeNameFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'trade_schemes_investor.name_user', 
            text: <span className='text'>ТС у Инвестора</span>,
            sort: true,
            formatter: tradeSchemmeInvestorFormatter,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'strategy_investor.name_user', 
            text: <span className='text'>Стратегия у Инвестора</span>,
            sort: true,
            formatter: strategyInvestorFormatter,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'portfolio.name', 
            text: <span className='text'>Портфель</span>,
            sort: true,
            formatter: portfolioNameFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'user.name_admin', 
            text: <span className='text'>Инвестор</span>,
            sort: true,
            formatter: userNameFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'date_open', 
            text: <span className='text'>Дата открытия</span>,
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'date_close', 
            text: <span className='text'>Дата закрытия</span>,
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'sumOrder', 
            text: <span className='text'>Сумма сделки</span>,
            sort: true,
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'price_open', 
            text: <span className='text'>Цена открытия</span>,
            sort: true,
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'price_close', 
            text: <span className='text'>Цена закрытия</span>,
            sort: true,
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'profit_money', 
            text: <span className='text'>Профит сделки, валюта</span>,
            sort: true,
            headerClasses: 'table-th-120'
        },
        {
            dataField: 'profit_percent', 
            text: <span className='text'>Профит сделки, %</span>,
            sort: true,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'profit_percent_investor', 
            text: <span className='text'>Профит Инвестора, %</span>,
            sort: true,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'comment',
            text: <span className='text'>Комментарии</span>,
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            headerClasses: 'py-20'
        },
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2'>
                <Link to={`/orders-investor/show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                    <i class="far fa-eye"></i>
                </Link>
                <Link to={`/orders-investor/edit/${props.id}`} className='btn btn-primary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
            </div>
        )
    }

    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/ip-iorders-index`)
                .then((response) => {
                    setItems(response.data)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    useEffect(() => {
        let tableData = []
        let tableDataExcel = [] 
        let itemObject = {}; 
        if (items.length > 0) {
            items.forEach(item => {
                itemObject = {};
                itemObject['№ сделки'] = item.order?.id;
                itemObject['№ сделки у Инвестора'] = item.id;
                itemObject['ТС'] = item.trade_scheme.name;
                itemObject['ТС у Инвестора'] = item.trade_schemes_investor.name_user;
                itemObject['Стратегия у Инвестора'] = item.strategy_investor.name_user;
                itemObject['Портфель'] = item.portfolio.name;
                itemObject['Инвестор'] = item.user.name_admin;
                itemObject['Дата открытия'] = item.date_open.substr(0, 10);
                itemObject['Время открытия'] = item.date_open.substr(11, 8);
                itemObject['Дата закрытия'] = item.date_close.substr(0, 10);
                itemObject['Время закрытия'] = item.date_close.substr(11, 8);
                itemObject['Сумма сделки'] = item.sumOrder;
                itemObject['Цена открытия'] = item.price_open;
                itemObject['Цена закрытия'] = item.price_close;
                itemObject['Профит сделки, валюта'] = item.profit_money;
                itemObject['Профит сделки, %'] = item.profit_percent;
                itemObject['Профит Инвестора, %'] = item.profit_percent_investor;
                itemObject['Комментарии'] = item.comment;
    
                tableData.push({
                    ...item,
                    "actions": (<ActionButtons id={item.id} />)
                })
                tableDataExcel.push(itemObject)
            })
        }          
        setTableData(tableData)
        setTableDataExcel(tableDataExcel)
    }, [items])
   

    return (
        <div className="page-content">
            <MetaTags>
                <title>Сделки Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Сделки Инвесторов</h4>
                                    </div>
                                </CardTitle>
                                <PaginationProviderCustom1 
                                    columns={columns} tableData={tableData} nameTable="tableSet_ordersInvestor" 
                                    tableDataExcel = {tableDataExcel} 
                                    defaultSorted = {defaultSorted}
                                    nameExcel = {'InvestorsOrders.xlsx'} 
                                    nameList = {'Orders'}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OrdersInvestor