import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    Label,
    Input,
    Button,
    FormFeedback,
    Modal, ModalBody, ModalHeader, ModalFooter,
} from "reactstrap";

import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import InputSpinner from "react-bootstrap-input-spinner";

import TextField from "@mui/material/TextField";
import { DateTimePicker, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import darkTheme from '../../muiTheme';

import moment from 'moment'


const SettingsTotal = (props) => {
    const [power, setPower] = useState('on')
    const { addToast } = useToasts()
    const [form, setForm] = useState({
        chat: null,
        new_password: '',
        conf_password: '',
        password: '',
        chatEdit: false,
        passEdit: false,
        minutEdit: false,
        minut: null,
        startup_tb: false,
        time_bot: []
    })

    const [timeValue, setTimeValue] = useState(null);

    const [error, setError] = useState({
        password: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const saveItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        if (!values.password) errors.password = " ";
        if (values.chat == '') errors.chat = "поле обязательное для заполнения";
        if ((!values.new_password == '') && (values.conf_password == '')) errors.conf_password = "подтвердите пароль";
        if ((!values.new_password == '') && (values.conf_password !== values.new_password)) {
            errors.conf_password = "пароли не совпадают";
            errors.new_password = "пароли не совпадают";
        }
        if ((!values.new_password == '') && (values.new_password.length < 6)) errors.new_password = "пароль должен быть не менее 6 символов";
        if (values.part_candle == '') errors.part_candle = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

            console.log(form)

            axios.post(`${config.apiUrl}/settings-edit`, form)
                .then((response) => {
                    // history.goBack()
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })
                    }, 300);
                })
        }
    }, [formErrors]);

    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/settings`)
                .then((response) => {
                    let startup_tb = false
                    if (response.data.tele_bot_startup === "on")
                        startup_tb = true
                    else
                        startup_tb = false

                    setForm({
                        ...form,
                        new_password: '',
                        conf_password: '',
                        password: '',
                        chatEdit: false,
                        passEdit: false,
                        minutEdit: false,
                        chat: response.data.chat,
                        minut: response.data.minutes,
                        startup_tb: startup_tb,
                        time_bot: response.data.time_bot
                    })
                    setPower(response.data.trade_core_power)
                    localStorage.setItem('power', response.data.trade_core_power)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    const [statusBot, setStatusBot] = useState(false)
    const [statusInvestor, setStatusInvestor] = useState(false)

    useEffect(() => {
        const getTelegramStatus = () => {
            axios.get(`${config.apiUrl}/telegram-bot-get-status`)
                .then((response) => {
                    if (response.data.powerButton) setStatusBot(true)
                    if (response.data.ppButton) setStatusInvestor(true)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getTelegramStatus()
    }, [])

    const powerBot = () => {
        let command, text
        statusBot ? command = "off" : command = "on"
        statusBot ? text = "Телеграм Бот приостановлен" : text = "Телеграм Бот запущен"
        axios.get(`${config.apiUrl}/telegram-bot-power/${command}`)
            .then((response) => {
                if (response) {
                    addToast(text, {
                        appearance: "info",
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const changeBot = () => {
        let sendStatus
        statusInvestor ? sendStatus = 'pause' : sendStatus = 'play'
        axios.get(`${config.apiUrl}/telegram-bot-power/ss/${sendStatus}`)
            .then(async (response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
            })
            .catch(error => {
                console.error(error);
            })
    }

    // Модалка удаления
    const [modalConfirm, setModalConfirm] = useState(false)
    const [status, setStatus] = useState(null)
    const [textModal, setTextModal] = useState('')
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
        handleEvent(status)
    }

    const handleEvent = (status) => {
        setPower(status)
        axios.get(`${config.apiUrl}/core-power/${status}`)
            .then((response) => {
                setPower(status)
                localStorage.setItem('power', status)
                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                }, 300);
            })
            .catch(error => {
                console.error(error);
            })
    }

    let errorItem;
    const history = useHistory();

    return (
        <div className="page-content">
            <MetaTags>
                <title>Общие | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Общие настройки</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={saveItem}>
                                                Изменить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>

                                        </div>

                                    </div>
                                </CardTitle>
                                <Row>

                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="password">Введите старый пароль для подтвержения изменений</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="password"
                                                value={form.password}
                                                name="password"
                                                invalid={
                                                    (form.password.length === 0 && error.password) ||
                                                    formErrors.password
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, password: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.password
                                                    setFormErrors(errorItem);
                                                }
                                                }
                                            />

                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-0">Telegram</h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="chat">Id группы для уведомлений</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="chat"
                                                value={form.chat}
                                                name="chat"
                                                invalid={
                                                    (!/^[0-9-]+$/.test(form.chat) && error.chat) ||
                                                    formErrors.chat
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value, chatEdit: true });
                                                    setError({ ...error, chat: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.chat
                                                    setFormErrors(errorItem);
                                                }
                                                }
                                            />
                                            <FormFeedback invalid>
                                                {formErrors.chat
                                                    ? formErrors.chat
                                                    : "некорректный номер"}
                                            </FormFeedback>

                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-0">Смена пароля пользователя</h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="new_password">Новый пароль</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="new_password"
                                                value={form.new_password}
                                                name="new_password"
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value, passEdit: true });
                                                    setError({ ...error, new_password: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.new_password
                                                    setFormErrors(errorItem);
                                                }
                                                }
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.new_password}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="conf_password">Подтвердите новый пароль</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="conf_password"
                                                value={form.conf_password}
                                                name="conf_password"
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, conf_password: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.conf_password
                                                    setFormErrors(errorItem);
                                                }
                                                }
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.conf_password}</p>

                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-0">Торговля</h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <div className="d-flex align-items-center">
                                        <Col xs={12} md={3} xl={3}>
                                            {form.minut &&
                                                <div className="mb-3">
                                                    <Label htmlFor="minut">Интервал проверки исполнения StopLoss по сделкам, в минутах: </Label>
                                                    <InputSpinner
                                                        value={form.minut}
                                                        name="minut"
                                                        type={"real"}
                                                        precision={0}
                                                        max={1000}
                                                        min={1}
                                                        step={1}
                                                        onChange={(num) => {
                                                            setForm({ ...form, minut: num, minutEdit: true });
                                                        }
                                                        }
                                                    />
                                                </div>
                                            }
                                        </Col>
                                        <Col xs={1} md={1} xl={1}></Col>
                                        <Col xs={12} md={3} xl={3}>

                                            {power == "on" &&
                                                <>
                                                    <button
                                                        style={{ marginTop: "30px", }}
                                                        onClick={() => {
                                                            setModalConfirm(true)
                                                            setStatus('off')
                                                            setTextModal('Вы уверены, что хотите остановить торговое ядро?')
                                                        }
                                                        }
                                                        className='btn btn-danger fw-bold'
                                                        title={'Остановить торговое ядро'}>
                                                        Остановить торговое ядро
                                                    </button>
                                                </>
                                            }
                                            {power == "off" &&
                                                <>
                                                    <button
                                                        style={{ marginTop: "30px", }}
                                                        onClick={() => {
                                                            setModalConfirm(true)
                                                            setStatus('on')
                                                            setTextModal('Вы уверены, что хотите запустить торговое ядро?')
                                                        }
                                                        }
                                                        className='btn btn-success fw-bold'
                                                        title={'Запустить торговое ядро'}>
                                                        Запустить торговое ядро
                                                    </button>
                                                </>
                                            }

                                        </Col>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-0">Telegram bot</h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={3} xl={3}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id='startup_tb'
                                                checked={form.startup_tb}
                                                onChange={() => {
                                                    setForm({ ...form, startup_tb: !form.startup_tb })
                                                }}
                                            />
                                            Включать Телеграм бот после перезагрузки
                                        </div>

                                    </Col>
                                    <Col xs={1} md={1} xl={1}></Col>

                                    {/* </Row>
                                <Row> */}
                                    <Col xs={12} md={3} xl={3}>
                                        <div className="mb-3 mt-3">
                                            <button
                                                onClick={() => {
                                                    setStatusInvestor(!statusInvestor)
                                                    changeBot()
                                                }
                                                }
                                                className='btn btn-primary'
                                                title={!statusInvestor ? 'Возобновить доступ к Боту для Инвесторов' : 'Приостановить доступ к Боту для Инвесторов'}>
                                                {!statusInvestor ? 'Возобновить для Инвесторов' : 'Приостановить для Инвесторов'}
                                            </button>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={3} xl={3}>
                                        <div className="mb-3 mt-3">
                                            <button
                                                onClick={() => {
                                                    setStatusBot(true)
                                                    powerBot()
                                                }
                                                }
                                                className={statusBot ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                                title={!statusBot ? 'Запустить Telegram Bot' : 'Telegram Bot запущен . . .'}>
                                                {!statusBot ? 'Запустить Telegram Bot' : 'Telegram Bot запущен . . .'}
                                            </button>
                                        </div>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-3">Время отправки сообщений в ТГ группу</h4>
                                    </div>
                                </CardTitle>
                                <Row className='align-items-center'>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className='d-flex w-100'>
                                            <ThemeProvider theme={darkTheme}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                    locale={ruLocale}
                                                >
                                                    <TimePicker
                                                        renderInput={(props) => <TextField size="small" {...props} />}
                                                        label="Введите время"
                                                        value={timeValue}
                                                        ampm={false}
                                                        onChange={(newValue) => {
                                                            console.log(newValue)
                                                            if (newValue) {
                                                                setTimeValue(moment(newValue));
                                                            }
                                                            else setTimeValue(null)
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </ThemeProvider>
                                            <button
                                                disabled={!timeValue?.isValid()}
                                                className='btn btn-primary ms-2'
                                                style={{ minHeight: 40 }}
                                                onClick={() => {
                                                    if (timeValue?.isValid()) {
                                                        const res = timeValue.format('HH') + ':' + timeValue.format('mm') + ':' + '00'
                                                        console.log(timeValue.isValid())
                                                        setTimeValue(null)
                                                        setForm(prev => ({ ...prev, time_bot: [...prev.time_bot, res] }))
                                                    }
                                                }}
                                            >
                                                Добавить
                                            </button>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={6} xl={8}>
                                        {
                                            (Array.isArray(form.time_bot) && !!form.time_bot.length) ?
                                                <div className='d-flex align-items-start justify-content-between'>
                                                    <div>
                                                        {
                                                            form.time_bot.map((item, index) => (
                                                                <div key={index} className='d-inline-flex align-items-center me-3 my-1'>
                                                                    <i className='bx bx-time fs-2 me-1'></i>
                                                                    <h5 className='mb-0'> {item}</h5>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <button
                                                        disabled={!form.time_bot.length}
                                                        title='Удалить время'
                                                        className='btn btn-danger white-space-nowrap'
                                                        onClick={() => {
                                                            setForm(prev => ({ ...prev, time_bot: [] }))
                                                        }}
                                                    >
                                                        <i className='mdi mdi-delete' />
                                                        &nbsp;
                                                        <span>
                                                            Удалить
                                                        </span>
                                                    </button>
                                                </div>
                                                :
                                                <h6 className="mb-0 text-muted">Нет выбранного времени</h6>
                                        }

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={modalConfirm} toggle={toggleModalConfirm} size='xs'>
                <ModalHeader>
                    Запуск торгового ядра
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {textModal}
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModalConfirm()
                        }}>
                        Выполнить
                    </Button>
                    <Button color="danger" onClick={(e) => {
                        e.preventDefault()
                        setModalConfirm(false)
                    }}>
                        Отменить</Button>
                </ModalFooter>
            </Modal>

        </div >
    )
}

export default SettingsTotal;