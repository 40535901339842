import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const Report = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Прибыль портфеля "USDT" по годам</CardTitle>
          <div className="text-center">
            <div className="mb-2">
              <i className="bx bx-map-pin text-primary display-6" />
            </div>
            <h3>155.82 %</h3>
            <p>Суммарная прибыль</p>
          </div>

          <div>
                <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                      15.24 %<span className="float-end">2023 год</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "15.24%" }}
                          aria-valuenow="15.24"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                      54.45 %<span className="float-end">2022 год</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "54.45%" }}
                          aria-valuenow="54.45"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                      8.69 %<span className="float-end">2021 год</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "8.69%" }}
                          aria-valuenow="8.69"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                      44.24 %<span className="float-end">2020 год</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "44.24%" }}
                          aria-valuenow="44.24"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                      15.27 % <span className="float-end">2019 год</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "15.27%" }}
                          aria-valuenow="15.27"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                      17.93 % <span className="float-end">2018 год</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "17.93%" }}
                          aria-valuenow="17.93"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

          {/* <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">первая половина 2022 год</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">54.45 %</h5>
                  </td>
                  <td>
                    <Progress
                      value="54.45"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">2021 год</p>
                  </td>
                  <td>
                    <h5 className="mb-0">8.69 %</h5>
                  </td>
                  <td>
                    <Progress
                      value="8.69"
                      color="success"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">2020 год</p>
                  </td>
                  <td>
                    <h5 className="mb-0">44.24 %</h5>
                  </td>
                  <td>
                    <Progress
                      value="44.24"
                      color="warning"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">2019 год</p>
                  </td>
                  <td>
                    <h5 className="mb-0">15.27 %</h5>
                  </td>
                  <td>
                    <Progress
                      value="15.27"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">2018 год</p>
                  </td>
                  <td>
                    <h5 className="mb-0">17.93 %</h5>
                  </td>
                  <td>
                    <Progress
                      value="17.93"
                      color="success"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Report
