import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import axios from "axios";
import config from "../../config";
import { Redirect, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ReactPinField from "react-pin-field";

const CheckPin = () => {
  const { addToast } = useToasts();
  const { action, id } = useParams()
  const [redirect, setRedirect] = useState(false);

  const [pinCode, setPinCode] = useState(null);

  useEffect(() => {
    if (/^\d{6}$/.test(pinCode)) {
      axios
        .get(
          `${config.apiUrl}/trade-schemes/check-pin/${pinCode}/${action}/${id}`
        )
        .then(async (response) => {
          addToast(response.data.msg, {
            appearance: response.data.res.toLowerCase(),
            autoDismiss: true,
            autoDismissTimeout: 7500,
            transitionDuration: 2000
          });
          setRedirect(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [pinCode]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Проверка ПИН | TradeBot</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>
                  <Row>
                    <div className="mb-3 d-flex justify-content-center align-items-center">
                      <h4>Введите ПИН-код:</h4>
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex">
                      <ReactPinField
                        length="6"
                        validate={/^\d$/}
                        onChange={(v) => {
                          setPinCode(v);
                        }}
                      />
                    </div>
                  </Row>
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {redirect && (
        <Redirect
          to={{
            pathname: "/trade",
          }}
        />
      )}
    </div>
  );
};

export default CheckPin;
