import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            paper: '#080f20'
        }
    }
})

export default darkTheme