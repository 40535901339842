import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";
import config from "../../config";
import Select from "react-select";
import InputSpinner from "react-bootstrap-input-spinner";
import WV from "../../workVariables";

const Close3 = (props) => {
  const { close3, setClose3 } = props;
  const [ma, setMa] = useState([]);
  useEffect(() => {
    const getMa = async () => {
      await axios
        .get(`${config.apiUrl}/schemas-ma`)
        .then((response) => {
          const ma2 = [];
          response.data.forEach((item) => {
            ma2.push({ label: item.name, value: item.id });
          });
          setMa(ma2);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getMa();
  }, []);
  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`
    }),
  }
  return (
    <>
      <div className="text-muted flex-grow-1">
        <Row className="mt-3">
          <Col xs={12} lg={7} className="mb-3">
            <div className="d-flex gap-2">
              <div className="flex-grow-1">
                <h6 className="text-center">Выберите Moving Average</h6>
                <Select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: `${WV.colorHoverSelect}`,
                      primary: `${WV.colorSelected}`,
                    },
                  })}
                  placeholder="выбрать..."
                  styles={customStyles}
                  isDisabled={close3.isDisabled}
                  onChange={(value) => {
                    setClose3({ ...close3, maId: value.value });
                  }}
                  options={ma}
                  value={ma.filter(
                    (opt) => opt.value === close3.maId
                  )}
                  classNamePrefix="select2-selection"
                  className="flex-grow-1"
                />
              </div>
            </div>
          </Col>
          <Col xs={12} lg={5} className="mb-3">
            <h6 className="text-center">Процент</h6>
            <div className="d-flex gap-2 w-100">
              <InputSpinner
                value={close3.percFrom}
                name="percFrom"
                type={"real"}
                precision={1}
                max={1000}
                min={-1000}
                step={0.1}
                disabled={close3.isDisabled}
                onChange={(num) =>
                  setClose3({ ...close3, percFrom: num })
                }
                placeholder="0"
              />
              <InputSpinner
                value={close3.percTo}
                name="percTo"
                type={"real"}
                precision={1}
                max={1000}
                min={-1000}
                step={0.1}
                disabled={close3.isDisabled}
                onChange={(num) => setClose3({ ...close3, percTo: num })}
                placeholder="0"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Close3;
