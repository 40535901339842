import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Button } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import config from "../../../config";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import WV from '../../../workVariables'
import TableSearchBar from "../../../components/TableSearchBar";
var XLSX = require("xlsx");

const SiInvestor = () => {
    const { addToast } = useToasts()
    const [items, setItems] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataExcel, setTableDataExcel] = useState([])
    function userNameFormatter(cell, row) {
        return (
            <Link to={'/ip-investors/show/' + row.user.id} className='btn btn_md' title='Перейти на страницу Инвестора'>
                {cell}
            </Link>
        );
    }
    function portfolioNameFormatter(cell, row) {
        return (
            <Link to={'/portfolio/show/' + row.portfolio.id} className='btn btn_md' title='Перейти на страницу Портфеля у Инвестора'>
                {cell}
            </Link>
        );
    }
    function strategyFormatter(cell, row) {
        return (
            <Link to={'/strategy/show/' + row.strategy.id} className='btn btn_md' title='Перейти на страницу Стратегия у Инвестора'>
                { cell }
            </Link>         
        );
      }
    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
            style: {
                width: 11,
            }
        },
        {
            dataField: 'user.name_admin',
            text: 'Инвестор',
            sort: true,
            formatter: userNameFormatter
        },
        {
            dataField: 'portfolio.name',
            text: 'Портфель',
            sort: true,
            formatter: portfolioNameFormatter
        },
        {
            dataField: 'strategy.name',
            text: 'Название Стратегии',
            sort: true,
            formatter: strategyFormatter
        },
        {
            dataField: 'name_user',
            text: 'Название для Инвестора',
            sort: true,
        },
        {
            dataField: 'comment',
            text: 'Комментарии',
            sort: true,
            style: {
                width: 11,
            }
        },
        {
            dataField: 'show',
            text: 'ТБ',
            sort: false,
            style: {
                width: 11,
            }
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            style: {
                width: 11,
            }
        },
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const sizePerPageList = WV.sizePerPageArr.concat([{ text: 'Все', value: (tableData).length }])
    let nameTable = 'tableSet_str_investor'
    let sizePerPage = parseInt(localStorage.getItem(nameTable + 'perPage'));
    if (!sizePerPage) sizePerPage = parseInt(WV.sizePerPage);
    let page = parseInt(localStorage.getItem(nameTable + 'page'));
    if (!page) page = 1;
    const pageOptions = {
        sizePerPage: sizePerPage,
        page: page,
        sizePerPageList: sizePerPageList,
        totalSize: tableData.length,
        custom: true,
        onSizePerPageChange: (sizePerPage, page) => {
            localStorage.setItem(nameTable + 'perPage', sizePerPage)
            localStorage.setItem(nameTable + 'page', 1)
        },
        onPageChange: (page, sizePerPage) => {
            localStorage.setItem(nameTable + 'page', page)
        },
    }

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2'>
                <Link to={`/si-investor/show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                    <i class="far fa-eye" />
                </Link>
                <Link to={`/si-investor/edit/${props.id}`} className='btn btn-primary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
            </div>
        )
    }

    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/ip-si-index`)
                .then((response) => {
                    setItems(response.data)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    useEffect(() => {
        let tableData = []
        let tableDataExcel = [] 
        let itemObject = {};   
        if (items.length > 0)  {
            items.forEach(item => {
                itemObject = {};
                itemObject['Id'] = item.id;
                itemObject['Инвестор'] = item.user.name_admin;
                itemObject['Портфель'] = item.portfolio.name;
                itemObject['Стратегия'] = item.strategy.name;
                itemObject['Название для Инвестора'] = item.name_user;
                itemObject['Комментарии'] = item.comment;
    
                if (item.show === 0) {
                    itemObject['Отображать стратегию в ТБ'] = 'нет';
                    item.show =
                        <div className='d-flex gap-2'>
                            <div class="d-flex justify-content-center">
                                <i class="fas fa-times"></i>
                            </div>
                        </div>
    
                }
                if (item.show === 1) {
                    itemObject['Отображать стратегию в ТБ'] = 'да';
                    item.show =
                        <div className='d-flex gap-2'>
                            <div class="d-flex justify-content-center">
                                <i class="fas fa-check"></i>
                            </div>
                        </div>
    
                }
    
                tableData.push({               
                    ...item,
                    "actions": (<ActionButtons id={item.id} />)
                })
                tableDataExcel.push(itemObject)
            })
        }         
        setTableData(tableData)
        setTableDataExcel(tableDataExcel)
    }, [items])

    const { SearchBar } = Search;

    const exportExcel = () => {
        let wb = XLSX.utils.book_new()
        let ws = XLSX.utils.json_to_sheet(tableDataExcel)
        XLSX.utils.book_append_sheet(wb, ws, "Strategies")
        XLSX.writeFile(wb, "InvestorsStrategies.xlsx")
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Стратегии у Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Стратегии у Инвесторов</h4>
                                    </div>
                                </CardTitle>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField='id'
                                    sizePerPageList={sizePerPageList}
                                    columns={columns}
                                    data={tableData}>
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField='id'
                                            columns={columns}
                                            data={tableData}
                                            search
                                        >
                                            {
                                                toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col xl="6">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <TableSearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xl="6">
                                                                <div className='mb-3 d-flex justify-content-end align-items-center'>
                                                                    <Button onClick={exportExcel} className="btn-sm btn-outline-dark m-2">
                                                                        <i className='mdi mdi-file-excel me-1' />
                                                                        XLS Export
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={true}
                                                                        striped={true}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        sizePerPageList={sizePerPageList}
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                            }
                                        </ToolkitProvider>

                                    )}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SiInvestor