import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
} from "reactstrap"
import { Link, useParams, Redirect } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";
import InputSpinner from "react-bootstrap-input-spinner";

const OrdersInvestorEdit = (props) => {
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)


    const { id } = useParams()
    const [form, setForm] = useState({
        id: '',
        profit_percent: '',
        comment: '',
    })

    useEffect(() => {
        const getActions = async () => {
            const response = await axios.get(`${config.apiUrl}/ip-iorders-edit/` + id)
            setForm({
                id: response.data.id,
                profit_percent: response.data.profit_percent,
                profit_percent_old: response.data.profit_percent,
                comment: response.data.comment,
                disabled: response.data.disabled,
                comment_old: response.data.comment 
            })
        }
        getActions()
    }, [])

    const updateItem = () => {
        axios.post(`${config.apiUrl}/ip-iorders-update`, form)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                // if (response.data.res === "Success") {
                    setRedirect(true)
                // }
            })
            .catch(error => {
                console.error(error);
            })
    }
    
    return (
        <div className="page-content">
            <MetaTags>
                <title>Редактирование сделки Инвестора | TradeBot</title>
            </MetaTags>
            <form onSubmit={(event) => {
                event.preventDefault()
                updateItem()
            }}>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle className={'mb-3'}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className='mb-0'>Редактирование сделки Инвестора #{id}</h4>
                                            <div className='d-flex gap-2 align-items-center'> 
                                            <Button color="primary" onClick={updateItem}>
                                                Изменить
                                            </Button>
                                            <Link to='/orders-investor' className='btn btn-danger btn'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                            {/* <Link to='/orders-investor' className='btn btn-danger btn-sm'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link> */}
                                        </div>
                                    </CardTitle>
                                    <Row>
                                    {form.profit_percent !== '' &&
                                        <Col xs={12} md={6} xl={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="profit_percent">Изменить процент прибыли сделки от факт. суммы:</Label>
                                                <InputSpinner
                                                value={form.profit_percent}
                                                name="profit_percent"
                                                type={"real"}
                                                precision={2}
                                                max={100}
                                                min={-100}
                                                step={0.01}
                                                disabled={form.disabled}
                                                onChange={(num) => {
                                                    setForm({ ...form, profit_percent: num })
                                                    // setError({ ...error, period: true })
                                                    // errorItem = formErrors;
                                                    // delete errorItem.period
                                                    // setFormErrors(errorItem);
                                                }}
                                            />
                                                {/* <Input
                                                    required={true}
                                                    type="number"
                                                    min={0}
                                                    step={0.01}
                                                    max={99.99}
                                                    className="form-control"
                                                    placeholder='Значение...'
                                                    id="profit_percent"
                                                    value={form.profit_percent}
                                                    name="profit_percent"
                                                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                                /> */}
                                            </div>
                                        </Col>
}
                                        <Col xs={12} md={6} xl={8}>
                                            <div className="mb-3">
                                                <Label htmlFor="comment">Комментарии</Label>
                                                <Input
                                                    type="text"
                                                    value={form.comment}
                                                    className="form-control"
                                                    placeholder='...'
                                                    id="comment"
                                                    name="comment"
                                                    onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                  
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </form>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/orders-investor",
                    }}
                />
            }
        </div>
    )
}

export default OrdersInvestorEdit