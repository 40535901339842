import React from "react";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import WV from "../../workVariables";

const Close2 = (props) => {
  const { close2, setClose2 } = props;
  return (
    <>
      <div className="text-muted flex-grow-1">
        <Row>
          <Col>
            <h6 className="text-center">Цвет свечи</h6>
            <div className="d-flex gap-2">
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: `${WV.colorHoverSelect}`,
                    primary: `${WV.colorSelected}`,
                  },
                })}
                placeholder="выбрать..."
                isDisabled={close2.isDisabled}
                onChange={(value) => {
                  setClose2({ ...close2, color: value.value });
                }}
                options={[
                  { label: "Красная", value: "red" },
                  { label: "Зелёная", value: "green" },
                ]}
                value={[
                  { label: "Красная", value: "red" },
                  { label: "Зелёная", value: "green" },
                ].filter((opt, i) => opt.value === close2.color)}
                classNamePrefix="select2-selection"
                className="flex-grow-1"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Close2;
