import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import Select from "react-select";
// import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import ru from 'date-fns/locale/ru';
import MetaTags from "react-meta-tags";
import { useEffect } from "react";
import axios from "axios";
import fileDownload from "js-file-download";

import config from "../../config";
import { useToasts } from "react-toast-notifications";
import WV from "../../workVariables";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";
import darkTheme from "../../muiTheme";
import moment from "moment";

const TdSequental = (props) => {
  const { addToast } = useToasts();
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [form, setForm] = useState({
    symbol: "",
    interval: "",
    startTime: "",
    endTime: "",
  });

  useEffect(() => {
    setForm({ ...form, startTime: startTime, endTime: endTime });
  }, [startTime, endTime]);

  const coreAction = () => {
    const fileName = form.symbol + "-" + form.interval + ".xlsx";
    setSpinnerStatus(true);
    axios({
      url: `${config.apiUrl}/tds-core`,
      method: "POST",
      data: form,
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status === "success") {
          // console.log('SECOND AXIOS . . . .');
          setTimeout(() => {
            // console.log('.......send');
            axios
              .get(`${config.apiUrl}/tds-get-file/${fileName}`, {
                responseType: "blob",
              })
              .then((response) => {
                setSpinnerStatus(false);
                // console.log(response.data);
                fileDownload(response.data, fileName);
              })
              .catch((error) => {
                console.error(error);
              });
          }, 2000);
        } else {
          setTimeout(() => {
            addToast("Отчет не построен! Возможно некорректный запрос!", {
              appearance: "info",
              autoDismiss: true,
              autoDismissTimeout: 7500,
              transitionDuration: 2000,
            });
            setSpinnerStatus(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`,
    }),
  };
  return (
    <div className="page-content">
      <MetaTags>
        <title>TD Sequental | TradeBot</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className={"mb-3"}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">TD Sequential</h4>
                    <div className="d-flex justify-content-start align-items-center">
                      {spinnerStatus && (
                        <div style={{ marginRight: "13px" }}>
                          <Spinner className="ms-2" color="info" />
                        </div>
                      )}
                      <Button
                        color="primary"
                        onClick={coreAction}
                        disabled={spinnerStatus}
                      >
                        <i
                          className="bx bx-line-chart"
                          style={{ marginRight: "8px" }}
                        ></i>
                        Протестировать TD Sequental
                      </Button>
                    </div>
                  </div>
                </CardTitle>
                <Row>
                  <Col xs={12} md={6} xl={6}>
                    <div>
                      <Label htmlFor="symbol">Символ</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="symbol"
                        placeholder="выбрать..."
                        options={WV.symbolsList}
                        classNamePrefix="select2-selection"
                        name="symbol"
                        onChange={(e) => {
                          setForm({ ...form, symbol: e.value });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={6}>
                    <div>
                      <Label htmlFor="interval">Интервал</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="interval"
                        placeholder="выбрать..."
                        options={WV.timeframesList}
                        classNamePrefix="select2-selection"
                        name="interval"
                        onChange={(e) => {
                          setForm({ ...form, interval: e.value });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={6}
                    xl={6}
                    className="mt-4 d-flex align-items-center gap-2"
                  >
                    {/* <Label htmlFor="startTime">Время начала</Label> */}

                    <ThemeProvider theme={darkTheme}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ruLocale}
                      >
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="Время начала"
                          value={startTime}
                          ampm={false}
                          onChange={(newValue) => {
                            setStartTime(moment.utc(newValue));
                          }}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                    {/* <DatePicker
                                            id='startTime'
                                            name='startTime'
                                            locale="ru"
                                            selected={startTime}
                                            onChange={(date) => setStartTime(date)}
                                            timeInputLabel="Time:"
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            showTimeInput
                                        /> */}
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    xl={6}
                    className="mt-4 d-flex align-items-center gap-2"
                  >
                    {/* <Label htmlFor="endTime">Время завершения</Label> */}
                    <ThemeProvider theme={darkTheme}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ruLocale}
                      >
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="Время завершения"
                          value={endTime}
                          ampm={false}
                          onChange={(newValue) => {
                            setEndTime(moment.utc(newValue));
                          }}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                    {/* <DatePicker
                                            id='endTime'
                                            name='endTime'
                                            locale="ru"
                                            selected={endTime}
                                            onChange={(date) => setEndTime(date)}
                                        /> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>            
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TdSequental;
