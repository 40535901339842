import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,

} from "reactstrap"
import { Redirect, useHistory, useParams, Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from 'react'
import LookButtons from '../../../components/LookButtons'
import axios from "axios";
import config from "../../../config";

const CatalogPortfolioShow = () => {
    const { id } = useParams()
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)
    const history = useHistory();
    const [form, setForm] = useState({
        id: null,
        name: '',
        info: '',
        comment: '',
    })
    const [newSelect, setNewSelect] = useState(false)
    const [selectScheme, setSelectScheme] = useState([])
    const [structData, setStructData] = useState([])
    const [structItem, setStructItem] = useState([])
    const [schemes, setSchemes] = useState([]);
    const [edCount, setEdCount] = useState(0)

    // При выборе стратегии подгружаем ТС
    useEffect(() => {
        if (edCount != 0) {
            console.log('Zaxod');
            let lastItem = selectScheme[selectScheme.length - 1]
            console.log(lastItem);
            structData.map((element) => {
                if (element.id === lastItem.value) {
                    let newInfo = []
                    element.scheme.map((item) => {
                        let row = {
                            id_scheme: item.id,
                            name: item.name,
                            percent_scheme: null
                        }
                        newInfo.push(row)
                    })
                    let newItem = {
                        "id_strategy": lastItem.value,
                        "name_strategy": lastItem.label,
                        "info_strategy": newInfo
                    }
                    setStructItem([...structItem, newItem])
                }
            })
            if (selectScheme.length !== 0 && newSelect) {
                setSchemes(schemes.filter(item => item.value !== lastItem.value))
                setNewSelect(false)
            }
        }
    }, [selectScheme])

    useEffect(() => {
        setForm({ ...form, info: structItem })
    }, [structItem])

    // Старт
    useEffect(() => {
        const getData = async () => {
            axios
                .get(`${config.apiUrl}/ip-catalog-portfolio-show/${id}`)
                .then(async (response) => {
                    setForm({
                        id: id,
                        name: response.data.name,
                        info: response.data.info,
                        comment: response.data.comment,
                    })

                    setSelectScheme(response.data.info)
                    setStructItem(response.data.info)

                    // Меню Стратегий
                    let arraySchemes = [];
                    const getStrategy = () => {
                        axios
                            .get(`${config.apiUrl}/ip-strategy-index`)
                            .then(async (resp) => {
                                setStructData(resp.data)
                                for await (let item of resp.data) {
                                    let option = {};
                                    option.label = item.name;
                                    option.value = item.id;
                                    arraySchemes.push(option);
                                }
                                let newArraySchemes = []
                                response.data.info.map((el, index) => {
                                    if (index === 0) {
                                        newArraySchemes = arraySchemes.filter(oneS => oneS.value !== el.id_strategy)
                                    } else {
                                        newArraySchemes = newArraySchemes.filter(oneS => oneS.value !== el.id_strategy)
                                    }
                                })
                                setSchemes(newArraySchemes);
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    };
                    getStrategy();

                })
                .catch((error) => {
                    console.error(error);
                });
        }
        getData()
    }, []);

    // СОХРАНИТЬ
    const edit = async () => {
        await axios.post(`${config.apiUrl}/ip-catalog-portfolio-update`, form)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500
                })
                if (response.data.res === "Success") {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    // Удалить стратегию из портфеля
    const deleteScheme = async (value, label) => {
        let data = form.info
        data.map((el, index) => {
            if (el.id_strategy === value) {
                data.splice(index, 1);
            }
        })
        setForm({ ...form, info: data })
        let option = {
            label: label,
            value: value
        }
        let schemesList = schemes
        schemesList.unshift(option)
        setSchemes(schemesList)
    }

    // Структура портфеля
    function Structure() {
        let structure = form.info
        console.log(form.info);
        if (structure.length > 0) {
            return (
                <Row>
                    <Col className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Название стратегии</th>
                                    <th className='d-flex justify-content-between align-items-center'>
                                        <span>
                                            Название торговой схемы
                                        </span>
                                        <span className="me-4">
                                            Процент от портфеля
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {structure.map((item, indexSTR) =>
                                    <tr>
                                        <td>
                                            <h6>
                                                <LookButtons
                                                    url='/strategy/show/'
                                                    id={item.id_strategy}
                                                    title='Посмотреть торговую стратегию'
                                                    lable={item.name_strategy}
                                                />
                                            </h6>
                                        </td>
                                        <td style={{ width: "60%" }}>
                                            <ul>
                                                {item.info_strategy.map((el, indexTS) =>
                                                    <li style={{ listStyle: "none", marginLeft: "25px", margin: "5px" }}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div>
                                                                <h6 className='mb-0'>
                                                                    <LookButtons
                                                                        url='/trade/show/'
                                                                        id={el.id_scheme}
                                                                        title='Посмотреть торговую схему'
                                                                        lable={el.name}
                                                                    />
                                                                </h6>
                                                            </div>
                                                            <div className="me-0">
                                                                <Input
                                                                    value={el.percent_scheme}
                                                                    name={indexSTR + ',' + indexTS}
                                                                    type={"text"}
                                                                    max={100}
                                                                    min={0}
                                                                    step={0.01}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </td>

                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                    </Col>
                </Row>
            )
        } else {
            return (<></>)
        }
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Стандартные портфели | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Просмотр Стандартного портфеля #{id}</h4>
                                        <div>
                                            <Link to={`/catalog-portfolio/edit/${id}`} className='btn btn-secondary me-2' title='Редактировать'>
                                                <i className='mdi mdi-pencil me-1' />
                                                Редактировать
                                            </Link>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Администратора</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                disabled={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                value={form.comment}
                                                disabled={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Структура Портфеля</h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} md={12} lg={12}>
                                                <div className="mb-3">
                                                    <Structure />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {redirect &&
                <Redirect
                    to={{
                        pathname: "/catalog-portfolio",
                    }}
                />
            }
        </div>
    )
}

export default CatalogPortfolioShow