import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Label,
    Input,
    Button,
    // FormFeedback
} from "reactstrap";
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import InputSpinner from "react-bootstrap-input-spinner";

const Type3 = (props) => {
    const { type3, setType3 } = props
    
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3  mb-4">
                        <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                            style={{ width: 50, height: 50 }}>
                            <i className="fas fa-info"></i>
                        </div>
                        <info_1 />
                        Тип В) Стоп передвигается на определённый процент от % от изменения цены закрытия;
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Разница Stop Price и Price, %</Label>
                        <InputSpinner
                        value={type3.delta_perc}
                        name="delta_perc"
                        type={"real"}
                        disabled={true}
                      />                       
                    </div>
                </Col>
                <Col xs={12} md={6} xl={8}>
                <Label htmlFor="period">Условие 1</Label>
                    <div className='d-flex align-items-center'>
                        <Input
                            type="checkbox"
                            className="form-check me-2"
                            placeholder='Введите процент...'
                            id='condition1'
                            disabled={true}
                            checked={type3.condition1}
                          
                        />
                        Stop не ниже изначального
                    </div>
                </Col>                
            </Row>
            <Row>
                <Col xs={12} md={6} xl={4}>
                <div className="mb-3">
                        <Label htmlFor="period">Сдвиг цены, %</Label>
                        {/* <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}> */}
                                <InputSpinner
                            value={type3.shift_sl}
                            name="shift_sl"
                            type={"real"}
                            precision={1}
                            disabled={true}
                        />                                
                    </div>
                   
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Условие 2 (не ниже безубытка, в %):</Label>
                        <InputSpinner
                            value={type3.condition2}
                            name="condition2"
                            type={"real"}
                            precision={1}
                            disabled={true}
                        />  
                        
                    </div>
                </Col>
                </Row>
                <Row>
                <Col xs={12} md={6} xl={4}> </Col>
                <Col xs={12} md={6} xl={8}>
                    <Label htmlFor="period">Условие 3</Label>
                    <div className='d-flex align-items-center'>
                        <Input
                            type="checkbox"
                            className="form-check me-2"
                            placeholder='Введите процент...'
                            id='condition3'
                            disabled={true}
                            checked={type3.condition3}                            
                        />
                        Stop не ниже предыдущего
                    </div>
                </Col>
                        {/* Т.к. набор сдвигов пока не используем - закомментили */}
                {/* <Col xs={12} md={6} xl={6}>
                    <div className="mb-3">
                        <Label htmlFor="period">Выбрать набор сдвигов, %</Label>
                        <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}>
                                <Select
                                    id='stop_id'
                                    placeholder="выбрать..."
                                    isDisabled={status.shift_sl && true}
                                    onChange={e => {
                                        setType3({ ...type3, stop_id: e.value })
                                        setStatus({ ...status, stop_id: true })
                                        setSelectStop({ label: e.label, value: e.value })
                                    }}
                                    options={stops}
                                    value={selectStop}
                                    classNamePrefix="select2-selection"

                                />
                            </div>
                            <Button
                                className='btn btn-danger'
                                disabled={status.shift_sl && true}
                                onClick={clearStop}>
                                <i class="far fa-trash-alt"></i>
                            </Button>
                        </div>
                    </div>
                </Col> */}
            </Row>
        </>
    );
}

export default Type3;
