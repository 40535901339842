import React from 'react';
import { Col, Row } from "reactstrap";

const Type4 = () => {
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3">
                        <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                            style={{ width: 50, height: 50 }}>
                            <i className="fas fa-info"></i>
                        </div>
                        <info_1 />
                        Тип Г) Стоп ставится изначально и не меняется (стандартный биржевой стоп);
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Type4;
