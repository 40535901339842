import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Label,
    Input,
    Button,
    // FormFeedback
} from "reactstrap";
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import InputSpinner from "react-bootstrap-input-spinner";

const Type3 = (props) => {
    const { type3, setType3, formErrors, setFormErrors } = props
    const [stops, setStops] = useState([])
    const [selectStop, setSelectStop] = useState({
        label: '',
        value: ''
    })
    const [status, setStatus] = useState({
        shift_sl: false,
        stop_id: false
    })
    useEffect(() => {
        let arrayStops = [];
        const getStops = async () => {
            await axios.get(`${config.apiUrl}/stops`)
                .then(async (response) => {
                    for await (let item of response.data) {
                        let option = {}
                        option.label = item.name
                        option.value = item.id
                        arrayStops.push(option)

                        if (item.id === type3.stop_id) {
                            setSelectStop({
                                label: item.name,
                                value: item.id
                            })
                        }
                    }
                    setStops(arrayStops)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStops()
    }, [type3.stop_id])

    // useEffect(() => {
    //     if (type3.shiftStatus) setStatus({ ...status, shift_sl: true })
    //     if (type3.stopStatus) setStatus({ ...status, stop_id: true })
    // }, [type3.shiftStatus, type3.stopStatus, status])

    useEffect(() => {
        if (type3.shiftStatus) setStatus({ ...status, shift_sl: true })
        if (type3.stopStatus) setStatus({ ...status, stop_id: true })
    }, [])

    const clearShift = () => {
        setStatus({ ...status, shift_sl: false })
        setType3({ ...type3, shift_sl: '' })
    }
    const clearStop = () => {
        setStatus({ ...status, stop_id: false })
        setType3({ ...type3, stop_id: '' })
        setSelectStop(null)
    }
    console.log(type3);
    console.log(status);
    let errorItem;
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3  mb-4">
                        <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                            style={{ width: 50, height: 50 }}>
                            <i className="fas fa-info"></i>
                        </div>
                        <info_1 />
                        Тип В) Стоп передвигается на определённый процент от % от изменения цены закрытия;
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Разница Stop Price и Price, %</Label>
                        <InputSpinner
                        value={type3.delta_perc}
                        name="delta_perc"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {
                            setType3({ ...type3, delta_perc: num })
                            errorItem = formErrors;
                            delete errorItem.delta_perc;
                            setFormErrors(errorItem);
                        }}
                      />
                      <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.delta_perc}</p>                       
                    </div>
                </Col>
                <Col xs={12} md={6} xl={8}>
                <Label htmlFor="period">Условие 1</Label>
                    <div className='d-flex align-items-center'>
                        <Input
                            type="checkbox"
                            className="form-check me-2"
                            placeholder='Введите процент...'
                            id='condition1'
                            disabled={type3.condition3 && true}
                            checked={type3.condition1}
                            onChange={() => setType3({ ...type3, condition1: !type3.condition1 })}                            
                        />
                        Stop не ниже изначального
                    </div>
                </Col>                
            </Row>
            <Row>
                <Col xs={12} md={6} xl={4}>
                <div className="mb-3">
                        <Label htmlFor="period">Сдвиг цены, %</Label>
                        {/* <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}> */}
                                <InputSpinner
                            value={type3.shift_sl}
                            name="shift_sl"
                            type={"real"}
                            precision={1}
                            max={100}
                            min={0}
                            step={0.1}
                            onChange={(num) => {
                                setType3({ ...type3, shift_sl: num })
                                setStatus({ ...status, shift_sl: true })
                                errorItem = formErrors;
                                delete errorItem.shift_sl;
                                setFormErrors(errorItem);
                            }}                           
                        />
                        <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.shift_sl}</p>   
                                {/* <Input
                                    type="number"
                                    min={1}
                                    step={0.01}
                                    value={type3.shift_sl}
                                    disabled={status.stop_id && true}
                                    className="form-control"
                                    placeholder='Введите процент...'
                                    id="shift_sl"
                                    onChange={(e) => {
                                        setType3({ ...type3, shift_sl: e.target.value })
                                        setStatus({ ...status, shift_sl: true })
                                    }}
                                />
                            </div>
                            ======== кнопку закомментили, т.к. набор сдвигов не работает 
                            <Button
                                className='btn btn-danger'
                                disabled={status.stop_id && true}
                                onClick={clearShift}>
                                <i class="far fa-trash-alt"></i>
                            </Button>
                        </div> */}
                    </div>
                   
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Условие 2 (не ниже безубытка, в %):</Label>
                        <InputSpinner
                            value={type3.condition2}
                            name="condition2"
                            type={"real"}
                            precision={1}
                            max={100}
                            min={0}
                            step={0.1}
                            onChange={(num) => {
                                setType3({ ...type3, condition2: num })
                            }}
                        />  
                     
                    </div>
                </Col>
                </Row>
                <Row>
                <Col xs={12} md={6} xl={4}> </Col>
                <Col xs={12} md={6} xl={8}>
                    <Label htmlFor="period">Условие 3</Label>
                    <div className='d-flex align-items-center'>
                        <Input
                            type="checkbox"
                            className="form-check me-2"
                            placeholder='Введите процент...'
                            id='condition3'
                            disabled={type3.condition1 && true}
                            checked={type3.condition3}
                            onChange={() => setType3({ ...type3, condition3: !type3.condition3 })}
                        />
                        Stop не ниже предыдущего
                    </div>
                </Col>
                        {/* Т.к. набор сдвигов пока не используем - закомментили */}
                {/* <Col xs={12} md={6} xl={6}>
                    <div className="mb-3">
                        <Label htmlFor="period">Выбрать набор сдвигов, %</Label>
                        <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}>
                                <Select
                                    id='stop_id'
                                    placeholder="выбрать..."
                                    isDisabled={status.shift_sl && true}
                                    onChange={e => {
                                        setType3({ ...type3, stop_id: e.value })
                                        setStatus({ ...status, stop_id: true })
                                        setSelectStop({ label: e.label, value: e.value })
                                    }}
                                    options={stops}
                                    value={selectStop}
                                    classNamePrefix="select2-selection"

                                />
                            </div>
                            <Button
                                className='btn btn-danger'
                                disabled={status.shift_sl && true}
                                onClick={clearStop}>
                                <i class="far fa-trash-alt"></i>
                            </Button>
                        </div>
                    </div>
                </Col> */}
            </Row>
        </>
    );
}

export default Type3;
