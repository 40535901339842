import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, Label, CardBody, CardTitle, Col, Container, Row, Modal, ModalBody, ModalHeader, ModalFooter, Button, Input } from "reactstrap";
import Select from "react-select"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import { useParams, useHistory, Link } from "react-router-dom";
import LookButtons from '../../components/LookButtons';
import ModalConfirm from "../../components/ModalConfirm";
import TableSearchBar from "../../components/TableSearchBar";
import TableCustomToggleList from "../../components/TableCustomToggleList";
import PaginationProviderCustom1 from "../../components/PaginationProviderCustom";
import WV from '../../workVariables'
import { cardHeaderClasses } from '@mui/material';
var XLSX = require("xlsx");

const Trades = (props) => {
    const { addToast } = useToasts()
    const { id } = useParams()
    const history = useHistory();

    const [orders, setOrders] = useState([])
    const [ordersLength, setOrdersLength] = useState(1)
    const [tableData, setTableData] = useState([])
    const [tableDataExcel, setTableDataExcel] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalConfirm, setModalConfirm] = useState(false)
    const [modalGiveLoan, setModalGiveLoan] = useState(false)
    const [modalDeleteAll, setModalDeleteAll] = useState(false)
    const [changeStatus, setChengeStatus] = useState({
        id: null,
        status: null
    })
    const [deleteId, setDeleteId] = useState(null)
    const [giveLoanId, setGiveLoanId] = useState(null)
    function tradeFormatter(cell, row) {
        return (
            <Link to={'/trade/show/' + row.trade_scheme.id} className='btn btn_md' title='Перейти на страницу Торговой схемы'>
                {cell}
            </Link>
        );
    }
    let columns = [
        {
            dataField: 'id',
            text: <span className='text'>№ сделки</span>,
            sort: true,
            classes: 'id-custom-cell',
            headerClasses: 'table-th-50', 
        },
        {
            dataField: 'trade_scheme.name',
            text: <span className='text'>ТС</span>,
            sort: true,
            formatter: tradeFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'position',
            text: <span className='text'>Позиция</span>,
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'sumOrder',
            text: <span className='text'>Сумма ордера</span>, 
            sort: true,
            headerClasses: 'table-th-50'
        },
        {
            dataField: 'date_open',
            text: <span className='text'>Дата открытия</span>, 
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'date_close',
            text: <span className='text'>Дата закрытия</span>,
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'price_open',
            text: <span className='text'>Цена открытия</span>,  
            sort: true, 
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'price_close',
            text: <span className='text'>Цена закрытия</span>,  
            sort: true, 
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'total_percent',
            text: <span className='text'>Комиссия</span>, 
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'profit_sum',
            text: <span className='text'>Прибыль в валюте</span>,  
            sort: true,
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'profit_percent',
            text: <span className='text'>Прибыль в %</span>,   
            sort: true,
            headerClasses: 'table-th-60'
        },
        {
            dataField: 'status_name', 
            text: <span className='text'>Статус</span>, 
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'actions',
            text: 'Действия',
            style: {
                width: 111,
            },
            headerClasses: 'py-20'
        }
    ];


    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const toggleModal = () => {
        setModalOpen(!modalOpen)
    }
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }
    const toggleGiveLoan = () => {
        setModalGiveLoan(!modalGiveLoan)
    }
    const toggleDeleteAll = () => {
        setModalDeleteAll(!modalDeleteAll)
    }
    const changeStatusCore = () => {
        axios.post(`${config.apiUrl}/order-change-status`, changeStatus)
            .then((response) => {
                const getOrders = async () => {
                    const response = await axios.get(`${config.apiUrl}/orders`)
                    setOrdersLength(response.data.length)
                    setOrders(response.data)
                }

                getOrders()

                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })

                }, 300);


            })
            .catch(error => {
                console.error(error);
            })
    }

    const removeItem = async (id) => {
        id = deleteId
        await axios.get(`${config.apiUrl}/orders-delete/${id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setOrders(orders.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const giveLoan = async (id) => {
        id = giveLoanId
        await axios.get(`${config.apiUrl}/orders/give-a-loan/${id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    // setOrders(orders.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2'>
                {props.status === 2
                    ?
                    <div className='d-flex gap-2'>
                        <Link to={`/history-show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                            <i class="far fa-eye" />
                        </Link>
                        <button onClick={() => setModalOpen(true)} className='btn btn-primary disabled' title='Изменить статус сделки'>
                            <i className='mdi mdi-pencil' />
                            {/* {props.id} */}
                        </button>
                    </div>
                    :
                    <div className='d-flex gap-2'>
                        <Link to={`/history-show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                            <i class="far fa-eye" />
                        </Link>
                        <button
                            onClick={() => {
                                setModalOpen(true)
                                setChengeStatus({ ...changeStatus, id: props.id })
                            }
                            }
                            className='btn btn-primary' title='Изменить статус сделки'>
                            <i className='mdi mdi-pencil' />
                            {/* {props.id} */}
                        </button>
                    </div>
                }
                {props.status === 3
                    ?
                    <div className='d-flex gap-2'>
                        <button
                            onClick={() => {
                                setModalGiveLoan(true)
                                setGiveLoanId(props.id)
                            }}
                            className='btn btn-warning'
                            title='Отдать заем на бирже в ручном режиме'>
                            <i class="fas fa-hand-holding-usd mr-2"></i>
                        </button>
                    </div>
                    :
                    <div className='d-flex gap-2'>
                        <button onClick={() => giveLoan(props.id)} className='btn btn-warning disabled' title='Отдать заем на бирже в ручном режиме'><i class="fas fa-hand-holding-usd mr-2"></i></button>
                    </div>
                }
                <div className='d-flex gap-2'>
                    <button
                        onClick={() => {
                            setModalConfirm(true)
                            setDeleteId(props.id)
                        }
                        }
                        className='btn btn-danger' title='Удалить'>
                        <i className='mdi mdi-delete' />
                    </button>
                </div>
            </div>
        )
    }

    useEffect(() => {

        const getOrders = async () => {
            const response = await axios.get(`${config.apiUrl}/orders`)
            setOrdersLength(response.data.length)
            setOrders(response.data)
        }

        const getOrdersByScheme = async () => {
            const response = await axios.get(`${config.apiUrl}/order-by-scheme/${id}`)
            setOrdersLength(response.data.length)
            setOrders(response.data)
        }

        if (id === 'all') {
            getOrders()
        } else {
            getOrdersByScheme()
        }
    }, [id])

    useEffect(() => {
        let tableData = []
        if (orders.length > 0) {
            orders.forEach(item => {
                switch (item.status) {
                    case 1: item.status_name = 'Открытая'
                        break;
                    case 2: item.status_name = 'Закрытая'
                        break;
                    case 3: item.status_name = 'Открытие/заем на бирже'
                        break;
                    case 4: item.status_name = 'Открытие/открыли на бирже'
                        break;
                    case 5: item.status_name = 'Закрытие/отменили stop-loss'
                        break;
                    case 6: item.status_name = 'Закрытие/закрыли на бирже'
                        break;
                    case 7: item.status_name = 'Закрытие/закрыли по stop-loss'
                        break;

                    default:
                        break;
                }

                tableData.push({
                    ...item,
                    "actions": (<ActionButtons id={item.id} status={item.status} />)
                })
            })
        }
        setTableData(tableData)
        let tableDataExcel = []
        let itemObject = {};
        if (orders.length > 0) {
            orders.forEach(item => {
                itemObject['№ сделки'] = item.id;
                itemObject['ТС'] = item.trade_scheme.name;
                itemObject['Позиция'] = item.position;
                itemObject['Сумма ордера'] = item.sumOrder;
                itemObject['Дата открытия'] = item.date_open.substr(0, 10);
                itemObject['Время открытия'] = item.date_open.substr(11, 8);
                itemObject['Дата закрытия'] = item.date_close.substr(0, 10);
                itemObject['Время закрытия'] = item.date_close.substr(11, 8);
                itemObject['Цена открытия'] = item.price_open;
                itemObject['Цена закрытия'] = item.price_close;
                itemObject['Комиссия'] = item.total_percent;
                itemObject['Прибыль в валюте'] = item.profit_sum;
                itemObject['Прибыль в %'] = item.profit_percent;
                switch (item.status) {
                    case 1: itemObject['Статус'] = 'Открытая'
                        break;
                    case 2: itemObject['Статус'] = 'Закрытая'
                        break;
                    case 3: itemObject['Статус'] = 'Открытие/заем на бирже'
                        break;
                    case 4: itemObject['Статус'] = 'Открытие/открыли на бирже'
                        break;
                    case 5: itemObject['Статус'] = 'Закрытие/отменили stop-loss'
                        break;
                    case 6: itemObject['Статус'] = 'Закрытие/закрыли на бирже'
                        break;
                    case 7: itemObject['Статус'] = 'Закрытие/закрыли по stop-loss'
                        break;
                    default:
                        break;
                }
                // console.log(itemObject);
                tableDataExcel.push(itemObject)
                itemObject = {}
            })
        }
        setTableDataExcel(tableDataExcel)
    }, [orders])
    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color: `${WV.colorSelectInput}`
        }),
    }

    const deleteOrders = () => {
        if (id === 'all') {
            const getOrders = async () => {
                const response = await axios.get(`${config.apiUrl}/orders`)
                setOrdersLength(response.data.length)
                setOrders(response.data)
            }

            axios.get(`${config.apiUrl}/orders/delete-all`)
                .then((response) => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    getOrders()
                })
                .catch(error => {
                    console.error(error);
                })
        } else {
            const getOrdersByScheme = async () => {
                const response = await axios.get(`${config.apiUrl}/order-by-scheme/${id}`)
                setOrdersLength(response.data.length)
                setOrders(response.data)
            }

            axios.get(`${config.apiUrl}/orders/delete-by-scheme/${id}`)
                .then((response) => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    getOrdersByScheme()
                })
                .catch(error => {
                    console.error(error);
                })

        }

    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>История сделок | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>История сделок</h4>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    setModalDeleteAll(true)
                                                }
                                                }
                                                className='btn-sm btn-danger m-2' title='Удалить все сделки с истории'>
                                                <i className='mdi mdi-delete me-1' />
                                                Удалить все сделки с истории
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>

                                {ordersLength === 0
                                    ?
                                    <>
                                        <Row>
                                            <Col xs={12} md={6} xl={10}>
                                                {/* <UncontrolledAlert
                                                    color="danger"
                                                    className="alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    <i className="mdi mdi-block-helper me-2"></i>
                                                    Сделок по ТС нет!
                                                </UncontrolledAlert> */}
                                                <div className="mb-4">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value='Сделок по ТС нет!'
                                                    /> </div>
                                            </Col>
                                            <Col xs={12} md={6} xl={2}>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                        <i className='mdi mdi-backspace' />
                                                        &nbsp;
                                                        Вернуться
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <PaginationProviderCustom1
                                        columns={columns} tableData={tableData} nameTable="tableSet_historyAll"
                                        tableDataExcel={tableDataExcel}
                                        defaultSorted={defaultSorted}
                                        nameExcel={'HistoryOrders.xlsx'}
                                        nameList={'Orders List'} />
                                }

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={modalOpen} toggle={toggleModal} size='xs'>
                <ModalHeader>
                    Изменить статус сделки #{changeStatus.id}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <div>
                                <Label htmlFor="status">Выберите стутус: </Label>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: `${WV.colorHoverSelect}`,
                                            primary: `${WV.colorSelected}`,
                                        },
                                    })}
                                    styles={customStyles}
                                    // id='status'
                                    placeholder="Выберите статус ..."
                                    options={WV.statusTradesList}
                                    classNamePrefix="select2-selection"
                                    name="status"
                                    // value={}
                                    onChange={(value) => {
                                        setChengeStatus({ ...changeStatus, status: parseInt(value.value) })
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModal()
                            changeStatusCore()
                        }}>
                        Изменить
                    </Button>
                    <Button color="danger" onClick={toggleModal}>Отмена</Button>
                </ModalFooter>
            </Modal>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление сделки'
            />

            <Modal isOpen={modalGiveLoan} toggle={toggleGiveLoan} size='xs'>
                <ModalHeader>
                    Отдать займ
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {WV.textGiveLoan}
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleGiveLoan()
                            giveLoan(changeStatus.id)
                        }}>
                        Подтвердить
                    </Button>
                    <Button color="danger" onClick={toggleGiveLoan}>Отмена</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalDeleteAll} toggle={toggleDeleteAll} size='xs'>
                <ModalHeader>
                    Удаление сделок
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {WV.textDeleteOrders}
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleDeleteAll()
                            deleteOrders()
                        }}>
                        Подтвердить
                    </Button>
                    <Button color="danger" onClick={toggleDeleteAll}>Отмена</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Trades;
