import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import "../../assets/datatables.scss"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import axios from "axios";
import config from "../../config";
import WV from '../../workVariables'
import { useToasts } from "react-toast-notifications";
import TableSearchBar from "../../components/TableSearchBar";

const TelegramUsers = () => {
    const { addToast } = useToasts()


    const blocking = (id, action) => {
        let urlPart
        action === 0 ? urlPart = "block" : urlPart = "unblock"
        axios.get(`${config.apiUrl}/telegram-bot/${urlPart}/${id}`)
            .then(async (response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    const response = await axios.get(`${config.apiUrl}/telegram-users`)
                    setTelegramchat(response.data)
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const statusUsers = (id, action) => {
        axios.get(`${config.apiUrl}/telegram-users/${action}/${id}`)
            .then(async (response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    const response = await axios.get(`${config.apiUrl}/telegram-users`)
                    setTelegramchat(response.data)
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2 justify-content-end'>
                <div className='d-flex gap-2'>
                    {props.role === 'user' &&
                        <button
                            onClick={() => {
                                toggleModalUserAdmin(true)
                                setUserInfo({ id: props.id, action: "toadmin" })
                            }}
                            className='btn btn-primary'
                            title='Перевести пользователя в Администраторы'>
                            <i className='mdi mdi-arrow-up' />
                        </button>
                    }
                    {props.role === 'admin' &&
                        <button
                            onClick={() => {
                                toggleModalUserAdmin(true)
                                setUserInfo({ id: props.id, action: "touser" })
                            }}
                            className='btn btn-primary'
                            title='Перевести администратора в Пользователя'>
                            <i className='mdi mdi-arrow-down' />
                        </button>
                    }
                </div>
                <div className='d-flex gap-2'>
                    {props.active === 0 &&
                        <button
                            onClick={() => {
                                toggleModalBlockConfirm(true)
                                setBlockInfo({ id: props.id, action: 1 })
                            }
                            }
                            className='btn btn-success'
                            title='Разблокировать пользователя'>
                            <i class="fas fa-lock-open"></i>
                        </button>
                    }
                    {props.active === 1 &&
                        <button
                            onClick={() => {
                                toggleModalBlockConfirm(true)
                                setBlockInfo({ id: props.id, action: 0 })
                            }
                            }
                            className='btn btn-danger'
                            title='Заблокировать пользователя'>
                            <i class="fas fa-lock"></i>
                        </button>
                    }
                    {props.active === 2 &&
                        <button
                            onClick={() => {
                                toggleModalBlockConfirm(true)
                                setBlockInfo({ id: props.id, action: 1 })
                            }
                            }
                            className='btn btn-success'
                            title='Разблокировать пользователя'>
                            <i class="fas fa-lock-open"></i>
                        </button>
                    }
                </div>
            </div>
        )

    }
    const [telegramchat, setTelegramchat] = useState([])
    const [tableData, setTableData] = useState([])

    // Модалка удаления
    const [modalBlockConfirm, setModalBlockConfirm] = useState(false)
    const [modalUserAdmin, setModalUserAdmin] = useState(false)

    const [blockInfo, setBlockInfo] = useState({
        id: null,
        action: null
    })

    const [userInfo, setUserInfo] = useState({
        id: null,
        action: null
    })

    const toggleModalBlockConfirm = () => {
        setModalBlockConfirm(!modalBlockConfirm)
    }
    const toggleModalUserAdmin = () => {
        setModalUserAdmin(!modalUserAdmin)
    }


    const columns = [
        {
            dataField: 'tb_from_id',
            text: 'Telegram ID',
            sort: true,
        },
        {
            dataField: 'tb_first_name',
            text: 'Имя',
            sort: true
        },
        {
            dataField: 'tb_last_name',
            text: 'Фамилия',
            sort: true
        },
        {
            dataField: 'tb_username',
            text: 'Ник',
            sort: true
        },
        {
            dataField: 'tb_role',
            text: 'Роль',
            sort: true
        },
        {
            dataField: 'tb_active',
            text: 'Активность',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            style: {
                width: 111,
            }

        },
    ]

    useEffect(() => {
        const getTelegram = async () => {
            const response = await axios.get(`${config.apiUrl}/telegram-users`)
            setTelegramchat(response.data)
        }
        getTelegram()
    }, [])

    useEffect(() => {
        let tableData = []
        if (telegramchat.length > 0) {
            telegramchat.forEach(tel => {
                if (tel.tb_role === null) return
                let role
                if (tel.tb_role === "admin") role = "Администратор"
                if (tel.tb_role === "user") role = "Пользователь"
                let active
                if (tel.tb_active === 0) active = <i class="fas fa-times"></i>
                if (tel.tb_active === 1) active = <i class="fas fa-check"></i>
                if (tel.tb_active === 2) active = <i class="fas fa-times"></i>

                tableData.push({
                    ...tel,
                    "actions": (<ActionButtons id={tel.id} role={tel.tb_role} active={tel.tb_active} />),
                    "tb_role": role,
                    "tb_active": active
                })
            })
        }
        setTableData(tableData)
    }, [telegramchat])


    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const sizePerPageList = WV.sizePerPageArr.concat([{ text: 'Все', value: (tableData).length }])
    let nameTable = 'tableSet_telegram_users'
    let sizePerPage = parseInt(localStorage.getItem(nameTable + 'perPage'));
    if (!sizePerPage) sizePerPage = parseInt(WV.sizePerPage);
    let page = parseInt(localStorage.getItem(nameTable + 'page'));
    if (!page) page = 1;
    const pageOptions = {
        sizePerPage: sizePerPage,
        page: page,
        sizePerPageList: sizePerPageList,
        totalSize: tableData.length,
        custom: true,
        onSizePerPageChange: (sizePerPage, page) => {
            localStorage.setItem(nameTable + 'perPage', sizePerPage)
            localStorage.setItem(nameTable + 'page', 1)
        },
        onPageChange: (page, sizePerPage) => {
            localStorage.setItem(nameTable + 'page', page)
        },
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Телеграм пользователи | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Телеграм пользователи</h4>
                                    </div>
                                </CardTitle>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField='id'
                                    sizePerPageList={sizePerPageList}
                                    columns={columns}
                                    data={tableData}
                                >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField='id'
                                            columns={columns}
                                            data={tableData}
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col md="4">
                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <TableSearchBar
                                                                        {...toolkitProps.searchProps}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    keyField={"id"}
                                                                    responsive
                                                                    bordered={true}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                />

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row className="align-items-md-center mt-30">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <SizePerPageDropdownStandalone
                                                                    sizePerPageList={sizePerPageList}
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                            }
                                        </ToolkitProvider>
                                    )
                                    }</PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={modalBlockConfirm} toggle={toggleModalBlockConfirm} size='xs'>
                <ModalHeader>
                    Блокировка Пользователей
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {blockInfo.action === 0 &&
                                WV.textConfirmBlock
                            }
                            {blockInfo.action === 1 &&
                                WV.textConfirmUnblock
                            }
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModalBlockConfirm()
                            blocking(blockInfo.id, blockInfo.action)
                        }}>
                        Подтвердить
                    </Button>
                    <Button color="danger" onClick={toggleModalBlockConfirm}>Отмена</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalUserAdmin} toggle={toggleModalUserAdmin} size='xs'>
                <ModalHeader>
                    Назначение Пользователей
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {userInfo.action === "toadmin" &&
                                WV.textConfirmUserAdmin
                            }
                            {userInfo.action === "touser" &&
                                WV.textConfirmAdminUser
                            }
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModalUserAdmin()
                            statusUsers(userInfo.id, userInfo.action)
                        }}>
                        Подтвердить
                    </Button>
                    <Button color="danger" onClick={toggleModalUserAdmin}>Отмена</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default TelegramUsers;
