import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Button} from "reactstrap";
import axios from "axios";
import config from "../../../config";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ModalConfirm from "../../../components/ModalConfirm";
import PaginationProviderCustom from "../../../components/PaginationProviderCustom";

var XLSX = require("xlsx");


const ProfitInvestor = () => {
    const { addToast } = useToasts()
    const [items, setItems] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataExcel, setTableDataExcel] = useState([])
    function userNameFormatter(cell, row) {
        return (
            <Link to={'/ip-investors/show/' + row.user.id} className='btn btn_md' title='Перейти на страницу Инвестора'>
                {cell}
            </Link>
        );
    }
    function portfolioNameFormatter(cell, row) {
        return (
            <Link to={'/portfolio/show/' + row.portfolio.id} className='btn btn_md' title='Перейти на страницу Портфеля у Инвестора'>
                {cell}
            </Link>
        );
    }
    const columns = [
        {
            dataField: 'year',
            text: 'Год',
            sort: true,
            style: {
                width: 111,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'month',
            text: 'Месяц',
            sort: true,
            style: {
                width: 111,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'user.name_admin',
            text: 'Инвестор',
            sort: true,
            style: {
                width: 111,
            },
            formatter: userNameFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'portfolio.name',
            text: 'Портфель',
            sort: true,
            style: {
                width: 111,
            },
            formatter: portfolioNameFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'profit',
            text: 'Прибыль',
            sort: true,
            style: {
                width: 111,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'comission_fond',
            text: 'Комиссия',
            sort: true,
            style: {
                width: 111,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'profit_investor',
            text: <span className='text'>Прибыль Инвестора</span>,
            sort: true, 
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'profit_investor_by_all_period',
            text: 'Прибыль Инвестора за весь период',
            text: <span className='text'>Прибыль Инвестора за весь период</span>,
            sort: true, 
            headerClasses: 'table-th-150'
        },
        {
            dataField: 'profit_in_balance',
            text: 'Прибыль в Балансе',
            text: <span className='text'>Прибыль в Балансе</span>,
            sort: true, 
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'comission_fond_by_all_period',
            text: 'Комиссия фонда за весь период',
            text: <span className='text'>Комиссия фонда за весь период</span>,
            sort: true, 
            headerClasses: 'table-th-150'
        },
        {
            dataField: 'comission_fond_in_balance', 
            text: <span className='text'>Комиссия фонда в Балансе</span>,
            sort: true, 
            headerClasses: 'table-th-150'
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            style: {
                width: 111,
            },
            headerClasses: 'py-20'
        },
    ];
   
    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    // Модалка удаления
    const [modalConfirm, setModalConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }
    const removeItem = async (id, portId) => {
        axios.get(`${config.apiUrl}/ip-pfb-delete/${id}/${portId}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    const getData = async () => {
                        axios.get(`${config.apiUrl}/ip-pfb-index`)
                            .then((response) => {
                                setItems(response.data)
                            })
                            .catch(error => {
                                console.error(error);
                            })
                    }
                    getData()
                    // setItems(items.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                console.error(error);
            })

    }


    const ActionButtons = (props) => {

        return (
            <div className='d-flex gap-2'>
                {/* <Link to={`/ip-balance-investor/edit/${props.id}`}
                    className='btn btn-secondary'
                    title='Просмотр записи в Балансе'>
                    <i class="far fa-eye mr-2"></i>
                </Link> */}

                {props.profitInBalance === null &&
                    <div className='d-flex gap-2'>
                        <button
                            onClick={() => {
                                setModalConfirm(true)
                                setDeleteId(props.id)
                            }
                            }
                            className='btn btn-danger' title='Удалить запись о начислении'>
                            <i className='mdi mdi-delete' />
                        </button>
                        {/* <button onClick={() => removeItem(props.id, props.portId)}
                            className='btn btn-danger'
                            title='Удалить запись о начислении'>
                            <i className='mdi mdi-delete' />
                        </button> */}
                    </div>
                }
                {props.profitInBalance !== null &&
                    <div className='d-flex gap-2'>
                        <button className='btn btn-danger disabled'
                            title='Удалить запись о начислении'>
                            <i className='mdi mdi-delete' />
                        </button>
                    </div>
                }
            </div>
        )
    }

    useEffect(() => {
        const getData = async () => {
            axios.get(`${config.apiUrl}/ip-pfb-index`)
                .then((response) => {
                    setItems(response.data)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    useEffect(() => {
        let tableData = []
        let tableDataExcel = [] 
        let itemObject = {};   
        if (items.length > 0) {
            items.forEach(item => {
                itemObject = {};
                itemObject['Год'] = item.year;
                itemObject['Месяц'] = item.month;
                itemObject['Инвестор'] = item.user.name_admin;
                itemObject['Портфель'] = item.portfolio.name;
                itemObject['Прибыль'] = item.profit;
                itemObject['Комиссия'] = item.comission_fond;
                itemObject['Прибыль инвестора'] = item.profit_investor;
                itemObject['Прибыль инвестора за весь период'] = item.profit_investor_by_all_period;
                itemObject['Прибыль в балансе'] = item.profit_in_balance;
                itemObject['Комиссия фонда за весь период'] = item.comission_fond_by_all_period;
                itemObject['Комиссия фонда в балансе'] = item.comission_fond_in_balance;
                tableData.push({
                    ...item,
                    "actions": (<ActionButtons id={item.id} profitInBalance={item.profit_in_balance} portId={item.portfolio_id} />)
                })
                tableDataExcel.push(itemObject)
            })
        }         
        setTableData(tableData)
        setTableDataExcel(tableDataExcel)
    }, [items])
    
    return (
        <div className="page-content">
            <MetaTags>
                <title>Прибыль и комиссия | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Прибыль и комиссия</h4>
                                        <div>
                                            <Link to='/ip-profit-investor-buttons/close_period' className='btn btn-outline-dark m-2'>
                                                Рассчитать период
                                            </Link>
                                            <Link to='/ip-profit-investor-buttons/calc_comission' className='btn btn-outline-dark m-2'>
                                                Начислить комиссию
                                            </Link>
                                            <Link to='/ip-profit-investor-buttons/show_profit' className='btn btn-outline-dark m-2'>
                                                Отразить прибыль в Балансе
                                            </Link>
                                            <Link to='/ip-profit-investor-buttons/show_comission' className='btn btn-outline-dark m-2'>
                                                Отразить комиссию в Балансе
                                            </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <PaginationProviderCustom
                                    columns={columns} tableData={tableData} nameTable="tableSet_profitInvestor" 
                                    tableDataExcel = {tableDataExcel} 
                                    defaultSorted = {defaultSorted}
                                    nameExcel = {'ProfitInvestor.xlsx'} 
                                    nameList = {'Profit'}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление записи о начислении'
            />
        </div>
    )
}

export default ProfitInvestor