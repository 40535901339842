const Logo = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 135.46667 135.46667"
        version="1.1"
        id="svg5"
        xmlns="http://www.w3.org/2000/svg"
        fill={props.fill}
    >

        <g
            id="layer1">
            <g
                id="g216">
                <path
                    d="m -73.745533,90.794756 c -5e-6,-12.0142 0.02017,-16.954074 0.04483,-10.977496 0.02466,5.976578 0.02467,15.806378 1e-5,21.844 -0.02466,6.03762 -0.04483,1.1477 -0.04484,-10.866504 z m 30.53544,20.288994 c 0.122236,-0.0489 0.268288,-0.0429 0.324556,0.0134 0.05627,0.0563 -0.04375,0.0963 -0.22225,0.0889 -0.197265,-0.008 -0.237389,-0.0482 -0.102306,-0.10231 z M -62.433912,88.424089 c 0.0014,-0.465667 0.03416,-0.636068 0.07308,-0.378672 0.03892,0.257397 0.03788,0.638397 -0.0023,0.846667 -0.04019,0.20827 -0.07204,-0.0023 -0.07077,-0.467995 z m -2.405729,-4.078111 c 0.0081,-0.197264 0.04825,-0.237388 0.102304,-0.102306 0.04892,0.122237 0.0429,0.268289 -0.01336,0.324556 -0.05627,0.05627 -0.09629,-0.04375 -0.08894,-0.22225 z m 2.396583,-1.763889 c 0,-0.325967 0.03485,-0.459317 0.07744,-0.296333 0.04259,0.162983 0.04259,0.429683 0,0.592666 -0.04259,0.162984 -0.07744,0.02963 -0.07744,-0.296333 z m -0.0063,-2.624667 c 0.0035,-0.2794 0.0414,-0.3732 0.08434,-0.208444 0.04293,0.164756 0.04011,0.393356 -0.0063,0.508 -0.04639,0.114644 -0.08151,-0.02016 -0.07806,-0.299556 z m -0.0063,-0.931333 c 0,-0.232833 0.03844,-0.328083 0.08541,-0.211667 0.04697,0.116417 0.04697,0.306917 0,0.423334 -0.04697,0.116416 -0.08541,0.02117 -0.08541,-0.211667 z m 31.348362,-7.874 c 0.0014,-0.465667 0.03416,-0.636068 0.07308,-0.378672 0.03892,0.257397 0.03788,0.638397 -0.0023,0.846667 -0.04019,0.20827 -0.07204,-0.0023 -0.07077,-0.467995 z"
                    id="path232" />
                <path
                    d="m -57.432654,111.07251 c 2.637629,-0.0262 6.904829,-0.0262 9.482667,4e-5 2.577837,0.0263 0.419777,0.0478 -4.79569,0.0477 -5.215466,-2e-5 -7.324606,-0.0215 -4.686977,-0.0478 z M -79.190807,56.547104 c 6.317044,-0.0246 16.604044,-0.02459 22.86,9e-6 6.255956,0.0246 1.087464,0.04473 -11.485536,0.04472 -12.573,-5e-6 -17.691508,-0.02013 -11.374464,-0.04473 z"
                    id="path230" />
                <path
                    d="m -35.749277,60.441756 c -0.210628,-0.268573 -0.204639,-0.274562 0.06393,-0.06393 0.162983,0.127821 0.296333,0.261171 0.296333,0.296334 0,0.139368 -0.139055,0.04967 -0.360267,-0.2324 z m -55.212816,-3.88334 c 0.122236,-0.04892 0.268288,-0.0429 0.324556,0.01336 0.05627,0.05627 -0.04375,0.09629 -0.22225,0.08894 -0.197265,-0.0081 -0.237389,-0.04825 -0.102306,-0.102304 z"
                    id="path228" />
                <path
                    d="m -34.812432,89.482422 -0.322578,-0.381 0.381,0.322579 c 0.358045,0.303144 0.458087,0.439421 0.322578,0.439421 -0.03213,0 -0.203582,-0.17145 -0.381,-0.381 z M -86.82401,67.892571 c 2.211917,-0.02674 5.831417,-0.02674 8.043333,0 2.211917,0.02674 0.402167,0.04861 -4.021666,0.04861 -4.423834,0 -6.233584,-0.02188 -4.021667,-0.04861 z m 23.371222,0.0095 c 0.164757,-0.04293 0.393357,-0.04011 0.508,0.0063 0.114644,0.04639 -0.02015,0.08151 -0.299555,0.07806 -0.2794,-0.0035 -0.373201,-0.0414 -0.208445,-0.08433 z m 3.054902,-0.0093 c 1.286151,-0.0288 3.343551,-0.02873 4.572,1.64e-4 1.228448,0.02889 0.176143,0.05246 -2.338457,0.05237 -2.5146,-9e-5 -3.519695,-0.02373 -2.233543,-0.05253 z m 8.287209,5.08e-4 c 0.814917,-0.03104 2.148417,-0.03104 2.963334,0 0.814916,0.03104 0.148166,0.05644 -1.481667,0.05644 -1.629833,0 -2.296583,-0.0254 -1.481667,-0.05644 z"
                    id="path226" />
                <path
                    d="m -53.539427,70.443749 c 0.122237,-0.04891 0.268289,-0.0429 0.324556,0.01336 0.05627,0.05627 -0.04375,0.09629 -0.22225,0.08894 -0.197264,-0.0081 -0.237388,-0.04825 -0.102306,-0.102305 z"
                    id="path224" />
                <path
                    d="m -37.590343,79.978156 c 0,-0.03516 0.13335,-0.168513 0.296333,-0.296334 0.268573,-0.210628 0.274562,-0.204639 0.06393,0.06393 -0.221211,0.282067 -0.360266,0.371768 -0.360266,0.2324 z m -49.730212,-9.545588 c 2.031951,-0.02703 5.308551,-0.027 7.281334,7.3e-5 1.972782,0.02707 0.310278,0.04919 -3.694456,0.04914 -4.004733,-3.9e-5 -5.618828,-0.02219 -3.586878,-0.04922 z m 26.752007,5.08e-4 c 0.912888,-0.03051 2.360688,-0.03037 3.217333,3.39e-4 0.856646,0.03067 0.109738,0.05563 -1.659795,0.05546 -1.769533,-1.63e-4 -2.470426,-0.02526 -1.557538,-0.05576 z m 5.325205,0.0038 c 0.302683,-0.03756 0.797983,-0.03756 1.100666,0 0.302684,0.03756 0.05503,0.0683 -0.550333,0.0683 -0.605367,0 -0.853017,-0.03073 -0.550333,-0.0683 z m 3.394706,-0.0036 c 0.772773,-0.03145 1.991973,-0.03127 2.709334,3.39e-4 0.717362,0.03166 0.08509,0.05739 -1.40504,0.05718 -1.490134,-1.7e-4 -2.077065,-0.02612 -1.304294,-0.05757 z"
                    id="path222" />
                <path
                    d="m -57.952677,113.61253 c 2.631017,-0.0262 6.936317,-0.0262 9.567334,0 2.631016,0.0262 0.478366,0.0477 -4.783667,0.0477 -5.262033,0 -7.414683,-0.0215 -4.783667,-0.0477 z M -78.767481,59.087104 c 6.270475,-0.02461 16.481275,-0.0246 22.690667,1e-5 6.209393,0.02461 1.079004,0.04474 -11.400863,0.04474 -12.479866,-5e-6 -17.560278,-0.02014 -11.289804,-0.04475 z"
                    id="path220" />
            </g>
            <g
                id="g5347"
                transform="matrix(0.74024104,0,0,0.74024104,17.81571,24.172194)">
                <path
                    d="m -12.273438,-23.458984 a 8.9357004,8.9357004 0 0 0 -8.935546,8.935546 8.9357004,8.9357004 0 0 0 8.935546,8.9375005 H 110.89844 a 8.936594,8.936594 0 0 0 0.11328,-0.00195 c 0,0 6.3036,0.044465 12.36133,3.1054687 6.05773,3.06100349 11.59765,7.5129689 11.59765,21.7421879 0,12.414271 -5.30529,18.730386 -12.07226,23.285156 -6.76698,4.55477 -15.86153,6.21875 -18.02149,6.21875 v 0.002 c -6e-5,10e-7 -0.0254,-0.002 -0.0254,-0.002 a 8.936594,8.936594 0 0 0 0.0254,17.871094 c 0.008,0 0.0177,-0.0019 0.0254,-0.002 v 0.002 c 0,0 9.4116,0.09614 18.48438,4.042968 9.07278,3.946833 16.88999,9.792461 17.11719,25.802735 0.19676,13.866501 -3.85225,18.863631 -8.34961,21.962891 -4.49736,3.09926 -10.8325,3.88476 -14.86914,3.88477 H 77.900391 V 25.134766 h 24.476559 a 8.9357004,8.9357004 0 0 0 8.93555,-8.935547 8.9357004,8.9357004 0 0 0 -8.93555,-8.9355471 H 68.964844 A 8.936594,8.936594 0 0 0 60.029297,16.199219 V 131.26367 a 8.936594,8.936594 0 0 0 8.935547,8.93555 h 48.320316 c 5.81008,0 15.77895,-0.67979 25.00976,-7.04102 9.23082,-6.36122 16.33515,-18.81893 16.07813,-36.931638 -0.29157,-20.546473 -12.09574,-33.465319 -24.00196,-39.982421 9.81207,-7.21402 18.47071,-19.671391 18.47071,-36.984375 0,-19.97775157 -10.9633,-32.414507 -21.41016,-37.69336 -10.44686,-5.278852 -20.64844,-5.023437 -20.64844,-5.023437 l 0.11524,-0.002 z"
                    id="path1199" />
                <path
                    d="m -12.240234,7.2636719 a 8.9357157,8.9357157 0 0 0 -8.935547,8.9355471 8.9357157,8.9357157 0 0 0 8.935547,8.935547 H 28.470703 V 131.37305 a 8.9357157,8.9357157 0 0 0 8.935547,8.93554 8.9357157,8.9357157 0 0 0 8.935547,-8.93554 V 16.199219 A 8.9366092,8.9366092 0 0 0 37.40625,7.2636719 Z"
                    id="path6501" />
            </g>
        </g>
    </svg>
);

export default Logo;
