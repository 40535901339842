import axios from "axios";
import config from "../../config";

const shortLong = [
  { label: "Short", value: "Short" },
  { label: "Long", value: "Long" },
];
const lessMore = [
  { label: "Меньше", value: "less" },
  { label: "Больше", value: "more" },
];
const openClose = [
  { label: "Открытия", value: "open" },
  { label: "Закрытия", value: "close" },
  { label: "Цена High", value: "high" },
  { label: "Цена Low", value: "low" },
];
const candleNumber = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
];
const tihTilList = [
  { label: "TIH", value: "TIH" },
  { label: "TIL", value: "TIL" },
];
const colors = [
  { label: "Красная", value: "red" },
  { label: "Зелёная", value: "green" },
];

let tt1 = [];
let tt2 = [];
let tihTil = [];
let tihTil1 = [];
let tihTil2 = [];

const getTihTil = async () => {
  const response = await axios.get(`${config.apiUrl}/schemas-tihtil`);
  tihTil = response.data;
  response.data[0].forEach((item, index) => {
    tt1.push({ label: item.name, value: item.id });
  });
  response.data[1].forEach((item, index) => {
    tt2.push({ label: item.name, value: item.id });
  });
  tihTil1 = tt1;
  tihTil2 = tt2;
};
// getTihTil();

const selectOptions = {
  shortLong,
  lessMore,
  openClose,
  candleNumber,
  tihTilList,
  colors,
  tihTil,
  tihTil1,
  tihTil2,
};

export default selectOptions;
