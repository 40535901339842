import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
} from "reactstrap"
import { useToasts } from "react-toast-notifications";
import { useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../config";
import InputSpinner from "react-bootstrap-input-spinner";

export default function StopsEdit() {
    const history = useHistory()
    const { addToast } = useToasts()
    const { id } = useParams()
    const [newLevel, setNewLevel] = useState(0)
    const [form, setForm] = useState({
        id: null,
        name: '',
        comment: null,
        sets: null
    })

    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/stops-show/` + id)
                .then((response) => {
                    setForm({
                        id: id,
                        name: response.data.name,
                        comment: response.data.comment,
                        sets: response.data.sets,
                    })
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [id])

    useEffect(() => {
        // let levelsAr = levels
        // if (setsAr && levelsAr.length > 0) {
        //     let fullAr = setsAr.concat(levelsAr)
        //     let uniqueArray = fullAr.filter(function (item, pos) {
        //         return fullAr.indexOf(item) == pos;
        //     })
        //     setForm({ ...form, sets: uniqueArray })
        // }
    }, [])

    const handleAddLevel = async (e) => {
        e.preventDefault()
        let check = true
        let setsAr = form.sets
        let setsNew = []
        setError({ ...error, sets: true });
        errorItem = formErrors;
        delete errorItem.sets;
        setFormErrors(errorItem);

        for await (let el of setsAr) {
            setsNew.push(parseFloat(el))
            if (parseFloat(el) === parseFloat(newLevel)) check = false
        }

        if (check) setsNew.push(parseFloat(newLevel))
        setForm({ ...form, sets: setsNew })
    }

    const deleteSet = (value) => {
        let sets = form.sets
        sets = sets.filter(item => item !== value)
        setForm({ ...form, sets: sets })
    }

    const StopsRow = (props) => {
        return (
            <>
                {props.stops &&
                    <div className='d-flex justify-content-center align-items-center flex-wrap'>
                        {props.stops.map((el, index) => {
                            return (
                                <a onClick={(e) => {
                                    if (props.stops.length != 1) {                                        
                                        e.preventDefault()
                                        deleteSet(el)
                                    } else {
                                        setTimeout(() => {
                                            addToast("Нельзя удалить все Стопы!", {
                                                appearance: "info",
                                                autoDismiss: true,
                                                autoDismissTimeout: 7500,
                                                transitionDuration: 2000
                                            })
                                        }, 300);
                                    }
                                }} title="Удалить текущий уровень стопа">
                                    <div className="p-3 d-flex justify-content-center align-items-center rounded-circle me-2"
                                        style={{
                                            width: 56,
                                            height: 56,
                                            backgroundColor: "rgba(0,0,0,.3)",
                                            marginRight: 4
                                        }}
                                        key={index}
                                    >
                                        {el}
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                }
            </>
        )
    }

    const [error, setError] = useState({
        name: false,
    });

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const edit = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";  
        if (!values.sets) errors.sets = "Введите стопы";      
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/stops-update`, form)
            .then((response) => {
                history.goBack()
                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })

                }, 300);
            })
            .catch(error => {
                console.error(error);
            })
        }
    }, [formErrors]); 
    
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Наборы стопов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Редактировать Набор стопов #{id}</h4>
                                        <div>
                                            <Button color="primary" className="me-2" onClick={edit}>
                                                Изменить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                name="name"
                                                value={form.name}
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                }}  
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <Label htmlFor="stops">Новый стоп</Label>
                                        <div className="d-flex align-items-center mr-2">
                                            <InputSpinner
                                                value={newLevel}
                                                id="new_level"
                                                name="new_level"
                                                type={"real"}
                                                precision={1}
                                                max={100}
                                                min={0}
                                                step={0.1}
                                                onChange={(num) => setNewLevel(num)}
                                            />
                                            <div style={{paddingLeft: 9}}>
                                                <Button color="success" onClick={handleAddLevel}>
                                                    +
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                value={form.comment}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row className="d-flex flex-wrap">
                                    <Col xs={12} md={12} lg={12}>
                                        <StopsRow stops={form.sets} />
                                    </Col>
                                    <p style={{ fontSize: "10px", color: "#e06466" }}>
                                    {" "}
                                    {formErrors.sets}
                                </p>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}
