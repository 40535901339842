import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
} from "reactstrap"
import { Link, useParams, Redirect } from "react-router-dom";
import Select from "react-select"
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import Type1 from "./types_show/Type1"
import Type2 from "./types_show/Type2"
import Type3 from "./types_show/Type3"
import Type4 from "./types_show/Type4"
import Type5 from "./types_show/Type5"
import Type6 from "./types_show/Type6"
import Type7 from "./types_show/Type7"
import WV from '../../workVariables'

export default function StopLossShow() {
    const { id } = useParams()

    const { addToast } = useToasts()
    const [type, setType] = useState('0')
    const [type1, setType1] = useState({})
    const [type2, setType2] = useState({})
    const [type3, setType3] = useState({})
    const [type5, setType5] = useState({})
    const [type6, setType6] = useState({})
    const [type7, setType7] = useState({})

    const [form, setForm] = useState({
        name: '',
        type: '',
        comment: '',
        schema: null,
    })

    useEffect(() => {

        const getStopLoss = async () => {
            await axios.get(`${config.apiUrl}/stop-loss/edit/` + id)
                .then(async (response) => {
                    // console.log(response.data.data);

                    let selectType = {}
                    for await (let item of WV.stopLossTypeList) {
                        item.type = parseInt(item.value)
                        if (response.data.data.type === item.type) {
                            selectType.label = item.label
                            selectType.value = item.value
                        }
                    }

                    let condition1 = false
                    let condition3 = false

                    if (response.data.data.condition1 === 1) condition1 = true
                    if (response.data.data.condition3 === 1) condition3 = true

                    let shift_sl = null, stop_id = null
                    let shiftStatus = false, stopStatus = false
                    if (response.data.data.shift_sl) {
                        shift_sl = response.data.data.shift_sl
                        stop_id = null
                        stopStatus = false
                        shiftStatus = true
                    }
                    if (response.data.data.stop_id) {
                        shift_sl = null
                        shiftStatus = false
                        stopStatus = true
                        stop_id = response.data.data.stop_id
                    }

                    switch (response.data.data.type) {
                        case 1:
                            setType1({
                                stop_price_perc: response.data.data.stop_price_perc,
                                delta_perc: response.data.data.delta_perc,
                                condition1: condition1,
                                condition2: response.data.data.condition2,
                                condition3: condition3,
                            })
                            break;
                        case 2:
                            setType2({
                                delta_perc: response.data.data.delta_perc,
                                condition1: condition1,
                                condition2: response.data.data.condition2,
                                condition3: condition3,
                                shift_sl: shift_sl,
                                stop_id: stop_id,
                                shiftStatus: shiftStatus,
                                stopStatus: stopStatus
                            })
                            break;
                        case 3:
                            setType3({
                                delta_perc: response.data.data.delta_perc,
                                condition1: condition1,
                                condition2: response.data.data.condition2,
                                condition3: condition3,
                                shift_sl: shift_sl,
                                stop_id: stop_id,
                                shiftStatus: shiftStatus,
                                stopStatus: stopStatus
                            })
                            break;
                        case 5:
                            setType5({
                                percent: response.data.data.percent,
                                delta_perc: response.data.data.delta_perc,
                                shift_sl: shift_sl,
                                stop_id: stop_id,
                                shiftStatus: shiftStatus,
                                stopStatus: stopStatus
                            })
                            break;
                        case 6:
                            setType6({
                                delta_perc: response.data.data.delta_perc,
                                moving_average_id: response.data.data.moving_average_id,
                                percent: response.data.data.percent,
                                shift_sl: shift_sl,
                                stop_id: stop_id,
                                condition1: condition1,
                                condition3: condition3,
                                shiftStatus: shiftStatus,
                                stopStatus: stopStatus
                            })
                            break;
                        case 7:
                            setType7({
                                delta_perc: response.data.data.delta_perc,
                                moving_average_id: response.data.data.moving_average_id,
                                shift_sl: shift_sl,
                                stop_id: stop_id,
                                shiftStatus: shiftStatus,
                                stopStatus: stopStatus
                            })
                            break;

                        default:
                            break;
                    }

                    setType(selectType)
                    setForm({
                        name: response.data.data.name,
                        type: selectType.label,
                        comment: response.data.data.comment
                    })
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStopLoss()
    }, [id])

    useEffect(() => {
        setForm({
            name: form.name,
            type: form.type,
            comment: form.comment
        })
    }, [type, form.name, form.type, form.comment])

    const storeItem = async () => {
        try {
            let data = form
            let dataType
            switch (form.type) {
                case '1': dataType = type1
                    break;
                case '2': dataType = type2
                    break;
                case '3': dataType = type3
                    break;
                case '5': dataType = type5
                    break;
                case '6': dataType = type6
                    break;
                case '7': dataType = type7
                    break;
                default:
                    break;
            }
            data = Object.assign(data, dataType);
            data.id = id

            await axios.post(`${config.apiUrl}/stop-loss/update`, data)
                .then((response) => {
                    console.log(response);
                    if (response.data.res === "Success") {
                        setRedirect(true)
                    }
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                })
                .catch((error) => {
                    // ????????????????????????  ???????
                    addToast(error.msg, {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 7500
                    })
                    console.error(error);
                })

        } catch (error) {
        }
    }

    const [redirect, setRedirect] = useState(false)

    return (
        <div className="page-content">
            <MetaTags>
                <title>Редактирование Stop Loss схемы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card className="pb-5">
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Просмотр Stop Loss схемы</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Link to={`/stop-loss/edit/${id}`} className='btn btn-secondary me-2' title='Редактировать'>
                                                <i className='mdi mdi-pencil' />
                                                &nbsp;
                                                Редактировать
                                            </Link>
                                            <Link to='/stop-loss' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={form.name}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div>
                                            <Label htmlFor="position">Тип StopLoss</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={form.type}
                                                readOnly={true}
                                            />                                           
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="name"
                                                value={form.comment}
                                                readOnly={true}
                                            />                                           
                                        </div>
                                    </Col>
                                </Row>
                                {type.value === '1' &&
                                    <div>
                                        <Type1 type1={type1} setType1={setType1} />
                                    </div>
                                }
                                {type.value === '2' &&
                                    <div>
                                        <Type2 type2={type2} setType2={setType2} />
                                    </div>
                                }
                                {type.value === '3' &&
                                    <div>
                                        <Type3 type3={type3} setType3={setType3} />
                                    </div>
                                }
                                {type.value === '4' &&
                                    <div>
                                        <Type4 />
                                    </div>
                                }
                                {type.value === '5' &&
                                    <div>
                                        <Type5 type5={type5} setType5={setType5} />
                                    </div>
                                }
                                {type.value === '6' &&
                                    <div>
                                        <Type6 type6={type6} setType6={setType6} />
                                    </div>
                                }
                                {type.value === '7' &&
                                    <div>
                                        <Type7 type7={type7} setType7={setType7} />
                                    </div>
                                }
                            </CardBody>
                        </Card>                     
                    </Col>
                </Row>
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/stop-loss",
                    }}
                />
            }
        </div>
    )
}
