import Sidebar from "../components/Sidebar";
import {useState} from "react";
import Header from "../components/Header";

const Layout = (props) => {

    // const [isPreloader, setIsPreloader] = useState(true)
    const [sidebarType, setSidebarType] = useState('default')



    // useEffect(() => {
    //     if (isPreloader === true) {
    //         document.getElementById("preloader").style.display = "block"
    //         document.getElementById("status").style.display = "block"
    //
    //         setTimeout(function () {
    //             setIsPreloader(false)
    //         }, 1000)
    //     } else {
    //         document.getElementById("preloader").style.display = "none"
    //         document.getElementById("status").style.display = "none"
    //     }
    // }, [isPreloader])

    const toggleMenuCallback = () => {
        if(sidebarType === 'default') {
            setSidebarType('condensed')
        } else {
            setSidebarType('default')
        }
    }

    return (
        <>
            {/*<div id="preloader">*/}
            {/*    <div id="status">*/}
            {/*        <div className="spinner-chase">*/}
            {/*            <div className="chase-dot" />*/}
            {/*            <div className="chase-dot" />*/}
            {/*            <div className="chase-dot" />*/}
            {/*            <div className="chase-dot" />*/}
            {/*            <div className="chase-dot" />*/}
            {/*            <div className="chase-dot" />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div id="layout-wrapper">
                <Header toggleMenuCallback={toggleMenuCallback} />
                <Sidebar
                    theme="dark"
                    type={sidebarType}
                    isMobile={false}
                />
                <div className="main-content">{props.children}</div>
                {/*<Footer />*/}
            </div>
        </>
    )
}

export default Layout
