import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import axios from "axios";
import config from "../../../config";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ModalConfirm from "../../../components/ModalConfirm";
import PaginationProviderCustom from "../../../components/PaginationProviderCustom";


const TsiInvestor = () => {
    const { addToast } = useToasts()
    const [items, setItems] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataExcel, setTableDataExcel] = useState([])
    function userNameFormatter(cell, row) {
        return (
            <Link to={'/ip-investors/show/' + row.user.id} className='btn btn_md' title='Перейти на страницу Инвестора'>
                {cell}
            </Link>
        );
    }
    function portfolioNameFormatter(cell, row) {
        return (
            <Link to={'/portfolio/show/' + row.portfolio.id} className='btn btn_md' title='Перейти на страницу Портфеля у Инвестора'>
                {cell}
            </Link>
        );
    }
    function strategyInvestorFormatter(cell, row) {
        return (
            <Link to={'/si-investor/show/' + row.strategy_investor.id} className='btn btn_md' title='Перейти на страницу Стратегия у Инвестора'>
                {cell}
            </Link>
        );
    }
    function tradeSchemmeNameFormatter(cell, row) {
        return (
            <Link to={'/trade/show/' + row.trade_scheme.id} className='btn btn_md' title='Перейти на страницу Портфеля'>
                {cell}
            </Link>
        );
    }
    const columns = [
        {
            dataField: 'id',
            text: <span className='text'>Id</span>, 
            sort: true,
            style: {
                width: 11,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'user.name_admin',
            text: 'Инвестор',
            sort: true,
            formatter: userNameFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'summa_investor',
            text: <span className='text'>Сумма инвестиций</span>,  
            sort: true,
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'summa',
            text: <span className='text'>Сумма в торговле</span>,   
            sort: true,
            headerClasses: 'table-th-80'
        },

        {
            dataField: 'portfolio.name',
            text: 'Портфель',
            sort: true,
            formatter: portfolioNameFormatter,
            headerClasses: 'table-th-nowrap'
        },

        {
            dataField: 'strategy_investor.name_user',
            text: <span className='text'>Стратегия у Инвестора</span>,
            sort: true,
            formatter: strategyInvestorFormatter,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'trade_scheme.name', 
            text: <span className='text'>ТС</span>,
            sort: true,
            formatter: tradeSchemmeNameFormatter,
            headerClasses: 'table-th-50 py-20'
        },
        {
            dataField: 'name_user',
            text: <span className='text'>Название для Инвестора</span>,
            sort: true,
            headerClasses: 'table-th-120'
        },
        {
            dataField: 'active',
            text: 'Активность',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'show',
            text: <span className='text'>Отображение в ТБ</span>,
            sort: true,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'comment',
            text: 'Комментарии',
            sort: true,
            style: {
                width: 11,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            style: {
                width: 111,
            },
            headerClasses: 'py-20'

        },
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    // Модалка удаления
    const [modalConfirm, setModalConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }
    const removeItem = (id) => {
        axios.get(`${config.apiUrl}/ip-tsi-delete/${id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "success") {
                    setItems(items.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                console.error(error);
            })

    }

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2'>
                <Link to={`/tsi-investor/show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                    <i class="far fa-eye" />
                </Link>
                <Link to={`/tsi-investor/edit/${props.id}`} className='btn btn-primary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
                <div className='d-flex gap-2'>
                    <button
                        onClick={() => {
                            setModalConfirm(true)
                            setDeleteId(props.id)
                        }
                        }
                        className='btn btn-danger' title='Удалить'>
                        <i className='mdi mdi-delete' />
                    </button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/ip-tsi-index`)
                .then((response) => {
                    setItems(response.data)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    useEffect(() => {
        let tableData = []
        let tableDataExcel = []
        let itemObject = {};
        if (items.length > 0) {
            items.forEach(item => {
                itemObject = {};
                itemObject['Id'] = item.id;
                itemObject['Инвестор'] = item.user.name_admin;
                itemObject['Сумма инвестиций'] = item.summa_investor;
                itemObject['Сумма в торговле'] = item.summa;
                itemObject['Портфель'] = item.portfolio.name;
                itemObject['Стратегия у Инвестора'] = item.strategy_investor.name_user;
                itemObject['ТС'] = item.trade_scheme.name;
                itemObject['Название для Инвестора'] = item.name_user;
                if (item.active === 0) {
                    itemObject['Активность'] = 'нет';
                    item.active =
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-times"></i>
                        </div>
                }
                if (item.active === 1) {
                    itemObject['Активность'] = 'да';
                    item.active =
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-check"></i>
                        </div>
                }
                if (item.show === 0) {
                    itemObject['Отображение в ТБ'] = 'нет';
                    item.show =
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-times"></i>
                        </div>
                }
                if (item.show === 1) {
                    itemObject['Отображение в ТБ'] = 'да';
                    item.show =
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-check"></i>
                        </div>
                }
                itemObject['Комментарии'] = item.comment;
               
                tableData.push({
                    ...item,
                    "actions": (<ActionButtons id={item.id} />)
                })
                tableDataExcel.push(itemObject)
            })
        }       
        setTableData(tableData)
        setTableDataExcel(tableDataExcel)
    }, [items])

   
    return (
        <div className="page-content">
            <MetaTags>
                <title>Торговые схемы Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Торговые схемы Инвесторов</h4>
                                    </div>
                                </CardTitle>
                                <PaginationProviderCustom
                                    columns={columns} tableData={tableData} nameTable="tableSet_tsiInvestor" 
                                    tableDataExcel = {tableDataExcel} 
                                    defaultSorted = {defaultSorted}
                                    nameExcel = {'TradeSchemes.xlsx'} 
                                    nameList = {'TS'}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление Торговой схемы Инвестора'
            />
        </div>
    )
}

export default TsiInvestor