import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Button } from "reactstrap";
import axios from "axios";
import config from "../../../config";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ModalConfirm from "../../../components/ModalConfirm";
import LookButtons from '../../../components/LookButtons';
import PaginationProviderCustom1 from "../../../components/PaginationProviderCustom";

const Portfolio = () => {
    const { addToast } = useToasts()
    const [items, setItems] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataExcel, setTableDataExcel] = useState([])
    const [modalConfirm, setModalConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    function userAdminFormatter(cell, row) {
        return (
            <Link to={'/ip-investors/show/' + row.user.id} className='btn btn_md' title='Перейти на страницу Инвестора'>
                { cell }
            </Link>         
        );
      }

      const columns = [
        {
            dataField: 'id',
            text: <span className='text'>Id</span>,  
            sort: true,
            style: {
                width: 11,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'user.name_admin',
            text: 'Инвестор',
            sort: true,
            style: {
                width: 11,
            },
            headerClasses: 'table-th-nowrap',
            formatter: userAdminFormatter
        },
        {
            dataField: 'summ',
            text: 'Сумма',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'name',
            text: <span className='text'>Название Портфеля</span>,
            sort: true, 
            headerClasses: 'table-th-80'
        },
        {
            dataField: 'name_user',
            text: <span className='text'>Название для Инвестора</span>, 
            sort: true,
            style: {
                width: 11,
            },
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'structure',
            text: <span className='text'>Стратегии</span>,
            sort: true,
            align: 'start',
            headerClasses: 'table-th-nowrap',
        },

        {
            dataField: 'comission',
            text: <span className='text'>Комиссия, %</span>,
            sort: true,
            headerClasses: 'table-th-nowrap',
            style: {
                width: 11,
            }
        },
        {
            dataField: 'percent_reserve',
            text: 'Резерв, %',
            sort: true,
            style: {
                width: 11,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'period',
            text: <span className='text'>Период погашения комиссии</span>,  
            sort: true, 
            headerClasses: 'table-th-150'
        },
        {
            dataField: 'show',
            text: <span className='text'>ТБ</span>,
            sort: false,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'comment',
            text: 'Комментарии',
            sort: true,
            style: {
                width: 11,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            style: {
                width: 111,
            },
            headerClasses: 'py-20'
        },
    ];
   
    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }

    const removeItem = (id) => {
        axios.get(`${config.apiUrl}/ip-portfolio-delete/${id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setItems(items.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2'>
                <Link to={`/portfolio/show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                    <i class="far fa-eye" />
                </Link>
                <Link to={`/portfolio/edit/${props.id}`} className='btn btn-primary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
                <div className='d-flex gap-2'>
                    <button
                        onClick={() => {
                            setModalConfirm(true)
                            setDeleteId(props.id)
                        }
                        }
                        className='btn btn-danger'
                        title='Удалить'>
                        <i className='mdi mdi-delete' />
                    </button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/ip-portfolio-index`)
                .then((response) => { 
                    setItems(response.data)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    useEffect(() => {
        let tableData = []
        let tableDataExcel = [] 
        let itemObject = {};       
       
        function TSList(props) {
            const stratInfo = props.stratInfo;
            const tsInfo = props.tsInfo
            let stratList;
            let stratListCheck = false;
            let tsList
            let tsListCheck = false;

            if (stratInfo.length > 0) {
                stratListCheck = true;
                stratList = stratInfo.map((item) =>
                    <>
                        <li style={{ listStyle: "none" }}>
                            <LookButtons
                                url='/strategy/show/'
                                id={item.id}
                                title='Посмотреть торговую стратегию'
                                lable={item.name}
                            />
                        </li>
                    </>
                );               
            }

            if (tsInfo.length > 0) {
                tsListCheck = true
                tsList = tsInfo.map((item) =>
                    <>
                        <li style={{ listStyle: "none", marginLeft: "25px" }} className='border-bottom border_bottom_style border-secondary'>
                            <Row>
                                <Col xl="9">
                                    {item.name}
                                </Col>
                                <Col xl="3">
                                    {item.summ}
                                </Col>
                            </Row>
                        </li>
                    </>
                )
            }

            return (

                <ul className='p-0 m-0'>{stratList}</ul>
            );
        }

        if (items.length > 0) {
            items.forEach(item => {
                itemObject = {};
                itemObject['Id'] = item.id;
                itemObject['Инвестор'] = item.user.name_admin;
                itemObject['Сумма'] = item.summ;
                itemObject['Название'] = item.name;
                if ((item.name_user) && (item.name_user != 'null'))  itemObject['Название для инвестора'] = item.name_user 
                else itemObject['Название для инвестора'] = 'нет';
                itemObject['Комиссия'] = item.comission;
                itemObject['Процент резерва'] = item.percent_reserve;  
                if (item.period === "byMonth") item.period = "месяц"
                if (item.period === "byQuarteYear") item.period = "квартал"
                if (item.period === "byHalfYear") item.period = "полугодие"
                if (item.period === "byYear") item.period = "год"
                itemObject['Период погашения'] = item.period;
                itemObject['Комментарии'] = item.comment;

                if (item.show === 0) {
                    itemObject['Отображать портфель в ТБ'] = 'нет';
                    item.show =
                        // <div class="d-flex justify-content-center">  
                        //     <i class="far fa-eye-slash" style={{ color: 'red' }}></i>
                        // </div>
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-times"></i>
                        </div>
                }
                if (item.show === 1) {
                    itemObject['Отображать портфель в ТБ'] = 'да';
                    item.show =
                        // <div class="d-flex justify-content-center">
                        //  <div class="d-flex justify-content-center btn btn-primary">
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-check"></i>
                            {/* <i class="far fa-eye" style={{ color: 'greenyellow' }}></i> */}
                        </div>
                }
                let i = 1;
                item.strategyInfo.map((item) => {
                    itemObject['Cт-гия ' + i] = item.name;
                    i++;
                }                     
                ); 

                let structure = item.info
                let name_user = '';
                item.name_user ? name_user = item.name_user : name_user = 'Нет'
                tableData.push({
                    ...item,
                    // "user.name_admin": (<LookButtons url='/ip-investors/show/'
                    //     id={item.user.id}
                    //     title='Перейти на страницу Инвестора'
                    //     lable={item.user.name_admin} />),
                    "structure": (
                        <TSList stratInfo={item.strategyInfo} tsInfo={item.dataTS} />
                    ),
                    "name_user": name_user,
                    "actions": (<ActionButtons id={item.id} />)
                })
                tableDataExcel.push(itemObject)
            })           
            setTableData(tableData)
            setTableDataExcel(tableDataExcel)
        }
    }, [items])

    return (
        <div className="page-content">
            <MetaTags>
                <title>Портфели Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Портфели Инвесторов</h4>
                                        <Link to='/portfolio/add' className='btn btn-primary'>
                                            <i className='mdi mdi-plus' />
                                            Добавить
                                        </Link>
                                    </div>
                                </CardTitle>                               
                                <PaginationProviderCustom1 
                                    columns={columns} tableData={tableData} nameTable="tableSet_portfolio" 
                                    tableDataExcel = {tableDataExcel} 
                                    defaultSorted = {defaultSorted}
                                    nameExcel = {'InvestorsPortfolio.xlsx'} 
                                    nameList = {'Portfolio'}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление портфеля Инвестора'
            />
        </div>
    )
}

export default Portfolio