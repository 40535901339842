import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,   
} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";

const OrdersInvestorShow = () => {
    const { addToast } = useToasts()
    const history = useHistory();

    const { id } = useParams()
    const [form, setForm] = useState({
        name_user: '',
        show: false,
        comment: ''
    })

    useEffect(() => {
        const getOrders = async () => {
            axios.get(`${config.apiUrl}/ip-iorders-show/` + id)
                .then((response) => {
                    let show = false
                    if (response.data.show === 1) show = true
                    setForm({
                        id: response.data.id,
                        sumOrder: response.data.sumOrder,
                        date_open: response.data.date_open,
                        date_close: response.data.date_close,
                        price_open: response.data.price_open,
                        price_close: response.data.price_close,
                        profit_money: response.data.profit_money,
                        profit_percent: response.data.profit_percent,
                        show: response.data.show,
                        comment: response.data.comment,
                        summa_investor: response.data.summa_investor,
                        profit_percent_investor: response.data.profit_percent_investor,
                        order_total: response.data.order.sumOrder,
                        user_id: response.data.user.id,
                        user_name: response.data.user.name_admin,
                        trade_scheme_id: response.data.trade_scheme.id,
                        trade_scheme_name: response.data.trade_scheme.name,
                        order_id: response.data.order.id,
                        strategy_investor_name: response.data.strategy_investor.name_user,
                        strategy_investor_id: response.data.strategy_investor.id,
                        trade_schemes_investor_name: response.data.trade_schemes_investor.name_user,
                        trade_schemes_investor_id: response.data.trade_schemes_investor.id,  
                        portfolio_name: response.data.portfolio.name,
                        portfolio_id: response.data.portfolio.id,
                        symbol: response.data.trade_scheme.symbol
                    })
                  
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getOrders()
    }, [id])

   
    return (
        <div className="page-content">
            <MetaTags>
                <title>Стратегия у Инвестора | TradeBot</title>
            </MetaTags>
            <Container fluid>               
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Просмотр сделки Инвестора #{id}</h4>
                                        <div>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>                                    
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Дата открытия: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.date_open}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Дата закрытия: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.date_close}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Валюта: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.symbol}                                               
                                            />
                                            
                                        </div>
                                    </Col>

                                    </Row>
                                    <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Сумма ордера у Инвестора: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.sumOrder}
                                            />

                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Сумма Инвестора в ТС: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.summa_investor}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Сумма общего ордера: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.order_total}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                   
                                    </Row>
                                    <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Профит, %: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.profit_percent}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Профит в деньгах: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.profit_money}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Профит Инвестора, %: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.profit_percent_investor}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Цена открытия: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.price_open}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                   
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Цена закрытия: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.price_close}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Комментарии: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.comment}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    
                                    </Row>
                                   
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                      <Col className="col-12">
                        <Card>
                            <CardBody>                                
                            <Row>
                                <Col xs={12} md={6} xl={4}>
                                    <div className="mb-3">
                                        <Label htmlFor="">Инвестор:</Label>
                                        <br />
                                        <Link to={`/ip-investors/show/${form.user_id}`} title='' className='btn btn-outline-dark m-0' >
                                            {form.user_name}
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} xl={4}>
                                    <div className="mb-3">
                                        <Label htmlFor="">Портфель:</Label>
                                        <br />
                                        <Link to={`/portfolio/show/${form.portfolio_id}`} title='' className='btn btn-outline-dark m-0' >
                                            {form.portfolio_name}
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} xl={4}>
                                    <div className="mb-3">
                                        <Label htmlFor="">Стратегия у Инвестора:</Label>
                                        <br />
                                        <Link to={`/si-investor/show/${form.strategy_investor_id}`} title='' className='btn btn-outline-dark m-0' >
                                            {form.strategy_investor_name}
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                            <Row>                                
                                <Col xs={12} md={6} xl={4}>
                                    <div className="mb-3">
                                        <Label htmlFor="">Торговая схема:</Label>
                                        <br />
                                        <Link to={`/trade/show/${form.trade_scheme_id}`} title='' className='btn btn-outline-dark m-0' >
                                            {form.trade_scheme_name}
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} xl={4}>
                                    <div className="mb-3">
                                        <Label htmlFor="investor">Торговая схема у Инвестора:</Label>
                                        <br />
                                        <Link to={`/tsi-investor/show/${form.trade_schemes_investor_id}`} title='' className='btn btn-outline-dark m-0' >
                                            {form.trade_schemes_investor_name}
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} xl={4}>
                                    <div className="mb-3">
                                        <Label htmlFor="investor">Основной ордер:</Label>
                                        <br />
                                        <Link to={`/history-show/${form.order_id}`} title='' className='btn btn-outline-dark m-0' >
                                            {form.order_id}
                                        </Link>
                                    </div>
                                </Col>
                            </Row>                        
                            </CardBody>
                        </Card>
                    </Col>
            </Container>
        </div >
    )
}

export default OrdersInvestorShow