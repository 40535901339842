import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    
} from "reactstrap"
import { Link, Redirect } from "react-router-dom";
import { useState, useEffect } from 'react'
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";
import InputSpinner from "react-bootstrap-input-spinner";
import WV from '../../../workVariables';


const BalanceInvestorAdd = (props) => {
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)
    const [users, setUsers] = useState([])
    const [actions, setActions] = useState([])
    const [portfolio, setPortfolio] = useState([])
    const [showMore, setShowMore] = useState(false)

    const [form, setForm] = useState({
        user_id: '',
        portfolio_id: '',
        sum: '',
        type: '',
        comment_for_investor: '',
        comment_for_admin: '',
        action_balance_id: ''
    })

    const [changeUser, setChangeUser] = useState(false)

    useEffect(() => {
        if (changeUser) {
            const getPortfolios = async () => {
                await axios.get(`${config.apiUrl}/ip-balance-getPortfolioByUser/${form.user_id}`)
                    .then(async (response) => {
                        let listPortfolio = []
                        for await (let one of response.data.portList) {
                            let onePortfolio = {
                                label: one.name,
                                value: one.id
                            }
                            listPortfolio.push(onePortfolio)
                        }
                        setPortfolio(listPortfolio)
                    })
                    .catch(error => {
                        console.error(error);
                    })
            }
            getPortfolios()
        }
        setChangeUser(false)
    }, [changeUser])

    useEffect(() => {
        const getData = async () => {
            await axios.get(`${config.apiUrl}/ip-balance-create`)
                .then(async (response) => {
                    let listUser = []
                    for await (let user of response.data.users) {
                        let oneUser = {
                            label: user.name_admin,
                            value: user.id
                        }
                        listUser.push(oneUser)
                    }
                    setUsers(listUser)
                    let listActions = []
                    for await (let action of response.data.actions) {
                        let oneAction = {
                            label: action.name,
                            value: action.id
                        }
                        listActions.push(oneAction)
                    }
                    setActions(listActions)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    const updateItem = async () => {
        await axios.post(`${config.apiUrl}/ip-balance-store`, form)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

     //белый цвет шрифта при поиске в Select
     const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Баланс пользователей | TradeBot</title>
            </MetaTags>
            <Container fluid>
                    <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Новая операция в Балансе Инвестора</h4>
                                        <div className='d-flex gap-2 align-items-center'> 
                                        <Button color="primary" onClick={updateItem}>
                                            Сохранить
                                        </Button>
                                        <Link to='/ip-balance-investor' className='btn btn-danger'>
                                            <i className='mdi mdi-backspace' />
                                            &nbsp;
                                            Вернуться
                                        </Link>                                        
                                        </div>                                        
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                    <div className="mb-3">
                                            <Label htmlFor="user_id">Инвестор: </Label>
                                            <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: `${WV.colorHoverSelect}`,
                                                    primary: `${WV.colorSelected}`,
                                                },
                                            })}
                                            styles={customStyles}   
                                                id='user_id'
                                                placeholder="выбрать..."
                                                options={users}
                                                classNamePrefix="select2-selection"
                                                name="user_id"
                                                onChange={e => {
                                                    setForm({ ...form, user_id: e.value })
                                                    setChangeUser(true)
                                                    setShowMore(true)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="portfolio_id">Портфель: </Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='portfolio_id'
                                                placeholder="выбрать..."
                                                options={portfolio}
                                                classNamePrefix="select2-selection"
                                                name="portfolio_id"
                                                onChange={e => {
                                                    setForm({ ...form, portfolio_id: e.value })
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    {/* <Col xs={12} md={6} xl={4}></Col> */}
                                </Row>
                                    <>
                                        {/* <Row>
                                            <Col xs={12} md={6} lg={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="type">Тип операции: </Label>
                                                <Select
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: `${WV.colorHoverSelect}`,
                                                            primary: `${WV.colorSelected}`,
                                                        },
                                                    })}
                                                    styles={customStyles}
                                                    id='type'
                                                    placeholder="выбрать..."
                                                    options={[
                                                        { label: 'Приход', value: 'plus' },
                                                        { label: 'Расход', value: 'minus' }
                                                    ]}
                                                    classNamePrefix="select2-selection"
                                                    name="type"
                                                    onChange={e => {
                                                        setForm({ ...form, type: e.value })
                                                    }}
                                                />
                                            </div>                                           
                                            </Col>
                                            <Col xs={12} md={6} lg={4}>
                                            <div className="mb-3">
                                                    <Label htmlFor="action_balance_id">Операция: </Label>
                                                    <Select
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: `${WV.colorHoverSelect}`,
                                                            primary: `${WV.colorSelected}`,
                                                        },
                                                    })}
                                                    styles={customStyles}   
                                                        id='action_balance_id'
                                                        placeholder="выбрать..."
                                                        options={actions}
                                                        classNamePrefix="select2-selection"
                                                        name="action_balance_id"
                                                        onChange={e => {
                                                            setForm({ ...form, action_balance_id: e.value })
                                                        }}
                                                    />
                                                </div>                                            
                                            </Col>
                                            <Col xs={12} md={6} lg={4}>
                                            <div className="mb-3">
                                                    <Label htmlFor="sum">Сумма операции: </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Сумма...'
                                                        id="sum"
                                                        value={form.sum}
                                                        name="sum"
                                                        onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                                    />
                                                </div>

                                            </Col>
                                        </Row>                                        */}
                                        <Row>
                                            <Col xs={12} md={6} lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="comment">Комментарии для Администратора:</Label>
                                                    <Input
                                                        type="textarea"
                                                        rows={3}
                                                        value={form.comment_for_admin}
                                                        className="form-control"
                                                        placeholder='...'
                                                        id="comment_for_admin"
                                                        name="comment_for_admin"
                                                        onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="comment">Комментарии для Инвестора:</Label>
                                                    <Input
                                                        type="textarea"
                                                        rows={3}
                                                        value={form.comment_for_investor}
                                                        className="form-control"
                                                        placeholder='...'
                                                        id="comment_for_investor"
                                                        name="comment_for_investor"
                                                        onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                            
                            </CardBody>
                        </Card>  
                        <Card>
                            <CardBody>
                            <Row>
                                            <Col xs={12} md={6} lg={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="type">Тип операции: </Label>
                                                <Select
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: `${WV.colorHoverSelect}`,
                                                            primary: `${WV.colorSelected}`,
                                                        },
                                                    })}
                                                    styles={customStyles}
                                                    id='type'
                                                    placeholder="выбрать..."
                                                    options={[
                                                        { label: 'Приход', value: 'plus' },
                                                        { label: 'Расход', value: 'minus' }
                                                    ]}
                                                    classNamePrefix="select2-selection"
                                                    name="type"
                                                    onChange={e => {
                                                        setForm({ ...form, type: e.value })
                                                    }}
                                                />
                                            </div>                                           
                                            </Col>
                                            <Col xs={12} md={6} lg={4}>
                                            <div className="mb-3">
                                                    <Label htmlFor="action_balance_id">Операция: </Label>
                                                    <Select
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: `${WV.colorHoverSelect}`,
                                                            primary: `${WV.colorSelected}`,
                                                        },
                                                    })}
                                                    styles={customStyles}   
                                                        id='action_balance_id'
                                                        placeholder="выбрать..."
                                                        options={actions}
                                                        classNamePrefix="select2-selection"
                                                        name="action_balance_id"
                                                        onChange={e => {
                                                            setForm({ ...form, action_balance_id: e.value })
                                                        }}
                                                    />
                                                </div>                                            
                                            </Col>
                                            <Col xs={12} md={6} lg={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="sum">Сумма операции: </Label>
                                            <InputSpinner
                                                value={form.sum}
                                                name="sum"
                                                type={"real"}
                                                precision={4}
                                                max={10000000}
                                                min={0}
                                                step={0.0001}
                                                onChange={(num) => {
                                                    setForm({ ...form, sum: num })
                                                    // setError({ ...error, interval_ma: true })
                                                    // errorItem = formErrors;
                                                    // delete errorItem.interval_ma
                                                    // setFormErrors(errorItem);
                                                }
                                                }
                                            />
                                            {/* <Input
                                                type="number"
                                                className="form-control"
                                                placeholder='Сумма...'
                                                id="sum"
                                                value={form.sum}
                                                name="sum"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            /> */}
                                        </div>

                                            </Col>
                                        </Row>     
                            </CardBody>
                        </Card>               

                    </Col>
                </Row>
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/ip-balance-investor",
                    }}
                />
            }
        </div>
    )
}

export default BalanceInvestorAdd