import { Link } from "react-router-dom";

const LookButtons = (props) => {
    return (
        <div className='d-flex gap-2'>
            <Link to={props.url + props.id} className='btn btn_md' title={props.title}>
                {props.lable}
            </Link>
        </div>
    )
}

export default LookButtons;
