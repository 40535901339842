import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags"
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import "./datatables.scss"
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {Link} from "react-router-dom";
import axios from "axios";
import config from "../../config";
import TableSearchBar from "../../components/TableSearchBar";
import ModalConfirm from "../../components/ModalConfirm";
import { useToasts } from "react-toast-notifications";
import LookButtons from '../../components/LookButtons';
import WV from '../../workVariables'
const moment = require('moment')

const Test2 = () => {
    const { addToast } = useToasts()
    const ActionButtons = (props) => {
        const copyItem = async (id) => {
            await axios.get(`${config.apiUrl}/test-second-schemas-copy/${id}`)
                .then(async (response) => {
                    console.log(response.data.msg);
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    if (response.data.res === "Success") {
                        const response = await axios.get(`${config.apiUrl}/test-second-schemas`)
                        setTestSchema(response.data)
                    }

                    // <Redirect to="/account" />

                })
                .catch(error => {
                    console.error(error);
                })
        }
        return (
            <div className='d-flex gap-2'>
                <Link to={`/test2/show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                    <i class="far fa-eye" />
                </Link>
                <Link to={`/test2/edit/${props.id}`} className='btn btn-primary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
                <div className='d-flex gap-2'>
                    <button onClick={() => copyItem(props.id)} className='btn btn-success' title='Дублировать'><i className='mdi mdi-content-copy' /></button>
                </div>
                <div className='d-flex gap-2'>
                    <button
                        onClick={() => {
                            setModalConfirm(true)
                            setDeleteId(props.id)
                        }
                        }
                        className='btn btn-danger' title='Удалить'>
                        <i className='mdi mdi-delete' />
                    </button>
                </div>
            </div>
        )

    }
    const removeItem = async (id) => {
        await axios.get(`${config.apiUrl}/test-second-schemas-delete/${id}`)
            .then((response) => {
                console.log(response.data.msg);
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setTestSchema(testSchema.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                console.error(error);
            })

    }
    const [testSchema, setTestSchema] = useState([])
    const [tableData, setTableData] = useState([])
    function schemeNameFormatter(cell, row) {
        return (
            <Link to={'/schemas/show/' + row.scheme.id} className='btn btn_md' title='Посмотреть схему'>
                {cell}
            </Link>
        );
    }
    function slSchemeNameFormatter(cell, row) {
        return (
            <Link to={'/stop-loss/show/' + row.sl_scheme.id} className='btn btn_md' title='Посмотреть stop loss схему'>
                {cell}
            </Link>
        );
    }
    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Название',
            sort: true
        },
        {
            dataField: 'scheme.name',
            text: 'Схема',
            sort: true,
            formatter: schemeNameFormatter
        },
        {
            dataField: 'sl_scheme.name',
            text: 'Набор стопов',
            sort: true,
            formatter: slSchemeNameFormatter
        },
        {
            dataField: 'startTime',
            text: 'Начало',
            sort: true
        },
        {
            dataField: 'endTime',
            text: 'Завершение',
            sort: true
        },
        {
            dataField: 'comment',
            text: 'Комментарии',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            style: {
                width: 111,
            }
        },


    ];
    useEffect(() => {
        const getTestScheme = async () => {
            const response = await axios.get(`${config.apiUrl}/test-second-schemas`)
            setTestSchema(response.data)
        }
        getTestScheme()
    }, [])

    useEffect(() => {
        let tableData = []
        if (testSchema.length > 0) {
            testSchema.forEach(itemSchema => {

                let st = moment.utc(itemSchema.startTime)
                // st = st._i.substr(0, 10) + " " + st._i.substr(11, 8)
                st = st._i.substr(0, 10)
                let et = moment.utc(itemSchema.endTime)
                // et = et._i.substr(0, 10) + " " + et._i.substr(11, 8)
                et = et._i.substr(0, 10)
                if (itemSchema.comment) {
                    if (itemSchema.comment.length > WV.longCommentTest)
                        itemSchema.comment = itemSchema.comment.substr(0, WV.longCommentTest) + ' ...'
                }  
                tableData.push({
                    ...itemSchema,
                    "startTime": st,
                    "endTime": et,
                    "actions": (<ActionButtons id={itemSchema.id} />)
                })
            })
        }
        setTableData(tableData)
    }, [testSchema])

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const sizePerPageList = WV.sizePerPageArr.concat([{ text: 'Все', value: (tableData).length }])
    let nameTable = 'tableSet_test_sl'
    let sizePerPage = parseInt(localStorage.getItem(nameTable + 'perPage'));
    if (!sizePerPage) sizePerPage = parseInt(WV.sizePerPage);
    let page = parseInt(localStorage.getItem(nameTable + 'page'));
    if (!page) page = 1;
    const pageOptions = {
        sizePerPage: sizePerPage,
        page: page,
        sizePerPageList: sizePerPageList,
        totalSize: tableData.length,
        custom: true,
        onSizePerPageChange: (sizePerPage, page) => {
            localStorage.setItem(nameTable + 'perPage', sizePerPage)
            localStorage.setItem(nameTable + 'page', 1)
        },
        onPageChange: (page, sizePerPage) => {
            localStorage.setItem(nameTable + 'page', page)
        },
    }


    // Select All Button operation
    // const selectRow = {
    //     mode: 'checkbox'
    // }

    const [modalConfirm, setModalConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Схемы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Тестовые Схемы 2.0</h4>
                                        <Link to='/test2/add' className='btn btn-primary'>
                                            <i className='mdi mdi-plus' />
                                            Добавить
                                        </Link>
                                    </div>
                                </CardTitle>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField='id'
                                    sizePerPageList={sizePerPageList}
                                    columns={columns}
                                    data={tableData}
                                >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField='id'
                                            columns={columns}
                                            data={tableData}
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>

                                                    <Row className="mb-2">
                                                        <Col md="4">
                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <TableSearchBar
                                                                        {...toolkitProps.searchProps}
                                                                    />                                                                
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    keyField={"id"}
                                                                    responsive
                                                                    bordered={true}
                                                                    striped={true}
                                                                    defaultSorted={defaultSorted}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                />

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row className="align-items-md-center mt-30">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <SizePerPageDropdownStandalone
                                                                    sizePerPageList={sizePerPageList}
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                            }
                                        </ToolkitProvider>
                                    )
                                    }</PaginationProvider>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление тестовой схемы 2.0'
            />
        </div>
    )
}

export default Test2
