import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    Spinner,
} from "reactstrap"
import { Link, useParams, Redirect } from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import config from "../../config";
import { registerLocale, setDefaultLocale }  from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from 'react-select/animated';
import WV from '../../workVariables'
import InputSpinner from "react-bootstrap-input-spinner";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from '../../muiTheme';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { useToasts } from "react-toast-notifications";
import fileDownload from 'js-file-download';
const moment = require('moment')

const animatedComponents = makeAnimated();

const Test1Edit = () => {
    const { id } = useParams()
    const { addToast } = useToasts()
    const [spinnerStatus, setSpinnerStatus] = useState(false)
    const [form, setForm] = useState({
        name: '',
        maId: '',
        show_open: '',
        scheme_id: '',
        symbol: '',
        interval: '',
        procent_comision: '',
        comment: '',
        stop_id: ''
    });

    registerLocale('ru', ru)
    setDefaultLocale('ru')

   const [redirect, setRedirect] = useState(false)
    useEffect(() => {
        const getTestScheme = async () => {
            const response = await axios.get(`${config.apiUrl}/test-schema/${id}`)

            let st = moment.utc(response.data.startTime)
            st = st._i.substr(0, 10) + " " + st._i.substr(11, 8)
            let et = moment.utc(response.data.endTime)
            et = et._i.substr(0, 10) + " " + et._i.substr(11, 8)
            
            setForm({
                id: id,
                name: response.data.name,
                comment: response.data.comment,
                show_open: response.data.show_open[0].label,
                scheme_id: response.data.scheme_id[0].label,
                symbol: response.data.symbol[0].value,
                interval: response.data.interval[0].value,
                procent_comision: response.data.procent_comision,
                stop_id: response.data.stop_id[0].label,
                maId: response.data.ma,
                startTime: st,
                endTime: et

            })

            // setStartTime(response.data.startTime)
            // setEndTime(response.data.endTime)

        }
        getTestScheme()

}, [])

  
    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }

    const coreAction = () => {
        const fileName = form.name + ".xlsx"
        setSpinnerStatus(true)
        axios({
            url: `${config.apiUrl}/test-scheme-core/${id}`,
            method: 'GET'
        }).then((response) => {
            // console.log(response.data);
            if (response.data.status === "success") {
                // console.log('SECOND AXIOS . . . .');
                setTimeout(() => {
                    // console.log('.......send');
                    axios.get(`${config.apiUrl}/test-scheme-get-file/${fileName}`, { responseType: 'blob' })
                        .then((response) => {
                            // console.log(response.data);
                            setSpinnerStatus(false)
                            fileDownload(response.data, fileName)
                        })
                        .catch(error => {
                            console.error(error);
                        })
                }, 2000);
            } else {
                setTimeout(() => {
                    addToast("Отчет не построен! Возможно некорректный запрос!", {
                        appearance: "info",
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    setSpinnerStatus(false)
                }, 1000);
            }
        }).catch(error => {
            console.error(error);
        })
    }
    
    return (
        <div className="page-content">
            <MetaTags>
                <title>Просмотр Тестовой Схемы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Просмотр Тестовой схемы #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                        {spinnerStatus &&
                                            <div style={{ marginRight: "13px" }}>
                                                <Spinner className="ms-2" color="info" />
                                            </div>
                                        }
                                        {/* <div className='d-flex justify-content-start align-items-center'> */}
                                            <Button color="primary" onClick={coreAction} disabled={spinnerStatus}>
                                                <i className='bx bxs-flask' style={{ marginRight: "8px" }}></i>
                                                Протестировать
                                            </Button>
                                        {/* </div> */}
                                        
                                            <Link to={`/test1/edit/${id}`} className='btn btn-secondary me-2' title='Редактировать'>
                                                <i className='mdi mdi-pencil' />
                                                &nbsp;
                                                Редактировать
                                            </Link>
                                            <Link to='/test1' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>

                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                value={form.name}
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                readOnly={true}
                                               
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label symbol="position">Символ</Label>
                                            <Input
                                                type="text"
                                                value={form.symbol}
                                                className="form-control"
                                                id="symbol"
                                                readOnly={true}
                                               
                                            />                                            
                                        </div>
                                        <div className="mb-3">
                                            <Label symbol="interval">Интервал</Label>
                                            <Input
                                                type="text"
                                                value={form.interval}
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                readOnly={true}                                               
                                            />
                                        </div>    
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <Row>                                            
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                value={form.comment}
                                                readOnly={true}    
                                            />
                                        </div>
                                       
                                            <Col xs={12} md={6} xl={6}>  
                                            {form.procent_comision !== '' && 
                                            <Col xs={12} md={12} xl={12}>  
                                            <div className="mb-3">
                                            <Label> Процент комиссии</Label>
                                                    <InputSpinner
                                                        value={form.procent_comision}
                                                        name="procent_comision"
                                                        type={"real"}
                                                        max={form.procent_comision}
                                                        min={form.procent_comision}
                                                        
                                                    />
                                        </div>   
                                        </Col>
                                         }                                        
                                            <div className="mb-3">
                                            <Label symbol="show_open">Отображение сделок в Excel</Label>
                                            <Input
                                                type="text"
                                                value={form.show_open}
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                readOnly={true}
                                               
                                            />
                                        </div>                                    

                                            </Col>
                                            <Col xs={12} md={6} xl={6}>   
                                              <div className="mb-3"> 
                                              <ThemeProvider theme={darkTheme}>
                                                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                      <DateTimePicker
                                                          renderInput={(props) => <TextField {...props} />}
                                                          label="Время начала"
                                                          value={form.startTime}
                                                          ampm={false}
                                                          readOnly={true}                                                         
                                                      />
                                                  </LocalizationProvider>
                                              </ThemeProvider>                                              
                                              </div> 
                                              <div className="mb-3"> 
                                              <ThemeProvider theme={darkTheme}>
                                                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                      <DateTimePicker
                                                          renderInput={(props) => <TextField {...props} />}
                                                          label="Время завершения"
                                                          value={form.endTime}
                                                          ampm={false}
                                                          readOnly={true}     
                                                      />
                                                  </LocalizationProvider>
                                              </ThemeProvider>
                             
                                              </div> 
                                          </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>
                                    </div>

                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>                                       
                                        <div className="mb-3">
                                            <Label symbol="stop_id">Набор стопов</Label>
                                            <Input
                                                type="text"
                                                value={form.stop_id}
                                                className="form-control"
                                                placeholder='...'
                                                // required
                                                id="name"
                                                readOnly={true}
                                               
                                            />
                                            
                                        </div>

                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <div className="mb-3">
                                            <Label symbol="maId">Скользящие средние &nbsp;
                                                <abbr title="MA1 > MA2 > ... > MAn">
                                                    ?
                                                </abbr></Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='maId'
                                                value={form.maId}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                placeholder="выбрать..."
                                                isDisabled
                                                classNamePrefix="select2-selection"
                                            />

                                        </div>
                                      
                                    </Col>
                                    <Col xs={12} md={12} xl={12}>
                                        <div className="mb-3">
                                            <Label symbol="scheme_id">Схема</Label>
                                            <Input
                                                type="text"
                                                value={form.scheme_id}
                                                className="form-control"
                                                placeholder='...'
                                                // required
                                                id="name"
                                                readOnly={true}
                                               
                                            />                                            
                                        </div>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/test1",
                    }}
                />
            }

        </div>
    );
}

export default Test1Edit;
