import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import { Link } from "react-router-dom";
import Select from "react-select"
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import Type1 from "./types/Type1"
import Type2 from "./types/Type2"
import Type3 from "./types/Type3"
import Type4 from "./types/Type4"
import Type5 from "./types/Type5"
import Type6 from "./types/Type6"
import Type7 from "./types/Type7"
import WV from '../../workVariables'
import { useHistory } from "react-router-dom";

export default function StopLossAdd() {
    const { addToast } = useToasts()
    const [type, setType] = useState('0')
    const [type1, setType1] = useState({
        stop_price_perc: '',
        delta_perc: '',
        condition1: true,
        condition2: '',
        condition3: false,
    })
    const [type2, setType2] = useState({
        delta_perc: '',
        condition1: true,
        condition2: '',
        condition3: false,
        shift_sl: '',
        stop_id: ''
    })
    const [type3, setType3] = useState({
        delta_perc: '',
        condition1: true,
        condition2: '',
        condition3: false,
        shift_sl: '',
        stop_id: ''
    })
    const [type5, setType5] = useState({
        stop_price_perc: '',
        delta_perc: '',
        shift_sl: '',
        stop_id: '',
        percent: ''
    })
    const [type6, setType6] = useState({
        delta_perc: '',
        moving_average_id: '',
        shift_sl: '',
        stop_id: '',
        condition1: true,
        condition3: false,
    })
    const [type7, setType7] = useState({
        delta_perc: '',
        moving_average_id: '',
        shift_sl: '',
        stop_id: ''
    })

    const [form, setForm] = useState({
        name: '',
        type: '',
        comment: ''
    })
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        setForm({
            name: form.name,
            type: form.type,
            comment: form.comment
        })

    }, [type, form.name, form.type, form.comment])

    const [error, setError] = useState({
        name: false,
    });
    
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        let data = form
        let dataType
        switch (form.type) {
            case '1': dataType = type1
                break;
            case '2': dataType = type2
                break;
            case '3': dataType = type3
                break;
            case '5': dataType = type5
                break;
            case '6': dataType = type6
                break;
            case '7': dataType = type7
                break;
            default:
                break;
        }
        data = Object.assign(data, dataType);
        console.log('form');
        console.log(form);
        setForm(data)
        console.log('form1');
        console.log(form);
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        if (form.type == '1') {
            if (values.stop_price_perc == '') errors.stop_price_perc = "поле обязательное для заполнения";
            if (values.delta_perc == '') errors.delta_perc = "поле обязательное для заполнения";       

        }
        if ((form.type == '2') || (form.type == '3')) {
            if (values.shift_sl == '') errors.shift_sl = "поле обязательное для заполнения";
            if (values.delta_perc == '') errors.delta_perc = "поле обязательное для заполнения";      

        }
        if (form.type == '5') {
            if (values.shift_sl == '') errors.shift_sl = "поле обязательное для заполнения";
            if (values.delta_perc == '') errors.delta_perc = "поле обязательное для заполнения";  
            if (values.percent == '') errors.percent = "поле обязательное для заполнения";     

        }
        if ((form.type == '6') || (form.type == '7'))  {
            if (values.shift_sl == '') errors.shift_sl = "поле обязательное для заполнения";
            if (values.delta_perc == '') errors.delta_perc = "поле обязательное для заполнения";  
            if (values.moving_average_id == '') errors.moving_average_id = "поле обязательное для заполнения";     

        }
        if (!values.name) errors.name = " ";
        if (!values.type) errors.type = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            let resSubmit = true
            if (((form.type == '1') || (form.type == '2') || (form.type == '3')) 
            && (!form.condition1) && (!form.condition3) && (form.condition2 == '')) resSubmit = false; 

            if ((form.type == '6') && (!form.condition1) && (!form.condition3)) resSubmit = false; 
         
            console.log(resSubmit);
            if (resSubmit) {
                axios.post(`${config.apiUrl}/stop-loss/store`, form)
                .then((response) => {
                    history.goBack()
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })
                    }, 300);  
              
                })
                .catch(error => {
                    console.error(error);
                })

            } else {
                setTimeout(() => {
                    addToast('Заполните хотя бы одно условие', {
                        appearance: 'Error'.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                }, 100);                
            }
       
        }
    }, [formErrors]);

    const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Новая Stop Loss схема | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card className="pb-5">
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Новая Stop Loss схема</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={storeItem}>
                                                Сохранить
                                            </Button>
                                            <Link to='/stop-loss' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                        {/* <Link to='/stop-loss' className='btn btn-danger'>
                                            <i className='mdi mdi-backspace' />
                                            &nbsp;
                                            Отмена
                                        </Link> */}
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                  }
                                                onChange={
                                                    (e) => {
                                                        setForm({ ...form, name: e.target.value })
                                                        setError({ ...error, name: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.name
                                                        setFormErrors(errorItem);
                                                    }
                                                }
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                        <div>
                                            <Label htmlFor="position">Тип StopLoss</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id='type'
                                                value={type}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setType(value)
                                                    setForm({ ...form, type: value.value })
                                                    setError({ ...error, type: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.type
                                                        setFormErrors(errorItem);
                                                }}
                                                options={WV.stopLossTypeList}
                                                styles={customStyles}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.type}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="textarea"
                                                style={{ resize: 'none', height: '7.4rem' }}
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                value={form.comment}
                                                onChange={
                                                    (e) => {
                                                        setForm({ ...form, comment: e.target.value })
                                                    }
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {type.value === '1' &&
                                    <div>
                                        <Type1 type1={type1} setType1={setType1} formErrors={formErrors} setFormErrors={setFormErrors} />
                                    </div>
                                }
                                {type.value === '2' &&
                                    <div>
                                        <Type2 type2={type2} setType2={setType2} formErrors={formErrors} setFormErrors={setFormErrors}/>
                                    </div>
                                }
                                {type.value === '3' &&
                                    <div>
                                        <Type3 type3={type3} setType3={setType3} formErrors={formErrors} setFormErrors={setFormErrors} />
                                    </div>
                                }
                                {type.value === '4' &&
                                    <div>
                                        <Type4 />
                                    </div>
                                }
                                {type.value === '5' &&
                                    <div>
                                        <Type5 type5={type5} setType5={setType5} formErrors={formErrors} setFormErrors={setFormErrors}/>
                                    </div>
                                }
                                {type.value === '6' &&
                                    <div>
                                        <Type6 type6={type6} setType6={setType6} formErrors={formErrors} setFormErrors={setFormErrors}/>
                                    </div>
                                }
                                {type.value === '7' &&
                                    <div>
                                        <Type7 type7={type7} setType7={setType7} formErrors={formErrors} setFormErrors={setFormErrors}/>
                                    </div>
                                }
                            </CardBody>
                        </Card>
             
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
