import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

const SocialSource = () => {
  const socials = [
    {
      title: "Telegram",
      bgColor: "bg-primary",
      iconClass: "mdi-telegram",
      description: "10.5 тыс.",
    },
    {
      title: "Twitter",
      bgColor: "bg-info",
      iconClass: "mdi-twitter",
      description: "10.5 тыс.",
    },
    {
      title: "Instagram",
      bgColor: "bg-pink",
      iconClass: "mdi-instagram",
      description: "10.5 тыс.",
    },
  ]

  return (
    <React.Fragment>
      <Card>
        <CardBody className="d-flex flex-column">
          <CardTitle className="mb-0">Социальные сети и контактная информация</CardTitle>
          <div className="flex-grow-1 d-flex flex-column justify-content-center mt-4" >
            <div className="text-center">
              <div className="avatar-sm mx-auto mb-4">
                <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                  <i className="mdi mdi-telegram text-primary"></i>
                </span>
              </div>
              <p className="font-16 text-muted mb-2"></p>
              <h5>
                <b>Telegram</b> техподдержки: <a href="http://t.me/snb_help" target={"_blank"}> @snb_help </a>
              </h5>
              <p className="text-muted">
                На все интересующие Вас вопросы сможет ответить наша техподдержка
              </p>
              <a href="http://t.me/snb_help" className="text-primary font-16" target={"_blank"}>
                Написать техподдержке <i className="mdi mdi-chevron-right"></i>
              </a>
            </div> 
            <Row className="mt-0">
              {socials.map((social, key) => (
                <Col xs="4" key={"_li_" + key}>
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">

                      <span
                        className={
                          "avatar-title rounded-circle " +
                          social.bgColor +
                          " font-size-16"
                        }
                      >
                        <i
                          className={"mdi " + social.iconClass + " text-white"}
                        ></i>
                      </span>
                    </div>
                    <h5 className="font-size-15">{social.title}</h5>
                    <p className="text-muted mb-0">{social.description} подписчиков</p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SocialSource
