import {
    Row, Modal, ModalBody, ModalHeader, ModalFooter, Button
} from "reactstrap";
import React from "react";
import WV from '../workVariables'

const ModalConfirm = (props) => {
    const { modalConfirm, toggleModalConfirm, removeItem, deleteId, lebel } = props
    return (
        <>
            <Modal isOpen={modalConfirm} toggle={toggleModalConfirm} size='xs'>
                <ModalHeader>
                    {lebel}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {WV.textConfirm}
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModalConfirm()
                            removeItem(deleteId)
                        }}>
                        Удалить
                    </Button>
                    <Button color="danger" onClick={toggleModalConfirm}>Отмена</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ModalConfirm;
