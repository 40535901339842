import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Label,
    Input,
    Button,
} from "reactstrap";
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import InputSpinner from "react-bootstrap-input-spinner";

const Type5 = (props) => {
    const { type5, setType5, formErrors, setFormErrors } = props
    const [stops, setStops] = useState([])
    const [status, setStatus] = useState({
        shift_sl: false,
        stop_id: false
    })
    const [selectStop, setSelectStop] = useState({
        label: '',
        value: ''
    })
    useEffect(() => {
        let arrayStops = [];
        const getStops = async () => {
            await axios.get(`${config.apiUrl}/stops`)
                .then(async (response) => {
                    for await (let item of response.data) {
                        let option = {}
                        option.label = item.name
                        option.value = item.id
                        arrayStops.push(option)
                        if (item.id === type5.stop_id) {
                            setSelectStop({
                                label: item.name,
                                value: item.id
                            })
                        }
                    }
                    setStops(arrayStops)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStops()
    }, [type5.stop_id])

    // useEffect(()=> {
    //     if (type5.shiftStatus) setStatus({...status, shift_sl: true})
    //     if (type5.stopStatus) setStatus({...status, stop_id: true})
    // }, [type5.shiftStatus, type5.stopStatus, status])

    useEffect(()=> {
        if (type5.shiftStatus) setStatus({...status, shift_sl: true})
        if (type5.stopStatus) setStatus({...status, stop_id: true})
    }, [])

    console.log(type5);

    const clearShift = () => {
        setStatus({ ...status, shift_sl: false })
        setType5({ ...type5, shift_sl: '' })
    }
    const clearStop = () => {
        setStatus({ ...status, stop_id: false })
        setType5({ ...type5, stop_id: '' })
        setSelectStop(null)
    }
    console.log(status);
    let errorItem;
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3  m-2">
                        <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                            style={{ width: 50, height: 50 }}>
                            <i className="fas fa-info"></i>
                        </div>
                        <info_1 />
                        Тип Д) 'Безубыточный стоп'. Стоп должен передвигаться на изначальную точку открытия сделки (+ комиссия биржи), если цена движется в выгодном нам движении на определённое количество %;
                    </div>
                </Col>
            </Row>
            <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Разница Stop Price и Price, %</Label>
                        <InputSpinner
                        value={type5.delta_perc}
                        name="delta_perc"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {
                            setType5({ ...type5, delta_perc: num })
                            errorItem = formErrors;
                            delete errorItem.delta_perc;
                            setFormErrors(errorItem);
                        }}
                       />
                       <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.delta_perc}</p>
                    </div>
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Сдвиг цены, %</Label>
                        {/* <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}> */}
                    <InputSpinner
                        value={type5.shift_sl}
                        name="shift_sl"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {
                            setType5({ ...type5, shift_sl: num })
                            setStatus({ ...status, shift_sl: true })
                            errorItem = formErrors;
                            delete errorItem.shift_sl;
                            setFormErrors(errorItem);
                        }}
                    />
                     <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.shift_sl}</p> 
                                {/* <Input
                                    type="number"
                                    min={1}
                                    step={0.01}
                                    value={type5.shift_sl}
                                    disabled={status.stop_id && true}
                                    className="form-control"
                                    placeholder='Введите процент...'
                                    id="shift_sl"
                                    onChange={(e) => {
                                        setType5({ ...type5, shift_sl: e.target.value })
                                        setStatus({ ...status, shift_sl: true })
                                    }}
                                /> */}
                            {/* </div>
                            <Button
                                className='btn btn-danger'
                                disabled={status.stop_id && true}
                                onClick={clearShift}>
                                <i class="far fa-trash-alt"></i>
                            </Button>
                        </div> */}
                    </div>
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Прибавляем к цене открытия сделки, %</Label>
                        <InputSpinner
                        value={type5.percent}
                        name="percent"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {
                            setType5({ ...type5, percent: num })
                            errorItem = formErrors;
                            delete errorItem.percent;
                            setFormErrors(errorItem);
                        }}
                    // onChange={(num) => {
                    //   setForm({ ...form, stop_loss_price: num })
                    //   setError({ ...error, stop_loss_price: true })
                    //   errorItem = formErrors;
                    //   delete errorItem.stop_loss_price
                    //   setFormErrors(errorItem);
                    // }                        
                    // }
                    />
                    <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.percent}</p> 
                        {/* <Input
                            type="number"
                            min={0}
                            step={0.01}
                            className="form-control"
                            placeholder='Введите процент...'
                            id="percent"
                            value={type5.percent}
                            onChange={(e) => setType5({ ...type5, percent: e.target.value })}
                        /> */}
                    </div>
                </Col>

                   {/* <Col xs={12} md={6} xl={6}>
                    <div className="mb-3">
                        <Label htmlFor="period">Выбрать набор сдвигов, %</Label>
                        <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}>
                                <Select
                                    id='stop_id'
                                    placeholder="выбрать..."
                                    isDisabled={status.shift_sl && true}
                                    onChange={e => {
                                        setType5({ ...type5, stop_id: e.value })
                                        setStatus({ ...status, stop_id: true })
                                        setSelectStop({label: e.label, value: e.value})
                                    }}
                                    options={stops}
                                    value={selectStop}
                                    classNamePrefix="select2-selection"
                             
                                />
                            </div>
                            <Button
                                className='btn btn-danger'
                                disabled={status.shift_sl && true}
                                onClick={clearStop}>
                                <i class="far fa-trash-alt"></i>
                            </Button>
                        </div>
                    </div>
                </Col> */}
           
        </>
    );
}

export default Type5;
