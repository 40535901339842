import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    } from "reactstrap"
import { Link, useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../../config";
import InputSpinner from "react-bootstrap-input-spinner";

const TsiInvestorShow = () => {
    const history = useHistory();
   
    const { id } = useParams()
    const [form, setForm] = useState({
        name_user: '',
        name_portfolio: '',
        name_trade_scheme: '',
        summa_investor: '',
        summa: '',
        show_check: false,
        active_check: false,
    })

    useEffect(() => {
        const getMovings = async () => {
            await axios.get(`${config.apiUrl}/ip-tsi-edit/` + id)
                .then((response) => {

                    let show_check = false
                    let active_check = false

                    if (response.data.show === 1)
                        show_check = true

                    if (response.data.active === 1)
                        active_check = true

                    setForm({
                        id: response.data.id,
                        name_user: response.data.name_user,
                        name_portfolio: response.data.portfolio.name,
                        id_portfolio: response.data.portfolio.id,
                        name_strategy: response.data.strategy_investor.name_user,
                        id_strategy: response.data.strategy_investor.id,
                        name_trade_scheme: response.data.trade_scheme.name,
                        id_trade_scheme: response.data.trade_scheme.id,
                        summa_investor: response.data.summa_investor,
                        summa: response.data.summa,
                        show_check: show_check,
                        active_check: active_check,
                        comment: response.data.comment 
                    })
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getMovings()
    }, [id])
       
    return (
        <div className="page-content">
            <MetaTags>
                <title>Торговые схемы Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Просмотр Торговой схемы Инвестора #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'> 
                                            <Link to={`/tsi-investor/edit/${id}`} className='btn btn-secondary me-2' title='Редактировать'>
                                                <i className='mdi mdi-pencil' />
                                                &nbsp;
                                                Редактировать
                                            </Link>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        
                                        </div>
                                    
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-4">
                                            <Label htmlFor="name_user">Название для Инвестора:</Label>
                                            <Input
                                                // disabled={true}
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name_user"
                                                value={form.name_user}
                                                name="name_user"
                                                disabled={true}                                                  
                                            />
                                             
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name_portfolio">Портфель:</Label>
                                            <br/>
                                            <Link to={`/portfolio/show/${form.id_portfolio}`}  title='' className='btn btn-outline-dark m-0'>
                                            {form.name_portfolio}
                </Link>
                                            {/* <Input
                                                disabled={true}
                                                type="text"
                                                className="form-control"
                                                placeholder='Название...'
                                                id="name_portfolio"
                                                value={form.name_portfolio}
                                                name="name_portfolio"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            /> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                {form.summa_investor !== '' &&
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="summa_investor">Сумма инвестиций: </Label>
                                            <InputSpinner
                                                value={form.summa_investor}
                                                name="summa_investor"
                                                type={"real"}
                                                precision={4}
                                                max={10000000}
                                                min={0}
                                                step={0.0001}
                                                disabled={true}  
                                                
                                            />
                                            {/* <Input
                                                min={0}
                                                step={1}
                                                type="number"
                                                className="form-control"
                                                placeholder='Название...'
                                                id="summa_investor"
                                                value={form.summa_investor}
                                                name="summa_investor"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            /> */}
                                        </div>
                                    </Col>
                                    }
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name_strategy">Стратегия у Инвестора:</Label>
                                            <br/>
                                            <Link to={`/si-investor/show/${form.id_strategy}`} title='' className='btn btn-outline-dark m-0'>
                                                {form.name_strategy}
                                            </Link>
                                            {/* <Input
                                                disabled={true}
                                                type="text"
                                                className="form-control"
                                                placeholder='Название...'
                                                id="name_strategy"
                                                value={form.name_strategy}
                                                name="name_strategy"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            /> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>    
                                {form.summa !== '' &&
                                        <Col xs={12} md={6} xl={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="summa">Сумма в торговле:</Label>
                                                <InputSpinner
                                                    value={form.summa}
                                                    name="summa"
                                                    type={"real"}
                                                    precision={4}
                                                    max={10000000}
                                                    min={0}
                                                    step={0.0001}
                                                    disabled={true}  
                                                   
                                                />
                                                {/* <Input
                                                min={0}
                                                step={1}
                                                type="number"
                                                className="form-control"
                                                placeholder='Название...'
                                                id="summa"
                                                value={form.summa}
                                                name="summa"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            /> */}
                                            </div>
                                        </Col>
                                    }                                
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name_trade_scheme">Торговая схема:</Label>
                                            <br/>
                                            <Link to={`/trade/show/${form.id_trade_scheme}`} title='' className='btn btn-outline-dark m-0'>
                                                {form.name_trade_scheme}
                                            </Link>
                                            {/* <Input
                                                disabled={true}
                                                type="text"
                                                className="form-control"
                                                placeholder='Название...'
                                                id="name_trade_scheme"
                                                value={form.name_trade_scheme}
                                                name="name_trade_scheme"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            /> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>                              
                                   
                                     <Col xs={12} lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.comment}
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                disabled={true}  
                                               
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        {/* <Label htmlFor="period">Активность ТС для Инвестора: </Label> */}
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='active_check'
                                                checked={form.active_check}
                                               
                                            />
                                            {form.active_check
                                                // ? <><i class="far fa-play-circle me-1" style={{ color: 'greenyellow' }}></i> ТС Активна</>
                                                // : <><i class="far fa-stop-circle me-1" style={{ color: 'red' }}></i> ТС Не Активна</>
                                                ? <>ТС Активна</>
                                                : <>ТС Не Активна</>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        {/* <Label htmlFor="period">Показывать в Telegram Bot: </Label> */}
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='show_check'
                                                checked={form.show_check}
                                               
                                            />
                                            {form.show_check
                                                ? <>Показывать в Telegram Bot</>
                                                : <>Не показывать в Telegram Bot</>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
           
        </div >
    )
}

export default TsiInvestorShow