import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
   
} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";

const OrderShow = () => {
    const { addToast } = useToasts()
    const history = useHistory();

    const { id } = useParams()
    const [form, setForm] = useState({
        name_user: '',
        show: false,
        comment: ''
    })

    useEffect(() => {
        const getOrders = async () => {
            axios.get(`${config.apiUrl}/orders/show/` + id)
                .then((response) => {
                    let statusName;
                    switch (response.data.status) {
                        case 1: statusName = 'Открытая'
                            break;
                        case 2: statusName = 'Закрытая'
                            break;
                        case 3: statusName = 'Открытие/заем на бирже'
                            break;
                        case 4: statusName = 'Открытие/открыли на бирже'
                            break;
                        case 5: statusName = 'Закрытие/отменили stop-loss'
                            break;
                        case 6: statusName = 'Закрытие/закрыли на бирже'
                            break;
                        case 7: statusName = 'Закрытие/закрыли по stop-loss'
                            break;
        
                        default:
                            break;
                    }
                    setForm({
                        id: response.data.id,
                        sumOrder: response.data.sumOrder,
                        date_open: response.data.date_open,
                        date_close: response.data.date_close,
                        price_open: response.data.price_open,
                        price_close: response.data.price_close,
                        profit_sum: response.data.profit_sum,
                        profit_percent: response.data.profit_percent,
                        total_percent: response.data.total_percent,
                        status: statusName,
                        position: response.data.position,
                        trade_scheme_id: response.data.trade_scheme.id,
                        trade_scheme_name: response.data.trade_scheme.name,
                    })                  
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getOrders()
    }, [id])

   console.log('87687687687');
    return (
        <div className="page-content">
            <MetaTags>
                <title>Ордер | TradeBot</title>
            </MetaTags>
            <Container fluid>               
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Просмотр Ордера #{id}</h4>
                                        <div>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>                                    
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Дата открытия: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.date_open}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Дата закрытия: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.date_close}                                               
                                            />
                                            
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Позиция: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"                                               
                                                value={form.position}                                               
                                            />
                                            
                                        </div>
                                    </Col>

                                    </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Сумма ордера у Инвестора: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.sumOrder}
                                            />

                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Цена открытия: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.price_open}
                                            />

                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Цена закрытия: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.price_close}
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Профит, %: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.profit_percent}
                                            />

                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Профит в деньгах: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.profit_sum}
                                            />

                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-4">
                                            <Label htmlFor="">Комиссия, %: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.total_percent}
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <div className="mb-4">
                                        <Label htmlFor="">Статус: </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={form.status}
                                        />
                                    </div> */}
                                </Row>
                                                              
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                      <Col className="col-12">
                        <Card>
                            <CardBody>                                
                            <Row>   
                            <Col xs={12} md={6} xl={4}>
                            <div className="mb-4">
                                        <Label htmlFor="">Статус: </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={form.status}
                                        />
                                    </div> 
                                    </Col>                           
                                <Col xs={12} md={6} xl={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="">Торговая схема:</Label>
                                        <br />
                                        <Link to={`/trade/show/${form.trade_scheme_id}`} title='' className='btn btn-outline-dark m-0' >
                                            {form.trade_scheme_name}
                                        </Link>
                                    </div>
                                </Col>                                
                            </Row>                        
                            </CardBody>
                        </Card>
                    </Col>
            </Container>
        </div >
    )
}

export default OrderShow