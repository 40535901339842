import { Alert, Card, CardBody, Col, Container, Row, Label, FormFeedback, } from "reactstrap";
import { Redirect } from "react-router-dom";
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"

import profile from "../assets/images/profile-img.png"
import logo from "../assets/images/SB_logo_small.svg"
import { useContext, useState } from "react";

import axios from 'axios';
import config from "../config";

import AppContext from "../store/AppContext";

const Login = () => {
    const [error, setError] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPin, setShowPin] = useState(false)
    const [pin, setPin] = useState('')

    const [typeInput, setTypeInput] = useState("password")

    const ctx = useContext(AppContext)

    const handleValidSubmit = async (event, values) => {
        setError(false)
        const response = await axios.post(`${config.apiUrl}/login`, values)
        const result = response.data
        if (result.res === 'Error') {
            setError(result.msg)
        } else {
            localStorage.setItem('token', result.token.token)
            setError(false)
            setShowPin(true)
        }
    }
    const handleValidSubmitPin = async (event, values) => {
        setError(false)
        values.jwt = localStorage.getItem('token')
        const response = await axios.post(`${config.apiUrl}/login-pin`, values)
        const result = response.data
        if (result.res === 'Error') {
            console.log(result);
            setError(result.msg)
            setPin('')
            setShowPin(false)
        } else {
            if (result.set != null) {
                let sets
                sets = JSON.parse(result.set)
                for (const key in sets) {
                    if (key != "token") localStorage.setItem(key, sets[key])
                }
            }
            setError(false)
            setPin('')
            ctx.saveUser({ name: 'Admin' })
        }
    }
    const handleChange = (event) => {
        switch (event.target.name) {
            case 'email':
                setEmail(event.target.value);
                if (error) setError(false);
                break;
            case 'password':
                setPassword(event.target.value);
                if (error) setError(false);
                break;
            case 'pin':
                setPin(event.target.value);
                if (error) setError(false);
                break;
            default:
                return null;
        }
    }

    const togglePassword = () => {
        if (typeInput === "password") {
            setTypeInput("text")
            return;
        }
        setTypeInput("password")
    }

    return (
        <AppContext.Consumer>
            {context => (
                <>
                    <MetaTags>
                        <title>Вход</title>
                    </MetaTags>
                    {context.user &&
                        <Redirect to='/' />
                    }
                    <div className="account-pages my-5 pt-sm-5">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="overflow-hidden">
                                        <div className="bg-primary bg-soft">
                                            <Row>
                                                <Col xs={7}>
                                                    {!showPin &&
                                                        <div className="text-primary p-4">
                                                            <h5>Добро пожаловать!</h5>
                                                            <h6>Для входа в личный кабинет - авторизуйтесь!</h6>
                                                        </div>
                                                    }
                                                    {showPin &&
                                                        <div className="text-primary p-4" >
                                                            <h5>Мы заботимся о Вашей безопасности!</h5>
                                                            <h6>Введите одноразовый код доступа!</h6>
                                                        </div>
                                                    }
                                                </Col>
                                                <Col className="col-5 align-self-end">
                                                    <img src={profile} alt="" className="img-fluid" />
                                                </Col>
                                            </Row>
                                        </div>
                                        <CardBody className="pt-0">
                                            <div>
                                                <div className="avatar-md profile-user-wid mb-4">
                                                    <span className="avatar-title rounded-circle bg-light">
                                                        <img
                                                            src={logo}
                                                            alt="" 
                                                            width="55"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            {!showPin &&
                                                (<div className="p-2 py-4">
                                                    <AvForm
                                                        className="form-horizontal"
                                                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                                    >

                                                        <div className="mb-3">
                                                            <Label htmlFor="email">E-mail</Label>
                                                            <AvField
                                                                name="email"
                                                                value={email}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                placeholder="..."
                                                                type="email"
                                                                invalid={
                                                                    email.length === 0 && error
                                                                }
                                                            // required
                                                            />
                                                        </div>
                                                        <FormFeedback invalid>
                                                            {" "}
                                                        </FormFeedback>

                                                        <Label htmlFor="password">Пароль</Label>
                                                        <div className="d-flex align-self-end">
                                                            <div className="w-100">
                                                                <AvField
                                                                    name="password"
                                                                    value={password}
                                                                    onChange={handleChange}
                                                                    type={typeInput}
                                                                    // required
                                                                    placeholder="..."
                                                                    invalid={
                                                                        password.length === 0 && error
                                                                    }
                                                                />
                                                            </div>
                                                            <FormFeedback invalid>
                                                                {" "}
                                                            </FormFeedback>
                                                            <div style={{ paddingLeft: 9 }}>
                                                                <button class="btn btn-light "
                                                                    type="button"
                                                                    id="password-addon"
                                                                    onClick={() => {
                                                                        console.log('Click');
                                                                        togglePassword()
                                                                    }}>
                                                                    {typeInput === "password" &&
                                                                        <i class="mdi mdi-eye-outline"></i>
                                                                    }
                                                                    {typeInput === "text" &&
                                                                        <i class="mdi mdi-eye-off-outline"></i>
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="mt-4 d-grid">
                                                            <button
                                                                className="btn btn-outline-primary btn-lg btn-block py-3"
                                                                type="submit"
                                                            >
                                                                Продолжить
                                                            </button>
                                                        </div>
                                                        <br />
                                                        {error ? <Alert color="danger">{error}</Alert> : null}

                                                    </AvForm>
                                                </div>)
                                            }
                                            {showPin &&
                                                (<div className="p-2 py-4">
                                                    <AvForm
                                                        className="form-horizontal"
                                                        onValidSubmit={(e, v) => handleValidSubmitPin(e, v)}
                                                    >

                                                        <div className="mb-3">
                                                            <Label htmlFor="pin">Код доступа</Label>
                                                            <AvField
                                                                name="pin"
                                                                value={pin}
                                                                onChange={handleChange}
                                                                className="form-control"
                                                                placeholder="..."
                                                                type="text"
                                                                // required
                                                                invalid={
                                                                    pin.length === 0 && error
                                                                }
                                                            />
                                                        </div>
                                                        <FormFeedback invalid>
                                                            {" "}
                                                        </FormFeedback>
                                                        <div className="mt-4 d-grid">
                                                            <button
                                                                className="btn btn-primary btn-lg btn-block py-3"
                                                                type="submit"
                                                            >
                                                                ВОЙТИ
                                                            </button>
                                                        </div>
                                                        {error ? <Alert color="danger">{error}</Alert> : null}

                                                    </AvForm>
                                                </div>)
                                            }


                                        </CardBody>
                                    </Card>

                                    {!showPin &&
                                        <div className="text-center">
                                            <p>
                                                Хотите зарегистрироваться? <a href="https://t.me/snb_help" target={"_blank"}>Обратитесь в поддержку</a>
                                            </p>
                                            <p>
                                                {/* {new Date().getFullYear()} by S&B Capital for Investors */}
                                                ©2018-2023 S&B Capital. Все права защищены.
                                            </p>
                                        </div>
                                    }
                                    {showPin &&
                                        <div className="text-center">
                                            <p>
                                                {/* {new Date().getFullYear()} by S&B Capital for Investors */}
                                                ©2018-2023 S&B Capital. Все права защищены.
                                            </p>
                                        </div>
                                    }

                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            )}
        </AppContext.Consumer>
    )
}

export default Login
