import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import { Link, useParams, Redirect } from "react-router-dom";
// import Select from "react-select"
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";


const ActionBalanceEdit = (props) => {
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)


    const { id } = useParams()
    const [form, setForm] = useState({
        name: '',
    })

    useEffect(() => {
        const getActions = async () => {
            const response = await axios.get(`${config.apiUrl}/ip-action-balance-edit/` + id)
            setForm({
                id: response.data.id,
                name: response.data.name
            })
        }
        getActions()
    }, [id])
    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const updateItem = (e) => {
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/ip-action-balance-update`, form)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.error(error);
            })
        }
    }, [formErrors]); 
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Редактирование действий Баланса | TradeBot</title>
            </MetaTags>
            <form onSubmit={(event)=>{
                console.log(event);
                event.preventDefault()
                updateItem()
            }}>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Редактирование действий Баланса #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                        <Button color="primary" type="submit">
                                            Изменить
                                        </Button>
                                        <Link to='/ip-action-balance' className='btn btn-danger btn'>
                                            <i className='mdi mdi-backspace' />
                                            &nbsp;
                                            Вернуться
                                        </Link>
                                        </div>                                        
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название нового действия</Label>
                                            <Input
                                                required={true}
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                }}
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </CardBody>
                        </Card>                    

                    </Col>
                </Row>
            </Container>
            </form>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/ip-action-balance",
                    }}
                />
            }
        </div>
    )
}

export default ActionBalanceEdit