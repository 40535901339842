import React from 'react';
import { Col, Row, Button } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import TableCustomToggleList from "../components/TableCustomToggleList";
import WV from '../workVariables'
var XLSX = require("xlsx");

const PaginationProviderCustom = ({ columns, tableData, tableDataExcel, nameTable, defaultSorted, nameExcel, nameList }) => {
  // Приблуда для скрытия/отображения столбцов, настроенных ранее
  let tableSets = localStorage.getItem(nameTable)
  if (tableSets) {
    workWithTableSet(tableSets)
  }
  let sizePerPage = parseInt(localStorage.getItem(nameTable + 'perPage'));
  if (!sizePerPage) sizePerPage = parseInt(WV.sizePerPage);
  let page1 = parseInt(localStorage.getItem(nameTable + 'page'));
  if ((!page1) || (nameTable == 'tableSet_historyAll')) page1 = 1;

  function workWithTableSet(tableSets) {
    tableSets = JSON.parse(tableSets)
    //  console.log(tableSets);
    columns.map((col) => {
      let name = col.dataField
      col.hidden = !tableSets[`${name}`]
      col.searchable = tableSets[`${name}`]
    })
  }
  const doTableSets = (name) => {
    let tableSets = localStorage.getItem(name)
    if (tableSets) {
      workWithTableSet(tableSets)
    }
  }

  const options = {
    // sizePerPage: WV.sizePerPage,
    sizePerPage: sizePerPage,
    sizePerPageList: WV.sizePerPageArr.concat([{ text: 'Все', value: (tableData).length }]),
    page: page1,
    onSizePerPageChange: (sizePerPage, page) => {
      localStorage.setItem(nameTable + 'perPage', sizePerPage)
      localStorage.setItem(nameTable + 'page', 1)
      doTableSets(nameTable)
    },
    onPageChange: (page, sizePerPage) => {
      localStorage.setItem(nameTable + 'page', page)
      doTableSets(nameTable)
    },
    totalSize: tableData.length,
    custom: true,
  }

  const exportExcel = () => {
    let wb = XLSX.utils.book_new()
    let ws = XLSX.utils.json_to_sheet(tableDataExcel)
    XLSX.utils.book_append_sheet(wb, ws, nameList)
    XLSX.writeFile(wb, nameExcel)
  }

  const TableSearchBarToggle = (props) => {
    let input;
    const handleChange = () => {
      doTableSets(nameTable)
      props.onSearch(input.value); //eslint-disable-line
    };
    return (
      <div>
        <label htmlFor="table-search-bar" className="w-100">
          <i class="bx bx-search-alt search-icon"></i>
          <input
            id="table-search-bar"
            className="form-control form-control-m "
            ref={n => input = n} //eslint-disable-line
            type="text"
            placeholder="Поиск..."
            onChange={handleChange}
          />
        </label>
      </div>
    );
  };

  return (
    <PaginationProvider
      pagination={paginationFactory(options)}
      keyField='id'
      columns={columns}
      data={tableData}>
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField='id'
          columns={columns}
          data={tableData}
          columnToggle
          search
        >
          {
            toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <Col md="6">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <TableSearchBarToggle
                          {...toolkitProps.searchProps}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl="6">
                    <div className='mb-3 d-flex justify-content-end align-items-center'>
                      <Button onClick={exportExcel} className="btn-sm me-2">
                        <i className='mdi mdi-file-excel me-1' />
                        XLS Export
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                      <TableCustomToggleList {...toolkitProps.columnToggleProps} name={nameTable} />
                    
                    <hr />
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField={"id"}
                        responsive
                        bordered={true}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={
                          // "table align-middle table-nowrap"
                          "table align-middle"
                        }
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone
                        {...paginationProps}
                      />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )
          }
        </ToolkitProvider>
      )}
    </PaginationProvider>
  )
}


export default PaginationProviderCustom;
