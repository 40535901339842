import MetaTags from "react-meta-tags";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link, useParams, Redirect } from "react-router-dom";
import Select from "react-select";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import makeAnimated from "react-select/animated";
import WV from "../../workVariables";
import InputSpinner from "react-bootstrap-input-spinner";
const animatedComponents = makeAnimated();

const TradeEdit = (props) => {
  const { addToast } = useToasts();
  const { id } = useParams();
  const [redirect, setRedirect] = useState(false);

  const [form, setForm] = useState({
    id: "",
    name: "",
    symbol: "",
    interval: "",
    status: "",
    sumOrder: "",
    no_borrow: false,
    commission_percent: "",
    stop_loss_price: "",
    stop_loss_percent: "",
    ma: "",
    scheme_id: "",
    account_id: "",
    symbol_bnb: "",
    sum_bnb: "",
    chats: "",
    comment: "",
    take_profit: ""
  });

  const [schemes, setSchemes] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [ma, setMa] = useState([]);
  const [chats, setChats] = useState([]);

  const [selectStatus, setSelectStatus] = useState({});
  const [selectSymbol, setSelectSymbol] = useState({});
  const [selectInterval, setSelectInterval] = useState({});
  const [selectSymbolBnb, setSelectSymbolBnb] = useState({});

  const [selectScheme, setSelectScheme] = useState({});
  const [selectAccount, setSelectAccount] = useState({});
  const [selectMa, setSelectMa] = useState([]);
  const [selectChat, setSelectChat] = useState([]);

  const [error, setError] = useState({
    name: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const storeItem = (e) => {
    // e.preventDefault();
    setFormErrors(validate(form));
    setIsSubmit(true);
  };
  const validate = (values) => {
    const errors = {};
    // if (!values.name) errors.name = "поле обязательное для заполнения";
    if (!values.name) errors.name = " ";
    if (values.commission_percent == "")
      errors.commission_percent = "поле обязательное для заполнения";
    if (values.sumOrder == "")
      errors.sumOrder = "поле обязательное для заполнения";
    if (values.stop_loss_price == "")
      errors.stop_loss_price = "поле обязательное для заполнения";
    if (values.stop_loss_percent == "")
      errors.stop_loss_percent = "поле обязательное для заполнения";
    return errors;
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      form.take_profit = (form.take_profit) ? form.take_profit : null;
      // console.log("form");
      // console.log(form);
      axios
        .post(`${config.apiUrl}/trade-schemes/update`, form)
        .then((response) => {
          if (response.data.res === "Success") {
            setRedirect(true);
          }
          setTimeout(() => {
            addToast(response.data.msg, {
              appearance: response.data.res.toLowerCase(),
              autoDismiss: true,
              autoDismissTimeout: 7500,
              transitionDuration: 2000,
            });
          }, 300);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [formErrors]);

  useEffect(() => {
    const getData = () => {
      axios
        .get(`${config.apiUrl}/trade-schemes/edit/` + id)
        .then(async (response) => {
          let no_borrow = false;
          response.data.no_borrow === 1
            ? (no_borrow = true)
            : (no_borrow = false);
          let inputSchemeId = response.data.scheme_id;
          let inputAccountId = response.data.account_id;
          let inputMa = response.data.ma;
          let inputChats = response.data.chats;

          parseInt(response.data.status) === 1
            ? setSelectStatus({ label: "ВКЛЮЧЕНА", value: 1 })
            : setSelectStatus({ label: "ВЫКЛЮЧЕНА", value: 0 });

          let symbols = WV.symbolsList;
          for await (let item of symbols) {
            if (response.data.symbol === item.value) {
              setSelectSymbol({ label: item.label, value: item.value });
            }
          }

          let intervals = WV.timeframesList;
          for await (let item of intervals) {
            if (response.data.interval === item.value) {
              setSelectInterval({ label: item.label, value: item.value });
            }
          }

          let symbolsBnb = WV.commissionSymbolsList;
          let strSymbolBnb = response.data.symbol_bnb;
          if (!strSymbolBnb) strSymbolBnb = "";
          for await (let item of symbolsBnb) {
            if (strSymbolBnb === item.value) {
              setSelectSymbolBnb({ label: item.label, value: item.value });
            }
          }
          setForm({
            id: response.data.id,
            name: response.data.name,
            symbol: response.data.symbol,
            interval: response.data.interval,
            status: parseInt(response.data.status),
            sumOrder: response.data.sumOrder,
            no_borrow: no_borrow,
            commission_percent: response.data.commission_percent,
            stop_loss_price: response.data.stop_loss_price,
            stop_loss_percent: response.data.stop_loss_percent,
            take_profit: (response.data.take_profit) ? response.data.take_profit : 0,
            // take_profit: 5,
            ma: response.data.ma,
            scheme_id: response.data.scheme_id,
            account_id: response.data.account_id,
            symbol_bnb: response.data.symbol_bnb,
            sum_bnb: response.data.sum_bnb,
            chats: response.data.chats,
            comment: response.data.comment,
          });
          let arraySchemes = [];
          let arrayAccounts = [];
          let arrayMa = [];
          let arrayChats = [];
          await axios
            .get(`${config.apiUrl}/schemas`)
            .then(async (response) => {
              for await (let item of response.data) {
                let option = {};
                option.label = item.name;
                option.value = item.id;
                arraySchemes.push(option);
                if (item.id === inputSchemeId) {
                  setSelectScheme({
                    label: item.name,
                    value: item.id,
                  });
                }
              }
              setSchemes(arraySchemes);
            })
            .catch((error) => {
              console.error(error);
            });

          await axios
            .get(`${config.apiUrl}/accounts`)
            .then(async (response) => {
              for await (let item of response.data) {
                let option = {};
                option.label = item.name;
                option.value = item.id;
                arrayAccounts.push(option);

                if (item.id === inputAccountId) {
                  setSelectAccount({
                    label: item.name,
                    value: item.id,
                  });
                }
              }
              setAccounts(arrayAccounts);
            })
            .catch((error) => {
              console.error(error);
            });

          await axios
            .get(`${config.apiUrl}/ma`)
            .then(async (response) => {
              let option = {
                label: "Текущая цена пары",
                value: "cpp",
              };
              arrayMa.push(option);
              for await (let item of response.data) {
                let option = {};
                option.label = item.name;
                option.value = item.id;
                arrayMa.push(option);
              }
              setMa(arrayMa);
              let dataMA = inputMa.split(", ");
              let selectOptions = [];
              for await (let one of dataMA) {
                if (one !== "cpp") {
                  one = parseInt(one);
                }
                for await (let option of arrayMa) {
                  let oneSelectOption = {};
                  if (option.value === one) {
                    oneSelectOption = {
                      label: option.label,
                      value: option.value,
                    };
                    selectOptions.push(oneSelectOption);
                  }
                }
              }
              setSelectMa(selectOptions);
            })
            .catch((error) => {
              console.error(error);
            });

          await axios
            .get(`${config.apiUrl}/chats`)
            .then(async (response) => {
              for await (let item of response.data) {
                let option = {};
                option.label = item.name;
                option.value = item.id;
                arrayChats.push(option);
              }
              setChats(arrayChats);

              let dataChats = inputChats.split(", ");
              // console.log(dataChats);
              let selectOptions = [];
              for await (let one of dataChats) {
                one = parseInt(one);
                for await (let option of arrayChats) {
                  let oneSelectOption = {};
                  if (option.value === one) {
                    oneSelectOption = {
                      label: option.label,
                      value: option.value,
                    };
                    selectOptions.push(oneSelectOption);
                  }
                }
              }
              setSelectChat(selectOptions);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getData();
  }, []);

  useEffect(() => {
    if (form.symbol_bnb === "") {
      setForm({ ...form, sum_bnb: "" });
    }
  }, [form.symbol_bnb]);

  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`,
    }),
  };
  // const history = useHistory();
  let strInfo = "Stop Price, % < Price, %";
  let errorItem;
  return (
    <div className="page-content">
      <MetaTags>
        <title>Торговые схемы | TradeBot</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className={"mb-3"}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">
                      Редактирование Торговой схемы #{id}
                    </h4>
                    <div className="d-flex gap-2 align-items-center">
                      <Button color="primary" onClick={storeItem}>
                        Изменить
                      </Button>
                      <Link to="/trade" className="btn btn-danger">
                        <i className="mdi mdi-backspace" />
                        &nbsp; Вернуться
                      </Link>
                    </div>
                  </div>
                </CardTitle>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="name">Название</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="..."
                        id="name"
                        value={form.name}
                        name="name"
                        invalid={
                          (form.name.length === 0 && error.name) ||
                          formErrors.name
                        }
                        onChange={(e) => {
                          setForm({
                            ...form,
                            [e.target.name]: e.target.value,
                          });
                          setError({ ...error, name: true });
                          errorItem = formErrors;
                          delete errorItem.name;
                          setFormErrors(errorItem);
                        }}
                      />
                      <FormFeedback invalid>
                        {" "}
                        {formErrors.name}
                        {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                      </FormFeedback>
                    </div>
                  </Col>
                  {form.sumOrder !== "" && (
                    <Col xs={12} md={6} xl={4}>
                      <div className="mb-3">
                        <Label htmlFor="sumOrder">Сумма ордера</Label>
                        <InputSpinner
                          value={form.sumOrder}
                          name="sumOrder"
                          type={"real"}
                          precision={4}
                          max={10000000}
                          min={0}
                          step={0.0001}
                          onChange={(num) => {
                            setForm({ ...form, sumOrder: num });
                            setError({ ...error, sumOrder: true });
                            errorItem = formErrors;
                            delete errorItem.sumOrder;
                            setFormErrors(errorItem);
                          }}
                        />
                        <p style={{ fontSize: "10px", color: "#e06466" }}>
                          {" "}
                          {formErrors.sumOrder}
                        </p>
                      </div>
                    </Col>
                  )}
                  {form.commission_percent !== "" && (
                    <Col xs={12} md={6} xl={4}>
                      <div className="mb-3">
                        <Label htmlFor="commission_percent">
                          Процент комиссии
                        </Label>
                        <InputSpinner
                          value={form.commission_percent}
                          name="commission_percent"
                          type={"real"}
                          precision={2}
                          max={100}
                          min={0}
                          step={0.01}
                          onChange={(num) => {
                            setForm({ ...form, commission_percent: num });
                            setError({ ...error, commission_percent: true });
                            errorItem = formErrors;
                            delete errorItem.commission_percent;
                            setFormErrors(errorItem);
                          }}
                        />
                        <p style={{ fontSize: "10px", color: "#e06466" }}>
                          {" "}
                          {formErrors.commission_percent}
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="symbol">Символ</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="symbol"
                        name="symbol"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, symbol: e.value });
                          setSelectSymbol({ label: e.label, value: e.value });
                        }}
                        options={WV.symbolsList}
                        value={selectSymbol}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  {form.stop_loss_price !== "" && (
                    <Col xs={12} md={6} lg={4}>
                      <div className="mb-3">
                        <Label htmlFor="stop_loss_price">Stop Price, % *</Label>
                        <InputSpinner
                          value={form.stop_loss_price}
                          name="stop_loss_price"
                          type={"real"}
                          precision={1}
                          max={100}
                          min={0}
                          step={0.1}
                          onChange={(num) => {
                            setForm({ ...form, stop_loss_price: num });
                            setError({ ...error, stop_loss_price: true });
                            errorItem = formErrors;
                            delete errorItem.stop_loss_price;
                            setFormErrors(errorItem);
                          }}
                        />
                        <p style={{ fontSize: "10px", color: "#e06466" }}>
                          {" "}
                          {formErrors.stop_loss_price}
                        </p>
                      </div>
                    </Col>
                  )}
                  <Col xs={12} md={6} lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="status_select">Статус схемы</Label>
                      <Select
                        id="status_select"
                        name="status"
                        placeholder="Активность схемы..."
                        onChange={(e) => {
                          setForm({ ...form, status: e.value });
                          setSelectStatus({ label: e.label, value: e.value });
                        }}
                        options={[
                          { label: "ВКЛЮЧЕНА", value: 1 },
                          { label: "ВЫКЛЮЧЕНА", value: 0 },
                        ]}
                        value={selectStatus}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="interval">Интервал</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="interval"
                        name="interval"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, interval: e.value });
                          setSelectInterval({
                            label: e.label,
                            value: e.value,
                          });
                        }}
                        options={WV.timeframesList}
                        value={selectInterval}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  {form.stop_loss_percent !== "" && (
                    <Col xs={12} md={6} xl={4}>
                      <div className="mb-3">
                        <Label htmlFor="stop_loss_percent">Price, % * </Label>
                        <InputSpinner
                          value={form.stop_loss_percent}
                          name="stop_loss_percent"
                          type={"real"}
                          precision={1}
                          max={100}
                          min={0}
                          step={0.1}
                          onChange={(num) => {
                            setForm({ ...form, stop_loss_percent: num });
                            setError({ ...error, stop_loss_percent: true });
                            errorItem = formErrors;
                            delete errorItem.stop_loss_percent;
                            setFormErrors(errorItem);
                          }}
                        />
                        <p style={{ fontSize: "10px", color: "#e06466" }}>
                          {" "}
                          {formErrors.stop_loss_percent}
                        </p>
                      </div>
                    </Col>
                  )}
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="symbol_bnb">
                        Торговая пара для погашения комиссии **
                      </Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="symbol_bnb"
                        name="symbol_bnb"
                        placeholder="Пара для погашения комиссии..."
                        onChange={(e) => {
                          setForm({ ...form, symbol_bnb: e.value });
                          setSelectSymbolBnb({
                            label: e.label,
                            value: e.value,
                          });
                        }}
                        options={WV.commissionSymbolsList}
                        value={selectSymbolBnb}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="comment">Комментарии</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="..."
                        id="comment"
                        name="comment"
                        value={form.comment}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            [e.target.name]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  {form.take_profit !== '' &&
                    <Col xs={12} md={6} xl={4}>
                      <div className="mb-3">
                        <Label> Тейк - профит, %</Label>
                        <InputSpinner
                          value={form.take_profit}
                          name="take_profit"
                          type={"real"}
                          precision={1}
                          max={100}
                          min={0}
                          step={0.1}
                          onChange={(num) => {
                            setForm({ ...form, take_profit: num });
                            setError({ ...error, take_profit: true })
                            errorItem = formErrors;
                            delete errorItem.take_profit
                            setFormErrors(errorItem);
                          }
                          }
                        />
                        <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.take_profit}</p>
                      </div>
                    </Col>
                  }                  
                  {form.symbol_bnb !== "" && form.symbol_bnb && (
                    <>
                      <Col xs={12} md={6} xl={4}>
                        <div className="mb-3">
                          <Label htmlFor="sum_bnb">Сумма комиссии</Label>
                          <InputSpinner
                            value={form.sum_bnb}
                            name="sum_bnb"
                            type={"real"}
                            max={10000000}
                            min={0}
                            step={0.000001}
                            onChange={(num) =>
                              setForm({ ...form, sum_bnb: num })
                            }
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div class="form-group form-check mt-4">
                      <Input
                        type="checkbox"
                        class="form-check-input"
                        placeholder="Введите процент..."
                        id="no_borrow"
                        checked={form.no_borrow}
                        onChange={() =>
                          setForm({ ...form, no_borrow: !form.no_borrow })
                        }
                      />
                      <Label htmlFor="no_borrow">Не использовать маржу</Label>
                    </div>
                  </Col>                 
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Подключение</h4>
                  </div>
                </CardTitle>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="scheme_id">Схема</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="scheme_id"
                        name="scheme_id"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, scheme_id: e.value });
                          setSelectScheme({ label: e.label, value: e.value });
                        }}
                        options={schemes}
                        value={selectScheme}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="accounts">Запись</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="accounts"
                        name="accounts"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, account_id: e.value });
                          setSelectAccount({
                            label: e.label,
                            value: e.value,
                          });
                        }}
                        options={accounts}
                        value={selectAccount}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <div className="mb-3">
                      <Label symbol="ma">
                        Скользящие средние &nbsp;
                        <abbr title="Select 1 > Select 2 > ... > Select N">
                          ?
                        </abbr>
                      </Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="ma"
                        value={selectMa}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        placeholder="выбрать..."
                        onChange={(value) => {
                          setForm({ ...form, ma: value });
                          setSelectMa(value);
                        }}
                        options={ma}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div className="mb-3">
                      <Label symbol="chats">Телеграм группы </Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="chats"
                        value={selectChat}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        placeholder="выбрать..."
                        onChange={(value) => {
                          setForm({ ...form, chats: value });
                          setSelectChat(value);
                        }}
                        options={chats}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center gap-3">
                      <div
                        className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                        style={{ width: 50, height: 50 }}
                      >
                        <i className="fas fa-info"></i>
                      </div>
                      <div className="d-flex flex-column">
                        <div>
                          <b>*</b> {strInfo}
                        </div>
                        <div>
                          <b>**</b> Если выбрана Торговая пара для погашения
                          комиссии и Сумма комиссии(во второй валюте торговой
                          пары), то перед открытием будет осуществлена покупка
                          первой пары на сумму комиссии и после закрытия сделки
                          будет выплачены бирже % за использование заемных
                          средств (interest) в BNB. <br></br>
                          Если выбрана Торговая пара для погашения и НЕ указана
                          Сумма комиссии, то после закрытия сделки будет
                          выплачены бирже % за использование заемных средств
                          (interest) в BNB.<br></br>
                          Если НЕ выбрана Торговая пара для погашения и НЕ
                          указана Сумма комиссии, то соостветственно ничего не
                          покупаем и ничего не гасим.
                          <br></br>Принцип погашения % за займ: если сумма
                          interest БОЛЬШЕ 10$, то покупаем BNB на сумму
                          погашения и гасим %. Если interest МЕНЬШЕ 10$, то
                          гасим с имеющихся BNB.
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {redirect && (
        <Redirect
          to={{
            pathname: "/trade",
          }}
        />
      )}
    </div>
  );
};
export default TradeEdit;
