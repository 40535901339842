import React, {useContext, useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import axios from "axios";
// import {useHistory} from "react-router-dom";
import config from "../../config";
import AppContext from "../../store/AppContext";

const ProfileMenu = (props) => {
    const [menu, setMenu] = useState(false)

    // const router = useHistory()

    const ctx = useContext(AppContext)

    const handleLogout = async () => {
        let token = localStorage.getItem('token')
        const response = await axios.get(`${config.apiUrl}/logout/${token}`)
        const result = response.data
        if(result.res === 'Success') {
            ctx.saveUser(null)
            localStorage.clear()
            // router.push('/login')
        }
    }

    return (
        <AppContext.Consumer>
            {context => (
                <>
                    {context.user &&
                        (<Dropdown
                            isOpen={menu}
                            toggle={() => setMenu(!menu)}
                            className="d-inline-block"
                        >
                            <DropdownToggle
                                className="btn header-item d-flex justify-content-between align-items-center"
                                id="page-header-user-dropdown"
                                tag="button"
                            >
                                <div className="rounded-circle header-profile-user d-flex justify-content-center align-items-center overflow-hidden">
                                    <i className="bx bx-user h5 mb-0" />
                                </div>
                                <span className="d-none d-xl-inline-block ms-2 me-1">
                                    {context.user.name}
                                </span>
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                {/* <DropdownItem tag="a" href="/settings" title='Настройки аккаунта'>
                                    <i className="bx bx-wrench font-size-16 align-middle me-1"/>
                                    Настройки
                                </DropdownItem> */}
                                {/* <div className="dropdown-divider"/> */}
                                <button className="dropdown-item" onClick={handleLogout}>
                                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
                                    <span>Выход</span>
                                </button>
                            </DropdownMenu>
                        </Dropdown>)
                    }
                </>
            )}
        </AppContext.Consumer>
    );
}

export default ProfileMenu;
