import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";
import config from "../../config";
import Select from "react-select";
import InputSpinner from "react-bootstrap-input-spinner";
import WV from "../../workVariables";

const Open3 = (props) => {
  const { open3, setOpen3 } = props;
  const [ma, setMa] = useState([]);
  useEffect(() => {
    const getMa = async () => {
      await axios
        .get(`${config.apiUrl}/schemas-ma`)
        .then((response) => {
          const ma2 = [];
          response.data.forEach((item) => {
            ma2.push({ label: item.name, value: item.id });
          });
          setMa(ma2);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getMa();
  }, []);
  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`,
    }),
  };
  return (
    <>
      <div className="text-muted flex-grow-1">
        <Row>
          {((!open3.num && !open3.color && !open3.price) ||
            open3.item === "price") && (
            <Col className={"mb-3"} style={{ paddingRight: 36 }}>
              <h6 className="text-center">
                Свеча закрытия. Применяется к цене
              </h6>
              <div className="d-flex gap-2">
                <Select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: `${WV.colorHoverSelect}`,
                      primary: `${WV.colorSelected}`,
                    },
                  })}
                  placeholder="выбрать..."
                  isDisabled={open3.isDisabled}
                  onChange={(value) => {
                    setOpen3({
                      ...open3,
                      closing: value.value,
                      item: "price",
                      color: "",
                    });
                  }}
                  options={[
                    { label: "Открытия", value: "open" },
                    { label: "Закрытия", value: "close" },
                    { label: "Цена High", value: "high" },
                    { label: "Цена Low", value: "low" },
                  ]}
                  value={[
                    { label: "Открытия", value: "open" },
                    { label: "Закрытия", value: "close" },
                    { label: "Цена High", value: "high" },
                    { label: "Цена Low", value: "low" },
                  ].filter((opt, i) => opt.value === open3.closing)}
                  classNamePrefix="select2-selection"
                  className="flex-grow-1"
                />
                {!open3.isDisabled && (
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      setOpen3({ ...open3, closing: "", price: "" })
                    }
                  >
                    <i className="mdi mdi-cancel"></i>
                  </button>
                )}
              </div>
            </Col>
          )}
          {(!open3.closing || open3.item !== "price") && (
            <Col xs={12} className={"mb-3"}>
              <div
                className="d-flex gap-2"
                style={{ width: "calc(100% - 16px)" }}
              >
                <div style={{ width: "28.8%" }}>
                  <h6 className="text-center">Номер свечи</h6>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `${WV.colorHoverSelect}`,
                        primary: `${WV.colorSelected}`,
                      },
                    })}
                    placeholder="выбрать..."
                    isDisabled={open3.isDisabled}
                    onChange={(value) => {
                      setOpen3({ ...open3, num: value.value, item: "noprice" });
                    }}
                    options={[
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 },
                      { label: 5, value: 5 },
                      { label: 6, value: 6 },
                      { label: 7, value: 7 },
                      { label: 8, value: 8 },
                      { label: 9, value: 9 },
                    ]}
                    value={[
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 },
                      { label: 5, value: 5 },
                      { label: 6, value: 6 },
                      { label: 7, value: 7 },
                      { label: 8, value: 8 },
                      { label: 9, value: 9 },
                    ].filter((opt, i) => opt.value === open3.num)}
                    classNamePrefix="select2-selection"
                    className="flex-grow-1"
                  />
                </div>
                <div style={{ width: "28%" }}>
                  <h6 className="text-center">Цвет свечи</h6>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `${WV.colorHoverSelect}`,
                        primary: `${WV.colorSelected}`,
                      },
                    })}
                    placeholder="выбрать..."
                    isDisabled={open3.isDisabled}
                    onChange={(value) => {
                      setOpen3({
                        ...open3,
                        color: value.value,
                        item: "noprice",
                      });
                    }}
                    options={[
                      { label: "Красная", value: "red" },
                      { label: "Зелёная", value: "green" },
                    ]}
                    value={[
                      { label: "Красная", value: "red" },
                      { label: "Зелёная", value: "green" },
                    ].filter((opt, i) => opt.value === open3.color)}
                    classNamePrefix="select2-selection"
                    className="flex-grow-1"
                  />
                </div>
                <div style={{ width: "39%", paddingLeft: 16 }}>
                  <h6 className="text-center">Применяется к цене</h6>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `${WV.colorHoverSelect}`,
                        primary: `${WV.colorSelected}`,
                      },
                    })}
                    placeholder="выбрать..."
                    isDisabled={open3.isDisabled}
                    onChange={(value) => {
                      setOpen3({
                        ...open3,
                        price: value.value,
                        item: "noprice",
                      });
                    }}
                    options={[
                      { label: "Открытия", value: "open" },
                      { label: "Закрытия", value: "close" },
                      { label: "Цена High", value: "high" },
                      { label: "Цена Low", value: "low" },
                    ]}
                    value={[
                      { label: "Открытия", value: "open" },
                      { label: "Закрытия", value: "close" },
                      { label: "Цена High", value: "high" },
                      { label: "Цена Low", value: "low" },
                    ].filter((opt, i) => opt.value === open3.price)}
                    classNamePrefix="select2-selection"
                    className="flex-grow-1"
                  />
                </div>
                {!open3.isDisabled && (
                  <button
                    className="btn btn-danger align-self-end"
                    onClick={() =>
                      setOpen3({ ...open3, num: "", color: "", price: "" })
                    }
                  >
                    <i className="mdi mdi-cancel"></i>
                  </button>
                )}
              </div>
            </Col>
          )}
          {/* <hr className="m-2" /> */}
          <Row className="mt-3">
            <Col xs={12} lg={7} className="mb-3">
              <div className="d-flex gap-2">
                <div className="flex-grow-1">
                  <h6 className="text-center">Выберите Moving Average</h6>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `${WV.colorHoverSelect}`,
                        primary: `${WV.colorSelected}`,
                      },
                    })}
                    placeholder="выбрать..."
                    styles={customStyles}
                    isDisabled={open3.isDisabled}
                    onChange={(value) => {
                      setOpen3({ ...open3, maId: value.value });
                    }}
                    options={ma}
                    value={ma.filter((opt) => opt.value === open3.maId)}
                    classNamePrefix="select2-selection"
                    className="flex-grow-1"
                  />
                </div>
                {!open3.closing && (
                  <div className="flex-grow-1">
                    <h6 className="text-center">МА от свечи или от цены</h6>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: `${WV.colorHoverSelect}`,
                          primary: `${WV.colorSelected}`,
                        },
                      })}
                      placeholder="выбрать..."
                      isDisabled={open3.isDisabled}
                      onChange={(value) => {
                        setOpen3({ ...open3, ma: value.value });
                      }}
                      options={[
                        { value: "candle", label: "От свечи" },
                        { value: "price", label: "От цены" },
                      ]}
                      value={[
                        { value: "candle", label: "От свечи" },
                        { value: "price", label: "От цены" },
                      ].filter((opt, i) => opt.value === open3.ma)}
                      classNamePrefix="select2-selection"
                      className="flex-grow-1"
                    />
                  </div>
                )}
                {open3.closing && (
                  <div className="flex-grow-1">
                    <h6 className="text-center">МА от свечи или от цены</h6>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: `${WV.colorHoverSelect}`,
                          primary: `${WV.colorSelected}`,
                        },
                      })}
                      placeholder="выбрать..."
                      isDisabled={open3.isDisabled}
                      onChange={(value) => {
                        setOpen3({ ...open3, ma: value.value });
                      }}
                      options={[{ value: "price", label: "От цены" }]}
                      value={[{ value: "price", label: "От цены" }].filter(
                        (opt, i) => opt.value === open3.ma
                      )}
                      classNamePrefix="select2-selection"
                      className="flex-grow-1"
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} lg={5} className="mb-3">
              <h6 className="text-center">Процент</h6>
              <div className="d-flex gap-2 w-100">
                <InputSpinner
                  value={open3.percFrom}
                  name="stops"
                  type={"real"}
                  precision={1}
                  max={1000}
                  min={-1000}
                  step={0.1}
                  disabled={open3.isDisabled}
                  onChange={(num) => setOpen3({ ...open3, percFrom: num })}
                  placeholder="0"
                />
                <InputSpinner
                  value={open3.percTo}
                  name="stops"
                  type={"real"}
                  precision={1}
                  max={1000}
                  min={-1000}
                  step={0.1}
                  disabled={open3.isDisabled}
                  onChange={(num) => setOpen3({ ...open3, percTo: num })}
                  placeholder="0"
                />
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
};

export default Open3;
