import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Label,
    Input,
    Button,
} from "reactstrap";
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import WV from "../../../workVariables";
import InputSpinner from "react-bootstrap-input-spinner";

const Type6 = (props) => {
    const { type6, setType6 } = props
    const [status, setStatus] = useState({
        shift_sl: false,
        stop_id: false
    })
    const [mas, setMas] = useState([])
    const [stops, setStops] = useState([])

    const [selectStop, setSelectStop] = useState({
        label: '',
        value: ''
    })

    const [selectMA, setSelectMA] = useState({
        label: '',
        value: ''
    })


    useEffect(() => {
        let arrayMas = [];
        let arrayStops = [];
        const getMas = async () => {
            await axios.get(`${config.apiUrl}/ma`)
                .then(async (response) => {
                    for await (let item of response.data) {
                        let option = {}
                        option.label = item.name
                        option.value = item.id
                        arrayMas.push(option)

                        if (item.id === type6.moving_average_id) {
                            setSelectMA({
                                label: item.name,
                                value: item.id
                            })
                        }
                    }
                    setMas(arrayMas)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        console.log(selectMA);
        const getStops = async () => {
            await axios.get(`${config.apiUrl}/stops`)
                .then(async (response) => {
                    for await (let item of response.data) {
                        let option = {}
                        option.label = item.name
                        option.value = item.id
                        arrayStops.push(option)

                        if (item.id === type6.stop_id) {
                            setSelectStop({
                                label: item.name,
                                value: item.id
                            })
                        }
                    }
                    setStops(arrayStops)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStops()
        getMas()
    }, [])
    // }, [type6.stop_id, type6.moving_average_id, selectMA])

    // useEffect(() => {
    //     if (type6.shiftStatus) setStatus({ ...status, shift_sl: true })
    //     if (type6.stopStatus) setStatus({ ...status, stop_id: true })
    // }, [type6.shiftStatus, type6.stopStatus, status])

    useEffect(() => {
        if (type6.shiftStatus) setStatus({ ...status, shift_sl: true })
        if (type6.stopStatus) setStatus({ ...status, stop_id: true })
    }, [])

    // console.log(type6);

    const clearShift = () => {
        setStatus({ ...status, shift_sl: false })
        setType6({ ...type6, shift_sl: '' })
    }
    const clearStop = () => {
        setStatus({ ...status, stop_id: false })
        setType6({ ...type6, stop_id: '' })
        setSelectStop(null)
    }
    const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3 mb-4">
                        <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                            style={{ width: 50, height: 50 }}>
                            <i className="fas fa-info"></i>
                        </div>
                        <info_1 />
                        Тип E) Стоп больше или меньше значения скользящей средней на n% на момент открытия сделки, он передвигается каждую закрытию свечу (то есть динамический стоп, больше или меньше скользящей на n%);
                    </div>
                </Col>
            </Row>
            <Row> 
            <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Разница Stop Price и Price, %</Label>
                        <InputSpinner
                        value={type6.delta_perc}
                        name="delta_perc"
                        type={"real"}
                        disabled={true}                        
                      />
                      
                    </div>
                </Col>
                <Col xs={12} md={6} xl={8}>
                    <Label htmlFor="period">Условие 1</Label>
                    <div className='d-flex align-items-center'>
                        <Input
                            type="checkbox"
                            className="form-check me-2"
                            placeholder='Введите процент...'
                            id='condition1'
                            disabled={true}
                            checked={type6.condition1}                            
                        />
                        Stop не ниже изначального
                    </div>
                </Col>
            </Row>
            <Row>
            <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Сдвиг цены, %</Label>
                        {/* <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}> */}
                            <InputSpinner
                                value={type6.shift_sl}
                                name="shift_sl"
                                type={"real"}
                                precision={1}
                                disabled={true}
                            />
                    </div>
                </Col>
                <Col xs={12} md={6} xl={8}>
                    <Label htmlFor="period">Условие 3</Label>
                    <div className='d-flex align-items-center'>
                        <Input
                            type="checkbox"
                            className="form-check me-2"
                            placeholder='Введите процент...'
                            id='condition3'
                            disabled={true}
                            checked={type6.condition3}                            
                        />
                        Stop не ниже предыдущего
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Выбрать MA</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="name"
                            value={selectMA.label}
                            readOnly={true}
                        />                       
                    </div>
                </Col>
            </Row>
       
        </>
    );
}

export default Type6;
