import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    FormFeedback,
} from "reactstrap"
import { useHistory, useParams, Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useToasts } from "react-toast-notifications";
import Select from "react-select"
import InputSpinner from "react-bootstrap-input-spinner";
import axios from "axios";
import LookButtons from '../../../components/LookButtons'
import config from "../../../config";
import WV from '../../../workVariables'

const PortfolioEditStr = (props) => {
    const { id } = useParams()
    const history = useHistory();
    const { addToast } = useToasts()


    const [strategyList, setStrategyList] = useState([])
    const [form, setForm] = useState({
        idPort: id,
        idStr: '',
        name: '',
        summInvestor: null,
        summ: null,
        info: [],
    })

    const getData = async () => {
        await axios
            .get(`${config.apiUrl}/ip-portfolio-show/${id}`)
            .then(async (response) => {
                setForm({
                    ...form,
                    info: response.data.info
                })
            })
            .catch((error) => {
                console.error(error);
            });
    };


    useEffect(() => {
        getData();
        const getStrategy = async () => {
            await axios
                .get(`${config.apiUrl}/ip-strategy-index`)
                .then(async (response) => {
                    let arrData = []
                    for await (let item of response.data) {
                        let el = {}
                        el.value = item.id
                        el.label = item.name
                        arrData.push(el)
                    }
                    setStrategyList(arrData)
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        getStrategy();
    }, []);

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2'>
                {/* <Link
                    to={`/strategy/show/${props.id}`}
                    className='btn btn-secondary'
                    title='Просмотр'>
                    <i class="far fa-eye" />
                </Link> */}
                <div className='d-flex gap-2'>
                    <button
                        onClick={() => {
                            // setModalConfirm(true)
                            deleteItem(props.id)
                        }
                        }
                        className='btn btn-danger'
                        title='Удалить стратегию из Портфеля'>
                        <i className='mdi mdi-delete' />
                    </button>
                </div>
            </div>
        )
    }


    function Structure() {
        let structure = form.info
        return (
            <Row>
                <Col className="col-12">
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th>Название стратегии</th>
                                <th>Название стратегии для Инвестора</th>
                                <th>Торговые схемы Портфеля</th>
                                <th style={{ width: 11 }}></th> */}
                                <th>Стратегия</th>
                                <th>Стратегия у Инвестора</th>
                                <th>Торговые схемы Стратегии</th>
                                <th>Торговые схемы в Стратегии Инвестора</th>
                                <th style={{ width: 11 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {structure.map((item) =>
                                <tr>
                                    <td>
                                        <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                            <LookButtons
                                                url='/strategy/show/'
                                                id={item.id}
                                                title='Посмотреть торговую стратегию'
                                                lable={item.name}
                                            />
                                        </li>   
                                    </td>
                                    <td>{item.nameUser}</td>
                                    <td>
                                        <ul>
                                            {item.ts.map((el) =>
                                                <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/strategy/show/'
                                                        id={el.id}
                                                        title='Посмотреть торговую схему'
                                                        lable={el.name}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </td>
                                    <td>
                                        <ActionButtons id={item.id} />
                                    </td>
                                </tr>
                            )
                            } */}
                             {structure.map((item) =>
                                <tr>
                                    <td>
                                        <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/strategy/show/'
                                                        id={item.id}
                                                        title='Посмотреть торговую стратегию'
                                                        lable={item.name}
                                                    />
                                        </li>                                           
                                    </td>
                                    <td>
                                        {/* {item.nameUser} */}
                                    <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/si-investor/show/'
                                                        id={item.idStrInv}
                                                        title='Посмотреть торговую стратегию у Инвестора'
                                                        lable={item.nameStrInv}
                                                    />
                                        </li>        
                                    </td>
                                    <td>
                                        <ul>
                                            {item.ts.map((el) =>
                                                <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/trade/show/'
                                                        id={el.id}
                                                        title='Посмотреть торговую схему'
                                                        lable={el.name}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            {item.ts.map((el) =>
                                                <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/tsi-investor/show/'
                                                        id={el.id_tsinvestor}
                                                        title='Посмотреть торговую схему в Стратегии Инвестора'
                                                        lable={el.name_tsinvestor}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </td>
                                    <td>
                                        <ActionButtons id={item.id} />
                                    </td>
                                </tr>
                            )
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        )
    }

    const deleteItem = async (id) => {
        let idPort = form.idPort
        let idStr = id
        await axios
            .get(`${config.apiUrl}/ip-portfolio-delete-str/${idPort}/${idStr}`)
            .then(async (response) => {
                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                }, 300);
                getData();
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const addItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.idStr == '') errors.idStr = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/ip-portfolio-add-str`, form)
            .then((response) => {
                // setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                // }, 300);
                if (response.data.res === "Success") {                    
                    getData();
                }
            })
            .catch(error => {
                console.error(error);
            })
        }
    }, [formErrors]); 
    let errorItem; 

    return (
        <div className="page-content">
            <MetaTags>
                <title>Портфели Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <Row>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className='mb-0'>Редактирование структуры Портфеля Инвестора</h4>
                                            <div>
                                                <button
                                                    className='btn btn-primary me-2'
                                                    onClick={addItem}
                                                    title='Добавить стратегию в Портфель'>
                                                    <i className='mdi mdi-plus' />
                                                    &nbsp;
                                                    Добавить
                                                </button>
                                                <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                    <i className='mdi mdi-backspace' />
                                                    &nbsp;
                                                    Вернуться
                                                </button>
                                            </div>
                                        </div>
                                    </Row>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="idStr">Новая стратегия</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="idStr"
                                                name="idStr"
                                                placeholder="выбрать..."
                                                onChange={(e) => {
                                                    setForm({ ...form, idStr: e.value });
                                                    setError({ ...error, idStr: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.idStr
                                                    setFormErrors(errorItem);
                                                }}
                                                options={strategyList}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.idStr}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название Стратегии для Инвестора</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);

                                                }}
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="summInvestor">Сумма инвестиций</Label>
                                            <InputSpinner
                                                name="summInvestor"
                                                type={"real"}
                                                precision={4}
                                                max={1000000000}
                                                min={0.0001}
                                                step={0.0001}
                                                onChange={(num) => {
                                                    setForm({ ...form, summInvestor: num })
                                                }
                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="summ">Сумма в торговле</Label>
                                            <InputSpinner
                                                name="summ"
                                                type={"real"}
                                                precision={4}
                                                max={1000000000}
                                                min={0.0001}
                                                step={0.0001}
                                                onChange={(num) => {
                                                    setForm({ ...form, summ: num })
                                                }
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <Row>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className='mb-0'>Структура Портфеля</h4>
                                        </div>
                                    </Row>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className="mb-3">
                                            <Structure />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PortfolioEditStr