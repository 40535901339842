import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback,
} from "reactstrap"
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../config";
import InputSpinner from "react-bootstrap-input-spinner";

export default function StopsAdd() {
    const history = useHistory()
    const { addToast } = useToasts()
    const [newLevel, setNewLevel] = useState(0)
    const [levels, setLevels] = useState([])
    const [form, setForm] = useState({
        name: '',
        comment: null,
        sets: null
    })
    const [error, setError] = useState({
        name: false,
    });

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const store = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (!values.sets) errors.sets = "Введите стопы";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/stops-store`, form)
                .then((response) => {
                    history.goBack()
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })

                    }, 300);
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [formErrors]);

    useEffect(() => {
        let setsRow = ""
        levels.map((el, index) => {
            if (index === 0) {
                setsRow = el
            } else {
                setsRow = setsRow + " " + el
            }
        })
        if (levels.length === 0) setsRow = null
        setForm({ ...form, sets: setsRow })
    }, [levels])

    const handleAddLevel = (e) => {
        e.preventDefault()
        setError({ ...error, sets: true })
        errorItem = formErrors;
        delete errorItem.sets
        setFormErrors(errorItem);
        setLevels([...levels, newLevel])
        setNewLevel(0)
    }

    console.log(form);
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Наборы стопов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Новый набор стопов</h4>
                                        <div>
                                            <Button color="primary" className="me-2" onClick={store}>
                                                Сохранить
                                            </Button>

                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                }}
                                            />
                                        </div>
                                        <FormFeedback invalid> {formErrors.name}
                                        </FormFeedback>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <Label htmlFor="stops">Новый стоп</Label>
                                        <div className="d-flex align-items-center">
                                            <InputSpinner
                                                value={newLevel}
                                                name="stops"
                                                type={"real"}
                                                precision={1}
                                                max={100}
                                                min={0}
                                                step={0.1}
                                                onChange={(num) => setNewLevel(num)
                                                }
                                            />
                                            <div style={{ paddingLeft: 9 }}>
                                                <Button color="success" onClick={handleAddLevel}>
                                                    +
                                                </Button>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row className="d-flex flex-wrap">

                                    <Col className="d-flex justify-content-center align-items-center flex-wrap">
                                        {
                                            levels.length > 0 &&
                                            levels.map((lvl, idx) => (
                                                <div className="p-3 d-flex justify-content-center align-items-center rounded-circle me-2"
                                                    style={{
                                                        width: 56,
                                                        height: 56,
                                                        backgroundColor: "#222736",
                                                        // backgroundColor: "rgba(0,0,0,.3)",
                                                        marginRight: 4
                                                    }}
                                                    key={idx}
                                                >
                                                    {lvl}
                                                </div>
                                            ))
                                        }
                                    </Col>
                                </Row>
                                <p style={{ fontSize: "10px", color: "#e06466" }}>
                                    {" "}
                                    {formErrors.sets}
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
