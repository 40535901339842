import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Button } from "reactstrap";
import axios from "axios";
import config from "../../../config";
import { Link } from "react-router-dom";
import PaginationProviderCustom from "../../../components/PaginationProviderCustom";

const BalanceInvestor = (props) => {

    const [actions, setActions] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataExcel, setTableDataExcel] = useState([])
    function userNameFormatter(cell, row) {
        return (
            <Link to={'/ip-investors/show/' + row.user.id} className='btn btn_md' title='Перейти на страницу Инвестора'>
                {cell}
            </Link>
        );
    }
    function portfolioNameFormatter(cell, row) {
        return (
            <Link to={'/portfolio/show/' + row.portfolio.id} className='btn btn_md' title='Перейти на страницу Портфеля у Инвестора'>
                {cell}
            </Link>
        );
    }
    const columns = [
        {
            dataField: 'id',
            text: <span className='text'>Id</span>, 
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'created_at',
            text: <span className='text'>Дата</span>,
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'user.name_admin', 
            text: <span className='text'>Инвестор</span>,
            sort: true, 
            formatter: userNameFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'portfolio.name',
            text: <span className='text'>Портфель</span>,
            sort: true,
            formatter: portfolioNameFormatter,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'saldo_start', 
            text: <span className='text'>Баланс на начало</span>,
            sort: true,
            style: {
                width: 111,
            },
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'plus', 
            text: <span className='text'>Приход</span>,
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'minus',
            text: <span className='text'>Расход</span>,
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'saldo_end', 
            text: <span className='text'>Баланс на конец</span>,
            sort: true,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'action_balance.name', 
            text: <span className='text'>Действие Баланса</span>,
            sort: true,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'comment_for_admin', 
            text: <span className='text'>Комментарии для Админа</span>,
            sort: true,
            style: {
                width: 111,
            },
            headerClasses: 'table-th-150'
        },
        {
            dataField: 'comment_for_investor', 
            text: <span className='text'>Комментарии для Инвестора</span>,
            sort: true, 
            headerClasses: 'table-th-150'
        },
        {
            dataField: 'actions',
            text: 'Действия',
            style: {
                width: 111,
            },
            headerClasses: 'py-20'
        },
    ];

    // Приблуда для скрытия/отображения столбцов, настроенных ранее
    let tableSets = localStorage.getItem('tableSet_balanceInvestor')
    if (tableSets) {
        tableSets = JSON.parse(tableSets)
        columns.map((col) => {
            let name = col.dataField
            col.hidden = !tableSets[`${name}`]
        })
    }

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2'>
                <Link to={`/ip-balance-investor/edit/${props.id}`} className='btn btn-primary' title='Редактировать комментарии к операции'>
                    <i className='mdi mdi-pencil' />
                </Link>
            </div>
        )
    }

    useEffect(() => {
        const getActions = async () => {
            const response = await axios.get(`${config.apiUrl}/ip-balance-index`)
            setActions(response.data)
        }
        getActions()
    }, [])

    useEffect(() => {
        let tableData = [];
        let tableDataExcel = [];
        let itemObject = {};   
        if (actions.length > 0) {
            actions.forEach(item => {
                itemObject = {};
                itemObject['Id'] = item.id;
                itemObject['Дата'] = item.created_at.substr(0, 10);
                itemObject['Время'] = item.created_at.substr(11, 8);
                itemObject['Инвестор'] = item.user.name_admin;
                itemObject['Портфель'] = item.portfolio.name;
                itemObject['Баланс на начало'] = item.saldo_start;
                itemObject['Приход'] = item.plus;
                itemObject['Расход'] = item.minus;
                itemObject['Баланс на конец'] = item.saldo_end;
                itemObject['Действие баланса'] = item.action_balance.name;
                itemObject['Комментарии для админа'] = item.comment_for_admin;
                itemObject['Комментарии для инвестора'] = item.comment_for_investor;
                tableData.push({
                    
                    ...item,
                    "actions": (<ActionButtons id={item.id} />)
                })
                tableDataExcel.push(itemObject)
            })
        }          
        setTableData(tableData)
        setTableDataExcel(tableDataExcel)
    }, [actions])

    return (
        <div className="page-content">
            <MetaTags>
                <title>Баланс Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Баланс Инвесторов</h4>
                                        <Link to='/ip-balance-investor/add' className='btn btn-primary'>
                                            <i className='mdi mdi-plus m-1' />
                                            Добавить операцию
                                        </Link>
                                    </div>
                                </CardTitle>
                                <PaginationProviderCustom
                                    columns={columns} tableData={tableData} nameTable="tableSet_balanceInvestor" 
                                    tableDataExcel = {tableDataExcel} 
                                    defaultSorted = {defaultSorted}
                                    nameExcel = {'BalanceInvestor.xlsx'} 
                                    nameList = {'Info'}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BalanceInvestor;
