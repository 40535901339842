import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    FormFeedback,
} from "reactstrap"
import { useHistory, useParams, Link, Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from 'react'
import InputSpinner from "react-bootstrap-input-spinner";
import LookButtons from '../../../components/LookButtons'
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import WV from '../../../workVariables'

const PortfolioEdit = (props) => {
    const { id } = useParams()
    const history = useHistory();
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)

    const [form, setForm] = useState({
        id: null,
        name: '',
        name_user: '',
        summ: '',
        comission: '',
        selectPeriod: '',
        period: '',
        percent_reserve: '',
        comment: '',
        user: '',
        strategies: [],
        schemes: [],
        info: [],
        show: null,
        all_period: null,
        start_year: null,
        by_month: null,
        by_quarte_year: null,
        by_half_year: null,
        comby_yearment: null,
        show_summ_portfolio: null,
        show_summ_strategy: null,
        show_summ_trade_sheme: null,
    })

    useEffect(() => {
        const getData = async () => {
                 axios
                .get(`${config.apiUrl}/ip-portfolio-show/${id}`)
                .then(async (response) => {
                    let label;
                    let sets = JSON.parse(response.data.statistics)

                    switch (response.data.period) {
                        case 'byMonth':
                            label = 'месяц'
                            break;
                        case 'byQuarteYear':
                            label = 'квартал'
                            break;
                        case 'byHalfYear':
                            label = 'полугодие'
                            break;
                        case 'byYear':
                            label = 'год'
                            break;
                        default:
                            break;
                    }
                    let show = false
                    let all_period = false
                    let start_year = false
                    let by_month = false
                    let by_quarte_year = false
                    let by_half_year = false
                    let by_year = false
                    let show_summ_portfolio = false
                    let show_summ_strategy = false
                    let show_summ_trade_sheme = false

                    if (response.data.show === 1) show = true
                    if (sets.all_period === 1) all_period = true
                    if (sets.start_year === 1) start_year = true
                    if (sets.by_month === 1) by_month = true
                    if (sets.by_quarte_year === 1) by_quarte_year = true
                    if (sets.by_half_year === 1) by_half_year = true
                    if (sets.by_year === 1) by_year = true
                    if (sets.show_summ_portfolio === 1) show_summ_portfolio = true
                    if (sets.show_summ_strategy === 1) show_summ_strategy = true
                    if (sets.show_summ_trade_sheme === 1) show_summ_trade_sheme = true

                    setForm({
                        id: parseInt(id),
                        name: response.data.name,
                        name_user: response.data.name_user,
                        summ: response.data.summ,
                        comission: response.data.comission,
                        selectPeriod: { label: label, value: response.data.period },
                        period: response.data.period,
                        percent_reserve: response.data.percent_reserve,
                        comment: response.data.comment,
                        user: response.data.user.name_admin,
                        strategies: response.data.strategyInfo,
                        schemes: response.data.dataTS,
                        info: response.data.info,
                        show: show,
                        all_period: all_period,
                        start_year: start_year,
                        by_month: by_month,
                        by_quarte_year: by_quarte_year,
                        by_half_year: by_half_year,
                        comby_yearment: by_year,
                        show_summ_portfolio: show_summ_portfolio,
                        show_summ_strategy: show_summ_strategy,
                        show_summ_trade_sheme: show_summ_trade_sheme,
                    })
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        getData();
    }, []);

    function Structure() {
        let structure = form.info
        return (
            <Row>
                <Col className="col-12">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Стратегия</th>
                                <th>Стратегия у Инвестора</th>
                                <th>Торговые схемы Стратегии</th>
                                <th>Торговые схемы в Стратегии Инвестора</th>
                                <th style={{ width: 11 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {structure.map((item) =>
                                <tr>
                                    <td>
                                        <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/strategy/show/'
                                                        id={item.id}
                                                        title='Посмотреть торговую стратегию'
                                                        lable={item.name}
                                                    />
                                        </li>                                           
                                    </td>
                                    <td>
                                        {/* {item.nameUser} */}
                                    <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/si-investor/show/'
                                                        id={item.idStrInv}
                                                        title='Посмотреть торговую стратегию у Инвестора'
                                                        lable={item.nameStrInv}
                                                    />
                                        </li>        
                                    </td>
                                    <td>
                                        <ul>
                                            {item.ts.map((el) =>
                                                <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/trade/show/'
                                                        id={el.id}
                                                        title='Посмотреть торговую схему'
                                                        lable={el.name}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            {item.ts.map((el) =>
                                                <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/tsi-investor/edit/'
                                                        id={el.id_tsinvestor}
                                                        title='Посмотреть торговую схему в Стратегии Инвестора'
                                                        lable={el.name_tsinvestor}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </td>
                                    {/* <td>
                                        <ActionButtons id={item.id} />
                                    </td> */}
                                </tr>
                            )
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        )
    }

    const [error, setError] = useState({
        name: false,
        name_user: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const updateItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (!values.name_user) errors.name_user = " ";
        if (values.summ == '') errors.summ = "поле обязательное для заполнения";
        if (values.comission === -1) errors.comission = "поле обязательное для заполнения";
        if (values.percent_reserve === -1) errors.percent_reserve = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/ip-portfolio-update`, form)
            .then((response) => {
                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })

                }, 300);
                if (response.data.res === "Success") {                    
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.error(error);
            })
        }
    }, [formErrors]); 
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Портфели Инвесторов | TradeBot</title>
            </MetaTags>
           
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <Row>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className='mb-0'>Редактирование Портфеля Инвестора #{id}</h4>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <button className='btn btn-primary me-2' onClick={updateItem} title='Редактировать'>
                                                    Изменить
                                                </button>                                                <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                    <i className='mdi mdi-backspace' />
                                                    &nbsp;
                                                    Вернуться
                                                </button>
                                            </div>
                                        </div>
                                    </Row>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Инвестор</Label>
                                            <Input
                                                disabled={true}
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.user}
                                                name="name"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                value={form.comment}
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mt-4'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='show_summ_portfolio'
                                                checked={form.show}
                                                onChange={() => setForm({ ...form, show: !form.show })}
                                            />
                                            Отображать Портфель Инвестору в ТБ
                                        </div>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Администратора</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                }}
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Инвестора:</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name_user"
                                                value={form.name_user}
                                                name="name_user"
                                                invalid={
                                                    (form.name_user.length === 0 && error.name_user) ||
                                                    formErrors.name_user
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, name_user: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name_user
                                                    setFormErrors(errorItem);
                                                }}
                                            />
                                            <FormFeedback invalid> {formErrors.name_user}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="scheme_id">Период погашения комиссии:</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="scheme_id"
                                                name="scheme_id"
                                                placeholder="выбрать..."
                                                value={form.selectPeriod}
                                                onChange={(e) => {
                                                    setForm({ ...form, period: e.value, selectPeriod: { label: e.label, value: e.value } })
                                                }}
                                                options={WV.periodForPortfolio}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>                                   
                                </Row>
                                <Row>
                                <Col xs={12} md={6} xl={4}>
                                        {form.summ !== '' &&
                                            <div className="mb-3">
                                                <Label htmlFor="summ">Сумма в управление:</Label>
                                                <InputSpinner
                                                    value={form.summ}
                                                    name="summ"
                                                    type={"real"}
                                                    precision={2}
                                                    max={1000000000}
                                                    min={0.01}
                                                    step={0.01}
                                                    disabled={true}
                                                    onChange={(num) => {
                                                        setForm({ ...form, summ: num })
                                                    }
                                                    }
                                                />
                                            </div>
                                        }
                                    </Col>
                                <Col xs={12} md={6} xl={4}>
                                        {form.comission !== '' &&
                                            <div className="mb-3">
                                                <Label htmlFor="comission">Вознаграждение за управление, %:</Label>
                                                <InputSpinner
                                                    value={form.comission}
                                                    name="comission"
                                                    type={"real"}
                                                    precision={2}
                                                    max={100}
                                                    min={0}
                                                    step={0.01}                                                    
                                                    onChange={(num) => {
                                                        setForm({ ...form, comission: num });
                                                        setError({ ...error, comission: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.comission
                                                        setFormErrors(errorItem);
                                                    }
                                                    }
                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.comission}</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        {form.percent_reserve !== '' &&
                                            <div className="mb-3">
                                                <Label htmlFor="percent_reserve">Процент резерва &nbsp;
                                                    <abbr title="Процент от суммы в управлении, который не участвует непосредственно в торговле">
                                                        ?
                                                    </abbr></Label>
                                                <InputSpinner
                                                    value={form.percent_reserve}
                                                    name="percent_reserve"
                                                    type={"real"}
                                                    precision={2}
                                                    max={1000000000}
                                                    min={0}
                                                    step={0.01}
                                                    onChange={(num) => {
                                                        setForm({ ...form, percent_reserve: num })
                                                        setError({ ...error, percent_reserve: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.percent_reserve
                                                        setFormErrors(errorItem);
                                                    }
                                                    }
                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.percent_reserve}</p>
                                            </div>
                                        }
                                    </Col>
                                </Row>  
                               
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <Row>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className='mb-0'>Структура Портфеля</h4>
                                            <div>
                                                <Link to={`/portfolio/edit-str/${id}`} className='btn btn-primary' title='Редактировать'>
                                                    Изменить структуру
                                                </Link>
                                            </div>
                                        </div>
                                    </Row>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className="mb-3">
                                            <Structure />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>
                                            Настройка отображения статистики по Портфелю
                                        </h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={8} lg={6}>
                                        <div className="mb-3">
                                            <h6 className='mb-0'>
                                                Статистика
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={6}>
                                        <h6 className='mb-0'>
                                            Отображать сумму
                                        </h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={2} lg={2}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='all_period'
                                                checked={form.all_period}
                                                onChange={() => setForm({ ...form, all_period: !form.all_period })}
                                            />
                                            За весь период
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='comby_yearment'
                                                checked={form.comby_yearment}
                                                onChange={() => setForm({ ...form, comby_yearment: !form.comby_yearment })}
                                            />
                                            По годам
                                        </div>
                                    </Col>                                    
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='show_summ_portfolio'
                                                checked={form.show_summ_portfolio}
                                                onChange={() => setForm({ ...form, show_summ_portfolio: !form.show_summ_portfolio })}
                                            />
                                            По портфелю 
                                        </div>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col xs={12} md={2} lg={2}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='start_year'
                                                checked={form.start_year}
                                                onChange={() => setForm({ ...form, start_year: !form.start_year })}
                                            />
                                            С начала года
                                        </div>
                                    </Col>                                    
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='by_half_year'
                                                checked={form.by_half_year}
                                                onChange={() => setForm({ ...form, by_half_year: !form.by_half_year })}
                                            />
                                            По полугодиям
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='show_summ_strategy'
                                                checked={form.show_summ_strategy}
                                                onChange={() => setForm({ ...form, show_summ_strategy: !form.show_summ_strategy })}
                                            />
                                            По стратегии 
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={2} lg={2}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='by_quarte_year'
                                                checked={form.by_quarte_year}
                                                onChange={() => setForm({ ...form, by_quarte_year: !form.by_quarte_year })}
                                            />
                                            По кварталам
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='by_month'
                                                checked={form.by_month}
                                                onChange={() => setForm({ ...form, by_month: !form.by_month })}
                                            />
                                            По месяцам
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='show_summ_trade_sheme'
                                                checked={form.show_summ_trade_sheme}
                                                onChange={() => setForm({ ...form, show_summ_trade_sheme: !form.show_summ_trade_sheme })}
                                            />
                                            По торговым схемам 
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>               
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/portfolio",
                    }}
                />
            }
        </div>
    )
}

export default PortfolioEdit