import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Label,
    Input,
    Button,
} from "reactstrap";
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import InputSpinner from "react-bootstrap-input-spinner";

const Type5 = (props) => {
    const { type5, setType5 } = props
    
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3  m-2">
                        <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                            style={{ width: 50, height: 50 }}>
                            <i className="fas fa-info"></i>
                        </div>
                        <info_1 />
                        Тип Д) 'Безубыточный стоп'. Стоп должен передвигаться на изначальную точку открытия сделки (+ комиссия биржи), если цена движется в выгодном нам движении на определённое количество %;
                    </div>
                </Col>
            </Row>
            <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Разница Stop Price и Price, %</Label>
                        <InputSpinner
                        value={type5.delta_perc}
                        name="delta_perc"
                        type={"real"}
                        precision={1}
                        disabled={true}
                      />                        
                    </div>
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Сдвиг цены, %</Label>
                        {/* <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}> */}
                    <InputSpinner
                        value={type5.shift_sl}
                        name="shift_sl"
                        type={"real"}
                        disabled={true}
                    />                                
                    </div>
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Прибавляем к цене открытия сделки, %</Label>
                        <InputSpinner
                        value={type5.percent}
                        name="percent"
                        type={"real"}
                        disabled={true}
                    />                        
                    </div>
                </Col>

                   {/* <Col xs={12} md={6} xl={6}>
                    <div className="mb-3">
                        <Label htmlFor="period">Выбрать набор сдвигов, %</Label>
                        <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}>
                                <Select
                                    id='stop_id'
                                    placeholder="выбрать..."
                                    isDisabled={status.shift_sl && true}
                                    onChange={e => {
                                        setType5({ ...type5, stop_id: e.value })
                                        setStatus({ ...status, stop_id: true })
                                        setSelectStop({label: e.label, value: e.value})
                                    }}
                                    options={stops}
                                    value={selectStop}
                                    classNamePrefix="select2-selection"
                             
                                />
                            </div>
                            <Button
                                className='btn btn-danger'
                                disabled={status.shift_sl && true}
                                onClick={clearStop}>
                                <i class="far fa-trash-alt"></i>
                            </Button>
                        </div>
                    </div>
                </Col> */}
           
        </>
    );
}

export default Type5;
