import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback,    
} from "reactstrap"
import { useHistory } from "react-router-dom";
import Select from "react-select"
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";
import InputSpinner from "react-bootstrap-input-spinner";
import WV from '../../../workVariables'


const PortfolioAdd = () => {
    const { addToast } = useToasts()
    const history = useHistory();

    const [form, setForm] = useState({
        name: '',
        name_user: '',
        summ: '',
        comission: '',
        period: '',
        percent_reserve: '',
        comment: '',
        user_id: '',
        standartPort_id: '',
        all_period: '',
        start_year: '',
        by_month: '',
        by_quarte_year: '',
        by_half_year: '',
        comby_yearment: '',
        show_summ_portfolio: '',
        show_summ_strategy: '',
        show_summ_trade_sheme: '',
    })

    const [users, setUsers] = useState([]);
    const [portfolio, setPortfolio] = useState([]);

    useEffect(() => {
        let arrayUser = [];
        let arrayPort = [];
        const getLists = async () => {
            await axios
                .get(`${config.apiUrl}/ip-portfolio-create`)
                .then(async (response) => {
                    let dataUser = response.data.userList
                    let dataPort = response.data.portList
                    if (dataUser.length > 0) {
                        for await (let item of dataUser) {
                            let option = {};
                            option.label = item.name_admin;
                            option.value = item.id;
                            arrayUser.push(option);
                        }
                    }
                    setUsers(arrayUser)
                    if (dataPort.length > 0) {
                        for await (let item of dataPort) {
                            let option = {};
                            option.label = item.name;
                            option.value = item.id;
                            arrayPort.push(option);
                        }
                    }
                    setPortfolio(arrayPort)
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        getLists();
    }, []);
    

    const [error, setError] = useState({
        name: false,
        name_user: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (!values.name_user) errors.name_user = " ";
        if (values.period == '') errors.period = "поле обязательное для заполнения";
        if (values.summ == '') errors.summ = "поле обязательное для заполнения";
        if (values.comission == '') errors.comission = "поле обязательное для заполнения";
        if (values.percent_reserve === '') errors.percent_reserve = "поле обязательное для заполнения";
        if (values.user_id == '') errors.user_id = "поле обязательное для заполнения";
        if (values.standartPort_id == '') errors.standartPort_id = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/ip-portfolio-store`, form)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                history.goBack()
            })
            .catch(error => {
                console.error(error);
            })
        }
    }, [formErrors]); 
    let errorItem;

    return (
        <div className="page-content">
            <MetaTags>
                <title>Портфели Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Создание Портфеля Инвестора</h4>
                                        <div>
                                            <Button color="primary me-2" onClick={storeItem}>
                                                Сохранить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Администратора:</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                }}
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Инвестора:</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name_user"
                                                value={form.name_user}
                                                name="name_user"
                                                invalid={
                                                    (form.name_user.length === 0 && error.name_user) ||
                                                    formErrors.name_user
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                    setError({ ...error, name_user: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name_user
                                                    setFormErrors(errorItem);
                                                }}
                                            />
                                            <FormFeedback invalid> {formErrors.name_user}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="scheme_id">Период погашения комиссии:</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="period"
                                                name="period"
                                                placeholder="выбрать..."
                                                onChange={(e) => {
                                                    setForm({ ...form, period: e.value });
                                                    setError({ ...error, period: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.period
                                                    setFormErrors(errorItem);
                                                }}
                                                options={WV.periodForPortfolio}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.period}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="summ">Сумма в управление</Label>
                                            <InputSpinner
                                                value={form.summ}
                                                name="summ"
                                                type={"real"}
                                                precision={2}
                                                max={1000000000}
                                                min={0.01}
                                                step={0.01}
                                                onChange={(num) => {
                                                    setForm({ ...form, summ: num });
                                                    setError({ ...error, summ: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.summ
                                                    setFormErrors(errorItem);
                                                }
                                                }
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.summ}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="comission">Вознаграждение за управление, %:</Label>
                                            <InputSpinner
                                                value={form.comission}
                                                name="comission"
                                                type={"real"}
                                                precision={2}
                                                max={100}
                                                min={0.01}
                                                step={0.01}
                                                onChange={(num) => {
                                                    setForm({ ...form, comission: num });
                                                    setError({ ...error, comission: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.comission
                                                    setFormErrors(errorItem);
                                                }
                                                }
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.comission}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="percent_reserve">Процент резерва &nbsp;
                                                <abbr title="Процент от суммы в управлении, который не участвует непосредственно в торговле">
                                                    ?
                                                </abbr>
                                            </Label>
                                            <InputSpinner
                                                value={form.percent_reserve}
                                                name="percent_reserve"
                                                type={"real"}
                                                precision={2}
                                                max={100}
                                                min={0}
                                                step={0.01}
                                                onChange={(num) => {
                                                    setForm({ ...form, percent_reserve: num });
                                                    setError({ ...error, percent_reserve: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.percent_reserve
                                                    setFormErrors(errorItem);

                                                }
                                                }
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.percent_reserve}</p>
                                        </div>
                                    </Col>
                                </Row>                              
                                <Row>
                                    <Col xs={12} lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>

                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="user_id">Выбрать Инвестора</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="user_id"
                                                name="user_id"
                                                placeholder="выбрать..."
                                                onChange={(e) => {
                                                    setForm({ ...form, user_id: e.value });
                                                    setError({ ...error, user_id: true });
                                                    errorItem = formErrors;
                                                    delete errorItem.user_id;
                                                    setFormErrors(errorItem);
                                                }}
                                                options={users}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.user_id}</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="standartPort_id">Выбрать Стандартный портфель</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="standartPort_id"
                                                name="standartPort_id"
                                                placeholder="выбрать..."
                                                onChange={(e) => {
                                                    setForm({ ...form, standartPort_id: e.value });
                                                    setError({ ...error, standartPort_id: true });
                                                    errorItem = formErrors;
                                                    delete errorItem.standartPort_id;
                                                    setFormErrors(errorItem);
                                                }}
                                                options={portfolio}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.standartPort_id}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>
                                            Настройка отображения статистики по Портфелю
                                        </h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={8} lg={6}>
                                        <div className="mb-3">
                                            <h6 className='mb-0'>
                                                Статистика
                                            </h6>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={6}>
                                        <h6 className='mb-0'>
                                            Отображать сумму
                                        </h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={2} lg={2}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='all_period'
                                                checked={form.all_period}
                                                onChange={() => setForm({ ...form, all_period: !form.all_period })}
                                            />
                                            За весь период
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='comby_yearment'
                                                checked={form.comby_yearment}
                                                onChange={() => setForm({ ...form, comby_yearment: !form.comby_yearment })}
                                            />
                                            По годам
                                        </div>
                                    </Col>                                    
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='show_summ_portfolio'
                                                checked={form.show_summ_portfolio}
                                                onChange={() => setForm({ ...form, show_summ_portfolio: !form.show_summ_portfolio })}
                                            />
                                            По портфелю 
                                        </div>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col xs={12} md={2} lg={2}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='start_year'
                                                checked={form.start_year}
                                                onChange={() => setForm({ ...form, start_year: !form.start_year })}
                                            />
                                            С начала года
                                        </div>
                                    </Col>                                    
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='by_half_year'
                                                checked={form.by_half_year}
                                                onChange={() => setForm({ ...form, by_half_year: !form.by_half_year })}
                                            />
                                            По полугодиям
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='show_summ_strategy'
                                                checked={form.show_summ_strategy}
                                                onChange={() => setForm({ ...form, show_summ_strategy: !form.show_summ_strategy })}
                                            />
                                            По стратегии 
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={2} lg={2}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='by_quarte_year'
                                                checked={form.by_quarte_year}
                                                onChange={() => setForm({ ...form, by_quarte_year: !form.by_quarte_year })}
                                            />
                                            По кварталам
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='by_month'
                                                checked={form.by_month}
                                                onChange={() => setForm({ ...form, by_month: !form.by_month })}
                                            />
                                            По месяцам
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} lg={4}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='show_summ_trade_sheme'
                                                checked={form.show_summ_trade_sheme}
                                                onChange={() => setForm({ ...form, show_summ_trade_sheme: !form.show_summ_trade_sheme })}
                                            />
                                            По торговым схемам 
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PortfolioAdd