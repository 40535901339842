import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    Spinner,
} from "reactstrap"
import { Link, useParams } from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import config from "../../config";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from 'react-select/animated';
import WV from '../../workVariables'
import { DateTimePicker } from '@mui/lab';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from '../../muiTheme';
import InputSpinner from "react-bootstrap-input-spinner";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import fileDownload from 'js-file-download';

const animatedComponents = makeAnimated();
const moment = require('moment')

const Test2Edit = (props) => {
    const { id } = useParams()
    const [spinnerStatus, setSpinnerStatus] = useState(false)
    const [spinnerStatus2, setSpinnerStatus2] = useState(false)
    const [form, setForm] = useState({
        name: '',
        maId: '',
        show_open: '',
        scheme_id: '',
        symbol: '',
        interval: '',
        procent_comision: '',
        comment: '',
        sl_scheme_id: '',
        birzha: '',
        stop_loss: '',
        period_test: '',
        annual_yield: '',
        trade_scheme_id: '',
        take_profit: ''
    });

    // const [startTime, setStartTime] = useState(new Date());
    // const [endTime, setEndTime] = useState(new Date());

    registerLocale('ru', ru)
    setDefaultLocale('ru')

    const [ma, setMa] = useState([])
    const [schema, setSchema] = useState([])
    const [stop, setStop] = useState([])
    const [tradeScheme, setTradeScheme] = useState([])
    useEffect(() => {
        const getTestScheme = async () => {
            const response = await axios.get(`${config.apiUrl}/test-second-schema/${id}`)
            let periodTest, birzha, tradeScheme;
            if (!response.data.period_test) periodTest = [{ label: 'Весь период', value: null }];
            if (response.data.period_test == 1) periodTest = [{ label: 'По месяцам', value: '1' }];
            if (response.data.period_test == 2) periodTest = [{ label: 'По кварталам', value: '2' }];
            if (response.data.period_test == 3) periodTest = [{ label: 'По полугодиям', value: '3' }];
            if (response.data.period_test == 4) periodTest = [{ label: 'По годам', value: '4' }];
            if (response.data.birzha == 1) birzha = [{ label: 'Binance', value: '1' }];
            if (response.data.birzha == 2) birzha = [{ label: 'Bitfinex', value: '2' }];
            if (response.data.trade_scheme_id) tradeScheme = response.data.trade_scheme_id[0].label
            else tradeScheme = 'Не выбирать торговую схему'

            console.log(response.data.startTime);
            console.log(response.data.endTime);

            let st = moment.utc(response.data.startTime)
            st = st._i.substr(0, 10) + " " + st._i.substr(11, 8)
            let et = moment.utc(response.data.endTime)
            et = et._i.substr(0, 10) + " " + et._i.substr(11, 8)

            setForm({
                id: id,
                name: response.data.name,
                comment: response.data.comment,
                show_open: response.data.show_open[0].label,
                scheme_id: response.data.scheme_id[0].label,
                sl_scheme_id: response.data.sl_scheme_id[0].label,
                trade_scheme_id: tradeScheme,
                symbol: response.data.symbol[0].label,
                interval: response.data.interval[0].label,
                procent_comision: response.data.procent_comision,
                // stop_id: response.data.stop_id,
                stop_loss: response.data.stop_loss,
                period_test: periodTest[0].label,
                annual_yield: response.data.annual_yield,
                birzha: birzha[0].label,
                maId: response.data.ma,
                startTime: st,
                // startTime: response.data.startTime,
                endTime: et,
                take_profit: (response.data.take_profit) ? response.data.take_profit : 0
            })
        }
        getTestScheme()
        const getMa = async () => {
            await axios.get(`${config.apiUrl}/schemas-ma`)
                .then((response) => {
                    let arrayMa = response.data;
                    for (let i = 0; i < arrayMa.length; i++) {
                        arrayMa[i].value = arrayMa[i].id;
                        arrayMa[i].label = arrayMa[i].name;
                        delete arrayMa[i].id;
                        delete arrayMa[i].name
                    }
                    if (arrayMa.length > 0) arrayMa.unshift({ value: 'cpp', label: 'Текущая цена пары' })
                    setMa(arrayMa)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getMa()
        const getScheme = async () => {
            await axios.get(`${config.apiUrl}/schemas`)
                .then((response) => {
                    let arraySchema = response.data;
                    for (let i = 0; i < arraySchema.length; i++) {
                        arraySchema[i].value = arraySchema[i].id;
                        arraySchema[i].label = arraySchema[i].name;
                        delete arraySchema[i].id;
                        delete arraySchema[i].name
                    }
                    setSchema(arraySchema)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getScheme()

        const getStop = async () => {
            await axios.get(`${config.apiUrl}/test-second-schemas-stops-sl`)
                .then((response) => {
                    // console.log(response.data);
                    let arrayStop = response.data;
                    for (let i = 0; i < arrayStop.length; i++) {
                        arrayStop[i].value = arrayStop[i].id;
                        arrayStop[i].label = arrayStop[i].name;
                        delete arrayStop[i].id;
                        delete arrayStop[i].name
                    }
                    setStop(arrayStop)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStop()

        const getTradeScheme = async () => {
            await axios.get(`${config.apiUrl}/test-second-schemas-trade-scheme`)
                .then((response) => {
                    // console.log(response.data);
                    let arrayTs = response.data;
                    for (let i = 0; i < arrayTs.length; i++) {
                        arrayTs[i].value = arrayTs[i].id;
                        arrayTs[i].label = arrayTs[i].name;
                        delete arrayTs[i].id;
                        delete arrayTs[i].name
                    }
                    setTradeScheme(arrayTs)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getTradeScheme()
    }, [])
    const { addToast } = useToasts()

    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color: `${WV.colorSelectInput}`
        }),
    }

    const coreAction = () => {
        const fileName = form.name + ".xlsx"
        setSpinnerStatus(true)
        axios({
            url: `${config.apiUrl}/test-second-schema-core/${id}`,
            method: 'GET'
        }).then((response) => {
            // console.log(response.data);
            if (response.data.status === "success") {
                // console.log('SECOND AXIOS . . . .');
                setTimeout(() => {
                    // console.log('.......send');
                    axios.get(`${config.apiUrl}/test-second-schema-core-get-file/${fileName}`, { responseType: 'blob' })
                        .then((response) => {
                            // console.log(response.data);
                            setSpinnerStatus(false)
                            fileDownload(response.data, fileName)
                        })
                        .catch(error => {
                            console.error(error);
                        })
                }, 2000);
            } else {
                setTimeout(() => {
                    addToast("Отчет не построен! Возможно некорректный запрос!", {
                        appearance: "info",
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    setSpinnerStatus(false)
                }, 1000);
            }
        }).catch(error => {
            console.error(error);
        })
    }



    const coreAction2 = () => {
        const fileName = form.name + "_compare" + ".xlsx"
        setSpinnerStatus2(true)
        axios({
            url: `${config.apiUrl}/test-second-schema-core-compare/${id}`,
            method: 'GET'
        }).then((response) => {
            // console.log(response.data);
            if (response.data.status === "success") {
                // console.log('SECOND AXIOS . . . .');
                setTimeout(() => {
                    // console.log('.......send');
                    axios.get(`${config.apiUrl}/test-second-schema-core-compare-get-file/${fileName}`, { responseType: 'blob' })
                        .then((response) => {
                            // console.log(response.data);
                            setSpinnerStatus2(false)
                            fileDownload(response.data, fileName)
                        })
                        .catch(error => {
                            console.error(error);
                        })
                }, 2000);
            } else {
                setTimeout(() => {
                    addToast("Отчет не построен! Возможно некорректный запрос!", {
                        appearance: "info",
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    setSpinnerStatus2(false)
                }, 1000);
            }
        }).catch(error => {
            console.error(error);
        })
    }


    // console.log(form);
    return (
        <div className="page-content">
            <MetaTags>
                <title>Просмотр Тестовой Схемы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Просмотр Тестовой Схемы 2.0 #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Link to={`/test2/edit/${id}`} className='btn btn-secondary me-2' title='Редактировать'>
                                                <i className='mdi mdi-pencil' />
                                                &nbsp;
                                                Редактировать
                                            </Link>
                                            <Link to='/test2' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                value={form.name}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label symbol="position">Символ</Label>
                                            <Input
                                                type="text"
                                                value={form.symbol}
                                                className="form-control"
                                                readOnly={true}

                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label symbol="interval">Интервал</Label>
                                            <Input
                                                type="text"
                                                value={form.interval}
                                                className="form-control"
                                                readOnly={true}
                                            />
                                        </div>
                                        {form.procent_comision !== '' &&
                                            <div className="mb-3">
                                                <Label> Изначальный стоп, %</Label>
                                                <InputSpinner
                                                    value={form.stop_loss}
                                                    name="stop_loss"
                                                    type={"real"}
                                                    max={form.stop_loss}
                                                    min={form.stop_loss}

                                                />
                                            </div>
                                        }

                                    </Col>

                                    <Col xs={12} md={6} xl={8}>
                                        <Row>
                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label symbol="birzha">Биржа</Label>
                                                    <Input
                                                        type="text"
                                                        value={form.birzha}
                                                        className="form-control"
                                                        readOnly={true}
                                                    />
                                                </div>
                                                {form.annual_yield !== '' &&
                                                    <div className="mb-3">
                                                        <Label> Годовой % доходности для Сортино</Label>
                                                        <InputSpinner
                                                            value={form.annual_yield}
                                                            name="annual_yield"
                                                            type={"real"}
                                                            precision={1}
                                                            max={form.annual_yield}
                                                            min={form.annual_yield}

                                                        />
                                                    </div>
                                                }
                                                {form.procent_comision !== '' &&
                                                    <div className="mb-3">
                                                        <Label> Процент комиссии</Label>
                                                        <InputSpinner
                                                            value={form.procent_comision}
                                                            name="procent_comision"
                                                            type={"real"}
                                                            precision={2}
                                                            max={form.procent_comision}
                                                            min={form.procent_comision}

                                                        />

                                                    </div>
                                                }
                                                
                                                {
                                                    form.take_profit !== '' &&
                                                    <div className="mb-3">
                                                        <Label> Тейк - профит, %</Label>
                                                        <InputSpinner
                                                            value={form.take_profit}
                                                            name="take_profit"
                                                            type={"real"}
                                                            max={form.take_profit}
                                                            min={form.take_profit}
                                                        />
                                                    </div>
                                                }

                                            </Col>

                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="comment">Комментарии</Label>
                                                    <Input
                                                        type="text"
                                                        // style={{ resize: 'none', height: '2.4rem' }}
                                                        className="form-control"
                                                        value={form.comment}
                                                        readOnly={true}

                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label symbol="show_open">Отображение сделок в Excel</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={form.show_open}
                                                        readOnly={true}

                                                    />

                                                </div>
                                                
                                                <div className="mb-4">
                                                    <Label symbol="position">Разбить на периоды</Label>
                                                    <Input
                                                        type="text"
                                                        value={form.period_test}
                                                        className="form-control"
                                                        readOnly={true}
                                                    />
                                                </div>

                                                <Row className='g-2'>
                                                    <Col xl={6}>
                                                        <div className='mb-3 mb-xl-0'>
                                                            <ThemeProvider theme={darkTheme}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                                    <DateTimePicker
                                                                        renderInput={(props) => <TextField {...props} />}
                                                                        label="Время начала"
                                                                        value={form.startTime}
                                                                        ampm={false}
                                                                        readOnly={true}
                                                                    />
                                                                </LocalizationProvider>
                                                            </ThemeProvider>
                                                        </div>
                                                    </Col>
                                                    <Col xl={6}>
                                                        <div className="mb-0">
                                                            <ThemeProvider theme={darkTheme}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                                    <DateTimePicker
                                                                        renderInput={(props) => <TextField {...props} />}
                                                                        label="Время завершения"
                                                                        value={form.endTime}
                                                                        ampm={false}
                                                                        readOnly={true}
                                                                    />
                                                                </LocalizationProvider>
                                                            </ThemeProvider>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>
                                    </div>

                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label symbol="sl_scheme_id">SL схема</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={form.sl_scheme_id}
                                                readOnly={true}

                                            />

                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <Row>
                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label symbol="maId">Скользящие средние &nbsp;
                                                        <abbr title="MA1 > MA2 > ... > MAn">
                                                            ?
                                                        </abbr></Label>
                                                    <Select
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: `${WV.colorHoverSelect}`,
                                                                primary: `${WV.colorSelected}`,
                                                            },
                                                        })}
                                                        styles={customStyles}
                                                        id='maId'
                                                        value={form.maId}
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        isMulti
                                                        placeholder="выбрать..."
                                                        onChange={(value) => {
                                                            setForm({ ...form, maId: value })
                                                        }}
                                                        options={ma}
                                                        isDisabled
                                                        classNamePrefix="select2-selection"
                                                    />

                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label symbol="trade_scheme_id">Проверка сделок по торговой схеме</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={form.trade_scheme_id}
                                                        readOnly={true}

                                                    />

                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>


                                    <div className="mb-3">
                                        <Label symbol="scheme_id">Схема</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={form.scheme_id}
                                            readOnly={true}

                                        />

                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <Row>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <Button color="primary" onClick={coreAction} disabled={spinnerStatus}>
                                            <i className='bx bxs-flask' style={{ marginRight: "8px" }}></i>
                                            Протестировать схему
                                        </Button>
                                        <Button style={{ marginLeft: "23px" }} color="primary" onClick={coreAction2} disabled={spinnerStatus}>
                                            <i className='bx bxs-flask' style={{ marginRight: "8px" }}></i>
                                            Сравнить торговлю с тестировщиком
                                        </Button>
                                        {spinnerStatus &&
                                            <div style={{ marginLeft: "13px" }}>
                                                <Spinner className="ms-2" color="info" />
                                            </div>
                                        }
                                        {spinnerStatus2 &&
                                            <div style={{ marginLeft: "13px" }}>
                                                <Spinner className="ms-2" color="info" />
                                            </div>
                                        }
                                    </div>
                                </Row>
                                {/* <Row style={{ marginTop: "9px" }}>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <Button color="primary" onClick={coreAction2} disabled={spinnerStatus}>
                                            <i className='bx bxs-flask' style={{ marginRight: "8px" }}></i>
                                            Сравнить торговлю с тестировщиком
                                        </Button>
                                        {spinnerStatus2 &&
                                            <div style={{ marginLeft: "13px" }}>
                                                <Spinner className="ms-2" color="info" />
                                            </div>
                                        }
                                    </div>
                                </Row> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>

        </div>
    );
}

export default Test2Edit;