import React, { useState, useEffect } from "react";
import InputSpinner from "react-bootstrap-input-spinner";

const FuckingSpinners2 = ({ open1, setOpen1 }) => {
  const [trigger, setTrigger] = useState(true);
  useEffect(() => {
    setTrigger(false);
    setTimeout(() => setTrigger(true), 10);
  }, [open1]);
  return (
    <div className="d-flex gap-2">
      {trigger && (
        <>
          <InputSpinner
            value={open1.percFrom}
            name="stops"
            type={"real"}
            precision={1}
            max={1000}
            min={-1000}
            step={0.1}
            disabled={open1.isDisabled}
            onChange={(num) => setOpen1({ ...open1, percFrom: num })}
            placeholder="0"
          />
          <InputSpinner
            value={open1.percTo}
            name="stops"
            type={"real"}
            precision={1}
            max={1000}
            min={-1000}
            step={0.1}
            disabled={open1.isDisabled}
            onChange={(num) => setOpen1({ ...open1, percTo: num })}
            placeholder="0"
          />
        </>
      )}
    </div>
  );
};

export default FuckingSpinners2;
