const WV = {
    // Кол-во записей на странице таблицы
    sizePerPage: 10,

    // Кастомный список для пагинации
    sizePerPageArr: [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
    ],

    // Список валют
    symbolsList: [
        { label: 'BTCUSDT', value: 'BTCUSDT' },
        { label: 'ETHUSDT', value: 'ETHUSDT' },
        { label: 'BNBUSDT', value: 'BNBUSDT' },
        { label: 'ADAUSDT', value: 'ADAUSDT' },
        { label: 'SOLUSDT', value: 'SOLUSDT' },
        { label: 'XRPUSDT', value: 'XRPUSDT' },
        { label: 'LTCUSDT', value: 'LTCUSDT' },
        { label: 'MATICUSDT', value: 'MATICUSDT' },
        { label: 'DOGEUSDT', value: 'DOGEUSDT' },
        { label: 'DOTUSDT', value: 'DOTUSDT' },
        { label: 'ETHBTC', value: 'ETHBTC' },
        { label: 'XRPBTC', value: 'XRPBTC' },
        { label: 'SOLBTC', value: 'SOLBTC' },
        { label: 'BNBBTC', value: 'BNBBTC' },
        { label: 'ADABTC', value: 'ADABTC' },
        { label: 'LTCBTC', value: 'LTCBTC' },
        { label: 'MATICBTC', value: 'MATICBTC' },
        { label: 'TRXBNB', value: 'TRXBNB' },
        { label: 'XRPBNB', value: 'XRPBNB' },
        { label: 'LTCBNB', value: 'LTCBNB' },
        { label: 'EOSBNB', value: 'EOSBNB' },
        { label: 'BCHBNB', value: 'BCHBNB' },
        { label: 'DOTBNB', value: 'DOTBNB' },
        { label: 'ADABNB', value: 'ADABNB' },
        { label: 'SOLBNB', value: 'SOLBNB' },
        { label: 'LINKETH', value: 'LINKETH' },
        { label: 'BNBETH', value: 'BNBETH' },
        { label: 'TRXETH', value: 'TRXETH' },
        { label: 'EOSETH', value: 'EOSETH' },
        { label: 'XRPETH', value: 'XRPETH' },
        { label: 'ADAETH', value: 'ADAETH' },
        { label: 'SOLETH', value: 'SOLETH' },
        { label: 'LTCETH', value: 'LTCETH' },
    ],

    // Список валют для погашения комиссии
    commissionSymbolsList: [
        { label: 'Не выбирать торговую пару', value: '' },
        { label: 'BNBUSDT', value: 'BNBUSDT' },
        { label: 'BNBBTC', value: 'BNBBTC' },
    ],

    // Список таймфреймов
    timeframesList: [
        { label: '1m', value: '1m' },
        { label: '3m', value: '3m' },
        { label: '5m', value: '5m' },
        { label: '15m', value: '15m' },
        { label: '30m', value: '30m' },
        { label: '1h', value: '1h' },
        { label: '2h', value: '2h' },
        { label: '4h', value: '4h' },
        { label: '6h', value: '6h' },
        { label: '8h', value: '8h' },
        { label: '12h', value: '12h' },
        { label: '1d', value: '1d' },
        { label: '3d', value: '3d' },
        { label: '1w', value: '1w' },
        { label: '1M', value: '1M' },
    ],

    // Список типов Стоп Лос схем
    stopLossTypeList: [
        { label: 'Тип А', value: '1' },
        { label: 'Тип Б', value: '2' },
        { label: 'Тип В', value: '3' },
        { label: 'Тип Г', value: '4' },
        { label: 'Тип Д', value: '5' },
        { label: 'Тип Е', value: '6' },
        { label: 'Тип З', value: '7' },
    ],

    // Список годов
    yaersList: [
        { label: '2021', value: '2021' },
        { label: '2022', value: '2022' },
        { label: '2023', value: '2023' },
        { label: '2024', value: '2024' },
        { label: '2025', value: '2025' },
    ],

    // Список месяцев
    monthsList: [
        { label: 'Январь', value: '01' },
        { label: 'Февраль', value: '02' },
        { label: 'Март', value: '03' },
        { label: 'Апрель', value: '04' },
        { label: 'Май', value: '05' },
        { label: 'Июнь', value: '06' },
        { label: 'Июль', value: '07' },
        { label: 'Август', value: '08' },
        { label: 'Сентябрь', value: '09' },
        { label: 'Октябрь', value: '10' },
        { label: 'Ноябрь', value: '11' },
        { label: 'Декабрь', value: '12' },
    ],

    // Список периодов
    periodsList: [
        { label: 'Как в портфеле', value: 'byPortfolio' },
        { label: 'Месяц', value: 'byMonth' },
        { label: 'Квартал', value: 'byQuarteYear' },
        { label: 'Полугодие', value: 'byHalfYear' },
        { label: 'Год', value: 'byYear' },
    ],

    // Длинна комментариев: кол-во символов
    longComment: 111,

    // Длинна комментариев в тестовых схемах: кол-во символов
    longCommentTest: 20,

    //Список бирж
    birzhaList: [
        { label: 'Binance', value: '1' },
        { label: 'Bitfinex', value: '2' },
    ],

    // Список периодов для тест
    periodТestList: [
        { label: 'Весь период', value: null },
        { label: 'По месяцам', value: '1' },
        { label: 'По кварталам', value: '2' },
        { label: 'По полугодиям', value: '3' },
        { label: 'По годам', value: '4' },
    ],

    // Список Периодов погашения комиссии
    periodForPortfolio: [
        { label: 'месяц', value: 'byMonth' },
        { label: 'квартал', value: 'byQuarteYear' },
        { label: 'полугодие', value: 'byHalfYear' },
        { label: 'год', value: 'byYear' }
    ],

    // Список статусов сделок
    statusTradesList: [
        { label: 'Открытая', value: '1' },
        { label: 'Закрытая', value: '2' },
        // { label: 'Открытие/заем на бирже', value: '3' },
        // { label: 'Открытие/открыли на бирже', value: '4' },
        // { label: 'Закрытие/отменили stop-loss', value: '5' },
        // { label: 'Закрытие/закрыли на бирже', value: '6' },
        // { label: 'Закрытие/закрыли по stop-loss', value: '7' }
    ],

    // Тектс КОНФИРМА для удаления сущности из таблиц
    textConfirm: 'Вы уверены, что хотите удалить?',
    // Тектс КОНФИРМА для Открытия сделки
    textConfirmOpenOrder: 'Вы уверены, что хотите ОТКРЫТЬ сделку вручную?',
    // Тектс КОНФИРМА для Закрытия сделки
    textConfirmCloseOrder: 'Вы уверены, что хотите ЗАКРЫТЬ сделку вручную?',
    // Тектс КОНФИРМА для включения ТС
    textConfirmOnScheme: 'Вы уверены, что хотите ВКЛЮЧИТЬ торговую схему?',
    // Тектс КОНФИРМА для выключения ТС
    textConfirmOffScheme: 'Вы уверены, что хотите ВЫКЛЮЧИТЬ торговую схему?',
    // Текст КОНФИРМА для отдачи заема на биржу
    textGiveLoan: 'Вы уверены, что собираетесь отдать заем на бирже?',
    // Текст КОНФИРМА для удаления всех сделок с истории
    textDeleteOrders: 'Вы уверены, что собираетесь удалить все сделки с истории?',
    // Текст КОНФИРМА для блокировки Пользователя
    textConfirmBlock: 'Уверены, что хотите заблокировать для Telegram Bot?',
    // Текст КОНФИРМА для разблокировки Пользователя
    textConfirmUnblock: 'Уверены, что хотите разблокировать для Telegram Bot?',
    // Текст КОНФИРМА для отображения статистики Фонда
    textConfirmShowFund: 'Вы уверены, что хотите включить отображение статистики Фонда в Telegram Bot для всех Инвесторов?',
    // Текст КОНФИРМА для отображения статистики Фонда
    textConfirmHideFund: 'Вы уверены, что хотите выключить отображение статистики Фонда в Telegram Bot для всех Инвесторов?',
    // Текст КОНФИРМА для назначения пользователя Администратором
    textConfirmUserAdmin: 'Вы уверены, что хотите назначить Пользователя Администратором?',
    // Текст КОНФИРМА для назначения Администратора Пользователем
    textConfirmAdminUser: 'Вы уверены, что хотите перевести Администратора в Пользователи?',



    // Цвета для селектов
    // Цвет для выбранного селекта
    colorSelected: '#1e6ace',
    // Цвет для наведенного селекта
    colorHoverSelect: '#2684ff',
    //Цвет шрифта при поиске 
    colorSelectInput: '#e0e0e2',

}

export default WV