import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from 'react'
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import WV from '../../../workVariables'

const CatalogPortfolioEdit = () => {
    const { id } = useParams()
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)
    const history = useHistory();
    const [form, setForm] = useState({
        id: id,
        name: '',
        info: [],
        comment: '',
    })
    const [newSelect, setNewSelect] = useState(false)
    const [selectScheme, setSelectScheme] = useState([])
    const [structData, setStructData] = useState([])
    const [structItem, setStructItem] = useState([])
    const [schemes, setSchemes] = useState([]);
    const [edCount, setEdCount] = useState(0)
    const [edEvent, setEdEvent] = useState(false)

    // При выборе стратегии подгружаем ТС
    useEffect(() => {
        if (edCount != 0) {
            console.log('Zaxod');
            let lastItem = selectScheme[selectScheme.length - 1]
            console.log(lastItem);
            structData.map((element) => {
                if (element.id === lastItem.value) {
                    let newInfo = []
                    element.scheme.map((item) => {
                        let row = {
                            id_scheme: item.id,
                            name: item.name,
                            percent_scheme: null
                        }
                        newInfo.push(row)
                    })
                    let newItem = {
                        "id_strategy": lastItem.value,
                        "name_strategy": lastItem.label,
                        "info_strategy": newInfo
                    }
                    setStructItem([...structItem, newItem])
                }
            })
            if (selectScheme.length !== 0 && newSelect) {
                setSchemes(schemes.filter(item => item.value !== lastItem.value))
                setNewSelect(false)
            }
        }
    }, [selectScheme])

    useEffect(() => {
        console.log('structItem IS CHAN');
        setForm({ ...form, info: structItem })
    }, [structItem])

    // Старт
    useEffect(() => {
        const getData = async () => {
            axios
                .get(`${config.apiUrl}/ip-catalog-portfolio-show/${id}`)
                .then(async (response) => {
                    setForm({ ...form, name: response.data.name, comment: response.data.comment})
                    setSelectScheme(response.data.info)
                    setStructItem(response.data.info)

                    // Меню Стратегий
                    let arraySchemes = [];
                    const getStrategy = () => {
                        axios
                            .get(`${config.apiUrl}/ip-strategy-index`)
                            .then(async (resp) => {
                                setStructData(resp.data)
                                for await (let item of resp.data) {
                                    let option = {};
                                    option.label = item.name;
                                    option.value = item.id;
                                    arraySchemes.push(option);
                                }
                                let newArraySchemes = []
                                response.data.info.map((el, index) => {
                                    if (index === 0) {
                                        newArraySchemes = arraySchemes.filter(oneS => oneS.value !== el.id_strategy)
                                    } else {
                                        newArraySchemes = newArraySchemes.filter(oneS => oneS.value !== el.id_strategy)
                                    }
                                })
                                setSchemes(newArraySchemes);
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    };
                    getStrategy();

                })
                .catch((error) => {
                    console.error(error);
                });
        }
        getData()
    }, []);


    // Удалить стратегию из портфеля
    const deleteScheme = async (value, label) => {
        let data = form.info
        data.map((el, index) => {
            if (el.id_strategy === value) {
                data.splice(index, 1);
            }
        })
        setForm({ ...form, info: data })
        let option = {
            label: label,
            value: value
        }
        let schemesList = schemes
        schemesList.unshift(option)
        setSchemes(schemesList)
    }

    // Структура портфеля
    function Structure() {
        let structure = form.info
        if (structure.length > 0) {
            return (
                <Row>
                    <Col className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Название стратегии</th>
                                    <th className='d-flex justify-content-between align-items-center'>
                                        <span>
                                            Название торговой схемы
                                        </span>
                                        <span className="me-4">
                                            Процент от портфеля
                                        </span>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {structure.map((item, indexSTR) =>
                                    <tr key={indexSTR}>
                                        <td>
                                            <h6>{item.name_strategy}</h6>
                                        </td>
                                        <td style={{ width: "60%" }}>
                                            <ul>
                                                {item.info_strategy.map((el, indexTS) =>
                                                    <li 
                                                    key={indexTS}
                                                    style={{ listStyle: "none", marginLeft: "25px", margin: "5px" }}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div>
                                                                <h6 className='mb-0'>
                                                                    {el.name}
                                                                </h6>
                                                            </div>
                                                            <div className="me-0">
                                                                <Input
                                                                    value={el.percent_scheme}
                                                                    name={indexSTR + ',' + indexTS}
                                                                    type={"text"}
                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                    onChange={(event) => {
                                                                        let name = event.target.name.split(',')
                                                                        setError({ ...error, info: true })
                                                                        errorItem = formErrors;
                                                                        delete errorItem.info
                                                                        setFormErrors(errorItem);
                                                                        setError({ ...error, info2: true })
                                                                        errorItem = formErrors;
                                                                        delete errorItem.info2
                                                                        setFormErrors(errorItem);
                                                                        let str = name[0]
                                                                        let ts = name[1]
                                                                        let temp = structItem
                                                                        temp[str].info_strategy[ts].percent_scheme = parseFloat(event.target.value)
                                                                        setStructItem(temp)
                                                                        setForm({ ...form, info: temp })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </td>
                                        <td className='d-flex align-items-center' style={{ width: "11px" }}>
                                            <div style={{ paddingTop: "22px"}}>
                                                <button
                                                    onClick={() => {
                                                        deleteScheme(item.id_strategy, item.name_strategy)
                                                    }
                                                    }
                                                    className='btn btn-danger'q
                                                    title='Удалить стратегию'>
                                                    <i className='mdi mdi-delete' />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </table>
                    </Col>
                </Row>
            )
        } else {
            return (<></>)
        }
    }

    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const edit = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.info.length == 0) errors.info = "введите хотя бы одну стратегию";
        let arrayInfo = values.info;
        for (let i = 0; i < arrayInfo.length; i++) {
            let arrayInfo2 = arrayInfo[i].info_strategy
            for (let j = 0; j < arrayInfo2.length; j++) {
                if (!arrayInfo2[j].percent_scheme) {
                    errors.info2 = "заполните поле процентов";
                    j = arrayInfo2.length;
                    i = arrayInfo.length;
                }
            }

        }
        if (values.typePrice == '') errors.typePrice = "поле обязательное для заполнения";
        if (values.typeMoving == '') errors.typeMoving = "поле обязательное для заполнения";
        if ((values.typeMoving == 'exp') && (values.interval_ma == '')) errors.interval_ma = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/ip-catalog-portfolio-update`, form)
                .then((response) => {
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })

                    }, 300);
                    if (response.data.res === "Success") {
                        setRedirect(true)
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [formErrors]);
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Стандартные портфели | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Редактирование Стандартного портфеля #{id}</h4>
                                        <div>
                                            <Button color="primary me-3" onClick={edit}>
                                                Изменить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Администратора</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                }}
                                            />
                                        </div>
                                        <FormFeedback invalid> {formErrors.name}
                                            {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                        </FormFeedback>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                value={form.comment}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="scheme_id">Подключить стратегию:</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="scheme_id"
                                                name="scheme_id"
                                                placeholder="выбрать..."
                                                onChange={(e) => {
                                                    let el = {}
                                                    el.value = e.value
                                                    el.label = e.label
                                                    setNewSelect(true)
                                                    setSelectScheme([...selectScheme, el])
                                                    setEdCount(1)
                                                }}
                                                options={schemes}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ fontSize: "10px", color: "#e06466" }}>
                                                {" "}
                                                {formErrors.info}
                                            </p>
                                            <p style={{ fontSize: "10px", color: "#e06466" }}>
                                                {" "}
                                                {formErrors.info2}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12}>
                                        <Row>
                                            <Col xs={12} md={12} lg={12}>
                                                <div className="mb-3">
                                                    {/* <Structure /> */}

                                                    {form.info.length > 0 && (
                                                        <Row>
                                                            <Col className="col-12">
                                                                <Row>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h4 className="mb-0">Структура Портфеля</h4>
                                                                    </div>
                                                                </Row>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Название стратегии</th>
                                                                            <th className="d-flex justify-content-between align-items-center">
                                                                                <span>Название торговой схемы</span>
                                                                                <span className="me-4">
                                                                                    Процент от портфеля
                                                                                </span>
                                                                            </th>
                                                                            <th> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {form.info.map((item, indexSTR) => (
                                                                            <tr key={indexSTR}>
                                                                                <td>
                                                                                    <h6>{item.name_strategy}</h6>
                                                                                </td>
                                                                                <td>
                                                                                    <ul>
                                                                                        {item.info_strategy.map(
                                                                                            (el, indexTS) => (
                                                                                                <li
                                                                                                    key={indexTS}
                                                                                                    style={{
                                                                                                        listStyle: "none",
                                                                                                        marginLeft: "25px",
                                                                                                        margin: "5px",
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                        <div>
                                                                                                            <h6 className="mb-0">
                                                                                                                {el.name}
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                        <div className="me-0">
                                                                                                            <Input
                                                                                                                value={
                                                                                                                    structItem[indexSTR]
                                                                                                                        .info_strategy[
                                                                                                                        indexTS
                                                                                                                    ].percent_scheme
                                                                                                                }
                                                                                                                name={
                                                                                                                    indexSTR +
                                                                                                                    "," +
                                                                                                                    indexTS
                                                                                                                }
                                                                                                                type={"text"}
                                                                                                                onKeyPress={(e) =>
                                                                                                                    !/[0-9]/.test(
                                                                                                                        e.key
                                                                                                                    ) &&
                                                                                                                    e.preventDefault()
                                                                                                                }
                                                                                                                onChange={(event) => {
                                                                                                                    if (!event.target.value) event.target.value = 0
                                                                                                                    setError({
                                                                                                                        ...error,
                                                                                                                        info: true,
                                                                                                                    });
                                                                                                                    errorItem =
                                                                                                                        formErrors;
                                                                                                                    delete errorItem.info;
                                                                                                                    setFormErrors(
                                                                                                                        errorItem
                                                                                                                    );
                                                                                                                    setError({
                                                                                                                        ...error,
                                                                                                                        info2: true,
                                                                                                                    });
                                                                                                                    errorItem =
                                                                                                                        formErrors;
                                                                                                                    delete errorItem.info2;
                                                                                                                    setFormErrors(
                                                                                                                        errorItem
                                                                                                                    );
                                                                                                                    let name =
                                                                                                                        event.target.name.split(
                                                                                                                            ","
                                                                                                                        );
                                                                                                                    let str = name[0];
                                                                                                                    let ts = name[1];
                                                                                                                    let temp = structItem;
                                                                                                                    temp[
                                                                                                                        str
                                                                                                                    ].info_strategy[
                                                                                                                        ts
                                                                                                                    ].percent_scheme = parseFloat(
                                                                                                                        event.target.value
                                                                                                                    );
                                                                                                                    setStructItem(temp);
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ul>
                                                                                </td>
                                                                                <td
                                                                                    className="d-flex align-items-center"
                                                                                    style={{ width: "11px" }}
                                                                                >
                                                                                    <div style={{ paddingTop: "22px" }}>
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                deleteScheme(
                                                                                                    item.id_strategy,
                                                                                                    item.name_strategy
                                                                                                );
                                                                                            }}
                                                                                            className="btn btn-danger"
                                                                                            title="Удалить стратегию"
                                                                                        >
                                                                                            <i className="mdi mdi-delete" />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {redirect &&
                <Redirect
                    to={{
                        pathname: "/catalog-portfolio",
                    }}
                />
            }
        </div>
    )
}

export default CatalogPortfolioEdit