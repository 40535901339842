import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";
import config from "../../config";
import Select from "react-select";
import InputSpinner from "react-bootstrap-input-spinner";
import WV from "../../workVariables";

const Close4 = (props) => {
  const { close4, setClose4 } = props;
  const [tihTil, setTihTil] = useState([]);
  const [tihTil1, setTihTil1] = useState([]);
  const [tihTil2, setTihTil2] = useState([]);
  const tt1 = [];
  const tt2 = [];
  useEffect(() => {
    const getTihTil = async () => {
      const response = await axios.get(`${config.apiUrl}/schemas-tihtil`);
      setTihTil(response.data);
      setTihTil(response.data);
      response.data[0].forEach((item, index) => {
        tt1.push({ label: item.name, value: item.id });
      });
      response.data[1].forEach((item, index) => {
        tt2.push({ label: item.name, value: item.id });
      });
      setTihTil1(tt1);
      setTihTil2(tt2);
    };
    getTihTil();
  }, []);
  // const open2options = {
  //     TIH: ['TIH_20_high'],
  //     TIL: ['TIH_30_close'],
  // }
  const close4options = {
    TIH: tihTil[0],
    TIL: tihTil[1],
  };
  const close2optionsArr = [
    { label: "TIH", value: "TIH" },
    { label: "TIL", value: "TIL" },
  ];
  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`
    }),
  }
  return (
    <>
      <div className="text-muted flex-grow-1">
        <Row className="mt-3">
          <Col xs={12} lg={7} className="mb-3">
            <div className="d-flex gap-2">
              <div className="flex-grow-1">
                <h6 className="text-center">Индикатор TIH / TIL</h6>
                <Select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: `${WV.colorHoverSelect}`,
                      primary: `${WV.colorSelected}`,
                    },
                  })}
                  placeholder="выбрать..."
                  isDisabled={close4.isDisabled}
                  onChange={(value) => {
                    setClose4({ ...close4, indicatorType: value.value });
                  }}
                  options={close2optionsArr}
                  value={close2optionsArr.filter(
                    (opt) => opt.value === close4.indicatorType
                  )}
                  classNamePrefix="select2-selection"
                  className="flex-grow-1"
                />
              </div>
              <div className="flex-grow-1">
                <h6 className="text-center">Выбрать Индикатор</h6>
                {close4.indicatorType === "TIH" && (
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `${WV.colorHoverSelect}`,
                        primary: `${WV.colorSelected}`,
                      },
                    })}
                    placeholder="выбрать..."
                    isDisabled={close4.isDisabled}
                    styles={customStyles}
                    onChange={(value) => {
                      setClose4({ ...close4, indicator: value.value });
                    }}
                    options={tihTil1}
                    value={tihTil1.filter(
                      (opt) => opt.value === close4.indicator
                    )}
                    classNamePrefix="select2-selection"
                    className="flex-grow-1"
                  />
                )}
                {close4.indicatorType === "TIL" && (
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `${WV.colorHoverSelect}`,
                        primary: `${WV.colorSelected}`,
                      },
                    })}
                    placeholder="выбрать..."
                    isDisabled={close4.isDisabled}
                    styles={customStyles}
                    onChange={(value) => {
                      setClose4({ ...close4, indicator: value.value });
                    }}
                    options={tihTil2}
                    value={tihTil2.filter(
                      (opt) => opt.value === close4.indicator
                    )}

                    classNamePrefix="select2-selection"
                    className="flex-grow-1"
                  />
                )}
                {close4.indicatorType !== "TIL" &&
                  close4.indicatorType !== "TIH" && (
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: `${WV.colorHoverSelect}`,
                          primary: `${WV.colorSelected}`,
                        },
                      })}
                      placeholder="Сначала выберите тип"
                      classNamePrefix="select2-selection"
                      className="flex-grow-1"
                      isDisabled={true}
                    />
                  )}
              </div>
            </div>
          </Col>
          <Col xs={12} lg={5} className="mb-3">
            <h6 className="text-center">Процент</h6>
            <div className="d-flex gap-2 w-100">
              <InputSpinner
                value={close4.percFrom}
                name="stops"
                type={"real"}
                precision={1}
                max={1000}
                min={-1000}
                step={0.1}
                disabled={close4.isDisabled}
                onChange={(num) =>
                  setClose4({ ...close4, percFrom: num })
                }
                placeholder="0"
              />
              <InputSpinner
                value={close4.percTo}
                name="stops"
                type={"real"}
                precision={1}
                max={1000}
                min={-1000}
                step={0.1}
                disabled={close4.isDisabled}
                onChange={(num) => setClose4({ ...close4, percTo: num })}
                placeholder="0"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Close4;
