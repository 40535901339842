import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    Spinner,
    FormFeedback
} from "reactstrap"
import { Link, useParams } from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import config from "../../config";
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from 'react-select/animated';
import WV from '../../workVariables'
import InputSpinner from "react-bootstrap-input-spinner";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import fileDownload from 'js-file-download';
const animatedComponents = makeAnimated();

const UnionSchemasEdit = (props) => {
    const { id } = useParams()
    const [spinnerStatus, setSpinnerStatus] = useState(false)

    const [form, setForm] = useState({
        name: '',
        schemas: '',
        comment: '',
        period_test: WV.periodТestList[0],
        annual_yield: '',
        count_strategy: ''
    });
    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.schemas == '') errors.schemas = "поле обязательное для заполнения";
        if (values.count_strategy == '') errors.count_strategy = "поле обязательное для заполнения";
        if (values.annual_yield == '') errors.annual_yield = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(form);
            let form1 = {};
            form1.id = id;
            form1.name = form.name;
            form1.comment = form.comment;
            form1.count_strategy = form.count_strategy;
            form1.period_test = form.period_test.value;
            form1.annual_yield = form.annual_yield;

            let arrSchemas = form.schemas;
            for (let i = 0; i < arrSchemas.length; i++)
                (i === 0) ? form1.ids_test_schemes_second = arrSchemas[i].value :
                    form1.ids_test_schemes_second = form1.ids_test_schemes_second + ', ' + arrSchemas[i].value

            axios.post(`${config.apiUrl}/union-schemas-update`, form1)
                .then((response) => {
                    console.log(response.data.msg);
                    if (response.data.res === "Success") {
                        history.goBack()
                        setTimeout(() => {
                            addToast(response.data.msg, {
                                appearance: response.data.res.toLowerCase(),
                                autoDismiss: true,
                                autoDismissTimeout: 7500,
                                transitionDuration: 2000
                            })
                        }, 300);
                        console.log("OK");

                        // <Redirect to="/account" />
                    }
                })
                .catch(error => {
                    console.error(error);
                })

        }
    }, [formErrors]);

    const [schema, setSchema] = useState([])
    useEffect(() => {
        const getUnionScheme = async () => {

            const response = await axios.get(`${config.apiUrl}/union-schemas/${id}`)
            let periodTest;
            if (!response.data.period_test) periodTest = [{ label: 'Весь период', value: null }];
            if (response.data.period_test == 1) periodTest = [{ label: 'По месяцам', value: '1' }];
            if (response.data.period_test == 2) periodTest = [{ label: 'По кварталам', value: '2' }];
            if (response.data.period_test == 3) periodTest = [{ label: 'По полугодиям', value: '3' }];
            if (response.data.period_test == 4) periodTest = [{ label: 'По годам', value: '4' }];

            setForm({
                id: id,
                name: response.data.name,
                comment: response.data.comment,
                schemas: response.data.schemas,
                period_test: periodTest[0].label,
                annual_yield: response.data.annual_yield,
                count_strategy: response.data.count_strategy,
            })
        }
        getUnionScheme()



    }, [])
    const { addToast } = useToasts()

    const coreAction = () => {
        const fileName = form.name + ".xlsx"
        setSpinnerStatus(true)
        axios({
            url: `${config.apiUrl}/union-schemes-test/${id}`,
            method: 'GET'
        }).then((response) => {
            // console.log(response.data);
            if (response.data.status === "success") {
                // console.log('SECOND AXIOS . . . .');
                setTimeout(() => {
                    // console.log('.......send');
                    axios.get(`${config.apiUrl}/union-schemes-test-get-file/${fileName}`, { responseType: 'blob' })
                        .then((response) => {
                            // console.log(response.data);
                            setSpinnerStatus(false)
                            fileDownload(response.data, fileName)
                        })
                        .catch(error => {
                            console.error(error);
                        })
                }, 2000);
            } else {
                setTimeout(() => {
                    addToast("Отчет не построен! Возможно некорректный запрос!", {
                        appearance: "info",
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    setSpinnerStatus(false)
                }, 1000);
            }
        }).catch(error => {
            console.error(error);
        })
    }

    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color: `${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Редактировать Объединение Схем | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Просмотр Объединение Схем #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            {spinnerStatus &&
                                                <div style={{ marginRight: "13px" }}>
                                                    <Spinner className="ms-2" color="info" />
                                                </div>
                                            }
                                            <Button color="primary" onClick={coreAction} disabled={spinnerStatus}>
                                                <i className='bx bxs-flask' style={{ marginRight: "8px" }}></i>
                                                Протестировать
                                            </Button>

                                            <Link to={`/union-schemas/edit/${id}`} className='btn btn-secondary me-2' title='Редактировать'>
                                                <i className='mdi mdi-pencil' />
                                                &nbsp;
                                                Редактировать
                                            </Link>
                                            <Link to='/union-schemas' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                // required
                                                id="name"
                                                value={form.name}
                                                readOnly={true}

                                            />

                                        </div>

                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                // style={{ resize: 'none', height: '2.4rem' }}
                                                className="form-control"
                                                value={form.comment}
                                                readOnly={true}

                                            />
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        {form.count_strategy !== '' &&
                                            <div className="mb-3">
                                                <Label symbol="count_strategy">Количество стратегий</Label>
                                                <InputSpinner
                                                    value={form.count_strategy}
                                                    name="count_strategy"
                                                    type={"real"}
                                                    precision={0}
                                                    max={form.count_strategy}
                                                    min={form.count_strategy}

                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.count_strategy}</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        {form.annual_yield !== '' &&
                                            <div className="mb-3">
                                                <Label> Годовой % доходности для Сортино</Label>
                                                <InputSpinner
                                                    value={form.annual_yield}
                                                    name="annual_yield"
                                                    type={"real"}
                                                    precision={1}
                                                    max={form.annual_yield}
                                                    min={form.annual_yield}

                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.annual_yield}</p>
                                            </div>
                                        }
                                    </Col>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label symbol="position">Разбить на периоды</Label>
                                            <Input
                                                type="text"
                                                value={form.period_test}
                                                className="form-control"
                                                readOnly={true}
                                            />
                                        </div>
                                    </Col>

                                </Row>

                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>
                                    </div>

                                </CardTitle>
                                <Row>
                                    <div className="mb-3">
                                        <Label symbol="schemas">Тестовые Схемы 2.0</Label>
                                        <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: `${WV.colorHoverSelect}`,
                                                    primary: `${WV.colorSelected}`,
                                                },
                                            })}
                                            // closeMenuOnSelect={false}
                                            // components={animatedComponents}
                                            // isMulti
                                            styles={customStyles}
                                            id='schemas'
                                            value={form.schemas}
                                            placeholder="выбрать..."
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            onChange={(value) => {
                                                setForm({ ...form, schemas: value });
                                                setError({ ...error, schemas: true })
                                                errorItem = formErrors;
                                                delete errorItem.schemas
                                                setFormErrors(errorItem);
                                            }}
                                            options={schema}
                                            isDisabled
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>                   

                    </Col>
                </Row>

            </Container>

        </div>
    );
}

export default UnionSchemasEdit;
