import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import { useParams, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useToasts } from "react-toast-notifications";
import makeAnimated from 'react-select/animated';
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import WV from '../../../workVariables';
const animatedComponents = makeAnimated();

const StrategyEdit = () => {
    const { addToast } = useToasts()
    const { id } = useParams()
    const [schemes, setSchemes] = useState([]);
    const [form, setForm] = useState({
        name: '',
        name_user: '',
        trade_schemes: '',
        comment: '',
    })

    const [error, setError] = useState({
        name: false,
        name_user: false
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const updateItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.name_user == '') errors.name_user = " ";
        if (values.trade_schemes == '') errors.trade_schemes = "поле обязательное для заполнения";
        return errors;
    };
    
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            if (form.trade_schemes) {
                if ((!form.trade_schemes.length) || (form.trade_schemes === '')) form.trade_schemes = null;
                else {
                    let arrTs = form.trade_schemes;
                    for (let i = 0; i < arrTs.length; i++)
                        (i === 0) ? form.trade_schemes = arrTs[i].value : form.trade_schemes = form.trade_schemes + ', ' + arrTs[i].value
        
                }
            } else form.trade_schemes = null
                axios.post(`${config.apiUrl}/ip-strategy-update`, form)
                .then((response) => {                
                        if (response.data.res === "Success") {
                            setRedirect(true)
                        }
                        setTimeout(() => {
                            addToast(response.data.msg, {
                                appearance: response.data.res.toLowerCase(),
                                autoDismiss: true,
                                autoDismissTimeout: 7500,
                                transitionDuration: 2000
                            })
                            
                        }, 300);
                  
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [formErrors]); 

    useEffect(() => {
        const getStrategy = async () => {
            axios.get(`${config.apiUrl}/ip-strategy-show/` + id)
                .then(async (response) => {
                    setForm({
                        id: response.data.id,
                        name: response.data.name,
                        name_user: response.data.name_user,
                        trade_schemes: response.data.trades,
                        comment: response.data.comment,
                    })
                  
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStrategy()
        const getSchemes = async () => {
            axios.get(`${config.apiUrl}/trade-schemes`)
                .then(async (response) => {
                    let arraySchema = response.data;
                    for (let i = 0; i < arraySchema.length; i++) {
                        arraySchema[i].value = arraySchema[i].id;
                        arraySchema[i].label = arraySchema[i].name;
                        delete arraySchema[i].id;
                        delete arraySchema[i].name
                    }
                   
                    setSchemes(arraySchema);
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        getSchemes();
    }, [id])

   
    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color: `${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    const [redirect, setRedirect] = useState(false);
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Торговые стратегии | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Редактирование Торговой стратегии #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={updateItem}>
                                                Изменить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Администратора</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={(e) => {
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                }}
                                            />
                                             <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Пользователя</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name_user"
                                                value={form.name_user}
                                                name="name_user"
                                                invalid={
                                                    (form.name_user.length === 0 && error.name_user) ||
                                                    formErrors.name_user
                                                }
                                                onChange={(e) => {
                                                    setError({ ...error, name_user: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name_user
                                                    setFormErrors(errorItem);
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                } }
                                            />
                                            <FormFeedback invalid> {formErrors.name_user}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                    <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="textarea"
                                                style={{resize: 'none', height: '7.4rem'}}
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                value={form.comment}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>

                                    </div>
                                </CardTitle>

                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="scheme_id">Торговая схема</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="scheme_id"
                                                name="scheme_id"
                                                placeholder="выбрать..."
                                                styles={customStyles}
                                                onChange={(value) => {
                                                    setError({ ...error, trade_schemes: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.trade_schemes
                                                    setFormErrors(errorItem);
                                                    setForm({ ...form, trade_schemes: value })
                                                }}
                                                value={form.trade_schemes}
                                                options={schemes}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.trade_schemes}</p>
                                        </div>
                                    </Col>
                                </Row>                             
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/strategy",
                    }}
                />
            }
        </div >
    )
}

export default StrategyEdit