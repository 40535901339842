import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";
import config from "../../config";
import Select from "react-select";
import WV from "../../workVariables";

const Close5 = (props) => {
  const { close5, setClose5 } = props;
  const [ma, setMa] = useState([]);
  useEffect(() => {
    const getRavi = async () => {
      await axios
        .get(`${config.apiUrl}/ravi`)
        .then((response) => {
          const ma2 = [];
          response.data.forEach((item) => {
            ma2.push({ label: item.name, value: item.id });
          });
          setMa(ma2);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getRavi();
  }, []);
  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`
    }),
  }
  return (
    <>
      <div className="text-muted flex-grow-1">
        <Row>
          <Col>
            <h6 className="text-center">RAVI</h6>
            <div className="d-flex gap-2">
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: `${WV.colorHoverSelect}`,
                    primary: `${WV.colorSelected}`,
                  },
                })}
                placeholder="выбрать..."
                styles={customStyles}
                isDisabled={close5.isDisabled}
                onChange={(value) => {
                  setClose5({ ...close5, raviId: value.value });
                }}
                options={ma}
                value={ma.filter(
                  (opt) => opt.value === close5.raviId
                )}
                classNamePrefix="select2-selection"
                className="flex-grow-1"
              />

            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Close5;
