import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import "./datatables.scss";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import TableSearchBar from "../../components/TableSearchBar";
import ModalConfirm from "../../components/ModalConfirm";
import { useToasts } from "react-toast-notifications";

// const ActionButtons = props => (
//     <div className='d-flex gap-2'>
//         <Link to='/' className='btn btn-secondary' title='Редактировать'>
//             <i className='mdi mdi-pencil'/>
//         </Link>
//         <Link to='/' className='btn btn-success' title='Дублировать'>
//             <i className='mdi mdi-content-copy'/>
//         </Link>
//         <Link to='/' className='btn btn-danger' title='Удалить'>
//             <i className='mdi mdi-delete'/>
//         </Link>
//     </div>
// )

const Schemas = () => {
  const { addToast } = useToasts()
  const ActionButtons = (props) => {
    const copyItem = async (id) => {
      axios
        .get(`${config.apiUrl}/schemas-copy/${id}`)
        .then(async (response) => {
          console.log(response.data.msg);
          addToast(response.data.msg, {
            appearance: response.data.res.toLowerCase(),
            autoDismiss: true,
            autoDismissTimeout: 7500,
            transitionDuration: 2000
          })
          if (response.data.res === "Success") {
            const response = await axios.get(`${config.apiUrl}/schemas`);
            setSchema(response.data);
          }

          // <Redirect to="/account" />
        })
        .catch((error) => {
          console.error(error);
        });
    };

    return (
      <div className="d-flex gap-2">
        <Link to={`/schemas/show/${props.id}`}
          className='btn btn-secondary'
          title='Просмотр'>
          <i class="far fa-eye" />
        </Link>
        <Link
          to={`/schemas/edit/${props.id}`}
          className="btn btn-primary"
          title="Редактировать"
        >
          <i className="mdi mdi-pencil" />
        </Link>
        <div className="d-flex gap-2">
          <button
            onClick={() => copyItem(props.id)}
            className="btn btn-success"
            title="Дублировать"
          >
            <i className="mdi mdi-content-copy" />
          </button>
        </div>
        <div className="d-flex gap-2">
          <button
            onClick={() => {
              setModalConfirm(true)
              setDeleteId(props.id)
            }
            }
            className='btn btn-danger' title='Удалить'>
            <i className='mdi mdi-delete' />
          </button>
          {/* <button
            onClick={() => removeItem(props.id)}
            className="btn btn-danger"
            title="Удалить"
          >
            <i className="mdi mdi-delete" />
          </button> */}
        </div>
      </div>
    );
  };
  const [modalConfirm, setModalConfirm] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const toggleModalConfirm = () => {
    setModalConfirm(!modalConfirm)
  }
  const removeItem = async (id) => {
    axios
      .get(`${config.apiUrl}/schemas-delete/${id}`)
      .then((response) => {
        console.log(response.data.msg);
        addToast(response.data.msg, {
          appearance: response.data.res.toLowerCase(),
          autoDismiss: true,
          autoDismissTimeout: 7500,
          transitionDuration: 2000
        })
        if (response.data.res === "Success") {
          setSchema(schema.filter((item) => item.id !== id));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [schema, setSchema] = useState([]);
  const [tableData, setTableData] = useState([]);
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "name",
      text: "Название",
      sort: true,
    },
    {
      dataField: "position",
      text: "Позиция",
      sort: true,
    },
    {
      dataField: "comment",
      text: "Комментарии",
      sort: false,
    },
    {
      dataField: "open1",
      text: "Open 1",
      sort: false,
    },
    {
      dataField: "open2",
      text: "Open 2",
      sort: false,
    },
    {
      dataField: "open3",
      text: "Open 3",
      sort: false,
    },
    {
      dataField: "open4",
      text: "Open 4",
      sort: false,
    },
    {
      dataField: "close1",
      text: "Close 1",
      sort: false,
    },
    {
      dataField: "close2",
      text: "Close 2",
      sort: false,
    },
    {
      dataField: "close3",
      text: "Close 3",
      sort: false,
    },
    {
      dataField: "close4",
      text: "Close 4",
      sort: false,
    },
    {
      dataField: "close5",
      text: "Close 5",
      sort: false,
    },
    {
      dataField: "actions",
      text: "Действия",
      sort: false,
    },
  ];
  useEffect(() => {
    const getScheme = async () => {
      const response = await axios.get(`${config.apiUrl}/schemas`);
      setSchema(response.data);
    };
    getScheme();
  }, []);

  useEffect(() => {
    let tableData = [];
    if (schema.length > 0) {
      schema.forEach((itemSchema) => {
        if (itemSchema.conditionOpen)
          itemSchema.open1 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.open1 = <i className="mdi mdi-cancel text-danger" />;

        if (itemSchema.conditionOpen2)
          itemSchema.open2 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.open2 = <i className="mdi mdi-cancel text-danger" />;

        if (itemSchema.conditionOpen3)
          itemSchema.open3 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.open3 = <i className="mdi mdi-cancel text-danger" />;

        if (itemSchema.conditionOpen4)
          itemSchema.open4 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.open4 = <i className="mdi mdi-cancel text-danger" />;

        if (itemSchema.conditionClose1)
          itemSchema.close1 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.close1 = <i className="mdi mdi-cancel text-danger" />;

        if (itemSchema.conditionClose2)
          itemSchema.close2 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.close2 = <i className="mdi mdi-cancel text-danger" />;

        if (itemSchema.conditionClose3)
          itemSchema.close3 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.close3 = <i className="mdi mdi-cancel text-danger" />;

        if (itemSchema.conditionClose4)
          itemSchema.close4 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.close4 = <i className="mdi mdi-cancel text-danger" />;

        if (itemSchema.conditionClose5)
          itemSchema.close5 = <i className="mdi mdi-check-bold text-success" />;
        else itemSchema.close5 = <i className="mdi mdi-cancel text-danger" />;

        tableData.push({
          ...itemSchema,
          actions: <ActionButtons id={itemSchema.id} />,
        });
      });
    }
    setTableData(tableData);
  }, [schema]);

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  };

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "25", value: 30 },
    { text: "Все", value: tableData.length },
  ];

  // Select All Button operation
  // const selectRow = {
  //     mode: 'checkbox'
  // }

  const { SearchBar } = Search;

  return (
    <div className="page-content">
      <MetaTags>
        <title>Схемы | TradeBot</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="mb-3 d-flex justify-content-between align-items-center">
                    <h4>Схемы</h4>
                    <Link to="/schemas/add" className="btn btn-primary">
                      <i className="mdi mdi-plus" />
                      Добавить
                    </Link>
                  </div>
                </CardTitle>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  sizePerPageList={sizePerPageList}
                  columns={columns}
                  data={tableData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={tableData}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <TableSearchBar
                                    {...toolkitProps.searchProps}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={true}
                                  striped={true}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <hr />
                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  sizePerPageList={sizePerPageList}
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalConfirm
        modalConfirm={modalConfirm}
        toggleModalConfirm={toggleModalConfirm}
        removeItem={removeItem}
        deleteId={deleteId}
        lebel='Удаление схемы'
      />
    </div>
  );
};

export default Schemas;
