import { Switch, BrowserRouter as Router } from "react-router-dom"
import { protectedRoutes, publicRoutes } from './routes'
import AuthMiddleware from "./routes/routes"
import Layout from "./layout/Layout";
import LoginLayout from "./layout/LoginLayout";
import axios from "axios";
import AppContext from "./store/AppContext";
import { useContext, useEffect } from "react";

const App = () => {  

    const { user } = useContext(AppContext);

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token && user) {
            axios.defaults.headers.common['Authorization'] = token
        }
    }, [user]);
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')

    return (
        <Router>
            <Switch>
                {publicRoutes.map((route, idx) => (
                    <AuthMiddleware
                        path={route.path}
                        layout={LoginLayout}
                        component={route.component}
                        key={idx}
                        isAuthProtected={false}
                        exact
                    />
                ))}

                {protectedRoutes.map((route, idx) => (
                    <AuthMiddleware
                        path={route.path}
                        layout={Layout}
                        component={route.component}
                        key={idx}
                        isAuthProtected={true}
                        exact
                    />
                ))}
            </Switch>
        </Router>
    )
}

export default App
