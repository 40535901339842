import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import "../../assets/datatables.scss"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import axios from "axios";
import config from "../../config";
import WV from '../../workVariables'
import { useToasts } from "react-toast-notifications";
import TableSearchBar from "../../components/TableSearchBar";
import ModalConfirm from "../../components/ModalConfirm";

const TelegramBot = (props) => {
    const { addToast } = useToasts()
    const [statusBot, setStatusBot] = useState(false)
    const [statusInvestor, setStatusInvestor] = useState(false)

    useEffect(() => {
        const getTelegramStatus = () => {
            axios.get(`${config.apiUrl}/telegram-bot-get-status`)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.powerButton) setStatusBot(true)
                    if (response.data.ppButton) setStatusInvestor(true)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getTelegramStatus()
    }, [])

    const powerBot = () => {
        axios.get(`${config.apiUrl}/telegram-bot-power/on`)
            .then((response) => {
                if (response) {
                    addToast("Телеграм Бот запущен", {
                        appearance: "info",
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                }
            })
            .catch(error => {
                console.error(error);
            })
    }
    // telegram-bot-power/ss/
    // pause
    // play

    const changeBot = () => {
        let sendStatus
        statusInvestor ? sendStatus = 'pause' : sendStatus = 'play'
        axios.get(`${config.apiUrl}/telegram-bot-power/ss/${sendStatus}`)
        .then(async (response) => {
            addToast(response.data.msg, {
                appearance: response.data.res.toLowerCase(),
                autoDismiss: true,
                autoDismissTimeout: 7500,
                transitionDuration: 2000
            })
        })
        .catch(error => {
            console.error(error);
        })
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Телеграм группы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-6">
                        <Card>
                            <CardBody>
                                <i className={statusBot ? 'fas fa-power-off text-success me-4' : 'fas fa-power-off text-danger me-4'} style={{ fontSize: "1.4rem" }} />
                                <button
                                    onClick={() => {
                                        setStatusBot(true)
                                        powerBot()
                                    }
                                    }
                                    className={statusBot ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                    title={!statusBot ? 'Запустить Telegram Bot' : 'Telegram Bot запущен . . .'}>
                                    {!statusBot ? 'Запустить Telegram Bot' : 'Telegram Bot запущен . . .'}
                                </button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <Card>
                            <CardBody>
                                <i className={statusInvestor ? 'fas fa-power-off text-success me-4' : 'fas fa-power-off text-danger me-4'} style={{ fontSize: "1.4rem" }} />
                                <button
                                    onClick={() => {
                                        console.log(statusInvestor);
                                        setStatusInvestor(!statusInvestor)
                                        changeBot()
                                    }
                                    }
                                    className='btn btn-primary'
                                    title={!statusInvestor ? 'Возобновить доступ к Боту для Инвесторов' : 'Приостановить доступ к Боту для Инвесторов'}>
                                    {!statusInvestor ? 'Возобновить для Инвесторов' : 'Приостановить для Инвесторов'}
                                </button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <h3>Что если тут дальше будет таблица с пользователями из "Управление Телеграм Ботом" старого кабинета ??? </h3>
                </Row>
            </Container>
        </div >
    )
}

export default TelegramBot;
