import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import ModalConfirm from "../../components/ModalConfirm";
import TableSearchBar from "../../components/TableSearchBar";

import WV from '../../workVariables'

const MovingAverage = (props) => {
    const { addToast } = useToasts()

    const [movings, setMovings] = useState([])
    const [tableData, setTableData] = useState([])

    // Модалка удаления
    const [modalConfirm, setModalConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }

    const removeItem = async (id) => {
        id = deleteId
        await axios.get(`${config.apiUrl}/ma-delete/${id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setMovings(movings.filter(item => item.id !== id))
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Название',
            sort: true,
        },
        {
            dataField: 'period',
            text: 'Период',
            sort: true,
        },
        {
            dataField: 'interval_ma',
            text: 'Количество свечей',
            sort: true,
        },
        {
            dataField: 'typePrice',
            text: 'Применяется к ценам',
            sort: true,
        },
        {
            dataField: 'typeMoving',
            text: 'Метод',
            sort: true,
        },
        {
            dataField: 'comment',
            text: 'Комментарии',
        },
        {
            dataField: 'actions',
            text: 'Действия',
            style: {
                width: 111,
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    const sizePerPageList = WV.sizePerPageArr.concat([{ text: 'Все', value: (tableData).length }])
    let nameTable = 'tableSet_ma'
    let sizePerPage = parseInt(localStorage.getItem(nameTable + 'perPage'));
    if (!sizePerPage) sizePerPage = parseInt(WV.sizePerPage);
    let page = parseInt(localStorage.getItem(nameTable + 'page'));
    if (!page) page = 1;
    const pageOptions = {
        sizePerPage: sizePerPage,
        page: page,
        sizePerPageList: sizePerPageList,
        totalSize: tableData.length,
        custom: true,
        onSizePerPageChange: (sizePerPage, page) => {
            localStorage.setItem(nameTable + 'perPage', sizePerPage)
            localStorage.setItem(nameTable + 'page', 1)
        },
        onPageChange: (page, sizePerPage) => {
            localStorage.setItem(nameTable + 'page', page)
        },
    }


    const ActionButtons = (props) => {
        const copyItem = async (id) => {
            await axios.get(`${config.apiUrl}/ma-copy/${id}`)
                .then(async (response) => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    if (response.data.res === "Success") {
                        await axios.get(`${config.apiUrl}/ma`)
                            .then((response) => {
                                if (response.data.length > 0)
                                    setMovings(response.data)
                            })
                            .catch(error => {
                                console.error(error);
                            })
                    }
                })
                .catch(error => {
                    console.error(error);
                })

        }

        return (
            <div className='d-flex gap-2'>
                <Link to={`/ma/edit/${props.id}`} className='btn btn-primary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
                <div className='d-flex gap-2'>
                    <button onClick={() => copyItem(props.id)} className='btn btn-success' title='Дублировать'><i className='mdi mdi-content-copy' /></button>
                </div>
                <div className='d-flex gap-2'>
                    <button
                        onClick={() => {
                            setModalConfirm(true)
                            setDeleteId(props.id)
                        }
                        }
                        className='btn btn-danger' title='Удалить'>
                        <i className='mdi mdi-delete' />
                    </button>
                </div>
            </div>
        )
    }


    useEffect(() => {
        const getMovings = async () => {
            const response = await axios.get(`${config.apiUrl}/ma`)
            setMovings(response.data)
        }
        getMovings()
    }, [])

    useEffect(() => {
        let tableData = []
        if (movings.length > 0) {
            movings.forEach(item => {
                tableData.push({
                    ...item,
                    "actions": (<ActionButtons id={item.id} />)
                })
            })
        }        
        setTableData(tableData)
    }, [movings])

    return (
        <div className="page-content">
            <MetaTags>
                <title>Скользящие средние | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Скользящие средние</h4>
                                        <Link to='/ma/add' className='btn btn-primary'>
                                            <i className='mdi mdi-plus' />
                                            Добавить
                                        </Link>
                                    </div>
                                </CardTitle>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField='id'
                                    sizePerPageList={sizePerPageList}
                                    columns={columns}
                                    data={tableData}>

                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField='id'
                                            columns={columns}
                                            data={tableData}
                                            search
                                        >
                                            {
                                                toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <TableSearchBar
                                                                            {...toolkitProps.searchProps}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={true}
                                                                        striped={true}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <hr/>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone
                                                                        sizePerPageList={sizePerPageList}
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                            }
                                        </ToolkitProvider>
                                    )}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление Скользящей средней'
            />
        </div>
    )
}

export default MovingAverage;