import React from 'react';
import {
    Col,
    Row,
    Label,
    Input,
} from "reactstrap";
import InputSpinner from "react-bootstrap-input-spinner";

const Type1 = (props) => {
    const { type1, setType1, formErrors, setFormErrors } = props
    let errorItem;
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3 mb-4">
                        <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                            style={{ width: 50, height: 50 }}>
                            <i className="fas fa-info"></i>
                        </div>
                        <info_1 />
                        Тип А) Стоп должен передвигаться в ту или иную сторону в зависимости от предыдущей закрытой свечи;
                    </div>
                </Col>
            </Row>
            <Row>
            
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="stop_price_perc">Stop Price от открытия свечи, %</Label>
                        <InputSpinner
                        value={type1.stop_price_perc}
                        name="stop_price_perc"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {setType1({ ...type1, stop_price_perc: num })
                        // onChange={(num) => {
                        //   setForm({ ...form, stop_loss_price: num })
                        //   setError({ ...error, stop_loss_price: true })
                                                 
                          errorItem = formErrors;
                          delete errorItem.stop_price_perc;
                          setFormErrors(errorItem);
                        }                        
                        }
                      />
                      <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.stop_price_perc}</p>
                        {/* <Input
                            type="number"
                            min={0}
                            step={0.01}
                            className="form-control"
                            placeholder='Введите процент...'
                            id="stop_price_perc"
                            value={type1.stop_price_perc}
                            onChange={(e) => setType1({ ...type1, stop_price_perc: e.target.value })}
                        /> */}
                    </div>
                </Col>
                <Col xs={12} md={6} xl={8}>
                    <Label htmlFor="period">Условие 1</Label>
                  
                    <div className='d-flex align-items-center'>
                        
                        <Input
                            type="checkbox"
                            className="form-check me-2"
                            placeholder='Введите процент...'
                            id='condition1'
                            disabled={type1.condition3 && true}
                            checked={type1.condition1}
                            onChange={() => setType1({ ...type1, condition1: !type1.condition1 })}
                        />
                        Stop не ниже изначального 
                    </div>
                </Col>
               
            </Row>
            <Row>
            
            <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="delta_perc">Разница Stop Price и Price, %</Label>
                        <InputSpinner
                        value={type1.delta_perc}
                        name="delta_perc"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {
                            setType1({ ...type1, delta_perc: num })
                            errorItem = formErrors;
                            delete errorItem.delta_perc;
                            setFormErrors(errorItem);
                        }}
                       />
                      <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.delta_perc}</p>
                        {/* <Input
                            type="number"
                            min={0}
                            step={0.01}
                            className="form-control"
                            placeholder='Введите процент...'
                            id="delta_perc"
                            value={type1.delta_perc}
                            onChange={(e) => setType1({ ...type1, delta_perc: e.target.value })}
                        /> */}
                    </div>
                </Col>
                <Col xs={12} md={6} xl={8}>
                    {/* <div className='d-flex align-items-center'> */}
                     <div className="mb-3">
                        <Label htmlFor="condition2">Условие 2 (не ниже безубытка, в %):</Label>
                        <Col xs={12} md={6} xl={6}>
                        <InputSpinner
                        value={type1.condition2}
                        name="condition2"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => setType1({ ...type1, condition2: num })}
                        // onChange={(num) => {
                        //   setForm({ ...form, stop_loss_price: num })
                        //   setError({ ...error, stop_loss_price: true })
                        //   errorItem = formErrors;
                        //   delete errorItem.stop_loss_price
                        //   setFormErrors(errorItem);
                        // }                        
                        // }
                      />

                        </Col>
                        
                        {/* <Input
                            type="number"
                            min={0}
                            step={0.01}
                            className="form-control"
                            placeholder='Введите процент...'
                            id="condition2"
                            value={type1.condition2}
                            onChange={(e) => setType1({ ...type1, condition2: e.target.value })}
                        /> */}
                    </div>
                </Col>

            </Row>
            <Row>  
            <Col xs={12} md={6} xl={4}> </Col>  
           
                <Col xs={12} md={6} xl={4}>
                    <Label htmlFor="period">Условие 3</Label>
                    <div className='d-flex align-items-center'>
                        <Input
                            type="checkbox"
                            className="form-check me-2"
                            placeholder='процент...'
                            id='condition3'
                            disabled={type1.condition1 && true}
                            checked={type1.condition3}
                            onChange={() => setType1({ ...type1, condition3: !type1.condition3 })}
                        />
                        Stop не ниже предыдущего
                    </div>
                </Col>
            </Row>

            {/* <Card>
              <CardBody>
                <Row>

                </Row>
                </CardBody>
                </Card> */}
        </>
    );
}

export default Type1;
