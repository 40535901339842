import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import Logo from "./Logo";


const Sidebar = props => {

    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-light">
                        <span className='logo-lg h3 fw-medium'>
                            <Logo fill={'rgb(80, 165, 241)'} width={26} height={26}/>
                            <span className='text-white mx-2 d-inline-block' style={{transform: "translateY(3px)"}}>TradeBot</span>
                        </span>
                        <span className='logo-sm text-info fw-bolder' style={{transform: "translateX(-5px)"}}>
                            <Logo fill={'rgb(80, 165, 241)'} width={30} height={30}/>
                        </span>
                    </Link>
                </div>
                <div data-simplebar className="h-100">
                    {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
                </div>
                <div className="sidebar-background"></div>
            </div>
        </React.Fragment>
    );
};

Sidebar.propTypes = {
    type: PropTypes.string,
};

export default withRouter(Sidebar);
