import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
} from "reactstrap"
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState } from 'react'
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import WV from '../../../workVariables'

const PortfolioAction = () => {
    const { addToast } = useToasts()
    const { action, id } = useParams()
    const history = useHistory();
    let periodInput = false
    let title

    if (action === "close_period") title = "Рассчитать период по Портфелю #" + id
    if (action === "calc_comission") {
        title = "Начислить комиссию по Портфелю #" + id
        periodInput = true
    }
    if (action === "show_profit") {
        title = "Отразить профит в Балансе по Портфелю #" + id
        periodInput = true
    }
    if (action === "show_comission") title = "Отразить комиссию в Балансе по Портфелю #" + id

    const [form, setForm] = useState({
        id: id,
        command: action,
        month: null,
        year: null,
        period: null,
    })

    const core = async () => {
        console.log(form);
        await axios.post(`${config.apiUrl}/ip-portfolio-button-action-core`, form)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                history.goBack()
            })
            .catch(error => {
                console.error(error);
            })
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Портфели Инвесторов | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>{title}</h4>
                                        <div>
                                            <Button color="primary me-3" onClick={core}>
                                                Выполнить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="year">Выбрать год</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="year"
                                                name="year"
                                                placeholder="выбрать..."
                                                onChange={(e) => {
                                                    setForm({ ...form, year: parseInt(e.value) })
                                                }}
                                                options={WV.yaersList}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="month">Выбрать месяц</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="month"
                                                name="month"
                                                placeholder="выбрать..."
                                                onChange={(e) => {
                                                    setForm({ ...form, month: parseInt(e.value) })
                                                }}
                                                options={WV.monthsList}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                {periodInput &&
                                    <Row>
                                        <Col xs={12} md={6} lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="period">Выбрать период</Label>
                                                <Select
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: `${WV.colorHoverSelect}`,
                                                            primary: `${WV.colorSelected}`,
                                                        },
                                                    })}
                                                    id="period"
                                                    name="period"
                                                    placeholder="выбрать..."
                                                    onChange={(e) => {
                                                        setForm({ ...form, period: e.value })
                                                    }}
                                                    options={WV.periodsList}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PortfolioAction