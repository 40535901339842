import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
} from "reactstrap"
import { Link, useParams, Redirect } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";


const BalanceInvestorEdit = (props) => {
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)
    const { id } = useParams()
    const [form, setForm] = useState({
        id: '',
        comment_for_investor: '',
        comment_for_admin: '',
    })

    useEffect(() => {
        const getCommit = async () => {
            axios.get(`${config.apiUrl}/ip-balance-edit/` + id)
            .then((response)=>{
                setForm({
                    id: response.data.id,
                    comment_for_investor: response.data.comment_for_investor,
                    comment_for_admin: response.data.comment_for_admin,
                })
            })
            .catch(error => {
                console.error(error);
            })
        }
        getCommit()
    }, [id])

    const updateItem = async () => {
        await axios.post(`${config.apiUrl}/ip-balance-update`, form)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    setRedirect(true)
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    return (
        <div className="page-content">
            <MetaTags>
                <title>Редактировать комментарии к операции | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Редактировать комментарии к операции #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'> 
                                        <Button color="primary" onClick={updateItem}>
                                                Изменить
                                            </Button>
                                        <Link to='/ip-balance-investor' className='btn btn-danger'>
                                            <i className='mdi mdi-backspace' />
                                            &nbsp;
                                            Вернуться
                                        </Link>                                        
                                        </div>                                       
                                    </div>
                                </CardTitle>

                                <Row>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии для Администратора:</Label>
                                            <Input
                                                type="textarea"
                                                rows={3}
                                                value={form.comment_for_admin}
                                                className="form-control"
                                                placeholder='...'
                                                id="comment_for_admin"
                                                name="comment_for_admin"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии для Инвестора:</Label>
                                            <Input
                                                type="textarea"
                                                rows={3}
                                                value={form.comment_for_investor}
                                                className="form-control"
                                                placeholder='...'
                                                id="comment_for_investor"
                                                name="comment_for_investor"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>                        

                    </Col>
                </Row>
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/ip-balance-investor",
                    }}
                />
            }
        </div>
    )
}

export default BalanceInvestorEdit