import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Schemas from "../pages/schemas/Schemas";
import SchemaAdd from "../pages/schemas/SchemaAdd";
import SchemaEdit from "../pages/schemas/SchemaEdit";
import Trades from "../pages/history/Trades";
import Logs from "../pages/logs/Logs";
import MovingAverage from "../pages/ma/MovingAverage";
import MovingAverageAdd from "../pages/ma/MovingAverageAdd";
import MovingAverageEdit from "../pages/ma/MovingAverageEdit";
import Ravi from "../pages/ravi/Ravi";
import RaviAdd from "../pages/ravi/RaviAdd";
import RaviEdit from "../pages/ravi/RaviEdit";
import Account from "../pages/account/Account";
import StopLoss from "../pages/stoploss/StopLoss";
import Stops from "../pages/stops/Stops";
import TdSequental from "../pages/td/TDSequental";
import Telegram from "../pages/telegram/Telegram";
import Test1 from "../pages/test/Test1";
import Test2 from "../pages/test_sl/Test2";
import TihTil from "../pages/tih_til/TihTil";
import Trade from "../pages/trade/Trade";
import CheckPin from "../pages/trade/CheckPin";
import TradeAdd from "../pages/trade/TradeAdd";
import TradeEdit from "../pages/trade/TradeEdit";
import TelegramAdd from "../pages/telegram/TelegramAdd";
import StopsAdd from "../pages/stops/StopsAdd";
import StopLossAdd from "../pages/stoploss/StopLossAdd";
import StopLossEdit from "../pages/stoploss/StopLossEdit";
import StopLossShow from "../pages/stoploss/StopLossShow";
import TihTilAdd from "../pages/tih_til/TihTilAdd";
import TihTilEdit from "../pages/tih_til/TihTilEdit";
import AccountAdd from "../pages/account/AccountAdd";
import TelegramEdit from "../pages/telegram/TelegramEdit";
import Strategy from "../pages/investors/strategy/Strategy";
import CatalogPortfolio from "../pages/investors/catalogPortfolio/CatalogPortfolio";
import Portfolio from "../pages/investors/portfolio/Portfolio";
import SiInvestor from "../pages/investors/siInvesor/SiInvestor";
import TsiInvestor from "../pages/investors/tsiInvestor/TsiInvestor";
import OrdersInvestor from "../pages/investors/ordersInvestor/OrdersInvestor";
import Investors from "../pages/investors/investors/Investors";
import ProfitInvestor from "../pages/investors/profitInvestor/ProfitInvestor";
import BalanceInvestor from "../pages/investors/balanceInvestor/BalanceInvestor";
import ActionBalance from "../pages/investors/actionBalance/ActionBalance";
import Test1Add from "../pages/test/Test1Add";
import Test1Edit from "../pages/test/Test1Edit";
import Test2Add from "../pages/test_sl/Test2Add";
import ActionBalanceAdd from "../pages/investors/actionBalance/ActionBalanceAdd";
import ActionBalanceEdit from "../pages/investors/actionBalance/ActionBalanceEdit";
import BalanceInvestorEdit from "../pages/investors/balanceInvestor/BalanceInvestorEdit";
import BalanceInvestorAdd from "../pages/investors/balanceInvestor/BalanceInvestorAdd";
import ButtonAction from "../pages/investors/profitInvestor/ButtonAction";
import OrdersInvestorEdit from "../pages/investors/ordersInvestor/OrdersInvestorEdit";
import TsiInvestorEdit from "../pages/investors/tsiInvestor/TsiInvestorEdit";
import TradeShow from "../pages/trade/TradeShow";
import Test1Show from "../pages/test/Test1Show";
import StrategyAdd from "../pages/investors/strategy/StrategyAdd";
import Test2Edit from "../pages/test_sl/Test2Edit";
import Test2Show from "../pages/test_sl/Test2Show";
import StrategyEdit from "../pages/investors/strategy/StrategyEdit";
import StrategyShow from "../pages/investors/strategy/StrategyShow";
import CatalogPortfolioAdd from "../pages/investors/catalogPortfolio/CatalogPortfolioAdd";
import UnionSchemas from "../pages/union_schemas/UnionSchemas";
import UnionSchemasAdd from "../pages/union_schemas/UnionSchemasAdd";
import UnionSchemasEdit from "../pages/union_schemas/UnionSchemasEdit";
import UnionSchemasShow from "../pages/union_schemas/UnionSchemasShow";
import SiInvestorEdit from "../pages/investors/siInvesor/SiInvestorEdit";
import PortfolioAdd from "../pages/investors/portfolio/PortfolioAdd";
import PortfolioShow from "../pages/investors/portfolio/PortfolioShow";
import PortfolioAction from "../pages/investors/portfolio/PortfolioAction";
import PortfolioEdit from "../pages/investors/portfolio/PortfolioEdit";
import PortfolioEditStr from "../pages/investors/portfolio/PortfolioEditStr";
import OrdersInvestorShow from "../pages/investors/ordersInvestor/OrdersInvestorShow";
import InvestorsEdit from "../pages/investors/investors/InvestorsEdit";
import InvestorsShow from "../pages/investors/investors/InvestorsShow";
import SiInvestorShow from "../pages/investors/siInvesor/SiInvestorShow";
import TsiInvestorShow from "../pages/investors/tsiInvestor/TsiInvestorShow";
import CatalogPortfolioShow from "../pages/investors/catalogPortfolio/CatalogPortfolioShow";
import CatalogPortfolioEdit from "../pages/investors/catalogPortfolio/CatalogPortfolioEdit";
import StopsEdit from "../pages/stops/StopsEdit";
import OrderShow from "../pages/history/OrderShow";
import TelegramBot from "../pages/telegram/TelegramBot";
import TelegramUsers from "../pages/telegram/TelegramUsers";
import Settings from "../pages/Settings";
import SettingsTotal from "../pages/setting/SettingsTotal";
import SchemaShow from "../pages/schemas/SchemaShow";

const protectedRoutes = [
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  { path: "/settings", component: Settings },
  { path: "/total-settings", component: SettingsTotal },
  {
    path: "/schemas",
    component: Schemas,
  },
  {
    path: "/schemas/add",
    component: SchemaAdd,
  },
    {
      path: "/schemas/edit/:id",
      component: SchemaEdit,
    },
  {
    path: "/schemas/show/:id",
    component: SchemaShow,
  },
  { path: "/history/:id", component: Trades },
  { path: "/history-show/:id", component: OrderShow },

  { path: "/logs", component: Logs },
  {
    path: "/ma",
    component: MovingAverage,
  },
  {
    path: "/ma/add",
    component: MovingAverageAdd,
  },
  {
    path: "/ma/edit/:id",
    component: MovingAverageEdit,
  },
  {
    path: "/ravi",
    component: Ravi,
  },
  {
    path: "/ravi/add",
    component: RaviAdd,
  },
  {
    path: "/ravi/edit/:id",
    component: RaviEdit,
  },
  {
    path: "/account",
    component: Account,
  },
  {
    path: "/account/add",
    component: AccountAdd,
  },

  { path: "/stop-loss", component: StopLoss },
  { path: "/stop-loss/add", component: StopLossAdd },
  { path: "/stop-loss/edit/:id", component: StopLossEdit },
  { path: "/stop-loss/show/:id", component: StopLossShow },

  { path: "/stops", component: Stops },
  { path: "/stops/add", component: StopsAdd },
  { path: "/stops/edit/:id", component: StopsEdit },

  {
    path: "/td",
    component: TdSequental,
  },

  // { path: "/telegram-bot", component: TelegramBot },
  { path: "/telegram", component: Telegram },
  { path: "/telegram/add", component: TelegramAdd },
  { path: "/telegram/edit/:id", component: TelegramEdit },
  { path: "/telegram-users", component: TelegramUsers },

  {
    path: "/test1",
    component: Test1,
  },
  {
    path: "/test1/add",
    component: Test1Add,
  },

  {
    path: "/test1/edit/:id",
    component: Test1Edit,
  },
  {
    path: "/test1/show/:id",
    component: Test1Show,
  },
  {
    path: "/test2",
    component: Test2,
  },
  {
    path: "/test2/add",
    component: Test2Add,
  },
  {
    path: "/test2/edit/:id",
    component: Test2Edit,
  },
  {
    path: "/test2/show/:id",
    component: Test2Show,
  },
  { path: "/union-schemas", component: UnionSchemas },
  { path: "/union-schemas/add", component: UnionSchemasAdd },
  { path: "/union-schemas/edit/:id", component: UnionSchemasEdit },
  { path: "/union-schemas/show/:id", component: UnionSchemasShow },

  { path: "/tih-til", component: TihTil },
  { path: "/tih-til/add", component: TihTilAdd },
  { path: "/tih-til/edit/:id", component: TihTilEdit },

  { path: "/trade", component: Trade },
  { path: "/trade/show/:id", component: TradeShow },
  { path: "/trade/add", component: TradeAdd },
  { path: "/trade/edit/:id", component: TradeEdit },
  { path: "/trade/check-pin/:action/:id", component: CheckPin },

  { path: "/strategy", component: Strategy },
  { path: "/strategy/add", component: StrategyAdd },
  { path: "/strategy/edit/:id", component: StrategyEdit },
  { path: "/strategy/show/:id", component: StrategyShow },

  { path: "/catalog-portfolio", component: CatalogPortfolio },
  { path: "/catalog-portfolio/add", component: CatalogPortfolioAdd },
  { path: "/catalog-portfolio/show/:id", component: CatalogPortfolioShow },
  { path: "/catalog-portfolio/edit/:id", component: CatalogPortfolioEdit },

  { path: "/portfolio", component: Portfolio },
  { path: "/portfolio/add", component: PortfolioAdd },
  { path: "/portfolio/action/:action/:id", component: PortfolioAction },
  { path: "/portfolio/show/:id", component: PortfolioShow },
  { path: "/portfolio/edit/:id", component: PortfolioEdit },
  { path: "/portfolio/edit-str/:id", component: PortfolioEditStr },

  { path: "/si-investor", component: SiInvestor },
  { path: "/si-investor/edit/:id", component: SiInvestorEdit },
  { path: "/si-investor/show/:id", component: SiInvestorShow },

  { path: "/tsi-investor", component: TsiInvestor },
  { path: "/tsi-investor/edit/:id", component: TsiInvestorEdit },
  { path: "/tsi-investor/show/:id", component: TsiInvestorShow },

  { path: "/orders-investor", component: OrdersInvestor },
  { path: "/orders-investor/edit/:id", component: OrdersInvestorEdit },
  { path: "/orders-investor/show/:id", component: OrdersInvestorShow },

  { path: "/ip-investors", component: Investors },
  { path: "/ip-investors/edit/:id", component: InvestorsEdit },
  { path: "/ip-investors/show/:id", component: InvestorsShow },

  { path: "/ip-profit-investor", component: ProfitInvestor },
  { path: "/ip-profit-investor-buttons/:command", component: ButtonAction },

  { path: "/ip-balance-investor", component: BalanceInvestor },
  { path: "/ip-balance-investor/add", component: BalanceInvestorAdd },
  { path: "/ip-balance-investor/edit/:id", component: BalanceInvestorEdit },

  { path: "/ip-action-balance", component: ActionBalance },
  { path: "/ip-action-balance/add", component: ActionBalanceAdd },
  { path: "/ip-action-balance/edit/:id", component: ActionBalanceEdit },
];
const publicRoutes = [
  {
    path: "/login",
    component: Login,
  },
];

export { protectedRoutes, publicRoutes };
