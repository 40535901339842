import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import { Link, useHistory, useParams, Redirect } from "react-router-dom";
import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../../config";
import { useToasts } from "react-toast-notifications";

const SiInvestorEdit = () => {
    const { addToast } = useToasts()
    const history = useHistory();

    const { id } = useParams()
    const [form, setForm] = useState({
        name_user: '',
        show: false,
        comment: ''
    })

    useEffect(() => {
        const getMovings = async () => {
            await axios.get(`${config.apiUrl}/ip-si-edit/` + id)
                .then((response) => {
                    let show = false
                    if (response.data.show === 1) show = true
                    setForm({
                        id: response.data.id,
                        name_user: response.data.name_user,
                        show: show,
                        comment: response.data.comment,
                        user_id: response.data.user.id,
                        user_name: response.data.user.name_admin,
                        portfolio_name: response.data.portfolio.name,
                        portfolio_id: response.data.portfolio.id,
                        strategy_name: response.data.strategy.name,
                        strategy_id: response.data.strategy.id, 
                    })
                  
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getMovings()
    }, [id])

    const [error, setError] = useState({
        name_user: false
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const updateItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        if (values.name_user == '') errors.name_user = " ";
        return errors;
    };

    const [redirect, setRedirect] = useState(false)
    
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            delete form.user_id;
            delete form.user_name;
            delete form.portfolio_name;
            delete form.portfolio_id;
            delete form.strategy_name;
            delete form.strategy_id;

            axios.post(`${config.apiUrl}/ip-si-update`, form)
                .then((response) => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                    if (response.data.res === "Success") {
                        setRedirect(true)
                    }
                })
                .catch(error => {
                    console.error(error);
                    addToast("Ничего не отредактировано!", {
                        appearance: "info",
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                })
        }
    }, [formErrors]); 

    let errorItem;    
    return (
        <div className="page-content">
            <MetaTags>
                <title>Стратегия у Инвестора | TradeBot</title>
            </MetaTags>
            <Container fluid>
                {/* <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Стратегия у Инвестора Редактирование #{id}</h4>
                                        <div>
                                            <Button color="primary me-2" onClick={updateItem}>
                                                Изменить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-4">
                                            <Label htmlFor="name_user">Название Стратегии для Инвестора: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name_user"
                                                value={form.name_user}
                                                name="name_user"
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={1} xl={1}>
                                    </Col>
                                    <Col xs={12} md={5} xl={5}>
                                        <Label htmlFor="period">Отображать для Инвестора: </Label>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='active_check'
                                                checked={form.show}
                                                onChange={() => setForm({ ...form, show: !form.show })}
                                            />
                                            {form.show
                                                // ? <><i class="far fa-eye me-1" style={{ color: 'greenyellow' }}></i> Отображать для Инвестора</>
                                                // : <><i class="far fa-eye-slash me-1" style={{ color: 'red' }}></i> Не отображать для Инвестора</>
                                                ? <>Отображать для Инвестора</>
                                                : <>Не отображать для Инвестора</>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                value={form.comment}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Стратегия у Инвестора Редактирование #{id}</h4>
                                        <div>
                                            <Button color="primary me-2" onClick={updateItem}>
                                                Изменить
                                            </Button>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-4">
                                            <Label htmlFor="name_user">Название Стратегии для Инвестора: </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name_user"
                                                value={form.name_user}
                                                invalid={
                                                    (form.name_user.length === 0 && error.name_user) ||
                                                    formErrors.name_user
                                                }
                                                name="name_user"
                                                onChange={(e) => {
                                                    setError({ ...error, name_user: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name_user
                                                    setFormErrors(errorItem);
                                                    setForm({ ...form, [e.target.name]: e.target.value });
                                                }}
                                            />
                                            <FormFeedback invalid> {formErrors.name_user}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={1} xl={1}>
                                    </Col>
                                    <Col xs={12} md={5} xl={5}>
                                        <div className="mb-3">
                                            <Label htmlFor="investor">Инвестор:</Label>
                                            <br />
                                            <Link to={`/ip-investors/show/${form.user_id}`} title='' className='btn btn-outline-dark m-0' >
                                                {form.user_name}                
                                            </Link>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                name="comment"
                                                value={form.comment}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={1} xl={1}>
                                    </Col>
                                    <Col xs={12} md={5} xl={5}>
                                        <div className="mb-3">
                                            <Label htmlFor="portfolio">Портфель:</Label>
                                            <br />
                                            <Link to={`/portfolio/show/${form.portfolio_id}`} title='' className='btn btn-outline-dark m-0'>
                                                {form.portfolio_name}                
                                            </Link>
                                        </div>
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <Label htmlFor="period">Отображать для Инвестора: </Label>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mb-3'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                id='active_check'
                                                checked={form.show}
                                                onChange={() => setForm({ ...form, show: !form.show })}
                                            />
                                            {form.show
                                                // ? <><i class="far fa-eye me-1" style={{ color: 'greenyellow' }}></i> Отображать для Инвестора</>
                                                // : <><i class="far fa-eye-slash me-1" style={{ color: 'red' }}></i> Не отображать для Инвестора</>
                                                ? <>Отображать для Инвестора</>
                                                : <>Не отображать для Инвестора</>
                                            }
                                        </div>
                                    </Col>
                                    <Col xs={12} md={1} xl={1}>
                                    </Col>
                                    <Col xs={12} md={5} xl={5}>
                                        <div className="mb-3">
                                            <Label htmlFor="strategy">Стратегия:</Label>
                                            <br />
                                            <Link to={`/strategy/show/${form.strategy_id}`} title='' className='btn btn-outline-dark m-0'>
                                                {form.strategy_name}                
                                            </Link>
                                        </div>
                                    </Col>
                                   
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {redirect &&
                <Redirect
                    to={{
                        pathname: "/si-investor",
                    }}
                />
            }
        </div >
    )
}

export default SiInvestorEdit