import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Label,
    Input,
    Button,
} from "reactstrap";
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import WV from "../../../workVariables";
import InputSpinner from "react-bootstrap-input-spinner";

const Type7 = (props) => {
    const { type7, setType7 } = props
    const [status, setStatus] = useState({
        shift_sl: false,
        stop_id: false
    })
    const [mas, setMas] = useState([])
    const [stops, setStops] = useState([])

    const [selectStop, setSelectStop] = useState({
        label: '',
        value: ''
    })

    const [selectMA, setSelectMA] = useState({
        label: '',
        value: ''
    })
    
    useEffect(() => {
        let arrayMas = [];
        let arrayStops = [];
        const getMas = async () => {
            await axios.get(`${config.apiUrl}/ma`)
                .then(async (response) => {
                    for await (let item of response.data) {
                        let option = {}
                        option.label = item.name
                        option.value = item.id
                        arrayMas.push(option)
                        if (item.id === type7.moving_average_id) {
                            setSelectMA({
                                label: item.name,
                                value: item.id
                            })
                        }
                    }
                    setMas(arrayMas)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        const getStops = async () => {
            await axios.get(`${config.apiUrl}/stops`)
                .then(async (response) => {
                    for await (let item of response.data) {
                        let option = {}
                        option.label = item.name
                        option.value = item.id
                        arrayStops.push(option)
                        if (item.id === type7.stop_id) {
                            setSelectStop({
                                label: item.name,
                                value: item.id
                            })
                        }
                    }
                    setStops(arrayStops)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStops()
        getMas()
    }, [type7.moving_average_id, type7.stop_id])

    // useEffect(()=> {
    //     if (type7.shiftStatus) setStatus({...status, shift_sl: true})
    //     if (type7.stopStatus) setStatus({...status, stop_id: true})
    // }, [type7.shiftStatus, type7.stopStatus, status ])

    useEffect(()=> {
        if (type7.shiftStatus) setStatus({...status, shift_sl: true})
        if (type7.stopStatus) setStatus({...status, stop_id: true})
    }, [])

    console.log(type7);

    const clearShift = () => {
        setStatus({ ...status, shift_sl: false })
        setType7({ ...type7, shift_sl: '' })
    }
    const clearStop = () => {
        setStatus({ ...status, stop_id: false })
        setType7({ ...type7, stop_id: '' })
        setSelectStop(null)
    }
    const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }
    return (
        <>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3 mb-4">
                        <div className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                            style={{ width: 50, height: 50 }}>
                            <i className="fas fa-info"></i>
                        </div>
                        <info_1 />
                        Тип З) Стоп больше или меньше значения скользящей средней на n% на момент открытия сделки и стоп дальше не меняется;
                    </div>
                </Col>
            </Row>
                    <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Разница Stop Price и Price, %</Label>
                        <InputSpinner
                        value={type7.delta_perc}
                        name="delta_perc"
                        type={"real"}
                        disabled={true}
                      />                        
                    </div>
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                        <Label htmlFor="period">Сдвиг цены, %</Label>
                        {/* <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}> */}
                            <InputSpinner
                                value={type7.shift_sl}
                                name="shift_sl"
                                type={"real"}
                                precision={1}
                                disabled={true}
                            />                               
                    </div>
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                    <Label htmlFor="period">Выбрать MA</Label>
                    <Input
                            type="text"
                            className="form-control"
                            id="name"
                            value={selectMA.label}
                            readOnly={true}
                        />                   
                    </div>
                </Col>
            
                            
                {/* <Col xs={12} md={6} xl={6}>
                    <div className="mb-3">
                        <Label htmlFor="period">Выбрать набор сдвигов, %</Label>
                        <div className='d-flex justify-content-between'>
                            <div className='w-100' style={{ marginRight: 15 }}>
                                <Select
                                    id='stop_id'
                                    placeholder="выбрать..."
                                    isDisabled={status.shift_sl && true}
                                    onChange={e => {
                                        setType7({ ...type7, stop_id: e.value })
                                        setStatus({ ...status, stop_id: true })
                                        setSelectStop({label: e.label, value: e.value})
                                    }}
                                    options={stops}
                                    value={selectStop}
                                    classNamePrefix="select2-selection"

                                />
                            </div>
                            <Button
                                className='btn btn-danger'
                                disabled={status.shift_sl && true}
                                onClick={clearStop}>
                                <i class="far fa-trash-alt"></i>
                            </Button>
                        </div>
                    </div>
                </Col> */}
            
        </>
    );
}

export default Type7;
