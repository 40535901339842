import React, {useEffect, useState} from 'react';
import moment from "moment";

const Clock = (props) => {
    const [utc, setUtc] = useState(moment().utc().format('HH:mm:ss'))

    let interval = setInterval(() => {setUtc(moment().utc().format('HH:mm:ss'))}, 1000)

    useEffect(() => {

        return () => {
            clearInterval(interval)
        }
    })
    return (
        <div className='fw-normal' style={{marginRight: "11px"}}>
            UTC {utc}
        </div>
    )
}

export default Clock;
