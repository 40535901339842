import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback,
} from "reactstrap"
import {Link} from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import config from "../../config";
import { registerLocale, setDefaultLocale }  from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from 'react-select/animated';
import WV from '../../workVariables'
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from '../../muiTheme';
import InputSpinner from "react-bootstrap-input-spinner";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
const animatedComponents = makeAnimated();
const moment = require('moment')

const Test1Add = (props) => {
  
   
    const [form, setForm] = useState({
        name: '',
        maId: '',
        show_open: { label: 'Показывать только открытые', value: '1' },
        scheme_id: '',
        symbol: '',
        interval: '',
        procent_comision: '',
        comment: '',
        stop_id: ''
    });
    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.symbol === '') errors.symbol = "поле обязательное для заполнения";
        if (values.procent_comision == '') errors.procent_comision = "поле обязательное для заполнения";
        if (values.interval === '') errors.interval = "поле обязательное для заполнения";
        if (values.scheme_id === '') errors.scheme_id = "поле обязательное для заполнения";
        if (values.stop_id === '') errors.stop_id = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            console.log(form);
            let form1 = {};
            form1.name = form.name;
            form1.comment = form.comment;
            form1.symbol = form.symbol.value;
            form1.scheme_id = form.scheme_id.value;
            form1.stop_id = form.stop_id.value;
            form1.interval = form.interval.value;
            form1.procent_comision = parseFloat(form.procent_comision);
            if (form.show_open.value == 1) form1.show_open = 1; else form1.show_open = null
            if (form.maId === '') form1.ma = null;
            else {
                let arrMa = form.maId;
                for (let i = 0; i < arrMa.length; i++)
                    (i === 0) ? form1.ma = arrMa[i].value : form1.ma = form1.ma + ', ' + arrMa[i].value

            }

            form1.startTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss')
            let st = form1.startTime.split(' ')
            form1.startTime = st[0] + "T" + st[1] + "Z"
            
            form1.endTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
            let et = form1.endTime.split(' ')
            form1.endTime = et[0] + "T" + et[1] + "Z"
           
            axios.post(`${config.apiUrl}/test-schemas-store`, form1)
                .then((response) => {
                    history.goBack()
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })
                    }, 300);

                    if (response.data.res === "Success") {
                        console.log("OK");

                        // <Redirect to="/account" />
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [formErrors]);

    let start = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    let s_t = start.split(' ')
    start = s_t[0] + " 00:00:00"
    const [startTime, setStartTime] = useState(start);
    
    let end = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    let e_t = end.split(' ')
    end = e_t[0] + " 00:00:00"
    const [endTime, setEndTime] = useState(end);

    registerLocale('ru', ru)
    setDefaultLocale('ru')

    const [ma, setMa] = useState([])
    const [schema, setSchema] = useState([])
    const [stop, setStop] = useState([])
    useEffect(() => {
        const getMa = async () => {
            await axios.get(`${config.apiUrl}/schemas-ma`)
                .then((response) => {
                    let arrayMa = response.data;
                    for (let i = 0; i < arrayMa.length; i++) {
                        arrayMa[i].value = arrayMa[i].id;
                        arrayMa[i].label = arrayMa[i].name;
                        delete arrayMa[i].id;
                        delete arrayMa[i].name
                    }
                    if (arrayMa.length > 0) arrayMa.unshift({ value: 'cpp', label: 'Текущая цена пары' })
                    setMa(arrayMa)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getMa()
        const getScheme = async () => {
            await axios.get(`${config.apiUrl}/schemas`)
                .then((response) => {
                    let arraySchema = response.data;
                    for (let i = 0; i < arraySchema.length; i++) {
                        arraySchema[i].value = arraySchema[i].id;
                        arraySchema[i].label = arraySchema[i].name;
                        delete arraySchema[i].id;
                        delete arraySchema[i].name
                    }
                    setSchema(arraySchema)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getScheme()

        const getStop = async () => {
            await axios.get(`${config.apiUrl}/test-schemas-stops`)
                .then((response) => {
                    console.log(response.data);
                    let arrayStop = response.data;
                    for (let i = 0; i < arrayStop.length; i++) {
                        arrayStop[i].value = arrayStop[i].id;
                        arrayStop[i].label = arrayStop[i].name;
                        delete arrayStop[i].id;
                        delete arrayStop[i].name
                    }
                    setStop(arrayStop)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStop()
    }, [])
    const { addToast } = useToasts()
    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color:`${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    // console.log(form);
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Новая Тестовая Схема | TradeBot</title>
            </MetaTags>
            <Container fluid>
            <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Новая Тестовая Схема</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={storeItem}>
                                                Сохранить
                                            </Button>
                                            <Link to='/test1' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                // required
                                                id="name"
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                  }
                                                onChange={
                                                    (e) => {
                                                        setForm({ ...form, name: e.target.value });
                                                        setError({ ...error, name: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.name
                                                        setFormErrors(errorItem); 
                                                    }
                                                }
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <Label symbol="position">Символ</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='symbol'
                                                name='symbol'
                                                value={form.symbol}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, symbol: value })
                                                    setError({ ...error, symbol: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.symbol
                                                    setFormErrors(errorItem); 
                                                }}
                                                options={WV.symbolsList}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.symbol}</p>
                                        </div>
                                        <div className="mb-3">
                                            <Label symbol="interval">Интервал</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='interval'
                                                value={form.interval}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, interval: value });
                                                    setError({ ...error, interval: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.interval
                                                    setFormErrors(errorItem); 
                                                }}
                                                options={WV.timeframesList}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.interval}</p>
                                        </div>    

                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <Row>                                            
                                        <div className="mb-3">
                                            <Label htmlFor="comment">Комментарии</Label>
                                            <Input
                                                type="text"
                                                // style={{ resize: 'none', height: '3.5rem' }}
                                                className="form-control"
                                                placeholder='...'
                                                id="comment"
                                                onChange={
                                                    (e) => {
                                                        setForm({ ...form, comment: e.target.value })
                                                    }
                                                }
                                            />
                                        </div>
                                            <Col xs={12} md={6} xl={6}>   
                                            <div className="mb-3">
                                            <Label> Процент комиссии</Label>
                                                    <InputSpinner
                                                        value={form.procent_comision}
                                                        name="procent_comision"
                                                        type={"real"}
                                                        precision={2}
                                                        max={100}
                                                        min={0}
                                                        step={0.01}
                                                        onChange={(num) => {
                                                        setForm({ ...form, procent_comision: num });
                                                        setError({ ...error, procent_comision: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.procent_comision
                                                        setFormErrors(errorItem);}
                                                        }
                                                    />
                                                     <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.procent_comision}</p>
                                        </div>                                          
                                            <div className="mb-3">
                                            <Label symbol="show_open">Отображение сделок в Excel</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='show_open'
                                                value={form.show_open}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, show_open: value })
                                                }}
                                                options={[
                                                    { label: 'Показывать только открытые', value: '1' },
                                                    { label: 'Показывать все сделки', value: '0' },
                                                ]}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>                                    

                                            </Col>
                                            <Col xs={12} md={6} xl={6}>   
                                              <div className="mb-3"> 
                                              <ThemeProvider theme={darkTheme}>
                                                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                      <DateTimePicker
                                                          renderInput={(props) => <TextField {...props} />}
                                                          label="Время начала"
                                                          value={startTime}
                                                          ampm={false}
                                                          onChange={(newValue) => {
                                                              setStartTime(newValue);
                                                             
                                                          }}
                                                      />
                                                  </LocalizationProvider>
                                              </ThemeProvider>                                              
                                              </div> 
                                              <div className="mb-3"> 
                                              <ThemeProvider theme={darkTheme}>
                                                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                      <DateTimePicker
                                                          renderInput={(props) => <TextField {...props} />}
                                                          label="Время завершения"
                                                          value={endTime}
                                                          ampm={false}
                                                          onChange={(newValue) => {
                                                              setEndTime(newValue);
                                                          }}
                                                      />
                                                  </LocalizationProvider>
                                              </ThemeProvider>
                             
                                              </div> 
                                          </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>
                                    </div>

                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label symbol="stop_id">Набор стопов</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id='stop_id'
                                                styles={customStyles}
                                                value={form.stop_id}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, stop_id: value });
                                                    setError({ ...error, stop_id: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.stop_id
                                                    setFormErrors(errorItem); 
                                                }}
                                                options={stop}
                                                classNamePrefix="select2-selection"
                                            />
                                            <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.stop_id}</p>
                                        </div>

                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <div className="mb-3">
                                            <Label symbol="maId">Скользящие средние &nbsp;
                                                <abbr title="MA1 > MA2 > ... > MAn">
                                                    ?
                                                </abbr></Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='maId'
                                                value={form.maId}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, maId: value })
                                                }}
                                                options={ma}
                                                classNamePrefix="select2-selection"
                                            />

                                        </div>

                                    </Col>
                                    <Col xs={12} md={12} xl={12}>
                                        <div className="mb-3">
                                            <Label symbol="scheme_id">Схема</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}                                              
                                                id='scheme_id'
                                                styles={customStyles}
                                                // closeMenuOnSelect={false}
                                                // components={animatedComponents}
                                                // isMulti
                                                value={form.scheme_id}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, scheme_id: value });
                                                    setError({ ...error, scheme_id: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.scheme_id
                                                    setFormErrors(errorItem); 
                                                }}
                                                options={schema}
                                                classNamePrefix="select2-selection"
                                            />
                                             <p style={{'fontSize':'10px','color': '#e06466'}}> {formErrors.scheme_id}</p>
                                        </div>     
                                        <div style={{height: 100}}></div>  
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
               
               
            </Container>

        </div>
    );
}

export default Test1Add;
