import {
    Modal
} from "reactstrap";
import React from "react";

const TBModal = (props) => {
    const {modal, setModal, content, title, contents} = props
    return (
        <>
            <Modal
                size='xs'
                isOpen={modal}
                toggle={() => {
                    setModal(false);
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {title}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {contents[content]()}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            setModal(false);
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Закрыть
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default TBModal;
