import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"

const CardUser = () => {
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Col xl="4">
        <Card>
          <CardBody>

            <div>
              <div className="mb-4 me-3">
                <i className="mdi mdi-account-circle text-primary h1"></i>
              </div>

              <div>
                <h5>Jhon</h5>
                <p className="text-muted mb-1">e.harev@snb.capital</p>
                <p className="text-muted mb-0">Администратор</p>
              </div>
            </div>
          </CardBody>

          <CardBody className="border-top">
            <Row>
              <div className="col-sm-6">
                <div>
                  <p className="fw-medium mb-2">Инвестировано в портфель "USDT"</p>
                  <h4>$ 50 000</h4>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mt-4 mt-sm-0">
                  <p className="fw-medium mb-2">Торгуемые монеты</p>
                  <div className="d-inline-flex align-items-center mt-1">
                    <Link to="#" className="m-1" id="bitcoin">
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-warning bg-soft text-warning font-size-18">
                          <i className="mdi mdi-bitcoin"></i>
                        </span>
                      </div>
                      <UncontrolledTooltip placement="top" target="bitcoin">
                        Bitcoin
                      </UncontrolledTooltip>
                    </Link>
                    <Link to="#" className="m-1" id="ethereum">
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                          <i className="mdi mdi-ethereum"></i>
                        </span>
                      </div>
                      <UncontrolledTooltip placement="top" target="ethereum">
                        Ethereum
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </div>
              </div>
            </Row>
          </CardBody>

          <CardFooter className="bg-transparent border-top">
            <div className="text-center">
              <Link to="/strategy" className="btn btn-outline-light me-2 w-md">
                Активные стратегии
              </Link>{" "}
              <Link to="/history/all" className="btn btn-primary me-2 w-md">
                История сделок
              </Link>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default CardUser
