import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Input,
  Collapse,
  Button,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import classnames from "classnames";
import Open1 from "./Open1";
import Open2 from "./Open2";
import Open3 from "./Open3";
import Open4 from "./Open4";
import Close1 from "./Close1";
import Close2 from "./Close2";
import Close3 from "./Close3";
import Close4 from "./Close4";
import Close5 from "./Close5";
import TBModal from "../../components/Modal";
import axios from "axios";
import config from "../../config";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import selectOptions from "./selectOptions";
import InputSpinner from "react-bootstrap-input-spinner";
import WV from "../../workVariables";

const contents = {
  open1: () => (
    <>
      <div>
        <b>Пример 1:</b> 3 больше 1, процент от: -5%, процент до: 5%
        <br />
        Процент разницы = (Цена закрытия 3 - Цена закрытия 1) * 100 / Цена
        закрытия 3<br />
        -5% &lt;= Процент разницы &lt;= 5%
        <br />
        <br />
        <b>Пример 2:</b> 3 меньше 1, процент от: -1%, процент до: 1%.
        <br />
        Процент разницы = (Цена закрытия 1 - Цена закрытия 3) * 100 / Цена
        закрытия 3<br />
        -1% &lt;= Процент разницы &lt;= 1%
        <br />
        <br />
        <b>
          Процент может быть отрицательный, например, при условии 3 больше 1
          <br />
          значит 3 может быть и меньше 1 на определенный процент
        </b>
      </div>
    </>
  ),
  open2: () => (
    <>
      <div>
        В расчете TIH/TIL не участвует последняя свеча. Если закрылась 3
        "зеленая" и TIH 20,
        <br />
        то считаем 20 свечей назад, начиная с 2 включительно.
        <br />
        TIH/TIL можно считать по open, close, high, low.
        <br />
        Сравниваем тоже по свече open, close, high, low.
        <br />
        <br />
        <br />
        <b>Пример 1:</b> TIH/TIL сравниваем со свечой закрытия (3 "зеленая").
        <br />
        Цена закрытия - 100, TIH - 120
        <br />
        Процент разницы = (100 - 120)/120 = -16,66%
        <br />
        Процент от &lt;= Процент разницы &lt;= Процент до
        <br />
        <br />
        <br />
        <b>Пример 2:</b> TIH/TIL сравниваем с любой свечой, например 1
        "зеленая".
        <br />
        Цена закрытия 1 зеленая - 100, TIH - 120
        <br />
        Процент разницы = (100 - 120)/120 = -16,66%
        <br />
        Процент от &lt;= Процент разницы &lt;= Процент до
        <br />
        <br />
        <br />
        <b>
          Процент может быть отрицательный, значит Цена свечи ниже TIH/TIL или
          положительный - Цена свечи выше TIH/TIL
        </b>
      </div>
    </>
  ),
  open3: () => (
    <>
      <div>
        <b>Пример 1:</b> МА сравниваем со свечой закрытия. Заполняем поле "Свеча
        закрытия. Применяется к цене".
        <br />
        Выбираем МА. В поле "МА от свечи или от цены" можно выбрать ТОЛЬКО "от
        цены".
        <br />
        МА будет расчитано от свечи закрытия, свеча закрытия входит в расчет МА
        <br />
        <br />
        Цена закрытия - 100, МА - 120
        <br />
        Процент разницы = (100 - 120)/120 = -16,66%
        <br />
        Процент от &lt;= Процент разницы &lt;= Процент до
        <br />
        <br />
        <b>Пример 2:</b> МА сравниваем с любой свечой, например 1 "зеленая".
        <br />
        Заполняем поле "Номер свечи", "Цвет свечи", "Применяется к цене".
        <br />
        Выбираем МА. В поле "МА от свечи или от цены" можно выбрать "от цены" -
        тогда МА расчитывается от свечи закрытия, включая ее или "от свечи" -
        цена закрытия расчитывается от 1 "зеленой", включая данную свечу.
        <br />
        <br />
        Цена закрытия 1 зеленая - 100, TIH - 120
        <br />
        Процент разницы = (100 - 120)/120 = -16,66%
        <br />
        Процент от &lt;= Процент разницы &lt;= Процент до
        <br />
        <br />
        <b>
          Процент может быть отрицательный, значит Цена свечи ниже МА или
          положительный - Цена свечи выше МА
        </b>
      </div>
    </>
  ),
  open4: () => (
    <>
      <div>
        <b>RAVI</b> 
      </div>
    </>
  ),
  close1: () => (
    <>
      <div>
        <b>Выбираем "Номер" и "Цвет свечи". </b>
      </div>
    </>
  ),
  close2: () => (
    <>
      <div>
        <b>Выбираем "Цвет свечи". </b>
      </div>
    </>
  ),
  close3: () => (
    <>
      <div>
        МА сравниваем со свечой закрытия (цена - close)
        <br />
        МА будет расчитано от свечи закрытия, свеча закрытия входит в расчет МА
        <br />
        Цена закрытия - 100, МА - 120
        <br />
        Процент разницы = (100 - 120)/120 = -16,66%
        <br />
        Процент от &lt;= Процент разницы &lt;= Процент до
        <br />
        <br />
        <b>
          Процент может быть отрицательный, значит Цена свечи ниже МА или
          положительный - Цена свечи выше МА
        </b>
      </div>
    </>
  ),
  close4: () => (
    <>
      <div>
        В расчете TIH/TIL не участвует последняя свеча. Если закрылась 3
        "зеленая" и TIH 20,
        <br />
        то считаем 20 свечей назад, начиная с 2 включительно.
        <br />
        TIH/TIL сравниваем со свечой закрытия (цена - close)
        <br />
        <br />
        Цена закрытия - 100, TIH - 120
        <br />
        Процент разницы = (100 - 120)/120 = -16,66%
        <br />
        Процент от &lt;= Процент разницы &lt;= Процент до
        <br />
        <br />
        <b>
          Процент может быть отрицательный, значит Цена свечи ниже TIH/TIL или
          положительный - Цена свечи выше TIH/TIL
        </b>
      </div>
    </>
  ),
  close5: () => (
    <>
      <div>
        <b>RAVI</b> 
      </div>
    </>
  ),
};

const SchemaAdd = () => {
  const { id } = useParams();
  const [schema, setSchema] = useState({
    id: id,
    name: "",
    position: null,
    comment: "",
  });
  const [open1, setOpen1] = useState([]);
  const [open2, setOpen2] = useState([]);
  const [open3, setOpen3] = useState([]);
  const [open4, setOpen4] = useState([]);
  const [close1, setClose1] = useState([]);
  const [close2, setClose2] = useState([]);
  const [close3, setClose3] = useState([]);
  const [close4, setClose4] = useState([]);
  const [close5, setClose5] = useState([]);
  const [open1new, setOpen1new] = useState({
    num1: "",
    color1: "",
    condition: "",
    num2: "",
    color2: "",
    percFrom: "",
    percTo: "",
  });
  const [open2new, setOpen2new] = useState({
    closing: "",
    num: "",
    color: "",
    price: "",
    indicatorType: "",
    indicator: "",
    percFrom: "",
    percTo: "",
  });
  const [open3new, setOpen3new] = useState({
    closing: "",
    num: "",
    color: "",
    price: "",
    maId: "",
    ma: "",
    percFrom: "",
    percTo: "",
  });
  const [open4new, setOpen4new] = useState({
    raviId: "",    
  });
  const [close1new, setClose1new] = useState({
    num: "",
    color: "",
  });
  const [close2new, setClose2new] = useState({
    color: "",
  });
  const [close3new, setClose3new] = useState({
    maId: "",
    percFrom: "",
    percTo: "",
  });
  const [close4new, setClose4new] = useState({
    indicatorType: "",
    indicator: "",
    percFrom: "",
    percTo: "",
  });
  const [close5new, setClose5new] = useState({
    raviId: "",    
  });
  const [tihTil, setTihTil] = useState([]);
  const [tihTil1, setTihTil1] = useState([]);
  const [tihTil2, setTihTil2] = useState([]);
  const [errorOpen, setErrorOpen] = useState("");
  const [errorClose, setErrorClose] = useState("");
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("open1");
  const [modalTitle, setModalTitle] = useState("");
  const [col1, setcol1] = useState(false);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false); // открытие Ravi
  const [col9, setcol9] = useState(false); // закрытие Ravi
  const { addToast } = useToasts();
  const history = useHistory();
  const tt1 = [];
  const tt2 = [];


  const [formErrors, setFormErrors] = useState({});
  useEffect(() => {
    let strError = '';
    if (Object.keys(formErrors).length != 0) {
      for (const [key, value] of Object.entries(formErrors)) {
        // console.log(`${key}: ${value}`);
        strError = strError + value;

      }
      addToast(strError, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 7500,
        transitionDuration: 2000
      })
    }
  }, [formErrors]);

  const storeItem = async () => {
    let form = {};
    const errors = {};
    form.name = schema.name
    if (schema.position) form.position = schema.position.value;
    else errors.position = 'Поле "Позиция" обязательное для заполнения! ';
    form.comment = schema.comment

    if (!form.name) errors.name = 'Поле "Название" обязательное для заполнения! ';


    if ((!open1.length) && (!open2.length) && (!open3.length) && (!open4.length))
      errors.open = "Заполните хотя бы одно условие для открытия! ";

    if ((!close1.length) && (!close2.length) && (!close3.length) && (!close4.length) && (!close5.length))
      errors.close = "Заполните хотя бы одно условие для закрытия! ";




    //Open1 
    !open1.length ? form.conditionOpen = null : form.conditionOpen = [];
    open1.forEach((item, i) => {
      let open1Object = {};
      console.log('item');
      console.log(item);
      if ((!item.num1) || (!item.num2) || (!item.color1) || (!item.color2))
        errors.errorsOpen1 = "Заполните все поля для open1! ";

      if (item.color1 === "red") {
        open1Object.buySetupIndex1 = parseInt(item.num1);
        open1Object.sellSetupIndex1 = 0;
      }
      if (item.color1 === "green") {
        open1Object.sellSetupIndex1 = parseInt(item.num1);
        open1Object.buySetupIndex1 = 0;
      }
      open1Object.condition1 = item.condition;

      if (item.color2 === "red") {
        open1Object.buySetupIndex2 = parseInt(item.num2);
        open1Object.sellSetupIndex2 = 0;
      }
      if (item.color2 === "green") {
        open1Object.sellSetupIndex2 = parseInt(item.num2);
        open1Object.buySetupIndex2 = 0;
      }

      if ((item.percFrom) || (item.percFrom === 0)) open1Object.percent = parseFloat(item.percFrom);
      else open1Object.percent = null;
      if ((item.percTo) || (item.percTo === 0)) open1Object.percent_to = parseFloat(item.percTo);
      else open1Object.percent_to = null;
      for (const [key, value] of Object.entries(open1Object)) {
        // console.log(`${key}: ${value}`);
        if ((value === "") || (value == null)) errors.errorsOpen1 = "Заполните все поля для open1! ";
      }
      form.conditionOpen.push(open1Object);
    });
    let k = 0;

    // Open2
    !open2.length ? form.conditionOpen2 = null : form.conditionOpen2 = [];
    console.log('open22222222');
    console.log(open2);
    open2.forEach((item, i) => {
      let open2Object = {};
      console.log('item2');
      console.log(item);
      if ((item.item === 'noprice') && (item.num === "") && (item.color == ''))
        errors.errorsOpen2 = "Заполните все поля для open2 1! "

      if (item.num === "") open2Object.item = "price";
      else open2Object.item = parseInt(item.num);
      if (item.price !== "") open2Object.value = item.price;
      if (item.closing !== "") open2Object.value = item.closing;
      if (item.color == 'red') open2Object.value_s = 'buySetupIndex';
      if (item.color == 'green') open2Object.value_s = 'sellSetupIndex';
      if (item.color == '') open2Object.value_s = null;
      if (item.indicator) open2Object.tih_til_id = parseInt(item.indicator);
      else open2Object.tih_til_id = null;
      if ((item.percFrom) || (item.percFrom === 0)) open2Object.perc_from = parseFloat(item.percFrom);
      else open2Object.perc_from = null;
      if ((item.percTo) || (item.percTo === 0)) open2Object.perc_to = parseFloat(item.percTo);
      else open2Object.perc_to = null;

      for (const [key, value] of Object.entries(open2Object)) {
        // console.log(`${key}: ${value}`);        
        // if (((value === "") || (value === null) || (!value)) && (key !== 'value_s')) errors.errorsOpen2 = "Заполните все поля для open2 2! ";
        // исправила 28.03.23 нужно тестить
        if (((value === "") || (value === null)) && (key !== 'value_s')) errors.errorsOpen2 = "Заполните все поля для open2 2! ";
      }
      if (typeof open2Object.value == 'undefined') errors.errorsOpen2 = "Заполните все поля для open2 3! ";
      if ((open2Object.value_s === 'sellSetupIndex' || open2Object.value_s === 'buySetupIndex') && (open2Object.item === "price"))
        errors.errorsOpen2 = "Заполните все поля для open2 4! "


      form.conditionOpen2.push(open2Object);
    });

    // Open3
    !open3.length ? form.conditionOpen3 = null : form.conditionOpen3 = [];
    open3.forEach((item, i) => {
      let open3Object = {};
      if (item.num == '') open3Object.item = "price"
      else open3Object.item = parseInt(item.num)
      if (item.closing != '') {
        open3Object.value = item.closing;
        open3Object.value_s = null;
      } else {
        open3Object.value = item.price;
        if (item.color == 'red') open3Object.value_s = 'buySetupIndex';
        if (item.color == 'green') open3Object.value_s = 'sellSetupIndex';
      }
      open3Object.value_ma = item.ma;

      open3Object.ma_id = parseInt(item.maId);
      if ((item.percFrom) || (item.percFrom === 0)) open3Object.perc_from = parseFloat(item.percFrom);
      else open3Object.perc_from = null;
      if ((item.percTo) || (item.percTo === 0)) open3Object.perc_to = parseFloat(item.percTo);
      else open3Object.perc_to = null;
      for (const [key, value] of Object.entries(open3Object)) {
        // console.log(`${key}: ${value}`);        
        if (((value === "") || (value === null)) && (key !== 'value_s')) errors.errorsOpen3 = "Заполните все поля для open3! ";
      }
      if (typeof open3Object.value_s === 'undefined') errors.errorsOpen3 = "Заполните все поля для open3! ";
      if ((open3Object.value_ma === 'candle') && (open3Object.item === "price"))
        errors.errorsOpen3 = "Заполните все поля для open3! "
      if ((open3Object.value_s === 'sellSetupIndex' || open3Object.value_s === 'buySetupIndex') && (open3Object.item === "price"))
        errors.errorsOpen3 = "Заполните все поля для open2! "

      form.conditionOpen3.push(open3Object)
    });

    // Open4
    !open4.length ? form.conditionOpen4 = null : form.conditionOpen4 = open4[0].raviId;
    
    // Close1
    !close1.length ? form.conditionClose1 = null : form.conditionClose1 = [];
    close1.forEach((item, i) => {
      let close1Object = {};
      if ((!item.color) || (!item.num))
        errors.errorsClose1 = "Заполните поле для close1! ";
      if (item.color == 'red') {
        close1Object.buySetupIndex1 = parseInt(item.num);
        close1Object.sellSetupIndex1 = 0
      } else {
        close1Object.sellSetupIndex1 = parseInt(item.num);
        close1Object.buySetupIndex1 = 0
      }
      form.conditionClose1.push(close1Object)
    });

    // Close2
    !close2.length ? form.conditionClose2 = null : form.conditionClose2 = [];
    close2.forEach((item, i) => {
      let close2Object = {};
      if (!item.color)
        errors.errorsClose2 = "Заполните поле для close2! ";
      //сохраняем наоборот, так нужно по логике приложения
      (item.color == 'green') ? close2Object.index = 'buySetupIndex' : close2Object.index = 'sellSetupIndex';
      form.conditionClose2.push(close2Object)
    });

    // Close3
    !close3.length ? form.conditionClose3 = null : form.conditionClose3 = [];
    close3.forEach((item, i) => {
      let close3Object = {};
      if (item.maId) close3Object.ma_id = parseInt(item.maId);
      else close3Object.ma_id = null;
      if ((item.percFrom) || (item.percFrom === 0)) close3Object.perc_from = parseFloat(item.percFrom);
      else close3Object.perc_from = null;
      if ((item.percTo) || (item.percTo === 0)) close3Object.perc_to = parseFloat(item.percTo);
      else close3Object.perc_to = null;
      for (const [key, value] of Object.entries(close3Object)) {
        if ((value === "") || (value == null)) errors.errorsClose3 = "Заполните все поля для close3! ";
      }
      form.conditionClose3.push(close3Object)
    });

    // Close4
    !close4.length ? form.conditionClose4 = null : form.conditionClose4 = [];
    close4.forEach((item, i) => {
      let close4Object = {};
      if (item.indicator) close4Object.tih_til_id = parseInt(item.indicator);
      else close4Object.tih_til_id = null;
      if ((item.percFrom) || (item.percFrom === 0)) close4Object.perc_from = parseFloat(item.percFrom);
      else close4Object.perc_from = null;
      if ((item.percTo) || (item.percTo === 0)) close4Object.perc_to = parseFloat(item.percTo);
      else close4Object.perc_to = null;
      for (const [key, value] of Object.entries(close4Object)) {
        if ((value === "") || (value == null)) errors.errorsClose4 = "Заполните все поля для close4! ";
      }
      form.conditionClose4.push(close4Object)
    });

    // Close5
    !close5.length ? form.conditionClose5 = null : form.conditionClose5 = close5[0].raviId;

    if (Object.keys(errors).length != 0) {
      setFormErrors(errors);
      return;
    }


    axios
      .post(`${config.apiUrl}/schemas-store`, form)
      .then((response) => {
        history.goBack();
        setTimeout(() => {
          addToast(response.data.msg, {
            appearance: response.data.res.toLowerCase(),
            autoDismiss: true,
            autoDismissTimeout: 7500,
            transitionDuration: 2000,
          });
        }, 300);

        console.log(response.data.msg);
        if (response.data.res === "Success") {
          console.log("OK");

          // <Redirect to="/account" />
        }
      })
      .catch((error) => {
        console.error(error);
      });

  }
  const changeOrder = (arr, dir, i, f) => {
    if (dir === "up") [arr[i], arr[i - 1]] = [arr[i - 1], arr[i]];
    else[arr[i], arr[i + 1]] = [arr[i + 1], arr[i]];
    f([...arr]);
  };

  const removeItem = (arr, i, f) => {
    arr.splice(i, 1);
    f([...arr]);
  };
  const addItem = (arr, obj, f) => {
    arr.push(obj);
    f([...arr]);
  };
  // условия открытия
  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol8(false);
  };
  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
    setcol8(false);
  };
  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
    setcol8(false);
  };
  const t_col8 = () => {
    setcol8(!col8);
    setcol1(false);
    setcol2(false);
    setcol3(false);
  };
  // условия закрытия
  const t_col4 = () => {
    setcol4(!col4);
    setcol5(false);
    setcol6(false);
    setcol7(false);
    setcol9(false);
  };
  const t_col5 = () => {
    setcol5(!col5);
    setcol4(false);
    setcol6(false);
    setcol7(false);
    setcol9(false);
  };
  const t_col6 = () => {
    setcol6(!col6);
    setcol5(false);
    setcol4(false);
    setcol7(false);
    setcol9(false);
  };
  const t_col7 = () => {
    setcol7(!col7);
    setcol5(false);
    setcol6(false);
    setcol4(false);
    setcol9(false);
  };
  const t_col9 = () => {
    setcol9(!col9);
    setcol5(false);
    setcol6(false);
    setcol4(false);
    setcol7(false);
  };
  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`
    }),
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Новая Схема | TradeBot</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className={"mb-3"}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Добавление Схемы</h4>
                    <div className='d-flex gap-2 align-items-center'>
                      <Button color="primary" onClick={storeItem}>
                        Сохранить
                      </Button>
                      <Link to="/schemas" className="btn btn-danger">
                        <i className="mdi mdi-backspace" />
                        &nbsp; Вернуться
                      </Link>
                    </div>
                  </div>
                </CardTitle>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="name">Название</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="название схемы..."
                        // required
                        id="name"
                        value={schema.name}
                        onChange={(e) => {
                          setSchema({ ...schema, name: e.target.value });
                        }}
                      />
                    </div>
                    <div>
                      <Label htmlFor="position">Позиция</Label>
                      <Select
                        id="position"
                        value={schema.position}
                        placeholder="выбрать..."
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        onChange={(value) => {
                          setSchema({ ...schema, position: value });
                        }}
                        options={[
                          { label: "Short", value: "Short" },
                          { label: "Long", value: "Long" },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={8}>
                    <div className="mb-3">
                      <Label htmlFor="comment">Комментарии</Label>
                      <Input
                        type="textarea"
                        style={{ resize: "none", height: "7.4rem" }}
                        className="form-control"
                        placeholder="..."
                        id="comment"
                        value={schema.comment}
                        onChange={(e) => {
                          setSchema({ ...schema, comment: e.target.value });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <h5 className={"text-success fw-bold"}>Условия открытия сделок</h5>
                </CardTitle>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col1,
                        })}
                        type="button"
                        onClick={t_col1}
                        style={{ cursor: "pointer" }}
                      >
                        Open 1
                        <span className="px-3 fw-normal">
                          С использованием Номера и Цвета свечи
                          {open1 && open1.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {open1.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("open1");
                          setModalTitle(
                            "С использованием Номера и Цвета свечи"
                          );
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col1} className="accordion-collapse">
                      <div className="accordion-body">
                        {open1 &&
                          open1.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Open1
                                open1={item}
                                setOpen1={(v) => {
                                  let tmp = open1;
                                  tmp[index] = v;
                                  setOpen1([...tmp]);
                                }}
                              />
                              <div className="btn-group ms-3 mt-4">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    changeOrder(open1, "up", index, setOpen1)
                                  }
                                  disabled={index === 0}
                                >
                                  ↑
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    changeOrder(open1, "down", index, setOpen1)
                                  }
                                  disabled={index === open1.length - 1}
                                >
                                  ↓
                                </button>
                              </div>
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(open1, index, setOpen1)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                              <hr className="m-2" />
                            </div>
                          ))}
                        <CardTitle>
                          <h5 className={"text-success fw-bold mt-3"}>
                            Добавить Open 1
                          </h5>
                        </CardTitle>
                        <div
                          className="d-flex align-items-center"
                          style={{
                            width: "calc(100% - 100px)",
                          }}
                        >
                          <Open1 open1={open1new} setOpen1={setOpen1new} />
                          <div className="btn-group ms-3 mt-1">
                            <button
                              className="btn btn-success"
                              onClick={() => addItem(open1, open1new, setOpen1)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col2,
                        })}
                        type="button"
                        onClick={t_col2}
                        style={{ cursor: "pointer" }}
                      >
                        Open 2
                        <span className="px-3 fw-normal">
                          С использованием Индикаторов TIH / TIL
                          {open2 && open2.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {open2.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("open2");
                          setModalTitle(
                            "С использованием Индикаторов TIH / TIL"
                          );
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col2} className="accordion-collapse">
                      <div className="accordion-body">
                        {open2 &&
                          open2.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Open2
                                open2={item}
                                setOpen2={(v) => {
                                  let tmp = open2;
                                  tmp[index] = v;
                                  setOpen2([...tmp]);
                                }}
                              />
                              {/* <div className="btn-group ms-4 mt-4">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    changeOrder(open2, "up", index, setOpen2)
                                  }
                                  disabled={index === 0}
                                >
                                  ↑
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    changeOrder(open2, "down", index, setOpen2)
                                  }
                                  disabled={index === open2.length - 1}
                                >
                                  ↓
                                </button>
                              </div> */}
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(open2, index, setOpen2)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                              {/* <div style={{height: 100}}></div>   */}
                            </div>
                          ))}
                        <CardTitle>
                          <h5 className={"text-success fw-bold mt-5"}>
                            Добавить Open 2
                          </h5>
                        </CardTitle>
                        <div className="d-flex align-items-start">
                          <Open2 open2={open2new} setOpen2={setOpen2new} />
                          <div className="btn-group ms-2 mt-4">
                            <button
                              className="btn btn-success"
                              onClick={() => addItem(open2, open2new, setOpen2)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col3,
                        })}
                        type="button"
                        onClick={t_col3}
                        style={{ cursor: "pointer" }}
                      >
                        Open 3
                        <span className="px-3 fw-normal">
                          С использованием MA
                          {open3 && open3.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {open3.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("open3");
                          setModalTitle("С использованием MA");
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col3} className="accordion-collapse">
                      <div className="accordion-body">
                        {open3 &&
                          open3.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Open3
                                open3={item}
                                setOpen3={(v) => {
                                  let tmp = open3;
                                  tmp[index] = v;
                                  setOpen3([...tmp]);
                                }}
                              />
                              {/* <div className="btn-group ms-4 mt-4">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    changeOrder(open3, "up", index, setOpen3)
                                  }
                                  disabled={index === 0}
                                >
                                  ↑
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    changeOrder(open3, "down", index, setOpen3)
                                  }
                                  disabled={index === open3.length - 1}
                                >
                                  ↓
                                </button>
                              </div> */}
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(open3, index, setOpen3)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          ))}
                        <CardTitle>
                          <h5 className={"text-success fw-bold mt-5"}>
                            Добавить Open 3
                          </h5>
                        </CardTitle>
                        <div className="d-flex align-items-start">
                          <Open3 open3={open3new} setOpen3={setOpen3new} />
                          <div className="btn-group ms-2 mt-4">
                            <button
                              className="btn btn-success"
                              onClick={() => addItem(open3, open3new, setOpen3)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col8,
                        })}
                        type="button"
                        onClick={t_col8}
                        style={{ cursor: "pointer" }}
                      >
                        Open 4
                        <span className="px-3 fw-normal">
                          С использованием RAVI
                          {open4 && open4.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {open4.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("open4");
                          setModalTitle("С использованием RAVI");
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col8} className="accordion-collapse">
                      <div className="accordion-body">
                        {open4 &&
                          open4.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Open4
                                open4={item}
                                setOpen4={(v) => {
                                  let tmp = open4;
                                  tmp[index] = v;
                                  setOpen4([...tmp]);
                                }}
                              />
                              {/* <div className="btn-group ms-4 mt-4">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    changeOrder(open3, "up", index, setOpen3)
                                  }
                                  disabled={index === 0}
                                >
                                  ↑
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    changeOrder(open3, "down", index, setOpen3)
                                  }
                                  disabled={index === open3.length - 1}
                                >
                                  ↓
                                </button>
                              </div> */}
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(open4, index, setOpen4)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          ))}
                          {(open4.length == 0) &&
                          <div><CardTitle>
                            <h5 className={"text-success fw-bold mt-3"}>
                              Добавить Open 4
                            </h5>
                          </CardTitle>
                            <div className="d-flex align-items-center">
                              <Open4 open4={open4new} setOpen4={setOpen4new} />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-success"
                                  onClick={() => addItem(open4, open4new, setOpen4)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>}                        
                      </div>
                    </Collapse>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <h5 className={"text-danger fw-bold"}>
                    Условия закрытия сделок
                  </h5>
                </CardTitle>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col4,
                        })}
                        type="button"
                        onClick={t_col4}
                        style={{ cursor: "pointer" }}
                      >
                        Close 1
                        <span className="px-3 fw-normal">
                          С использованием Номера и Цвета свечи
                          {close1 && close1.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {close1.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("close1");
                          setModalTitle(
                            "С использованием Номера и Цвета свечи"
                          );
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col4} className="accordion-collapse">
                      <div className="accordion-body">
                        {close1 &&
                          close1.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Close1
                                close1={item}
                                setClose1={(v) => {
                                  let tmp = close1;
                                  tmp[index] = v;
                                  setClose1([...tmp]);
                                }}
                              />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(close1, index, setClose1)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          ))}
                        {(close1.length == 0) &&
                          <div><CardTitle>
                            <h5 className={"text-success fw-bold mt-3"}>
                              Добавить Close 1
                            </h5>
                          </CardTitle>
                            <div className="d-flex align-items-center">
                              <Close1 close1={close1new} setClose1={setClose1new} />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-success"
                                  onClick={() => addItem(close1, close1new, setClose1)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>}

                      </div>
                    </Collapse>

                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col5,
                        })}
                        type="button"
                        onClick={t_col5}
                        style={{ cursor: "pointer" }}
                      >
                        Close 2
                        <span className="px-3 fw-normal">
                          С использованием Цвета свечи
                          {close2 && close2.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {close2.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("close2");
                          setModalTitle("С использованием Цвета свечи");
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col5} className="accordion-collapse">
                      <div className="accordion-body">
                        {close2 &&
                          close2.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Close2
                                close2={item}
                                setClose2={(v) => {
                                  let tmp = close2;
                                  tmp[index] = v;
                                  setClose2([...tmp]);
                                }}
                              />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(close2, index, setClose2)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          ))}
                        {(close2.length == 0) &&
                          <div><CardTitle>
                            <h5 className={"text-success fw-bold mt-3"}>
                              Добавить Close 2
                            </h5>
                          </CardTitle>
                            <div className="d-flex align-items-center">
                              <Close2 close2={close2new} setClose2={setClose2new} />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-success"
                                  onClick={() => addItem(close2, close2new, setClose2)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>}

                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col6,
                        })}
                        type="button"
                        onClick={t_col6}
                        style={{ cursor: "pointer" }}
                      >
                        Close 3
                        <span className="px-3 fw-normal">
                          С использованием MA
                          {close3 && close3.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {close3.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("close3");
                          setModalTitle("С использованием MA");
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col6} className="accordion-collapse">
                      <div className="accordion-body">
                        {close3 &&
                          close3.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Close3
                                close3={item}
                                setClose3={(v) => {
                                  let tmp = close3;
                                  tmp[index] = v;
                                  setClose3([...tmp]);
                                }}
                              />
                               <div
                                className="btn-group ms-2"
                                style={{ marginTop: 40 }}
                              >
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(close3, index, setClose3)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          ))}
                        {(close3.length == 0) &&
                          <div><CardTitle>
                            <h5 className={"text-success fw-bold mt-3"}>
                              Добавить Close 3
                            </h5>
                          </CardTitle>
                            <div className="d-flex align-items-center">
                              <Close3 close3={close3new} setClose3={setClose3new} />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-success"
                                  onClick={() => addItem(close3, close3new, setClose3)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>}

                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col7,
                        })}
                        type="button"
                        onClick={t_col7}
                        style={{ cursor: "pointer" }}
                      >
                        Close 4
                        <span className="px-3 fw-normal">
                          С использованием Индикаторов TIH / TIL
                          {close4 && close4.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {close4.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("close4");
                          setModalTitle(
                            "С использованием Индикаторов TIH / TIL"
                          );
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col7} className="accordion-collapse">
                      <div className="accordion-body">
                        {close4 &&
                          close4.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Close4
                                close4={item}
                                setClose4={(v) => {
                                  let tmp = close4;
                                  tmp[index] = v;
                                  setClose4([...tmp]);
                                }}
                              />
                             <div
                                className="btn-group ms-2"
                                style={{ marginTop: 40 }}
                              >
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(close4, index, setClose4)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          ))}
                        {(close4.length == 0) &&
                          <div><CardTitle>
                            <h5 className={"text-success fw-bold mt-3"}>
                              Добавить Close 4
                            </h5>
                          </CardTitle>
                            <div className="d-flex align-items-center">
                              <Close4 close4={close4new} setClose4={setClose4new} />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-success"
                                  onClick={() => addItem(close4, close4new, setClose4)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>}

                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header d-flex" id="headingOne">
                      <button
                        className={classnames("accordion-button", "fw-bold", {
                          collapsed: !col9,
                        })}
                        type="button"
                        onClick={t_col9}
                        style={{ cursor: "pointer" }}
                      >
                        Close 5
                        <span className="px-3 fw-normal">
                          С использованием RAVI
                          {close5 && close5.length > 0 && (
                            <span className="ms-3">
                              (Добавлено: {close5.length})
                            </span>
                          )}
                        </span>
                      </button>
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          setModal(true);
                          setModalContent("close5");
                          setModalTitle("С использованием RAVI");
                        }}
                      >
                        <i className="bx bx-info-circle"></i>
                      </button>
                    </h2>
                    <Collapse isOpen={col9} className="accordion-collapse">
                      <div className="accordion-body">
                        {close5 &&
                          close5.map((item, index) => (
                            <div
                              className="d-flex align-items-start mb-4 pb-4 border-bottom"
                              key={index}
                            >
                              <Close5
                                close5={item}
                                setClose5={(v) => {
                                  let tmp = close5;
                                  tmp[index] = v;
                                  setClose5([...tmp]);
                                }}
                              />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    removeItem(close5, index, setClose5)
                                  }
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          ))}
                        {(close5.length == 0) &&
                          <div><CardTitle>
                            <h5 className={"text-success fw-bold mt-3"}>
                              Добавить Close 5
                            </h5>
                          </CardTitle>
                            <div className="d-flex align-items-center">
                              <Close5 close5={close5new} setClose5={setClose5new} />
                              <div className="btn-group ms-2 mt-4">
                                <button
                                  className="btn btn-success"
                                  onClick={() => addItem(close5, close5new, setClose5)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>}

                      </div>
                    </Collapse>
                  </div>
                </div>
              </CardBody>
            </Card>
            {/* <Card>
              <CardBody>
                <Row>
                  <Col>
                    <Button color="primary" onClick={storeItem}>
                      Сохранить
                    </Button>
                    {errorOpen.length > 0 && (
                      <div className="mt-2">{errorOpen}</div>
                    )}
                    {errorClose.length > 0 && (
                      <div className="mt-2">{errorClose}</div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card> */}
          </Col>
        </Row>
      </Container>
      <TBModal
        modal={modal}
        setModal={setModal}
        content={modalContent}
        contents={contents}
        title={modalTitle}
      />
    </div>
  );
};

export default SchemaAdd;
