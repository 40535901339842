import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    Table
} from "reactstrap"
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useToasts } from "react-toast-notifications";
import makeAnimated from 'react-select/animated';
import Select from "react-select"
import axios from "axios";
import LookButtons from '../../../components/LookButtons';
import config from "../../../config";
import WV from '../../../workVariables';
const animatedComponents = makeAnimated();

const StrategyEdit = () => {
    const { addToast } = useToasts()
    const { id } = useParams()
    const [schemes, setSchemes] = useState([]);
    const [selectScheme, setSelectScheme] = useState([])
    const [newSelect, setNewSelect] = useState(false)
    const [form, setForm] = useState({
        name: '',
        name_user: '',
        trade_schemes: '',
        comment: '',
        info: [],
    })
    function Structure() {
        let structure = form.info
        console.log('structure');
        console.log(structure);
        return (
            <Row>
                <Col className="col-12">
                    <table className="table align-middle table-nowrap">
                        <thead>
                            <tr>
                                <th>Торговые схемы Стратегии</th>
                                <th style={{ width: 11 }}></th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr>                                  
                                    
                                    <td>
                                        <ul>
                                            {structure.map((el) =>
                                                <li style={{ listStyle: "none", marginLeft: "25px", margin: "0" }}>
                                                    <LookButtons
                                                        url='/trade/show/'
                                                        id={el.id}
                                                        title='Посмотреть торговую схему'
                                                        lable={el.name}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </td>                                    
                                </tr>                           
                        </tbody>
                    </table>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        const getStrategy = async () => {
                axios.get(`${config.apiUrl}/ip-strategy-show/` + id)
                .then(async (response) => {
                    
                    let arrayInfo = response.data.trades;
       
        let arr2 = [];
        let itemObject = {};
        for (let i = 0; i < arrayInfo.length; i++) {
            itemObject.id = arrayInfo[i].value;
            itemObject.name = arrayInfo[i].label;  
            arr2.push(itemObject);
            itemObject = {};
        }       
        setForm({
            id: response.data.id,
            name: response.data.name,
            name_user: response.data.name_user,
            trade_schemes: response.data.trades,
            comment: response.data.comment,
            info: arr2 
        })     
         
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStrategy()

        const getSchemes = async () => {
            await axios
                .get(`${config.apiUrl}/trade-schemes`)
                .then(async (response) => {
                    let arraySchema = response.data;
                    for (let i = 0; i < arraySchema.length; i++) {
                        arraySchema[i].value = arraySchema[i].id;
                        arraySchema[i].label = arraySchema[i].name;
                        delete arraySchema[i].id;
                        delete arraySchema[i].name
                    }
                   
                    setSchemes(arraySchema);
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        getSchemes();
    }, [id])
   
    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color: `${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    console.log('arrayInfo');
    console.log(form);   
    return (
        <div className="page-content">
            <MetaTags>
                <title>Торговые стратегии | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                    <h4 className='mb-0'>Торговая стратегия #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Link to={`/strategy/edit/${id}`} className='btn btn-secondary me-2' title='Редактировать'>
                                                <i className='mdi mdi-pencil' />
                                                &nbsp;
                                                Редактировать
                                            </Link>
                                            <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Администратора</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name="name"
                                                readOnly={true}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название для Пользователя</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name_user"
                                                value={form.name_user}
                                                name="name_user"
                                                readOnly={true}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                    <div className="mb-3">
                                            <Label htmlFor="comment">Комментарий</Label>
                                            <Input
                                                type="textarea"
                                                style={{resize: 'none', height: '7.4rem'}}
                                                className="form-control"
                                                placeholder='Комментарий...'
                                                id="comment"
                                                name="comment"
                                                value={form.comment}
                                                readOnly={true}
                                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>

                                    </div>
                                </CardTitle>

                                {/* <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="scheme_id">Подключить торговую схему</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                id="scheme_id"
                                                name="scheme_id"
                                                placeholder="выбрать..."
                                                styles={customStyles}                                                
                                                onChange={(value) => {
                                                    setForm({ ...form, trade_schemes: value })
                                                }}
                                                value={form.trade_schemes}
                                                options={schemes}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                isDisabled
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>                                   
                                </Row> */}
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className="mb-3">
                                            <Structure />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default StrategyEdit