import React from "react";
import MetaTags from "react-meta-tags"
import { Col, Container, Row } from "reactstrap";

import CardUser from "../components/dashboard/card-user"
import CardWelcome from "../components/dashboard/card-welcome"
import MiniWidget from "../components/dashboard/mini-widget"

import SocialSource from "../components/dashboard/SocialSource"
import ActivityComp from "../components/dashboard/ActivityComp"
import TopCities from "../components/dashboard/Report"
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import WF from '../workFunctions'

const Dashboard = () => {

    const [price_1, setPrice_1] = useState({
        price: '- - -',
        change: ' - ',
        perc: ' - ',
        arrow: ''
    })
    const [price_2, setPrice_2] = useState({
        price: '- - -',
        change: ' - ',
        perc: ' - ',
        arrow: ''
    })
    const [price_3, setPrice_3] = useState({
        price: '- - -',
        change: ' - ',
        perc: ' - ',
        arrow: ''
    })

    let ws_1;

    useEffect(() => {
        ws_1 = new WebSocket('wss://stream.binance.com:9443/ws/btcusdt@ticker/ethusdt@ticker/ltcusdt@ticker')
        ws_1.onmessage = async (event) => {
            let res = await WF.workWithPrice(event.data)
            // console.log(res);

            switch (res.symb) {
                case "BTCUSDT":
                    if (res.price != price_1) {
                        setPrice_1({
                            price: res.price,
                            change: res.change,
                            perc: res.perc,
                            arrow: res.arrow,
                        })
                    }
                    break;
                case "ETHUSDT":
                    if (res.price != price_2) {
                        setPrice_2({
                            price: res.price,
                            change: res.change,
                            perc: res.perc,
                            arrow: res.arrow,
                        })
                    }
                    break;
                case "LTCUSDT":
                    if (res.price != price_3) {
                        setPrice_3({
                            price: res.price,
                            change: res.change,
                            perc: res.perc,
                            arrow: res.arrow,
                        })
                    }
                    break;
                default:
                    break;
            }
        }
        return () => {
            ws_1.close()
        }
    }, [])
    let [array1, setArray1] = useState([]);
    let [array2, setArray2] = useState([]);
    let [array3, setArray3] = useState([]);

    useEffect(() => {
        const getData = async (symbol) => {
            let nowTime = moment().utc().format();
            let startTime = moment(nowTime).utc().valueOf();
            const endTimeLong = moment(startTime).subtract(12, 'hours').utc().valueOf();
            let arrayItem1 = [];
            axios.get(`https://api.binance.com/api/v1/klines?startTime=`
                + endTimeLong + `&endTime=` + startTime + `&interval=1h&symbol=` + symbol, {
                transformRequest: (data, headers) => {
                    delete headers.common['Authorization'];
                }
            })
                .then((response) => {
                    let arrayItem = response.data;
                    let arrayItem1 = [];
                    arrayItem.map(a => (
                        arrayItem1.push(parseFloat(a[4]))
                    ));

                    switch (symbol) {
                        case 'BTCUSDT':
                            setArray1(arrayItem1);
                            break;
                        case 'ETHUSDT':
                            setArray2(arrayItem1);
                            break;
                        case 'LTCUSDT':
                            setArray3(arrayItem1);
                            break;

                        default:
                            break;
                    }
                })
                .catch(error => {
                    console.error(error);
                })
            return arrayItem1
        }
        getData('BTCUSDT')
        getData('ETHUSDT')
        getData('LTCUSDT')
    }, [])

    //Bitcoin Chart
    const series1 = [
        { name: "BTC", data: array1 },
    ]
    const options1 = {
        chart: { sparkline: { enabled: !0 } },
        stroke: { curve: "smooth", width: 2 },
        colors: ["#f1b44c"],
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: !1,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [25, 100, 100, 100],
            },
        },
        tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
    }

    //Etherium Chart
    const series2 = [
        { name: "ETH", data: array2 },
    ]
    const options2 = {
        chart: { sparkline: { enabled: !0 } },
        stroke: { curve: "smooth", width: 2 },
        colors: ["#3452e1"],
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: !1,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [25, 100, 100, 100],
            },
        },
        tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
    }

    //LiteCoin Chart
    const series3 = [
        { name: "LTC", data: array3 },
    ]
    const options3 = {
        chart: { sparkline: { enabled: !0 } },
        stroke: { curve: "smooth", width: 2 },
        colors: ["#50a5f1"],
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: !1,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [25, 100, 100, 100],
            },
        },
        tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
    }



    const reports = [
        {
            title: "Bitcoin",
            icon: "mdi mdi-bitcoin",
            color: "warning",
            value: "$ " + price_1.price,
            desc: price_1.change + " ( " + price_1.perc + " % )",
            series: series1,
            options: options1,
            arrowUpDown: 'mdi ms-1 ' + price_1.arrow
        },
        {
            title: "Ethereum",
            icon: "mdi mdi-ethereum",
            color: "primary",
            value: "$ " + price_2.price,
            desc: price_2.change + " ( " + price_2.perc + " % )",
            series: series2,
            options: options2,
            arrowUpDown: 'mdi ms-1 ' + price_2.arrow
        },
        {
            title: "Litecoin",
            icon: "mdi mdi-litecoin",
            color: "info",
            value: "$ " + price_3.price,
            desc: price_3.change + " ( " + price_3.perc + " % )",
            series: series3,
            options: options3,
            arrowUpDown: 'mdi ms-1 ' + price_3.arrow
        },
    ]

    return (
        <div className="page-content main-page-style">
            <MetaTags>
                <title>Главная | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <CardUser />

                    <Col xl="8">
                        <CardWelcome />

                        <Row>
                            <MiniWidget reports={reports} />
                        </Row>
                    </Col>
                </Row>


                <Row>
                    <Col xl="4">
                        <SocialSource />
                    </Col>
                    <Col xl="4">
                        <ActivityComp />
                    </Col>
                    <Col xl="4">
                        <TopCities />
                    </Col>
                </Row>



            </Container>
        </div>
    )
}

export default Dashboard
