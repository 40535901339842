import MetaTags from "react-meta-tags";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";

export default function TelegramAdd() {
  const { addToast } = useToasts();

  const [form, setForm] = useState({
    name: "",
    number: null,
    numb_order: null,
  });
  const [error, setError] = useState({
    name: false,
    number: false,
    numb_order: false,
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(form));
    setIsSubmit(true);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = "поле обязательное для заполнения";
    if (!values.number) errors.number = "поле обязательное для заполнения";
    if (!values.numb_order)
      errors.numb_order = "поле обязательное для заполнения";
    return errors;
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      axios
        .post(`${config.apiUrl}/chats-store`, form)
        .then((response) => {
          if (response.data.res === "Success") {
            history.goBack()
                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })

                }, 1000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [formErrors]);

  const history = useHistory();
  let errorItem;
  return (
    <div className="page-content">
      <MetaTags>
        <title>Новая Группа | TradeBot</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className={"mb-3"}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Новая Доп. телеграмм группа</h4>
                    <div className='d-flex gap-2 align-items-center'>
                    <Button color="primary" onClick={handleSubmit}>
                      Сохранить
                    </Button>
                    <button
                      className="btn btn-danger"
                      onClick={history.goBack}
                      title="Отмена"
                    >
                      <i className="mdi mdi-backspace" />
                      &nbsp; Вернуться
                    </button>

                    </div>                    
                  </div>
                </CardTitle>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="name">Название</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="..."
                        id="name"
                        value={form.name}
                        name="name"
                        invalid={
                          (form.name.length === 0 && error.name) ||
                          formErrors.name
                        }
                        // onChange={handleChange}
                        onChange={(e) => {
                          setForm({ ...form, [e.target.name]: e.target.value });
                          setError({ ...error, name: true })
                          errorItem = formErrors;
                          delete errorItem.name
                          setFormErrors(errorItem);
                      }}
                      />
                      <FormFeedback invalid>
                        {formErrors.name
                          ? formErrors.name
                          : "это поле обязательное"}
                      </FormFeedback>
                    </div>
                    {/* <p>{formErrors.name}</p> */}
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="number">ID группы</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="..."
                        id="number"
                        value={form.number}
                        name="number"
                        // invalid={!/^[\d-]+$/.test(form.number) && error.number}
                        invalid={
                          (!/^[0-9-]+$/.test(form.number) && error.number) ||
                          formErrors.number
                        }
                        // onChange={handleChange}
                        onChange={(e) => {
                          setForm({ ...form, [e.target.name]: e.target.value });
                          setError({ ...error, number: true })
                          errorItem = formErrors;
                          delete errorItem.number
                          setFormErrors(errorItem);
                      }}
                        
                      />
                      <FormFeedback invalid>
                        {formErrors.number
                          ? formErrors.number
                          : "некорректный номер"}
                      </FormFeedback>
                    </div>
                    {/* <p className="invalid">{formErrors.number}</p> */}
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="numb_order">
                        Начать нумерацию сделок с...
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="..."
                        id="numb_order"
                        value={form.numb_order}
                        name="numb_order"
                        // pattern="/\d+/"
                        invalid={
                          (!/^\d+$/.test(form.numb_order) &&
                            error.numb_order) ||
                          formErrors.numb_order
                        }
                        // onChange={handleChange}
                        onChange={(e) => {
                          setForm({ ...form, [e.target.name]: e.target.value.replace(/\D/, "") });
                          setError({ ...error, numb_order: true })
                          errorItem = formErrors;
                          delete errorItem.numb_order
                          setFormErrors(errorItem);
                      }}
                      />
                      <FormFeedback invalid>
                        {formErrors.numb_order
                          ? formErrors.numb_order
                          : "только целые числа"}
                      </FormFeedback>
                    </div>
                    {/* <p>{formErrors.numb_order}</p> */}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
