import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import config from "../../config";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ModalConfirm from "../../components/ModalConfirm";


const Account = (props) => {
    const { addToast } = useToasts()
    const [accounts, setAccounts] = useState([])
    const [tableData, setTableData] = useState([])
    const columns = [
        {
            dataField: 'id',
            text: 'Id',
            style: {
                width: 111,
            }
        },
        {
            dataField: 'name',
            text: 'Название',
        },
        {
            dataField: 'actions',
            text: 'Действия',
            style: {
                width: 111,
            }
        },
    ];

    const removeItem = async (id) => {
        axios.get(`${config.apiUrl}/accounts-delete/${id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res === "Success") {
                    axios.get(`${config.apiUrl}/accounts`)
                    .then((response) => {
                        setAccounts(response.data)
                    })
                    .catch(error => {
                        console.error(error);
                    })
                }
            })
            .catch(error => {
                console.error(error);
            })
    }
    
    const DeleteButton = props => (
        <div className='d-flex gap-2'>
             <button
                        onClick={() => {
                            setModalConfirm(true)
                            setDeleteId(props.id)
                        }
                        }
                        className='btn btn-danger'
                        title='Удалить'>
                        <i className='mdi mdi-delete' />
                    </button>
            {/* <button onClick={() => removeItem(props.id)} className='btn btn-danger' title='Удалить'><i className='mdi mdi-delete' /></button> */}
        </div>
    )

    useEffect(() => {
        const getAccounts = async () => {
            const response = await axios.get(`${config.apiUrl}/accounts`)
            setAccounts(response.data)
        }
        getAccounts()
    }, [])

    useEffect(() => {
        let tableData = []
        if (accounts.length > 0) {
            accounts.forEach(acc => {
                tableData.push({
                    ...acc,
                    "actions": (<DeleteButton id={acc.id} />)
                })
            })
        }       
        setTableData(tableData)
    }, [accounts])

    const [modalConfirm, setModalConfirm] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }
    return (
        <div className="page-content">
            {/* <p>{accList}</p> */}
            <MetaTags>
                <title>Записи | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Записи</h4>
                                        <Link to='/account/add' className='btn btn-primary'>
                                            <i className='mdi mdi-plus' />
                                            Добавить
                                        </Link>
                                    </div>
                                </CardTitle>

                                <ToolkitProvider
                                    keyField='id'
                                    columns={columns}
                                    data={tableData}>

                                    {
                                        toolkitProps => (
                                            <React.Fragment>
                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"id"}
                                                                responsive
                                                                bordered={true}
                                                                striped={true}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                    }
                                </ToolkitProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление записи'
            />
        </div>
    )
}

export default Account;
