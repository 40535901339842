import MetaTags from "react-meta-tags";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import { useToasts } from "react-toast-notifications";
import makeAnimated from "react-select/animated";
import WV from "../../workVariables";
import InputSpinner from "react-bootstrap-input-spinner";
import { useHistory } from "react-router-dom";
const animatedComponents = makeAnimated();

const TradeAdd = (props) => {
  const { addToast } = useToasts();

  const [form, setForm] = useState({
    name: "",
    symbol: "",
    interval: "",
    status: "",
    sumOrder: "",
    no_borrow: false,
    commission_percent: "",
    stop_loss_price: "",
    stop_loss_percent: "",
    ma: "",
    scheme_id: "",
    account_id: "",
    symbol_bnb: "",
    sum_bnb: "",
    chats: "",
    comment: "",
    take_profit: 0
  });
  const [error, setError] = useState({
    name: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const storeItem = (e) => {
    // e.preventDefault();
    setFormErrors(validate(form));
    setIsSubmit(true);
  };
  const validate = (values) => {
    const errors = {};
    // if (!values.name) errors.name = "поле обязательное для заполнения";
    if (!values.name) errors.name = " ";
    if (values.symbol === "")
      errors.symbol = "поле обязательное для заполнения";
    if (values.interval === "")
      errors.interval = "поле обязательное для заполнения";
    if (values.commission_percent == "")
      errors.commission_percent = "поле обязательное для заполнения";
    if (values.status == "") errors.status = "поле обязательное для заполнения";
    if (values.sumOrder == "")
      errors.sumOrder = "поле обязательное для заполнения";
    if (values.stop_loss_price == "")
      errors.stop_loss_price = "поле обязательное для заполнения";
    if (values.stop_loss_percent == "")
      errors.stop_loss_percent = "поле обязательное для заполнения";
    if (values.scheme_id === "")
      errors.scheme_id = "поле обязательное для заполнения";
    if (values.account_id == "")
      errors.account_id = "поле обязательное для заполнения";
    return errors;
  };
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      form.take_profit = (form.take_profit) ? form.take_profit : null;
      axios
        .post(`${config.apiUrl}/trade-schemes/store`, form)
        .then((response) => {
          history.goBack();
          setTimeout(() => {
            addToast(response.data.msg, {
              appearance: response.data.res.toLowerCase(),
              autoDismiss: true,
              autoDismissTimeout: 7500,
              transitionDuration: 2000,
            });
          }, 300);
          console.log(response.data);
          if (response.data.res === "Success") {
            console.log("OK REDIRECT!!!");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [formErrors]);

  const [schemes, setSchemes] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [ma, setMa] = useState([]);
  const [chats, setChats] = useState([]);

  useEffect(() => {
    if (form.symbol_bnb === "") {
      setForm({ ...form, sum_bnb: "" });
    }
  }, [form.symbol_bnb]);

  useEffect(() => {
    let arraySchemes = [];
    let arrayAccounts = [];
    let arrayMa = [];
    let arrayChats = [];
    const getSchemes = async () => {
      await axios
        .get(`${config.apiUrl}/schemas`)
        .then(async (response) => {
          for await (let item of response.data) {
            let option = {};
            option.label = item.name;
            option.value = item.id;
            arraySchemes.push(option);
          }
          setSchemes(arraySchemes);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getSchemes();
    const getAccounts = async () => {
      await axios
        .get(`${config.apiUrl}/accounts`)
        .then(async (response) => {
          for await (let item of response.data) {
            let option = {};
            option.label = item.name;
            option.value = item.id;
            arrayAccounts.push(option);
          }
          setAccounts(arrayAccounts);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getAccounts();
    const getMa = async () => {
      await axios
        .get(`${config.apiUrl}/ma`)
        .then(async (response) => {
          let option = {};
          option.label = "Текущая цена пары";
          option.value = "cpp";
          arrayMa.push(option);
          for await (let item of response.data) {
            let option = {};
            option.label = item.name;
            option.value = item.id;
            arrayMa.push(option);
          }
          setMa(arrayMa);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getMa();
    const getChats = async () => {
      await axios
        .get(`${config.apiUrl}/chats`)
        .then(async (response) => {
          for await (let item of response.data) {
            let option = {};
            option.label = item.name;
            option.value = item.id;
            arrayChats.push(option);
          }
          setChats(arrayChats);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getChats();
  }, []);

  console.log(form);

  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`,
    }),
  };
  const history = useHistory();
  let strInfo = "Stop Price, % < Price, %";
  let errorItem;
  return (
    <div className="page-content">
      <MetaTags>
        <title>Торговые схемы | TradeBot</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className={"mb-3"}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Новая Торговая схема </h4>
                    <div className="d-flex gap-2 align-items-center">
                      <Button color="primary" onClick={storeItem}>
                        Сохранить
                      </Button>
                      <Link to="/trade" className="btn btn-danger">
                        <i className="mdi mdi-backspace" />
                        &nbsp; Вернуться
                      </Link>
                    </div>
                    {/* <Link to="/trade" className="btn btn-danger btn-sm">
                      <i className="mdi mdi-backspace" />
                      &nbsp; Вернуться
                    </Link> */}
                  </div>
                </CardTitle>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="name">Название</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="..."
                        id="name"
                        invalid={
                          (form.name.length === 0 && error.name) ||
                          formErrors.name
                        }
                        value={form.name}
                        name="name"
                        onChange={(e) => {
                          setForm({ ...form, [e.target.name]: e.target.value });
                          setError({ ...error, name: true });
                          errorItem = formErrors;
                          delete errorItem.name;
                          setFormErrors(errorItem);
                        }}
                      />
                      <FormFeedback invalid>
                        {" "}
                        {formErrors.name}
                        {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                      </FormFeedback>
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="sumOrder">Сумма ордера</Label>
                      {/* <div className="merged-buttons"> */}
                      {/* обернуть в этот div ^ */}
                      {/* https://www.npmjs.com/package/react-bootstrap-input-spinner */}
                      <InputSpinner
                        value={form.sumOrder}
                        name="sumOrder"
                        type={"real"}
                        precision={4}
                        max={10000000}
                        min={0}
                        step={0.0001}
                        onChange={(num) => {
                          setForm({ ...form, sumOrder: num });
                          setError({ ...error, sumOrder: true });
                          errorItem = formErrors;
                          delete errorItem.sumOrder;
                          setFormErrors(errorItem);
                        }}
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.sumOrder}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="commission_percent">
                        Процент комиссии
                      </Label>
                      <InputSpinner
                        value={form.commission_percent}
                        name="commission_percent"
                        type={"real"}
                        precision={2}
                        max={100}
                        min={0}
                        step={0.01}
                        onChange={(num) => {
                          setForm({ ...form, commission_percent: num });
                          setError({ ...error, commission_percent: true });
                          errorItem = formErrors;
                          delete errorItem.commission_percent;
                          setFormErrors(errorItem);
                        }}
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.commission_percent}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="symbol">Символ</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="symbol"
                        name="symbol"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, symbol: e.value });
                          setError({ ...error, symbol: true });
                          errorItem = formErrors;
                          delete errorItem.symbol;
                          setFormErrors(errorItem);
                        }}
                        options={WV.symbolsList}
                        classNamePrefix="select2-selection"
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.symbol}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="stop_loss_price">Stop Price, % *</Label>
                      <InputSpinner
                        value={form.stop_loss_price}
                        name="stop_loss_price"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {
                          setForm({ ...form, stop_loss_price: num });
                          setError({ ...error, stop_loss_price: true });
                          errorItem = formErrors;
                          delete errorItem.stop_loss_price;
                          setFormErrors(errorItem);
                        }}
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.stop_loss_price}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={4}>
                    <div className="mb-3">
                      <Label htmlFor="status_select">Статус схемы</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="status_select"
                        name="status"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, status: e.value });
                          setError({ ...error, status: true });
                          errorItem = formErrors;
                          delete errorItem.status;
                          setFormErrors(errorItem);
                        }}
                        options={[
                          { label: "ВКЛЮЧЕНА", value: 1 },
                          { label: "ВЫКЛЮЧЕНА", value: 0 },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.status}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="interval">Интервал</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="interval"
                        name="interval"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, interval: e.value });
                          setError({ ...error, interval: true });
                          errorItem = formErrors;
                          delete errorItem.interval;
                          setFormErrors(errorItem);
                        }}
                        options={WV.timeframesList}
                        classNamePrefix="select2-selection"
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.interval}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="stop_loss_percent">Price, % * </Label>
                      <InputSpinner
                        value={form.stop_loss_percent}
                        name="stop_loss_percent"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {
                          setForm({ ...form, stop_loss_percent: num });
                          setError({ ...error, stop_loss_percent: true });
                          errorItem = formErrors;
                          delete errorItem.stop_loss_percent;
                          setFormErrors(errorItem);
                        }}
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.stop_loss_percent}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="symbol_bnb">
                        Торговая пара для погашения комиссии **
                      </Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="symbol_bnb"
                        name="symbol_bnb"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, symbol_bnb: e.value });
                        }}
                        options={WV.commissionSymbolsList}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label htmlFor="comment">Комментарии</Label>
                      <Input
                        type="text"
                        // rows={3}
                        className="form-control"
                        placeholder="..."
                        id="comment"
                        name="comment"
                        onChange={(e) =>
                          setForm({ ...form, [e.target.name]: e.target.value })
                        }
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} xl={4}>
                    <div className="mb-3">
                      <Label> Тейк - профит, %</Label>
                      <InputSpinner
                        value={form.take_profit}
                        name="take_profit"
                        type={"real"}
                        precision={1}
                        max={100}
                        min={0}
                        step={0.1}
                        onChange={(num) => {
                          setForm({ ...form, take_profit: num });
                          setError({ ...error, take_profit: true })
                          errorItem = formErrors;
                          delete errorItem.take_profit
                          setFormErrors(errorItem);
                        }
                        }
                      />
                      <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.take_profit}</p>
                    </div>
                  </Col>                 
                  {form.symbol_bnb !== "" && (
                    <>
                      <Col xs={12} md={6} xl={4}>
                        <div className="mb-3">
                          <Label htmlFor="sum_bnb">Сумма комиссии</Label>
                          <InputSpinner
                            value={form.sum_bnb}
                            name="sum_bnb"
                            type={"real"}
                            precision={6}
                            max={10000000}
                            min={0}
                            step={0.000001}
                            onChange={(num) =>
                              setForm({ ...form, sum_bnb: num })
                            }
                          />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col xs={12} md={6} xl={4}>
                  <div class="form-group form-check mt-4">
                      <Input
                        type="checkbox"
                        class="form-check-input"
                        placeholder="Введите процент..."
                        id="no_borrow"
                        checked={form.no_borrow}
                        onChange={() =>
                          setForm({ ...form, no_borrow: !form.no_borrow })
                        }
                      />
                      <Label class="form-check-label" htmlFor="no_borrow">
                        Не использовать маржу
                      </Label>
                    </div>                    
                  </Col>                  
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="mb-0">Подключение</h4>
                  </div>
                </CardTitle>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="scheme_id">Схема</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="scheme_id"
                        name="scheme_id"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, scheme_id: e.value });
                          setError({ ...error, scheme_id: true });
                          errorItem = formErrors;
                          delete errorItem.scheme_id;
                          setFormErrors(errorItem);
                        }}
                        options={schemes}
                        classNamePrefix="select2-selection"
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.scheme_id}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div className="mb-3">
                      <Label htmlFor="accounts">Запись</Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="accounts"
                        name="accounts"
                        placeholder="выбрать..."
                        onChange={(e) => {
                          setForm({ ...form, account_id: e.value });
                          setError({ ...error, account_id: true });
                          errorItem = formErrors;
                          delete errorItem.account_id;
                          setFormErrors(errorItem);
                        }}
                        options={accounts}
                        classNamePrefix="select2-selection"
                      />
                      <p style={{ fontSize: "10px", color: "#e06466" }}>
                        {" "}
                        {formErrors.account_id}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6}>
                    <div className="mb-3">
                      <Label symbol="ma">
                        Скользящие средние &nbsp;
                        <abbr title="MA1 > MA2 > ... > MAn">
                          ?
                        </abbr>
                      </Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="ma"
                        value={form.ma}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        placeholder="выбрать..."
                        onChange={(value) => {
                          setForm({ ...form, ma: value });
                        }}
                        options={ma}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div className="mb-3">
                      <Label symbol="chats">Телеграм группы </Label>
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        styles={customStyles}
                        id="chats"
                        value={form.chats}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        placeholder="выбрать..."
                        onChange={(value) => {
                          setForm({ ...form, chats: value });
                        }}
                        options={chats}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex align-items-center gap-3">
                      <div
                        className="border border-4 d-flex justify-content-center flex-shrink-0 align-items-center rounded-circle"
                        style={{ width: 50, height: 50 }}
                      >
                        <i className="fas fa-info"></i>
                      </div>
                      <div className="d-flex flex-column">
                        <div>
                          <b>*</b> {strInfo}
                        </div>
                        <div>
                          <b>** </b> Если выбрана Торговая пара для погашения
                          комиссии и Сумма комиссии(во второй валюте торговой
                          пары), то перед открытием будет осуществлена покупка
                          первой пары на сумму комиссии и после закрытия сделки
                          будет выплачены бирже % за использова ние заемных
                          средств (interest) в BNB. <br></br> Если выбрана
                          Торговая пара для погашения и НЕ указана Сумма
                          комиссии, то после закрытия сделки будет выплачены
                          бирже % за использование заемных средств (interest) в
                          BNB. <br></br> Если НЕ выбрана Торговая пара для
                          погашения и НЕ указана Сумма комиссии, то
                          соостветственно ничего не покупаем и ничего не гасим.{" "}
                          <br></br>Принцип погашения % за займ: если сумма
                          interest БОЛЬШЕ 10$, то покупаем BNB на сумму
                          погашения и гасим %. Если interest МЕНЬШЕ 10$, то
                          гасим с имеющихся BNB.
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TradeAdd;
