import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from "react-router-dom"
import './assets/scss/theme.scss'
import AppProvider from "./store/AppProvider";
import { ToastProvider } from "react-toast-notifications";


ReactDOM.render(
    <AppProvider>
        <BrowserRouter>
            <ToastProvider>
                <App />
            </ToastProvider>
        </BrowserRouter>
    </AppProvider>,
    document.getElementById('root')
)
