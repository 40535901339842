import React from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback,
} from "reactstrap"

import { useState, useEffect } from 'react'
import axios from "axios";
import config from "../../config";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const AccountAdd = (props) => {
    const { addToast } = useToasts();

    const [form, setForm] = useState({
        name: '',
        atk: '',
        atks: ''
    });
    const [error, setError] = useState({
        name: false,
        atk: false,
        atks: false,
      });
    
      const [formErrors, setFormErrors] = useState({});
      const [isSubmit, setIsSubmit] = useState(false);
      const storeItem = (e) => {
        setFormErrors(validate(form));
        setIsSubmit(true);
      };
      const validate = (values) => {
        const errors = {};
        if (values.name === '') errors.name = "поле обязательное для заполнения";
        if (values.atk === '') errors.atk = "поле обязательное для заполнения";
        if (values.atks === '') errors.atks = "поле обязательное для заполнения";
        return errors;
      };
      useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            axios.post(`${config.apiUrl}/accounts-store`, form)
            .then((response) => {
              if (response.data.res === "Success") {
                history.goBack()
                    setTimeout(() => {
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })
    
                    }, 1000);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }, [formErrors]);
    
      const history = useHistory();
      let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Новая Запись | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Новая запись</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={storeItem}>
                                                Сохранить
                                            </Button>
                                            <button
                                                className="btn btn-danger"
                                                onClick={history.goBack}
                                                title="Отмена"
                                            >
                                                <i className="mdi mdi-backspace" />
                                                &nbsp; Вернуться
                                            </button>

                                        </div> 
                                     
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="name"
                                                value={form.name}
                                                name='name'
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                  }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                    setError({ ...error, name: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.name
                                                    setFormErrors(errorItem);
                                                } }
                                            />
                                            <FormFeedback invalid>
                                                {formErrors.name
                                                    ? formErrors.name
                                                    : "поле обязательное для заполнения"}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Запись 1</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="atk"
                                                value={form.atk}
                                                name='atk'
                                                invalid={
                                                    (form.atk.length === 0 && error.atk) ||
                                                    formErrors.atk
                                                  }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                    setError({ ...error, atk: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.atk
                                                    setFormErrors(errorItem);
                                                } }
                                            />
                                            <FormFeedback invalid>
                                                {formErrors.atk
                                                    ? formErrors.atk
                                                    : "поле обязательное для заполнения"}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Запись 2</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                id="atks"
                                                name='atks'
                                                value={form.atks}
                                                invalid={
                                                    (form.atks.length === 0 && error.atks) ||
                                                    formErrors.atks
                                                  }
                                                onChange={(e) => {
                                                    setForm({ ...form, [e.target.name]: e.target.value })
                                                    setError({ ...error, atks: true })
                                                    errorItem = formErrors;
                                                    delete errorItem.atks
                                                    setFormErrors(errorItem);
                                                } }
                                            />
                                            <FormFeedback invalid>
                                                {formErrors.atks
                                                    ? formErrors.atks
                                                    : "поле обязательное для заполнения"}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default AccountAdd