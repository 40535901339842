import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
    Button,
    FormFeedback
} from "reactstrap"
import { Link, useParams, Redirect } from "react-router-dom";
import Select from "react-select"
import axios from "axios";
import config from "../../config";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from 'react-select/animated';
import WV from '../../workVariables'
import { DateTimePicker } from '@mui/lab';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from '../../muiTheme';
import InputSpinner from "react-bootstrap-input-spinner";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
const animatedComponents = makeAnimated();
const moment = require('moment')

const Test2Edit = (props) => {
    const { id } = useParams()
    const [form, setForm] = useState({
        name: '',
        maId: '',
        show_open: '',
        scheme_id: '',
        symbol: '',
        interval: '',
        procent_comision: '',
        comment: '',
        sl_scheme_id: '',
        birzha: '',
        stop_loss: '',
        period_test: '',
        annual_yield: '',
        trade_scheme_id: '',
        take_profit: ''
    });
    const [error, setError] = useState({
        name: false,
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const storeItem = (e) => {
        // e.preventDefault();
        setFormErrors(validate(form));
        setIsSubmit(true);
    };
    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = "поле обязательное для заполнения";
        if (!values.name) errors.name = " ";
        if (values.procent_comision == '') errors.procent_comision = "поле обязательное для заполнения";
        if (values.stop_loss == '') errors.stop_loss = "поле обязательное для заполнения";
        if (values.annual_yield == '') errors.annual_yield = "поле обязательное для заполнения";
        return errors;
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            let form1 = {};
            form1.take_profit = (form.take_profit) ? form.take_profit : null;
            form1.id = id;
            form1.name = form.name;
            form1.comment = form.comment;
            form1.symbol = form.symbol.value;
            form1.sl_scheme_id = form.sl_scheme_id.value;
            form1.scheme_id = form.scheme_id.value;
            form1.trade_scheme_id = form.trade_scheme_id.value;
            // form1.stop_id = form.stop_id.value;
            form1.stop_loss = form.stop_loss;
            form1.period_test = form.period_test.value;
            form1.annual_yield = form.annual_yield;

            form1.interval = form.interval.value;
            form1.birzha = form.birzha.value;
            form1.procent_comision = parseFloat(form.procent_comision);
            form1.show_open = form.show_open.value;
            // if (form.show_open.value === 1) form1.show_open = 1; else form1.show_open = null
            if (form.maId) {
                if ((!form.maId.length) || (form.maId === '')) form1.ma = null;
                // if (form.maId === '') form1.ma = null;
                else {
                    let arrMa = form.maId;
                    for (let i = 0; i < arrMa.length; i++)
                        (i === 0) ? form1.ma = arrMa[i].value : form1.ma = form1.ma + ', ' + arrMa[i].value

                }
            } else form1.ma = null
            // form1.startTime = moment.utc(startTime).format();
            // form1.endTime = moment.utc(endTime).format();
            // form1.startTime = moment.utc(form.startTime).format();
            // form1.endTime = moment.utc(form.endTime).format();

            form1.startTime = moment(form.startTime).format('YYYY-MM-DD HH:mm:ss')
            let st = form1.startTime.split(' ')
            form1.startTime = st[0] + "T" + st[1] + "Z"

            form1.endTime = moment(form.endTime).format('YYYY-MM-DD HH:mm:ss')
            let et = form1.endTime.split(' ')
            form1.endTime = et[0] + "T" + et[1] + "Z"

            console.log(form1.startTime);
            console.log(form1.endTime);

            axios.post(`${config.apiUrl}/test-second-schema-update`, form1)
                .then((response) => {
                    // console.log(response.data.msg);
                    if (response.data.res === "Success") {
                        setRedirect(true)
                        setTimeout(() => {
                            addToast(response.data.msg, {
                                appearance: response.data.res.toLowerCase(),
                                autoDismiss: true,
                                autoDismissTimeout: 7500,
                                transitionDuration: 2000
                            })
                        }, 300);
                        console.log("OK");

                        // <Redirect to={`/test2/show/${form1.id}`} />
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, [formErrors]);


    // const [startTime, setStartTime] = useState(new Date());
    // const [endTime, setEndTime] = useState(new Date());

    registerLocale('ru', ru)
    setDefaultLocale('ru')

    const [ma, setMa] = useState([])
    const [schema, setSchema] = useState([])
    const [stop, setStop] = useState([])
    const [tradeScheme, setTradeScheme] = useState([])
    useEffect(() => {
        const getTestScheme = async () => {

            const response = await axios.get(`${config.apiUrl}/test-second-schema/${id}`)
            let periodTest, birzha, tradeId;
            if (!response.data.period_test) periodTest = [{ label: 'Весь период', value: null }];
            if (response.data.period_test == 1) periodTest = [{ label: 'По месяцам', value: '1' }];
            if (response.data.period_test == 2) periodTest = [{ label: 'По кварталам', value: '2' }];
            if (response.data.period_test == 3) periodTest = [{ label: 'По полугодиям', value: '3' }];
            if (response.data.period_test == 4) periodTest = [{ label: 'По годам', value: '4' }];

            if (response.data.birzha == 1) birzha = [{ label: 'Binance', value: '1' }];
            if (response.data.birzha == 2) birzha = [{ label: 'Bitfinex', value: '2' }];

            if (!response.data.trade_scheme_id) tradeId = [{ value: null, label: 'Не выбирать торговую схему' }]
            else tradeId = response.data.trade_scheme_id;

            let st = moment.utc(response.data.startTime)
            st = st._i.substr(0, 10) + " " + st._i.substr(11, 8)
            let et = moment.utc(response.data.endTime)
            et = et._i.substr(0, 10) + " " + et._i.substr(11, 8)

            setForm({
                id: id,
                name: response.data.name,
                comment: response.data.comment,
                show_open: response.data.show_open,
                scheme_id: response.data.scheme_id,
                sl_scheme_id: response.data.sl_scheme_id,
                trade_scheme_id: tradeId,
                symbol: response.data.symbol,
                interval: response.data.interval,
                procent_comision: response.data.procent_comision,
                // stop_id: response.data.stop_id,
                stop_loss: response.data.stop_loss,
                period_test: periodTest,
                annual_yield: response.data.annual_yield,
                birzha: birzha,
                maId: response.data.ma,
                startTime: st,
                endTime: et,
                take_profit: (response.data.take_profit) ? response.data.take_profit : 0
            })
        }
        getTestScheme()
        const getMa = async () => {
            await axios.get(`${config.apiUrl}/schemas-ma`)
                .then((response) => {
                    let arrayMa = response.data;
                    for (let i = 0; i < arrayMa.length; i++) {
                        arrayMa[i].value = arrayMa[i].id;
                        arrayMa[i].label = arrayMa[i].name;
                        delete arrayMa[i].id;
                        delete arrayMa[i].name
                    }
                    if (arrayMa.length > 0) arrayMa.unshift({ value: 'cpp', label: 'Текущая цена пары' })
                    setMa(arrayMa)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getMa()
        const getScheme = async () => {
            await axios.get(`${config.apiUrl}/schemas`)
                .then((response) => {
                    let arraySchema = response.data;
                    for (let i = 0; i < arraySchema.length; i++) {
                        arraySchema[i].value = arraySchema[i].id;
                        arraySchema[i].label = arraySchema[i].name;
                        delete arraySchema[i].id;
                        delete arraySchema[i].name
                    }
                    setSchema(arraySchema)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getScheme()

        const getStop = async () => {
            await axios.get(`${config.apiUrl}/test-second-schemas-stops-sl`)
                .then((response) => {
                    // console.log(response.data);
                    let arrayStop = response.data;
                    for (let i = 0; i < arrayStop.length; i++) {
                        arrayStop[i].value = arrayStop[i].id;
                        arrayStop[i].label = arrayStop[i].name;
                        delete arrayStop[i].id;
                        delete arrayStop[i].name
                    }
                    setStop(arrayStop)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getStop()

        const getTradeScheme = async () => {
            await axios.get(`${config.apiUrl}/test-second-schemas-trade-scheme`)
                .then((response) => {
                    // console.log(response.data);
                    let arrayTs = response.data;
                    for (let i = 0; i < arrayTs.length; i++) {
                        arrayTs[i].value = arrayTs[i].id;
                        arrayTs[i].label = arrayTs[i].name;
                        delete arrayTs[i].id;
                        delete arrayTs[i].name
                    }
                    if (arrayTs.length > 0) arrayTs.unshift({ value: null, label: 'Не выбирать торговую схему' })
                    setTradeScheme(arrayTs)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getTradeScheme()
    }, [])
    const { addToast } = useToasts()
    const [redirect, setRedirect] = useState(false)

    //белый цвет шрифта при поиске в Select
    const customStyles = {
        input: () => ({
            color: `${WV.colorSelectInput}`
        }),
    }
    const history = useHistory();
    // console.log(form);
    let errorItem;
    return (
        <div className="page-content">
            <MetaTags>
                <title>Редактировать Тестовая Схема | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Редактировать Тестовую Схему 2.0 #{id}</h4>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <Button color="primary" onClick={storeItem}>
                                                Изменить
                                            </Button>
                                            <Link to='/test2' className='btn btn-danger'>
                                                <i className='mdi mdi-backspace' />
                                                &nbsp;
                                                Вернуться
                                            </Link>
                                        </div>
                                    </div>
                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Название</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                // required
                                                id="name"
                                                value={form.name}
                                                invalid={
                                                    (form.name.length === 0 && error.name) ||
                                                    formErrors.name
                                                }
                                                onChange={
                                                    (e) => {
                                                        setForm({ ...form, name: e.target.value })
                                                        setError({ ...error, name: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.name
                                                        setFormErrors(errorItem);
                                                    }
                                                }
                                            />
                                            <FormFeedback invalid> {formErrors.name}
                                                {/* {formErrors.name
                                                    ? formErrors.name
                                                    : "это поле обязательное"} */}
                                            </FormFeedback>
                                        </div>
                                        <div className="mb-3">
                                            <Label symbol="position">Символ</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='symbol'
                                                value={form.symbol}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, symbol: value })
                                                }}
                                                options={WV.symbolsList}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Label symbol="interval">Интервал</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='interval'
                                                value={form.interval}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, interval: value })
                                                }}
                                                options={WV.timeframesList}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                        {form.procent_comision !== '' &&
                                            <div className="mb-3">
                                                <Label> Изначальный стоп, %</Label>
                                                <InputSpinner
                                                    value={form.stop_loss}
                                                    name="stop_loss"
                                                    type={"real"}
                                                    precision={1}
                                                    max={100}
                                                    min={0}
                                                    step={0.1}
                                                    onChange={(num) => {
                                                        setForm({ ...form, stop_loss: num });
                                                        setError({ ...error, stop_loss: true })
                                                        errorItem = formErrors;
                                                        delete errorItem.stop_loss
                                                        setFormErrors(errorItem);
                                                    }
                                                    }
                                                />
                                                <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.stop_loss}</p>
                                            </div>
                                        }

                                    </Col>

                                    <Col xs={12} md={6} xl={8}>
                                        <Row>
                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label symbol="birzha">Биржа</Label>
                                                    <Select
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: `${WV.colorHoverSelect}`,
                                                                primary: `${WV.colorSelected}`,
                                                            },
                                                        })}
                                                        styles={customStyles}
                                                        id='birzha'
                                                        value={form.birzha}
                                                        placeholder="выбрать..."
                                                        onChange={(value) => {
                                                            setForm({ ...form, birzha: value })
                                                        }}
                                                        options={WV.birzhaList}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                {form.annual_yield !== '' &&
                                                    <div className="mb-3">
                                                        <Label> Годовой % доходности для Сортино</Label>
                                                        <InputSpinner
                                                            value={form.annual_yield}
                                                            name="annual_yield"
                                                            type={"real"}
                                                            precision={1}
                                                            max={100}
                                                            min={0}
                                                            step={0.1}
                                                            onChange={(num) => {
                                                                setForm({ ...form, annual_yield: num })
                                                                setError({ ...error, annual_yield: true })
                                                                errorItem = formErrors;
                                                                delete errorItem.annual_yield
                                                                setFormErrors(errorItem);
                                                            }
                                                            }
                                                        />
                                                        <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.annual_yield}</p>
                                                    </div>
                                                }
                                                {form.procent_comision !== '' &&
                                                    <div className="mb-3">
                                                        <Label> Процент комиссии</Label>
                                                        <InputSpinner
                                                            value={form.procent_comision}
                                                            name="procent_comision"
                                                            type={"real"}
                                                            precision={2}
                                                            max={100}
                                                            min={0}
                                                            step={0.01}
                                                            onChange={(num) => {
                                                                setForm({ ...form, procent_comision: num });
                                                                setError({ ...error, procent_comision: true })
                                                                errorItem = formErrors;
                                                                delete errorItem.procent_comision
                                                                setFormErrors(errorItem);

                                                            }
                                                            }
                                                        />
                                                        <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.procent_comision}</p>
                                                    </div>
                                                }
                                                
                                                {
                                                    form.take_profit !== '' &&
                                                    <div className="mb-3">
                                                        <Label> Тейк - профит, %</Label>
                                                        <InputSpinner
                                                            value={form.take_profit}
                                                            name="take_profit"
                                                            type={"real"}
                                                            precision={1}
                                                            max={100}
                                                            min={0}
                                                            step={0.1}
                                                            onChange={(num) => {
                                                                setForm({ ...form, take_profit: num });
                                                                setError({ ...error, take_profit: true })
                                                                errorItem = formErrors;
                                                                delete errorItem.take_profit
                                                                setFormErrors(errorItem);
                                                            }
                                                            }
                                                        />
                                                        <p style={{ 'fontSize': '10px', 'color': '#e06466' }}> {formErrors.take_profit}</p>
                                                    </div>
                                                }

                                            </Col>

                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="comment">Комментарии</Label>
                                                    <Input
                                                        type="text"
                                                        // style={{ resize: 'none', height: '2.4rem' }}
                                                        className="form-control"
                                                        placeholder='...'
                                                        id="comment"
                                                        value={form.comment}
                                                        onChange={
                                                            (e) => {
                                                                setForm({ ...form, comment: e.target.value })
                                                            }
                                                        }
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label symbol="show_open">Отображение сделок в Excel</Label>
                                                    <Select
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: `${WV.colorHoverSelect}`,
                                                                primary: `${WV.colorSelected}`,
                                                            },
                                                        })}
                                                        styles={customStyles}
                                                        id='show_open'
                                                        value={form.show_open}
                                                        placeholder="выбрать..."
                                                        onChange={(value) => {
                                                            setForm({ ...form, show_open: value })
                                                        }}
                                                        options={[
                                                            { label: 'Показывать только открытые', value: 1 },
                                                            { label: 'Показывать все сделки', value: null },
                                                        ]}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                
                                                <div className="mb-4">
                                                    <Label symbol="position">Разбить на периоды</Label>
                                                    <Select
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: `${WV.colorHoverSelect}`,
                                                                primary: `${WV.colorSelected}`,
                                                            },
                                                        })}
                                                        styles={customStyles}
                                                        id='period_test'
                                                        value={form.period_test}
                                                        placeholder="выбрать..."
                                                        onChange={(value) => {
                                                            setForm({ ...form, period_test: value })
                                                        }}
                                                        options={WV.periodТestList}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>

 
                                                <Row className='g-2 mt-3'>
                                                    <Col xl={6}>

                                                        <div className='mb-3 mb-xl-0'>

                                                            <ThemeProvider theme={darkTheme}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                                    {/* <DateTimePicker
                                                                renderInput={(props) => <TextField {...props} />}
                                                                label="Время начала"
                                                                value={startTime}
                                                                ampm={false}
                                                                onChange={(newValue) => {
                                                                    setStartTime(newValue);
                                                                }}
                                                            /> */}
                                                                    <DateTimePicker
                                                                        renderInput={(props) => <TextField {...props} />}
                                                                        label="Время начала"
                                                                        value={form.startTime}
                                                                        ampm={false}
                                                                        onChange={(newValue) => {
                                                                            // setStartTime(newValue);
                                                                            setForm({ ...form, startTime: newValue })
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </ThemeProvider>

                                                        </div>
                                                    </Col>

                                                    <Col xl={6}>

                                                        <div className="mb-3">
                                                            <ThemeProvider theme={darkTheme}>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                                    {/* <DateTimePicker
                                                                renderInput={(props) => <TextField {...props} />}
                                                                label="Время завершения"
                                                                value={endTime}
                                                                ampm={false}
                                                                onChange={(newValue) => {
                                                                    setEndTime(newValue);
                                                                }}
                                                            /> */}
                                                                    <DateTimePicker
                                                                        renderInput={(props) => <TextField {...props} />}
                                                                        label="Время завершения"
                                                                        value={form.endTime}
                                                                        ampm={false}
                                                                        onChange={(newValue) => {
                                                                            // setEndTime(newValue);
                                                                            setForm({ ...form, endTime: newValue })
                                                                        }}
                                                                    />
                                                                </LocalizationProvider>
                                                            </ThemeProvider>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className='col-12'>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>Подключение</h4>
                                    </div>

                                </CardTitle>
                                <Row>
                                    <Col xs={12} md={6} xl={4}>
                                        <div className="mb-3">
                                            <Label symbol="sl_scheme_id">SL схема</Label>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: `${WV.colorHoverSelect}`,
                                                        primary: `${WV.colorSelected}`,
                                                    },
                                                })}
                                                styles={customStyles}
                                                id='sl_scheme_id'
                                                value={form.sl_scheme_id}
                                                placeholder="выбрать..."
                                                onChange={(value) => {
                                                    setForm({ ...form, sl_scheme_id: value })
                                                }}
                                                options={stop}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={8}>
                                        <Row>
                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label symbol="maId">Скользящие средние &nbsp;
                                                        <abbr title="MA1 > MA2 > ... > MAn">
                                                            ?
                                                        </abbr></Label>
                                                    <Select
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: `${WV.colorHoverSelect}`,
                                                                primary: `${WV.colorSelected}`,
                                                            },
                                                        })}
                                                        styles={customStyles}
                                                        id='maId'
                                                        value={form.maId}
                                                        closeMenuOnSelect={false}
                                                        components={animatedComponents}
                                                        isMulti
                                                        placeholder="выбрать..."
                                                        onChange={(value) => {
                                                            setForm({ ...form, maId: value })
                                                        }}
                                                        options={ma}
                                                        classNamePrefix="select2-selection"
                                                    />

                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} xl={6}>
                                                <div className="mb-3">
                                                    <Label symbol="trade_scheme_id">Проверка сделок по торговой схеме</Label>
                                                    <Select
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: `${WV.colorHoverSelect}`,
                                                                primary: `${WV.colorSelected}`,
                                                            },
                                                        })}
                                                        styles={customStyles}
                                                        id='trade_scheme_id'
                                                        value={form.trade_scheme_id}
                                                        placeholder="выбрать..."
                                                        onChange={(value) => {
                                                            setForm({ ...form, trade_scheme_id: value })
                                                        }}
                                                        options={tradeScheme}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>

                                        </Row>
                                    </Col>


                                    <div className="mb-3">
                                        <Label symbol="scheme_id">Схема</Label>
                                        <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: `${WV.colorHoverSelect}`,
                                                    primary: `${WV.colorSelected}`,
                                                },
                                            })}
                                            // closeMenuOnSelect={false}
                                            // components={animatedComponents}
                                            // isMulti
                                            styles={customStyles}
                                            id='scheme_id'
                                            value={form.scheme_id}
                                            placeholder="выбрать..."
                                            onChange={(value) => {
                                                setForm({ ...form, scheme_id: value })
                                            }}
                                            options={schema}
                                            classNamePrefix="select2-selection"
                                        />
                                    </div>
                                    <div style={{ height: 100 }}></div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container >
            {redirect &&
                <Redirect
                    // to={{
                    //     pathname: "/test2",
                    // }}
                    to={{
                        pathname: `/test2/show/${id}`,
                    }}
                />
            }

        </div >
    );
}

export default Test2Edit;