import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    Col,
    Container,
    Row,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
    Input,
    Button
} from "reactstrap";

import axios from "axios";
import config from "../config";
import { useToasts } from "react-toast-notifications";
import classnames from "classnames"

const Settings = (props) => {
    const { addToast } = useToasts()
    const [form, setForm] = useState({
        chat: null,
        new_password: null,
        conf_password: null,
        password: null,
        chatEdit: false,
        passEdit: false
    })
    const [form_2, setForm_2] = useState({
        minut: null,
    })
    const [customActiveTab, setcustomActiveTab] = useState("1")
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab)
        }
    }

    const saveItem = () => {
        axios.post(`${config.apiUrl}/settings-edit`, form)
            .then((response) => {
                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                }, 300);
            })
    }

    const saveItem_2 = () => {
        axios.post(`${config.apiUrl}/settings-edit-stop-minutes`, form_2)
            .then((response) => {
                setTimeout(() => {
                    addToast(response.data.msg, {
                        appearance: response.data.res.toLowerCase(),
                        autoDismiss: true,
                        autoDismissTimeout: 7500,
                        transitionDuration: 2000
                    })
                }, 300);
            })
    }

    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/settings`)
                .then((response) => {
                    setForm({ chat: response.data.chat })
                    setForm_2({ minut: response.data.minutes })
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    return (
        <div className="page-content">
            <MetaTags>
                <title>Настройки аккаунта | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Настройки аккаунта</h4>

                                    </div>
                                </CardTitle>

                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <Nav tabs className="nav-tabs-custom nav-justified">
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: customActiveTab === "1",
                                                            })}
                                                            onClick={() => {
                                                                toggleCustom("1")
                                                            }}
                                                        >
                                                            <span className="d-block d-sm-none">
                                                                <i className="fas fa-home"></i>
                                                            </span>
                                                            <span className="d-none d-sm-block">Настройки авторизации</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{ cursor: "pointer" }}
                                                            className={classnames({
                                                                active: customActiveTab === "2",
                                                            })}
                                                            onClick={() => {
                                                                toggleCustom("2")
                                                            }}
                                                        >
                                                            <span className="d-block d-sm-none">
                                                                <i className="far fa-user"></i>
                                                            </span>
                                                            <span className="d-none d-sm-block">Настройки торговые</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>

                                                <TabContent
                                                    activeTab={customActiveTab}
                                                    className="p-3 text-muted"
                                                >
                                                    <TabPane tabId="1">
                                                        <Row>
                                                            <Col sm="12">
                                                                <CardText className="mb-0">
                                                                    <Row>
                                                                        <Col xs={12} md={6} xl={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="chat">Id группы входа</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder='...'
                                                                                    id="chat"
                                                                                    value={form.chat}
                                                                                    name="chat"
                                                                                    // invalid={
                                                                                    // (form.name.length === 0 && error.name) ||
                                                                                    // formErrors.name
                                                                                    // }
                                                                                    // invalid={form.name.length < 4}
                                                                                    onChange={(e) => {
                                                                                        setForm({ ...form, [e.target.name]: e.target.value, chatEdit: true });
                                                                                    }
                                                                                    }
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <Col>
                                                                <CardText className="mb-0">
                                                                    <Row>
                                                                        <Col xs={12} md={6} xl={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="new_password">Новый пароль</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder='...'
                                                                                    id="new_password"
                                                                                    value={form.new_password}
                                                                                    name="new_password"
                                                                                    // invalid={
                                                                                    // (form.name.length === 0 && error.name) ||
                                                                                    // formErrors.name
                                                                                    // }
                                                                                    // invalid={form.name.length < 4}
                                                                                    onChange={(e) => {
                                                                                        setForm({ ...form, [e.target.name]: e.target.value, passEdit: true });
                                                                                    }
                                                                                    }
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={12} md={6} xl={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="conf_password">Подтвердите пароль</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder='...'
                                                                                    id="conf_password"
                                                                                    value={form.conf_password}
                                                                                    name="conf_password"
                                                                                    // invalid={
                                                                                    // (form.name.length === 0 && error.name) ||
                                                                                    // formErrors.name
                                                                                    // }
                                                                                    // invalid={form.name.length < 4}
                                                                                    onChange={(e) => {
                                                                                        setForm({ ...form, [e.target.name]: e.target.value });
                                                                                    }
                                                                                    }
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                        <hr />

                                                        <Row>
                                                            <Col sm="12">
                                                                <CardText className="mb-0">
                                                                    <Row>
                                                                        <Col xs={12} md={6} xl={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="password">Введите старый пароль</Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder='...'
                                                                                    id="password"
                                                                                    value={form.password}
                                                                                    name="password"
                                                                                    // invalid={
                                                                                    // (form.name.length === 0 && error.name) ||
                                                                                    // formErrors.name
                                                                                    // }
                                                                                    // invalid={form.name.length < 4}
                                                                                    onChange={(e) => {
                                                                                        setForm({ ...form, [e.target.name]: e.target.value });
                                                                                    }
                                                                                    }
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className='d-flex gap-2 justify-content-end'>
                                                                    <Button color="primary" onClick={saveItem}>
                                                                        Изменить
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="2">
                                                        <Row>
                                                            <Col sm="12">
                                                                <CardText className="mb-0">
                                                                    <Row>
                                                                        <Col xs={12} md={6} xl={6}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="minut">Интервал проверки исполнения StopLoss по сделкам, в минутах: </Label>
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder='...'
                                                                                    id="minut"
                                                                                    value={form_2.minut}
                                                                                    name="minut"
                                                                                    // invalid={
                                                                                    // (form.name.length === 0 && error.name) ||
                                                                                    // formErrors.name
                                                                                    // }
                                                                                    // invalid={form.name.length < 4}
                                                                                    onChange={(e) => {
                                                                                        setForm_2({ ...form_2, [e.target.name]: e.target.value });
                                                                                    }
                                                                                    }
                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className='d-flex gap-2 justify-content-end'>
                                                                                <Button color="primary" onClick={saveItem_2}>
                                                                                    Изменить
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </CardText>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Settings;