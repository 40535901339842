import config from "../src/config";
import axios from "axios";

const WF = {
    // Ф_Я ДЛЯ ОТПРАВКИ НАСТРОЕК ТАБЛИЦ НА СЕРВЕР!
    setTableSets: async () => {
        const items = { ...localStorage };
        await axios.post(`${config.apiUrl}/login-setset`, items)
    },

    // Ф_Я ДЛЯ ОБРАБОТКИ ЦЕНЫ ДЛЯ ИНФОРМАЦИОННОЙ ПАНЕЛИ
    workWithPrice: async (data) => {
        data = JSON.parse(data)
        let res = {}
        res.symb = data.s

        let arrow = ''
        if (data.P > 0)
            arrow = 'mdi-arrow-up text-success'
        if (data.P < 0)
            arrow = 'mdi-arrow-down text-danger'

        res.price = parseFloat(data.c).toFixed(2)
        res.change = parseFloat(data.p).toFixed(2)
        res.perc = parseFloat(data.P).toFixed(2)
        res.arrow = arrow
        return res
    },
}

export default WF