import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";
import config from "../../config";
import Select from "react-select";
import InputSpinner from "react-bootstrap-input-spinner";
import WV from "../../workVariables";

const Open2 = (props) => {
  const { open2, setOpen2 } = props;
  const [tihTil, setTihTil] = useState([]);
  const [tihTil1, setTihTil1] = useState([]);
  const [tihTil2, setTihTil2] = useState([]);
  const tt1 = [];
  const tt2 = [];
  useEffect(() => {
    const getTihTil = async () => {
      const response = await axios.get(`${config.apiUrl}/schemas-tihtil`);
      setTihTil(response.data);
      response.data[0].forEach((item, index) => {
        tt1.push({ label: item.name, value: item.id });
      });
      response.data[1].forEach((item, index) => {
        tt2.push({ label: item.name, value: item.id });
      });
      setTihTil1(tt1);
      setTihTil2(tt2);
    };
    getTihTil();
  }, []);
  // const open2options = {
  //     TIH: ['TIH_20_high'],
  //     TIL: ['TIH_30_close'],
  // }
  const open2options = {
    TIH: tihTil[0],
    TIL: tihTil[1],
  };
  const open2optionsArr = [
    { label: "TIH", value: "TIH" },
    { label: "TIL", value: "TIL" },
  ];
  //белый цвет шрифта при поиске в Select
  const customStyles = {
    input: () => ({
      color: `${WV.colorSelectInput}`,
    }),
  };
  return (
    <>
      <div className="text-muted flex-grow-1">
        <Row>
          {/* {!open2.num && !open2.color && !open2.price && ( */}
          {((!open2.num && !open2.color && !open2.price) ||
            open2.item === "price") && (
            <Col xs={12} className={"mb-3"}>
              <h6 className="text-center">
                Свеча закрытия. Применяется к цене
              </h6>
              <div
                className="d-flex gap-2"
                style={{
                  width: "calc(100% - 23px)",
                }}
              >
                <Select
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: `${WV.colorHoverSelect}`,
                      primary: `${WV.colorSelected}`,
                    },
                  })}
                  placeholder="выбрать..."
                  isDisabled={open2.isDisabled}
                  onChange={(value) => {
                    setOpen2({
                      ...open2,
                      closing: value.value,
                      item: "price",
                      color: "",
                    });
                  }}
                  options={[
                    { label: "Открытия", value: "open" },
                    { label: "Закрытия", value: "close" },
                    { label: "Цена High", value: "high" },
                    { label: "Цена Low", value: "low" },
                  ]}
                  value={[
                    { label: "Открытия", value: "open" },
                    { label: "Закрытия", value: "close" },
                    { label: "Цена High", value: "high" },
                    { label: "Цена Low", value: "low" },
                  ].filter((opt, i) => opt.value === open2.closing)}
                  classNamePrefix="select2-selection"
                  className="flex-grow-1"
                />
                {!open2.isDisabled && (
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      setOpen2({ ...open2, closing: "", price: "" })
                    }
                  >
                    <i className="mdi mdi-cancel"></i>
                  </button>
                )}
              </div>
            </Col>
          )}
          {(!open2.closing || open2.item !== "price") && (
            <Row>
              <Col xs={12} lg={8} className={"mb-3"}>
                <div className="d-flex gap-2">
                  <div className="flex-grow-1">
                    <h6 className="text-center">Номер свечи</h6>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: `${WV.colorHoverSelect}`,
                          primary: `${WV.colorSelected}`,
                        },
                      })}
                      placeholder="выбрать..."
                      isDisabled={open2.isDisabled}
                      onChange={(value) => {
                        setOpen2({
                          ...open2,
                          num: value.value,
                          item: "noprice",
                        });
                      }}
                      options={[
                        { label: 1, value: 1 },
                        { label: 2, value: 2 },
                        { label: 3, value: 3 },
                        { label: 4, value: 4 },
                        { label: 5, value: 5 },
                        { label: 6, value: 6 },
                        { label: 7, value: 7 },
                        { label: 8, value: 8 },
                        { label: 9, value: 9 },
                      ]}
                      value={[
                        { label: 1, value: 1 },
                        { label: 2, value: 2 },
                        { label: 3, value: 3 },
                        { label: 4, value: 4 },
                        { label: 5, value: 5 },
                        { label: 6, value: 6 },
                        { label: 7, value: 7 },
                        { label: 8, value: 8 },
                        { label: 9, value: 9 },
                      ].filter((opt, i) => opt.value === open2.num)}
                      classNamePrefix="select2-selection"
                      className="flex-grow-1"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="text-center">Цвет свечи</h6>
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: `${WV.colorHoverSelect}`,
                          primary: `${WV.colorSelected}`,
                        },
                      })}
                      placeholder="выбрать..."
                      isDisabled={open2.isDisabled}
                      onChange={(value) => {
                        setOpen2({
                          ...open2,
                          color: value.value,
                          item: "noprice",
                        });
                      }}
                      options={[
                        { label: "Красная", value: "red" },
                        { label: "Зелёная", value: "green" },
                      ]}
                      value={[
                        { label: "Красная", value: "red" },
                        { label: "Зелёная", value: "green" },
                      ].filter((opt, i) => opt.value === open2.color)}
                      classNamePrefix="select2-selection"
                      className="flex-grow-1"
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={4}>
                <h6 className="text-center">Применяется к цене</h6>
                <div className=" d-flex gap-2">
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `${WV.colorHoverSelect}`,
                        primary: `${WV.colorSelected}`,
                      },
                    })}
                    placeholder="выбрать..."
                    isDisabled={open2.isDisabled}
                    onChange={(value) => {
                      setOpen2({
                        ...open2,
                        price: value.value,
                        item: "noprice",
                      });
                    }}
                    options={[
                      { label: "Открытия", value: "open" },
                      { label: "Закрытия", value: "close" },
                      { label: "Цена High", value: "high" },
                      { label: "Цена Low", value: "low" },
                    ]}
                    value={[
                      { label: "Открытия", value: "open" },
                      { label: "Закрытия", value: "close" },
                      { label: "Цена High", value: "high" },
                      { label: "Цена Low", value: "low" },
                    ].filter((opt, i) => opt.value === open2.price)}
                    classNamePrefix="select2-selection"
                    className="flex-grow-1"
                  />
                  {!open2.isDisabled && (
                    <button
                      className="btn btn-danger align-self-end"
                      onClick={() =>
                        setOpen2({ ...open2, num: "", color: "", price: "" })
                      }
                    >
                      <i className="mdi mdi-cancel"></i>
                    </button>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col xs={12} lg={8} className="mb-3">
              <div className="d-flex gap-2">
                <div className="flex-grow-1">
                  <h6 className="text-center">Индикатор TIH / TIL</h6>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `${WV.colorHoverSelect}`,
                        primary: `${WV.colorSelected}`,
                      },
                    })}
                    placeholder="выбрать..."
                    isDisabled={open2.isDisabled}
                    onChange={(value) => {
                      setOpen2({ ...open2, indicatorType: value.value });
                    }}
                    options={open2optionsArr}
                    value={open2optionsArr.filter(
                      (opt) => opt.value === open2.indicatorType
                    )}
                    classNamePrefix="select2-selection"
                    className="flex-grow-1"
                  />
                </div>
                <div className="flex-grow-1">
                  <h6 className="text-center">Выбрать Индикатор</h6>
                  {open2.indicatorType === "TIH" && (
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: `${WV.colorHoverSelect}`,
                          primary: `${WV.colorSelected}`,
                        },
                      })}
                      placeholder="выбрать..."
                      styles={customStyles}
                      isDisabled={open2.isDisabled}
                      onChange={(value) => {
                        setOpen2({ ...open2, indicator: value.value });
                      }}
                      options={tihTil1}
                      value={tihTil1.filter(
                        (opt) => opt.value === open2.indicator
                      )}
                      classNamePrefix="select2-selection"
                      className="flex-grow-1"
                    />
                  )}
                  {open2.indicatorType === "TIL" && (
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: `${WV.colorHoverSelect}`,
                          primary: `${WV.colorSelected}`,
                        },
                      })}
                      placeholder="выбрать..."
                      styles={customStyles}
                      isDisabled={open2.isDisabled}
                      onChange={(value) => {
                        setOpen2({ ...open2, indicator: value.value });
                      }}
                      options={tihTil2}
                      value={tihTil2.filter(
                        (opt) => opt.value === open2.indicator
                      )}
                      classNamePrefix="select2-selection"
                      className="flex-grow-1"
                    />
                  )}
                  {open2.indicatorType !== "TIL" &&
                    open2.indicatorType !== "TIH" && (
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: `${WV.colorHoverSelect}`,
                            primary: `${WV.colorSelected}`,
                          },
                        })}
                        placeholder="Сначала выберите тип"
                        classNamePrefix="select2-selection"
                        className="flex-grow-1"
                        isDisabled={true}
                      />
                    )}
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} className="mb-3">
              <h6 className="text-center">Процент</h6>
              <div className="d-flex gap-2 w-100">
                <InputSpinner
                  value={open2.percFrom}
                  name="stops"
                  type={"real"}
                  precision={1}
                  max={1000}
                  min={-1000}
                  step={0.1}
                  disabled={open2.isDisabled}
                  onChange={(num) => setOpen2({ ...open2, percFrom: num })}
                  placeholder="0"
                />
                <InputSpinner
                  value={open2.percTo}
                  name="stops"
                  type={"real"}
                  precision={1}
                  max={1000}
                  min={-1000}
                  step={0.1}
                  disabled={open2.isDisabled}
                  onChange={(num) => setOpen2({ ...open2, percTo: num })}
                  placeholder="0"
                />
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
};

export default Open2;
