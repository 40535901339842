import React from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"

const ActivityComp = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Развитие S&B Capital</CardTitle>
          <ul className="verti-timeline list-unstyled">
          <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <Media>
                <div className="me-3">
                  <h5 className="font-size-14">
                    2022 год{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div>Разработка новых торговых стратегий, реализ телеграм - бота
                  </div>
                </Media>
              </Media>
            </li>
          <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <Media>
                <div className="me-3">
                  <h5 className="font-size-14">
                    2021 год{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div>Привлечение дополнительного капитала, улучшение торгового бота
                  </div>
                </Media>
              </Media>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <Media>
              <div className="me-3">
                  <h5 className="font-size-14">
                    2020 год{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div>Окончание разработки MVP торгового бота, переход на полную автоматическую торговлю</div>
                </Media>
              </Media>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <Media>
                <div className="me-3">
                  <h5 className="font-size-14">
                    2019 год{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div id="activitytext">
                    Привлечение первого капитала, начало разработки автоматического торгового бота
                  </div>
                </Media>
              </Media>
            </li>           
            
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle font-size-18" />
              </div>
              <Media>
                <div className="me-3">
                  <h5 className="font-size-14">
                    2018 год{" "}
                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                  </h5>
                </div>
                <Media body>
                  <div>Создание фонда, ведение торговли в ручном режиме</div>
                </Media>
              </Media>
            </li>           
          </ul>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default ActivityComp;