import React from "react";

const TableSearchBar = (props) => {
    let input;
    const handleChange = () => {
      props.onSearch(input.value); //eslint-disable-line
    };
    return (
      <div>
        <label htmlFor="table-search-bar" className="w-100">
        <i class="bx bx-search-alt search-icon"></i>
            <input
            id="table-search-bar"
            className="form-control form-control-m "
            ref={n => input = n} //eslint-disable-line
            type="text"
            placeholder="Поиск..."
            onChange={handleChange}
          />
        </label>
      </div>
    );
  };



export default TableSearchBar;
