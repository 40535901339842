import MetaTags from "react-meta-tags";
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Input,
} from "reactstrap"
import { useHistory, useParams, Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useState, useEffect } from 'react'
import InputSpinner from "react-bootstrap-input-spinner";
import LookButtons from '../../../components/LookButtons'
import Select from "react-select"
import axios from "axios";
import config from "../../../config";
import WV from '../../../workVariables'

const InvestorsShow = (props) => {
    const { id } = useParams()
    const history = useHistory();
    const { addToast } = useToasts()

    const [form, setForm] = useState({       
    })
    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/ip-investors-show/${id}`)
                .then((response) => {
                    let fund = false;
                    let showFund = false;
                    let tbActive = false;
                    if (response.data.fund === 1) fund = true;
                    if (response.data.show_fund === 1) showFund = true;
                    if (response.data.tb_active === 1) tbActive = true;
                    setForm({
                        id: id,
                        username: response.data.username,
                        email: response.data.email,
                        tb_from_id: response.data.tb_from_id,
                        tb_first_name: response.data.tb_first_name,
                        tb_last_name: response.data.tb_last_name,
                        tb_username: response.data.tb_username,
                        name_admin: response.data.name_admin,
                        fund: fund,
                        show_fund: showFund,
                        tb_active: tbActive                       
                    })
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])
    console.log(form);
 
    return (
        <div className="page-content">
            <MetaTags>
                <title>Инвесторы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <Row>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className='mb-0'>Просмотр Инвестора #{id}</h4>
                                            <div>
                                                <Link to={`/ip-investors/edit/${id}`} className='btn btn-secondary me-2' title='Редактировать'>
                                                    <i className='mdi mdi-pencil' />
                                                    &nbsp;
                                                    Редактировать
                                                </Link>
                                                {/* <button className='btn btn-primary me-2' onClick={updateItem} title='Редактировать'>
                                                    Изменить
                                                </button>                                                 */}
                                                <button className='btn btn-danger' onClick={history.goBack} title='Вернуться назад'>
                                                    <i className='mdi mdi-backspace' />
                                                    &nbsp;
                                                    Вернуться
                                                </button>
                                            </div>
                                        </div>
                                    </Row>
                                </CardTitle>
                                <Row>
                                <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="">Имя для Администратора:</Label>
                                            <Input
                                                disabled={true}
                                                type="text"
                                                className="form-control"
                                                value={form.name_admin}
                                                onChange={(e) => setForm({ ...form, name_admin: e.target.value })}                                               
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="name">Email Инвестора:</Label>
                                            <Input
                                                disabled={true}
                                                type="text"
                                                className="form-control"
                                                placeholder='...'
                                                value={form.email}
                                                name="name"
                                            />
                                        </div>
                                    </Col>                                   
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="tb_from_id">ID Инвестора Telegram:</Label>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                className="form-control"
                                                placeholder='...'
                                                id="tb_from_id"
                                                value={form.tb_from_id}
                                                name="tb_from_id"
                                                // onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="tb_username">Ник Инвестора Telegram:</Label>
                                            <Input
                                                type="text"
                                                disabled={true}
                                                className="form-control"
                                                placeholder='...'
                                                id="tb_username"
                                                value={form.tb_username}
                                                name="tb_username"
                                                // onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="tb_first_name">Имя Инвестора Telegram:</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                                placeholder='...'
                                                id="tb_first_name"
                                                value={form.tb_first_name}
                                                name="tb_first_name"
                                                // onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} xl={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="tb_last_name">Фамилия Инвестора Telegram:</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                                placeholder='...'
                                                id="tb_last_name"
                                                value={form.tb_last_name}
                                                name="tb_last_name"
                                                // onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })}
                                            />
                                        </div>
                                    </Col>                                 
                                   

                                </Row>                               
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
               
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle className={'mb-3'}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h4 className='mb-0'>
                                            Настройки отображения
                                        </h4>
                                    </div>
                                </CardTitle>
                                <Row>
                                     <Col xs={12} md={12} lg={12}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mt-4'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                checked={form.fund}
                                            />
                                            Фонд
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mt-4'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                checked={form.show_fund}
                                            />
                                            Показывать Инвестору статистику фонда
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} lg={12}>
                                        <div className='d-flex align-items-center form-check form-switch form-switch-md mt-4'>
                                            <Input
                                                type="checkbox"
                                                className="form-check-input  me-2"
                                                checked={form.tb_active}
                                            />
                                            Активность для Telegram Bot
                                        </div>
                                    </Col>
                                </Row>                               
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default InvestorsShow