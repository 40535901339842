import React from "react";
import WF from '../workFunctions'

const CustomToggleList = ({ columns, onColumnToggle, toggles, name }) => (
  <div
    className="btn-group btn-group-toggle btn-group-sm"
    data-toggle="buttons"
  >
    {columns
      .map((column) => ({
        ...column,
        toggle: toggles[column.dataField],
      }))
      .map((column) => (
        <button
          type="button"
          key={column.dataField}
          className={`btn  ${column.toggle ? "btn-primary" : "btn-light"}`}
          data-toggle="button"
          aria-pressed={column.toggle ? "true" : "false"}
          onClick={() => {
            onColumnToggle(column.dataField)
            localStorage.setItem(name, JSON.stringify(toggles))
            WF.setTableSets()
          }}
        >
          {column.text}
        </button>
      ))}
  </div>
);

export default CustomToggleList;
