import React from "react";
import { Col, Row } from "reactstrap";
import Select from "react-select";
// import InputSpinner from "react-bootstrap-input-spinner";
import WV from "../../workVariables";
import selectOptions from "./selectOptions";
import FuckingSpinners2 from "./FuckingSpinners2";
// import FuckingSpinners from "./FuckingSpinners";

const Open1 = (props) => {
  const { open1, setOpen1 } = props;
  return (
    <>
      <div className="text-muted flex-grow-1">
        <Row>
          <Col
            xs={12}
            md={6}
            className={"col-lg mb-3"}
            style={{
              width: "29%",
            }}
          >
            <h6 className="text-center">Первая свеча</h6>
            <div className="d-flex gap-2">
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: `${WV.colorHoverSelect}`,
                    primary: `${WV.colorSelected}`,
                  },
                })}
                placeholder="выбрать..."
                isDisabled={open1.isDisabled}
                onChange={(value) => {
                  setOpen1({ ...open1, num1: value.value });
                }}
                options={selectOptions.candleNumber}
                value={selectOptions.candleNumber.filter(
                  (opt) => opt.value === open1.num1
                )}
                classNamePrefix="select2-selection"
                className="flex-grow-1"
              />
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: `${WV.colorHoverSelect}`,
                    primary: `${WV.colorSelected}`,
                  },
                })}
                placeholder="выбрать..."
                onChange={(value) => {
                  setOpen1({ ...open1, color1: value.value });
                }}
                options={selectOptions.colors}
                isDisabled={open1.isDisabled}
                value={selectOptions.colors.filter(
                  (opt) => opt.value === open1.color1
                )}
                classNamePrefix="select2-selection"
                className="flex-grow-1"
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={2}
            className={"mb-3"}
            style={{
              width: "13%",
            }}
          >
            <h6 className="text-center">Условие</h6>
            <div>
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: `${WV.colorHoverSelect}`,
                    primary: `${WV.colorSelected}`,
                  },
                })}
                placeholder="выбрать..."
                isDisabled={open1.isDisabled}
                onChange={(value) => {
                  setOpen1({ ...open1, condition: value.value });
                }}
                options={selectOptions.lessMore}
                value={selectOptions.lessMore.filter(
                  (opt) => opt.value === open1.condition
                )}
                classNamePrefix="select2-selection"
                className="flex-grow-1"
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className={"col-lg mb-3"}
            style={{
              width: "29%",
            }}
          >
            <h6 className="text-center">Вторая свеча</h6>
            <div className="d-flex gap-2">
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: `${WV.colorHoverSelect}`,
                    primary: `${WV.colorSelected}`,
                  },
                })}
                placeholder="выбрать..."
                isDisabled={open1.isDisabled}
                onChange={(value) => {
                  setOpen1({ ...open1, num2: value.value });
                }}
                options={selectOptions.candleNumber}
                value={selectOptions.candleNumber.filter(
                  (opt) => opt.value === open1.num2
                )}
                classNamePrefix="select2-selection"
                className="flex-grow-1"
              />
              <Select
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: `${WV.colorHoverSelect}`,
                    primary: `${WV.colorSelected}`,
                  },
                })}
                placeholder="выбрать..."
                isDisabled={open1.isDisabled}
                onChange={(value) => {
                  setOpen1({ ...open1, color2: value.value });
                }}
                options={selectOptions.colors}
                value={selectOptions.colors.filter(
                  (opt) => opt.value === open1.color2
                )}
                classNamePrefix="select2-selection"
                className="flex-grow-1"
              />
            </div>
          </Col>
          <Col
            xs={12}
            md={6}
            className={"col-lg mb-3"}
            style={{
              width: "29%",
            }}
          >
            <h6 className="text-center">Процент</h6>
            {/* <FuckingSpinners open1={open1} setOpen1={setOpen1} /> */}
            <FuckingSpinners2 open1={open1} setOpen1={setOpen1} />
            {/* <div className="d-flex gap-2">
              <InputSpinner
                value={open1.percFrom}
                name="stops"
                type={"real"}
                precision={1}
                max={1000}
                min={-1000}
                step={0.1}
                disabled={open1.isDisabled}
                onChange={(num) => setOpen1({ ...open1, percFrom: num })}
                placeholder="0"
              />
              <InputSpinner
                value={open1.percTo}
                name="stops"
                type={"real"}
                precision={1}
                max={1000}
                min={-1000}
                step={0.1}
                disabled={open1.isDisabled}
                onChange={(num) => setOpen1({ ...open1, percTo: num })}
                placeholder="0"
              />
            </div> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Open1;
