import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import axios from "axios";
import config from "../../../config";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import WV from '../../../workVariables'
import ModalConfirm from "../../../components/ModalConfirm";
import PaginationProviderCustom from "../../../components/PaginationProviderCustom";

const Investors = () => {
    const { addToast } = useToasts()
    const [items, setItems] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataExcel, setTableDataExcel] = useState([])
    const columns = [
        {
            dataField: 'id',
            text: <span className='text'>Id</span>,
            sort: true,
            style: {
                width: 11,
            },
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'name_admin',
            text: 'Имя',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'email_post',
            text: 'Email для рассылки',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'tb_first_name',
            text: 'TG Имя',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'tb_last_name',
            text: 'TG Фамилия',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'tb_username',
            text: 'TG Ник',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'tb_from_id',
            text: 'TG ID',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'tb_active',
            text: 'Активность',
            sort: true,
            headerClasses: 'table-th-nowrap'
        },
        {
            dataField: 'show_fund',
            text: <span className='text'>Статистика Фонда</span>,
            sort: true,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'fund',
            text: <span className='text'>Тип пользователя</span>,
            sort: true,
            headerClasses: 'table-th-100'
        },
        {
            dataField: 'actions',
            text: 'Действия',
            sort: false,
            headerClasses: 'py-20',
            style: {
                width: 111,
            }
        },
    ];

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
    }];

    // Модалка удаления
    const [modalConfirm, setModalConfirm] = useState(false)
    const [modalBlockConfirm, setModalBlockConfirm] = useState(false)
    const [modalShowFund, setModalShowFund] = useState(false)

    const [deleteId, setDeleteId] = useState(null)
    const [blockInfo, setBlockInfo] = useState({
        id: null,
        action: null
    })
    const [showFundStatus, setShowFundStatus] = useState(null)

    const toggleModalConfirm = () => {
        setModalConfirm(!modalConfirm)
    }
    const toggleModalBlockConfirm = () => {
        setModalBlockConfirm(!modalBlockConfirm)
    }
    const toggleModalShowFund = () => {
        setModalShowFund(!modalShowFund)
    }

    // Ф-я Удалить пользователя
    const removeItem = (id) => {
        axios.get(`${config.apiUrl}/ip-investors-delete/${blockInfo.id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                if (response.data.res == "Success") {
                    setItems(items.filter(item => item.id !== blockInfo.id))
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    // Ф-я Блокирование пользователя
    const blocking = (id, action) => {
        let urlAction
        switch (action) {
            case 0:
                urlAction = 'block'
                break;
            case 1:
                urlAction = 'unblock'
                break;
            default:
                break;
        }
        axios.get(`${config.apiUrl}/telegram-bot/${urlAction}/${id}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                axios.get(`${config.apiUrl}/ip-investors-index`)
                    .then((response) => {
                        setItems(response.data)
                        addToast(response.data.msg, {
                            appearance: response.data.res.toLowerCase(),
                            autoDismiss: true,
                            autoDismissTimeout: 7500,
                            transitionDuration: 2000
                        })
                    })
                    .catch(error => {
                        console.error(error);
                    })
            })
            .catch(error => {
                console.error(error);
            })
    }

    const ActionButtons = (props) => {
        return (
            <div className='d-flex gap-2'>
                <Link to={`ip-investors/show/${props.id}`} className='btn btn-secondary' title='Просмотр'>
                    <i class="far fa-eye"></i>
                </Link>
                <Link to={`ip-investors/edit/${props.id}`} className='btn btn-primary' title='Редактировать'>
                    <i className='mdi mdi-pencil' />
                </Link>
                {props.tb_active === 0 &&
                    <>
                        <div className='d-flex gap-2'>
                            <button onClick={() => {
                                setModalBlockConfirm(true)
                                setBlockInfo({ id: props.id, action: 1 })
                            }}
                                className='btn btn-success'
                                title='Разблокировать для Telegram Bot'>
                                <i class="fas fa-lock-open"></i>
                            </button>
                        </div>
                    </>
                }
                {props.tb_active === 1 &&
                    <>
                        <div className='d-flex gap-2'>
                            <button onClick={() => {
                                setModalBlockConfirm(true)
                                setBlockInfo({ id: props.id, action: 0 })
                            }}
                                className='btn btn-danger'
                                title='Заблокировать для Telegram Bot'>
                                <i class="fas fa-lock"></i>
                            </button>
                        </div>

                    </>
                }
                <div className='d-flex gap-2'>
                    <button onClick={() => {
                        setModalConfirm(true)
                        setBlockInfo({ id: props.id, action: 0 })
                    }}
                        className='btn btn-danger'
                        title='Удалить Пользователя'>
                        <i className='mdi mdi-delete' />
                    </button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        const getData = () => {
            axios.get(`${config.apiUrl}/ip-investors-index`)
                .then((response) => {
                    setItems(response.data)
                })
                .catch(error => {
                    console.error(error);
                })
        }
        getData()
    }, [])

    useEffect(() => {
        let tableData = []
        if (items.length > 0) {
            items.forEach(item => {
                if (item.show_fund === 0) {
                    item.show_fund_tb = 'нет'
                    item.show_fund =
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-times"></i>    </div>
                }
                if (item.show_fund === 1) {
                    item.show_fund_tb = 'да'
                    item.show_fund =
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-check"></i>
                        </div>
                }
                if (item.tb_active === 0) {
                    item.tb_active_tb = 'Подал заявку'
                    // item.tb_active = "❌ Подал заявку"
                    item.tb_active = "Подал заявку"
                    item.tb_active_code = 0
                }
                if (item.tb_active === 1) {
                    item.tb_active_tb = 'Активен'
                    // item.tb_active = "✅ Активен"
                    item.tb_active = <div class="d-flex justify-content-center">
                        <i class="fas fa-check"></i>
                    </div>
                    item.tb_active_code = 1
                }
                if (item.tb_active === 2) {
                    item.tb_active_tb = 'Заблокирован'
                    // item.tb_active = "❌ Заблокирован"
                    item.tb_active = <div class="d-flex justify-content-center">
                        <i class="fas fa-times"></i>
                    </div>
                    item.tb_active_code = 0
                }
                if (item.fund === 1) {
                    item.fund_tb = 'Фонд';
                    item.fund = <b>Фонд</b>
                } else {
                    item.fund_tb = 'Инвестор';
                    item.fund = "Инвестор"
                }

                tableData.push({
                    ...item,
                    "actions": (<ActionButtons id={item.id} tb_active={item.tb_active_code} />)
                })
            })
        }
        setTableData(tableData)
        let tableDataExcel = []
        let itemObject = {};
        if (items.length > 0) {
            items.forEach(item => {
                itemObject['Id '] = item.id;
                itemObject['Имя'] = item.name_admin;
                itemObject['Email'] = item.email;
                itemObject['Email для рассылки'] = item.email_post;
                itemObject['TG Имя'] = item.tb_first_name;
                itemObject['TG Фамилия'] = item.tb_last_name;
                itemObject['TG Ник'] = item.tb_username;
                itemObject['TG ID'] = item.tb_from_id;
                itemObject['Активность'] = item.tb_active_tb;
                itemObject['Стат-ка Фонда'] = item.show_fund_tb;
                itemObject['Тип пользователя'] = item.fund_tb;
                tableDataExcel.push(itemObject)
                itemObject = {}
            })
        }
        setTableDataExcel(tableDataExcel)
    }, [items])

    const showFund = (action) => {
        axios.get(`${config.apiUrl}/ip-investors-showfund/${action}`)
            .then((response) => {
                addToast(response.data.msg, {
                    appearance: response.data.res.toLowerCase(),
                    autoDismiss: true,
                    autoDismissTimeout: 7500,
                    transitionDuration: 2000
                })
                axios.get(`${config.apiUrl}/ip-investors-index`)
                    .then((response) => {
                        setItems(response.data)
                    })
                    .catch(error => {
                        console.error(error);
                    })
            })
            .catch(error => {
                console.error(error);
            })
    }


    return (
        <div className="page-content">
            <MetaTags>
                <title>Инвесторы | TradeBot</title>
            </MetaTags>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                                        <h4>Инвесторы</h4>
                                        <div>
                                            <button class="btn btn-primary btn m-2" onClick={() => {
                                                setModalShowFund(true)
                                                setShowFundStatus(1)
                                            }}
                                                title="Включить отображение статистики Фонда в Telegram Bot для всех Инвесторов">
                                                <i class="far fa-eye" style={{ marginRight: 10 }}></i>
                                                Вкл для всех
                                            </button>
                                            <button class="btn btn-danger btn m-2" onClick={() => {
                                                setModalShowFund(true)
                                                setShowFundStatus(0)
                                            }}
                                                title="Выключить отображение статистики Фонда в Telegram Bot для всех Инвесторов">
                                                <i class="far fa-eye-slash" style={{ marginRight: 10 }}></i>
                                                Выкл для всех
                                            </button>
                                        </div>
                                    </div>
                                </CardTitle>
                                <PaginationProviderCustom
                                    columns={columns} tableData={tableData} nameTable="tableSet_investors"
                                    tableDataExcel={tableDataExcel}
                                    defaultSorted={defaultSorted}
                                    nameExcel={'Investors.xlsx'}
                                    nameList={'Investors'} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalConfirm
                modalConfirm={modalConfirm}
                toggleModalConfirm={toggleModalConfirm}
                removeItem={removeItem}
                deleteId={deleteId}
                lebel='Удаление Пользователя'
            />

            <Modal isOpen={modalBlockConfirm} toggle={toggleModalBlockConfirm} size='xs'>
                <ModalHeader>
                    Блокировка Пользователей
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {blockInfo.action === 0 &&
                                WV.textConfirmBlock
                            }
                            {blockInfo.action === 1 &&
                                WV.textConfirmUnblock
                            }
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModalBlockConfirm()
                            blocking(blockInfo.id, blockInfo.action)
                        }}>
                        Подтвердить
                    </Button>
                    <Button color="danger" onClick={toggleModalBlockConfirm}>Отмена</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalShowFund} toggle={toggleModalShowFund} size='xs'>
                <ModalHeader>
                    Отображение статистики фонда
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            {showFundStatus === 0 &&
                                WV.textConfirmHideFund
                            }
                            {showFundStatus === 1 &&
                                WV.textConfirmShowFund
                            }
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary"
                        onClick={() => {
                            toggleModalShowFund()
                            showFund(showFundStatus)
                        }}>
                        Подтвердить
                    </Button>
                    <Button color="danger" onClick={toggleModalShowFund}>Отмена</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default Investors